import React from 'react';
import {Tooltip} from 'react-bootstrap';
import {toAbsoluteUrl} from '../../../_helpers';
import SVG from 'react-inlinesvg';
import {OverlayTrigger} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const AsideElement = ({
  handleTabChange,
  activeTab,
  titleTooltip,
  tabID,
  icon,
  submenu,
  href,
  title,
  notification,
  submenu: sub,
}) => {
  return (
    <li
      className="nav-item mb-3"
      data-toggle="tooltip"
      data-placement="rigth"
      data-container="body"
      data-boundary="window"
      title={titleTooltip}
    >
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="metronic-features">{titleTooltip}</Tooltip>}
      >
        <Link
          to={href || sub[0].href || ''}
          style={{cursor: 'pointer'}}
          className={`nav-link btn btn-icon btn-clean btn-lg ${
            activeTab.tabID === tabID && 'active'
          }`}
          data-toggle="tab"
          data-target={`#${tabID}`}
          onClick={() =>
            handleTabChange({
              tabID: tabID,
              submenu: submenu,
              titleTooltip: titleTooltip,
              icon: icon,
              title: title,
            })
          }
          role="tab"
        >
          <span className="svg-icon svg-icon-lg">
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
          {notification && <span class="label label-primary label-dot ml-1"></span>}
        </Link>
      </OverlayTrigger>
    </li>
  );
};

export default AsideElement;
