import React, {useEffect, useState} from 'react';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Edit from '@material-ui/icons/Edit';
import Trash from '@material-ui/icons/Delete';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import FilePicker from '../../VisitSheet/FilePicker';

const Image = ({
  data,
  edit,
  parentClasses,
  label,
  required,
  labelSuffix,
  classes,
  disabled,
  activeContent,
  setContent,
  setReady,
  handlerSetLayout,
  rank,
  setNewModule,
}) => {
  const [urls, setUrls] = useState(
    data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
  );
  const [modify, setModify] = useState(!data);

  useEffect(() => {
    setUrls(
      data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
    );
  }, [data]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleDelete = () => {
    const layout = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
    delete layout[rank];
    handlerSetLayout(layout);
    setModify(false);
    setReady(true);
  };

  return edit ? (
    <div className={'form-group fv-plugins-icon-container ' + parentClasses}>
      {label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {label}
            {!modify && (
              <>
                <Edit
                  fontSize="medium"
                  className="mx-5"
                  onClick={() => setModify(true)}
                />
                <Trash fontSize="medium" htmlColor="red" onClick={handleDelete} />
              </>
            )}
          </label>
          {labelSuffix}
        </div>
      )}
      <div className="w-100 d-flex align-items-center">
        {modify ? (
          <FilePicker
            accept={'image/*'}
            multiple={true}
            rank={rank}
            files={activeContent.files}
            setReady={setReady}
            setContent={setContent}
            activeContent={activeContent}
            handlerSetLayout={handlerSetLayout}
            layout_field={'image'}
            initialState={data?.filter((el) => el.id).map((el) => el.blob_id) || []}
            setOpenFilePicker={() => setModify(false)}
          />
        ) : (
          <div className="w-100" style={{height: 300}}>
            <Slider {...settings}>
              {urls.map((el, index) => {
                return (
                  <div key={index} className="w-100 h-100">
                    <img
                      src={el}
                      alt={el}
                      style={{width: '100%', height: 300, objectFit: 'cover'}}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        )}
        <div className="d-flex align-items-center px-2" style={{gap: 10}}>
          {modify && (
            <>
              <CheckCircleOutline color="primary" fontSize="large" />
              <HighlightOff
                color="error"
                fontSize="large"
                onClick={() => {
                  setNewModule();
                  setModify(false);
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="w-100 my-10" style={{height: 300}}>
      <Slider {...settings}>
        {urls.map((el, index) => {
          return (
            <div key={index} className="w-100 h-100">
              <img
                src={el}
                alt={el}
                style={{width: '100%', height: 300, objectFit: 'cover'}}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Image;
