import React from 'react';
import {capitalize} from 'lodash';

const DAYS = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
const Scheduler = ({selectedCTS, setSelectedCTS}) => {
  return (
    <div className="my-3 d-flex flex-column w-100">
      {DAYS.map((el, i) => (
        <div className="my-2" key={el}>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              name={el}
              checked={!!selectedCTS.schedule[el]}
              onChange={(e) => {
                let tmp = {...selectedCTS.schedule};
                if (e.target.checked) {
                  tmp[el] = {};
                } else {
                  delete tmp[el];
                }
                setSelectedCTS((state) => {
                  return {
                    ...state,
                    schedule: {...tmp},
                  };
                });
              }}
            />
            <label className="p-0 m-0 ml-2" htmlFor={el}>
              {capitalize(el)}
            </label>
          </div>
          {selectedCTS.schedule[el] && (
            <div className="w-100">
              <div className="d-flex align-items-center ml-2 mt-1">
                <input
                  type="checkbox"
                  name={'closed_noon'}
                  checked={!!selectedCTS.schedule[el].closed_noon}
                  onChange={(e) => {
                    let tmp = {...selectedCTS.schedule[el]};
                    if (e.target.checked) {
                      tmp = {...tmp, closed_noon: true};
                    } else {
                      delete tmp.closed_noon;
                    }
                    setSelectedCTS((state) => {
                      return {
                        ...state,
                        schedule: {...state.schedule, [el]: tmp},
                      };
                    });
                  }}
                />
                <label className="p-0 m-0 ml-2" htmlFor={'closed_noon'}>
                  Fermé à midi
                </label>
              </div>
              <div className="ml-4 row w-100">
                <div className="d-flex flex-column align-items-start justify-content-between col-6 mt-1">
                  <label className="w-100" htmlFor={'open_morning'}>{`Heure d'ouverture${
                    selectedCTS.schedule[el]?.closed_noon ? ' le matin' : ''
                  }`}</label>
                  <input
                    className="w-100"
                    type="text"
                    name={'open_morning'}
                    value={selectedCTS.schedule[el].open_morning || ''}
                    onChange={(e) =>
                      setSelectedCTS((state) => {
                        return {
                          ...state,
                          schedule: {
                            ...state.schedule,
                            [el]: {
                              ...state.schedule[el],
                              open_morning: e.target.value,
                            },
                          },
                        };
                      })
                    }
                    placeholder="00:00"
                  />
                </div>
                {selectedCTS.schedule[el] && selectedCTS.schedule[el]?.closed_noon && (
                  <>
                    <div className="d-flex flex-column align-items-start justify-content-between col-6 mt-1">
                      <label className="w-100" htmlFor={'close_morning'}>
                        Heure de fermeture le midi
                      </label>
                      <input
                        className="w-100"
                        type="text"
                        name={'close_morning'}
                        value={selectedCTS.schedule[el].close_morning || ''}
                        onChange={(e) =>
                          setSelectedCTS((state) => {
                            return {
                              ...state,
                              schedule: {
                                ...state.schedule,
                                [el]: {
                                  ...state.schedule[el],
                                  close_morning: e.target.value,
                                },
                              },
                            };
                          })
                        }
                        placeholder="00:00"
                      />
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-between col-6 mt-1">
                      <label className="w-100" htmlFor={'open_afternoon'}>
                        Heure d'ouverture l'après-midi
                      </label>
                      <input
                        className="w-100"
                        type="text"
                        name={'open_afternoon'}
                        value={selectedCTS.schedule[el].open_afternoon || ''}
                        onChange={(e) =>
                          setSelectedCTS((state) => {
                            return {
                              ...state,
                              schedule: {
                                ...state.schedule,
                                [el]: {
                                  ...state.schedule[el],
                                  open_afternoon: e.target.value,
                                },
                              },
                            };
                          })
                        }
                        placeholder="00:00"
                      />
                    </div>
                  </>
                )}
                <div className="d-flex flex-column align-items-start justify-content-between col-6 mt-1">
                  <label className="w-100" htmlFor={'close_night'}>{`Heure de fermeture${
                    selectedCTS.schedule[el]?.closed_noon ? ' le soir' : ''
                  }`}</label>
                  <input
                    className="w-100"
                    type="text"
                    name={'close_night'}
                    value={selectedCTS.schedule[el].close_night || ''}
                    onChange={(e) =>
                      setSelectedCTS((state) => {
                        return {
                          ...state,
                          schedule: {
                            ...state.schedule,
                            [el]: {
                              ...state.schedule[el],
                              close_night: e.target.value,
                            },
                          },
                        };
                      })
                    }
                    placeholder="00:00"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Scheduler;
