import React, {useEffect, useState} from 'react';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import CreateExperience from '../ExperienceCreate/CreateExperience';
import {Modal} from 'react-bootstrap';
import {API_GET_ONE_EXPERIENCE} from '../../../constants/api';

const ExperienceEdit = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [experience, setExperience] = useState(null);

  useEffect(() => {
    if (show) {
      handleRequest('get', API_GET_ONE_EXPERIENCE(id), {}, config, (data, status) => {
        if (status === 'Done') {
          const cts = [];
          data.trip_days.forEach((el) => cts.push(...el.touristic_sites));

          const order = [];
          data.trip_days.forEach((el) => {
            el.touristic_sites.forEach((i, index) =>
              order.push({...i, day_number: el.day_number, rank: index + 1})
            );
          });

          const trip_days = data.trip_days.map((el) => {
            return {
              ...el,
              image: el.picture,
            };
          });

          const parallaxes = data.parallaxes.map((el) => {
            const tmp = {
              ...el,
              image: el.file_url,
            };
            delete tmp['file_url'];
            return tmp;
          });
          const res = {
            id: data.id,
            title: data.title,
            description: data.description,
            tips: data.road_trip_tips,
            image: data.picture,
            amount: data.amount / 100,
            currency: data.currency,
            place: JSON.parse(data.place),
            country: data.country,
            cts: Object.values(cts.reduce((acc, obj) => ({...acc, [obj.id]: obj}), {})),
            order: order,
            trip_days: trip_days,
            parallaxes: parallaxes,
            published: data.published,
          };
          setExperience(res);
        } else if (status === 'Error') {
          console.error(data);
          onHide();
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setExperience(null);
    onHide();
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName="create-modal"
    >
      {experience && (
        <CreateExperience exp={experience} onHide={handleHide} refresh={refresh} />
      )}
    </Modal>
  );
};

export default ExperienceEdit;
