import React, {useEffect, useState} from 'react';
import {Card, Modal} from 'react-bootstrap';
import {Form, Formik} from 'formik';
import ReviewsTSInitialValues from './ReviewsTSFormik/ReviewsTSInitialValues';
import ReviewsTSValidationSchema from './ReviewsTSFormik/ReviewsTSValidationSchema';
import ReviewsTSForm from './ReviewsTSForm';
import {useSelector} from 'react-redux';
import useRequest from '../../../_metronic/_helpers/useRequest';
import useConfig from '../../../_metronic/_helpers/useConfig';
import {API_GET_ONE_REVIEW, API_UPDATE_REVIEW} from '../../constants/api';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FeedbackNotation from '../../../_metronic/layout/components/feedback/feedback-notation';
import MyTextArea from '../../../_metronic/layout/components/form/textarea/textarea';
import FileInputAsync from '../../../_metronic/layout/components/form/fileInput/fileInputAsync';

const {
  formField: {note, content, answer},
} = ReviewsTSForm;

const ReviewsTSEdit = ({road_trips, refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const {is_admin} = useSelector((state) => state.auth);
  const [review, setReview] = useState(null);

  useEffect(() => {
    if (show) {
      handleRequest(
        'get',
        road_trips ? API_GET_ONE_REVIEW(id, 'rt') : API_GET_ONE_REVIEW(id, 'ts'),
        {},
        config,
        (data, status) => {
          if (status === 'Done') {
            setReview({
              ...data,
              answer: data.answer || '',
            });
          } else if (status === 'Error') {
            console.log(data);
            onHide();
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setReview(undefined);
    onHide();
  };

  const handleSubmit = (values, formikBag) => {
    const data = {note: values.note, content: values.content, answer: values.answer};
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    });
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Chargement',
      message: 'Enregistrer modifications en cours ...',
      autoClose: false,
      disallowClose: true,
    });
    handleRequest(
      'put',
      road_trips
        ? API_UPDATE_REVIEW(review.id, 'rt')
        : API_UPDATE_REVIEW(review.id, 'ts'),
      data,
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          updateNotification({
            id: 'load-data',
            color: 'teal',
            title: 'OK',
            message: 'Les modifications sont Enregistrées',
            icon: <CheckIcon />,
            autoClose: 4000,
          });
          refresh.current && refresh.current.onQueryChange();
          handleHide();
        } else if (status === 'Error') {
          const msg = {
            code: dataRes?.status,
            msg: dataRes?.text,
          };
          formikBag.setStatus(msg);
          updateNotification({
            id: 'load-data',
            color: 'red',
            title: 'Erreur',
            message: "Une erreur s'est produite",
            icon: <CloseIcon />,
            autoClose: 4000,
            loading: false,
          });
        }
      }
    );
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {review && (
        <Card className="rounded-lg shadow-sm card-custom card-transparent">
          <div className="card-body p-0">
            <div
              className="wizard wizard-4"
              id="kt_wizard"
              data-wizard-state="first"
              data-wizard-clickable="true"
            >
              <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <Formik
                      initialValues={review || ReviewsTSInitialValues}
                      validationSchema={ReviewsTSValidationSchema[0]}
                      validateOnBlur={false}
                      validateOnChange={false}
                      validateOnMount={false}
                      onSubmit={handleSubmit}
                    >
                      {({handleSubmit, ...subProps}) => (
                        <div className="col-xl-12 col-xxl-10">
                          <Form
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                            id="kt_form"
                          >
                            <div className="row justify-content-center">
                              <div className="col-xl-9">
                                {subProps.status && (
                                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                      Une erreur de type {subProps.status.code} est
                                      survenue...
                                      <p>Message: {subProps.status.msg}</p>
                                    </div>
                                  </div>
                                )}
                                {!subProps.isValid && subProps.submitCount > 0 && (
                                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                      Vérifiez que toutes les données entrées sont
                                      justes...
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="my-5 step"
                                  data-wizard-type="step-content"
                                  data-wizard-state="current"
                                >
                                  <h5 className="text-dark font-weight-bold mb-10">
                                    Avis
                                  </h5>
                                </div>
                                <h6 className="font-size-h6 text-dark pt-5">
                                  Informations de la commentaire
                                </h6>
                                {road_trips ? (
                                  <p>
                                    Road Trip: {review.road_trip.id}-
                                    {review.road_trip.title}
                                  </p>
                                ) : (
                                  <p>
                                    Site Touristique: {review.touristic_site.id}-
                                    {review.touristic_site.title_fr}
                                  </p>
                                )}

                                <p>
                                  Par {review.username || review.user?.username || ''} le{' '}
                                  {new Date(review.created_at).toLocaleDateString()}
                                </p>
                                <FeedbackNotation
                                  {...subProps}
                                  {...note}
                                  disabled={!is_admin}
                                />
                                <FileInputAsync
                                  {...subProps}
                                  name={'images'}
                                  label={'Images'}
                                  field={'images[]'}
                                  multiple={true}
                                  accept={'image/*'}
                                  disabled={!is_admin}
                                  api_upload={
                                    road_trips
                                      ? `/api/v1/pro/reviews_rt/${id}/upload`
                                      : `/api/v1/pro/reviews_ts/${id}/upload`
                                  }
                                  api_delete={
                                    road_trips
                                      ? `/api/v1/pro/reviews_rt/${id}/image`
                                      : `/api/v1/pro/reviews_ts/${id}/image`
                                  }
                                />
                                <MyTextArea
                                  {...subProps}
                                  {...content}
                                  disabled={!is_admin}
                                />
                                <MyTextArea {...subProps} {...answer} />
                                <div className="d-flex justify-content-between border-top pt-10 mt-15">
                                  <div className="mr-2"></div>
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn btn-primary font-weight-bolder px-9 py-4"
                                    >
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default ReviewsTSEdit;
