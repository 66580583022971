export const HOME_ROUTE = '/dashboard';

export const ERROR_ROUTE = '/error/no-access';

// TICKETS

export const TICKETS_ROUTE = '/tickets';

export const TICKET_CREATE = '/ticket/create';

export const TICKET_EDIT = (id) => TICKETS_ROUTE + `/${id}/edit`;

export const TICKET_DELETE = (id) => TICKETS_ROUTE + `/${id}/delete`;

export const TICKET_CALENDAR = '/ticket/calendar';

// TOURISTIC_SITES

export const TOURISTIC_SITES = '/touristic-sites';

export const TOURISTIC_SITE_CREATE = '/touristic-site/create';

export const TOURISTIC_SITE_VIEW = (id) => TOURISTIC_SITES + `/${id}/view`;

export const TOURISTIC_SITE_EDIT = (id) => TOURISTIC_SITES + `/${id}/edit`;

export const TOURISTIC_SITE_DELETE = (id) => TOURISTIC_SITES + `/${id}/delete`;

export const TOURISTIC_SITE_PUBLISH = (id) => TOURISTIC_SITES + `/${id}/publish`;

export const TOURISTIC_SITE_STATS = (id) => TOURISTIC_SITES + `/${id}/stats`;

// EVENTS

export const EVENTS = '/events';

export const EVENT_CREATE = '/event/create';

export const EVENT_DELETE = (touristicSiteID, id) =>
  EVENTS + `/${touristicSiteID}/${id}/delete`;

export const EVENT_EDIT = (touristicSiteID, id) =>
  EVENTS + `/${touristicSiteID}/${id}/edit`;

// ACTIVITIES

export const ACTIVITIES = '/activities';

export const ACTIVITY_CREATE = '/activity/create';

export const ACTIVITY_VIEW = (id) => ACTIVITIES + `/${id}/view`;

export const ACTIVITY_EDIT = (id) => ACTIVITIES + `/${id}/edit`;

export const ACTIVITY_DELETE = (id) => ACTIVITIES + `/${id}/delete`;

// CHAT

export const CHAT_CONVERSATION = (ts_id, id) => `/conversation/${ts_id}/${id}`;

export const CHAT_CONVERSATIONS = '/conversations';

// USERS

export const USERS = '/users';

export const ADMINS = '/admins';

export const SUPER_MANAGERS = '/super_managers';

export const MANAGERS = '/managers';

export const MANAGER_INVITE = '/manager/invite';

export const USER_EDIT = (id) => USERS + `/${id}/edit`;

export const USER_DELETE = (id) => USERS + `/${id}/delete`;

export const ADMIN_EDIT = (id) => ADMINS + `/${id}/edit`;

export const ADMIN_DELETE = (id) => ADMINS + `/${id}/delete`;

export const SUPER_MANAGER_EDIT = (id) => SUPER_MANAGERS + `/${id}/edit`;

export const SUPER_MANAGER_DELETE = (id) => SUPER_MANAGERS + `/${id}/delete`;

export const MANAGER_EDIT = (id) => MANAGERS + `/${id}/edit`;

export const MANAGER_DELETE = (id) => MANAGERS + `/${id}/delete`;

//AGENCIES

export const AGENCIES = '/agencies';

export const AGENCY_CREATE = '/agency/create';

export const AGENCY_EDIT = (id) => AGENCIES + `/${id}/edit`;

export const AGENCY_DELETE = (id) => AGENCIES + `/${id}/delete`;

// ROAD_TRIPS

export const ROAD_TRIPS = '/road-trips';

export const TRIPS_CREATE = '/road-trip/create-trip';

export const EXPERIENCE_CREATE = '/road-trip/create-experience';

export const ROAD_TRIPS_EDIT = (id) => ROAD_TRIPS + `/${id}/edit`;

export const ROAD_TRIPS_DUPLICATE = (id) => ROAD_TRIPS + `/${id}/copy`;

export const ROAD_TRIPS_DELETE = (id) => ROAD_TRIPS + `/${id}/delete`;

export const ROAD_TRIPS_STATS = (id) => ROAD_TRIPS + `/${id}/stats`;

// EXPERIENCES

export const EXPERIENCES = '/experiences';

export const EXPERIENCES_EDIT = (id) => EXPERIENCES + `/${id}/edit`;

export const EXPERIENCES_VIEW = (id) => EXPERIENCES + `/${id}/view`;

export const EXPERIENCES_DELETE = (id) => EXPERIENCES + `/${id}/delete`;

export const EXPERIENCES_DUPLICATE = (id) => EXPERIENCES + `/${id}/duplicate`;
// SUGGESTIONS

export const SUGGESTIONS = '/suggestions';

export const SUGGESTION_DETAILS = (id) => SUGGESTIONS + `/${id}/details`;

export const SUGGESTION_DELETE = (id) => SUGGESTIONS + `/${id}/delete`;

// REVIEWS

export const REVIEWS = '/reviews';

export const REVIEWS_EDIT = (id) => REVIEWS + `/${id}/edit`;

export const REVIEWS_DELETE = (id) => REVIEWS + `/${id}/delete`;

export const REVIEWS_RT = '/rt_reviews';

export const REVIEWS_RT_EDIT = (id) => REVIEWS_RT + `/${id}/edit`;

export const REVIEWS_RT_DELETE = (id) => REVIEWS_RT + `/${id}/delete`;
