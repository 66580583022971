import React from 'react';
import {FormGroup} from '@material-ui/core';
import MyCheckbox from '../../../../../_metronic/layout/components/form/checkbox/checkbox';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import ManagerForm from '../../ManagerForm';
import {FieldArray} from 'formik';

const {
  formField: {is_super_manager, touristic_sites},
} = ManagerForm;

const CreateManagerTouristicSites = ({formik, title, ...props}) => {
  const {touristicsites: touristicSites} = useSelector((state) => state.touristicSites);

  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
      </div>
      <MyCheckbox {...props} {...is_super_manager} />
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">
          Sites touristiques dont il a accès:
        </h5>
      </div>
      {props.values[is_super_manager.name] ? (
        <p className="font-size-h6">
          Un super manager a accès à tous les sites touristiques.
        </p>
      ) : !touristicSites?.length ? (
        <div>
          <p>Vous n'avez pas encore ajouté de sites touristiques.</p>
          <Link to="/site-touristique/create">Ajouter un site touristique</Link>
        </div>
      ) : (
        <FormGroup>
          <FieldArray
            name={touristic_sites.name}
            render={(arrayHelpers) =>
              touristicSites.map((el) => (
                <MyCheckbox
                  key={el.id}
                  name={touristic_sites.name}
                  el={el}
                  arrayHelpers={arrayHelpers}
                  {...props}
                ></MyCheckbox>
              ))
            }
          ></FieldArray>
        </FormGroup>
      )}
    </>
  );
};

export default CreateManagerTouristicSites;
