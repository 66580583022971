import React, {useCallback} from 'react';
import {Card} from 'react-bootstrap';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import {API_GET_ALL_AGENCIES} from '../../constants/api';
import {AGENCY_EDIT, AGENCY_DELETE, AGENCIES} from '../../constants/constant-routes';
import {Route} from 'react-router-dom';
import AgencyEdit from './AgencyEdit/AgencyEdit';
import AgencyDelete from './AgencyEdit/AgencyDelete';

const AgenciesPage = ({history, ...props}) => {
  const ref = React.createRef();
  const enabledComponent = useCallback((row) => {
    return (
      <div
        className={`${
          row.enabled ? 'bg-primary text-white' : 'bg-warning'
        } p-2 rounded no-wrap`}
      >
        <span>{row.enabled ? 'Active' : 'Non Active'}</span>
      </div>
    );
  }, []);

  const approvedComponent = useCallback((row) => {
    return (
      <div
        className={`${
          row.approved ? 'bg-primary text-white' : 'bg-warning'
        } p-2 rounded no-wrap`}
      >
        <span>{row.approved ? 'Approuvée' : 'Non approuvée'}</span>
      </div>
    );
  }, []);
  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
      filtering: false,
    },
    {
      field: 'name',
      title: 'Nom',
    },
    {
      field: 'enabled',
      title: 'Statut',
      render: enabledComponent,
      filtering: false,
    },
    {
      field: 'approved',
      title: 'Approuvé',
      render: approvedComponent,
      filtering: false,
    },
    {
      field: 'email',
      title: 'Adresse mail',
    },
    {
      field: 'phone_number',
      title: 'Téléphone',
      filtering: false,
      sorting: false,
    },
  ];

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) => history.push(AGENCY_EDIT(rowData.id)),
    },
    {
      icon: 'delete',
      tooltip: 'Supprimer',
      onClick: (event, rowData) => history.push(AGENCY_DELETE(rowData.id)),
    },
  ];

  return (
    <Card className="p-4 rounded-lg shadow-sm">
      <h3 className="m-0">Gestion des agences</h3>
      <p className="text-muted">Gérer les agences</p>
      <MyTable
        ref={ref}
        columns={columns}
        apiUrl={API_GET_ALL_AGENCIES}
        actions={actions}
      />

      <Route path={AGENCY_EDIT(':id')}>
        {({history, match}) => (
          <AgencyEdit
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(AGENCIES);
            }}
          />
        )}
      </Route>
      <Route path={AGENCY_DELETE(':id')}>
        {({history, match}) => (
          <AgencyDelete
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(AGENCIES);
            }}
          />
        )}
      </Route>
    </Card>
  );
};

export default AgenciesPage;
