const HENRI_FRONT_PUBLIC_URL = process.env.REACT_APP_HENRI_FRONT_PUBLIC_URL;

const touristicSites = {
  new: () => `${HENRI_FRONT_PUBLIC_URL}/touristic-sites/new`,
  edit: (id) => `${HENRI_FRONT_PUBLIC_URL}/touristic-sites/${id}/edit`,
};

const henriFrontPublicUrl = {
  touristicSites,
};

export default henriFrontPublicUrl;
