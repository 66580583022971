import React from 'react';
import MyInput from '../../../../../_metronic/layout/components/form/input/input';
import InputMask from '../../../../../_metronic/layout/components/form/input/inputMask';
import TouristicSitesForm from '../../TouristicSitesForm';
import InputAddress from '../../../../../_metronic/layout/components/form/address/inputAddress';

const {
  formField: {address, telephone_number, website_url, tickets, video},
} = TouristicSitesForm;

const CreateTouristicSiteMapManager = (props) => {
  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{props.title}</h5>
      </div>
      <div className="row w-100 m-0">
        <InputAddress {...props} parentClasses="col-12" {...address} />
        <InputMask {...props} parentClasses="col-12" {...telephone_number} />
        <MyInput {...props} parentClasses="col-12" {...website_url} />
        <MyInput {...props} parentClasses="col-12" {...tickets} />
        <MyInput {...props} parentClasses="col-12" {...video} />
      </div>
    </>
  );
};

export default CreateTouristicSiteMapManager;
