import {Field} from 'formik';
import React, {useState} from 'react';

const MyCustomInput = ({
  label,
  labelSuffix,
  name,
  classes,
  placeholder,
  type,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(toHoursAndMinutes(props.values[name]) || '');
  const handleChange = (v) => {
    props.handleChange(v);
    setValue(toHoursAndMinutes(v.target.value));
    if (
      props.status_name &&
      props.values &&
      props.values.attachments &&
      props.values.attachments[props.i]?.status !== 'created'
    ) {
      props.setFieldValue(props.status_name, 'modified');
    }
  };

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
  }
  return (
    <div className={'form-group fv-plugins-icon-container ' + props.parentClasses}>
      {label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {label}
            {props.required ? ' *' : ''}
          </label>
          {labelSuffix}
        </div>
      )}
      {focused ? (
        <Field
          onChange={handleChange}
          onBlur={() => setFocused(false)}
          placeholder={placeholder}
          type={type || 'text'}
          className={`form-control form-control-solid h-auto py-5 px-6 ${classes}`}
          name={name}
          disabled={props.disabled}
          {...props.inputProps}
        />
      ) : (
        <Field
          onFocus={() => setFocused(true)}
          value={value}
          onBlur={props.handleBlur}
          placeholder={placeholder}
          type={'text'}
          className={`form-control form-control-solid h-auto py-5 px-6 ${classes}`}
          name={name}
          disabled={props.disabled}
        />
      )}

      {props.helper && <small className="text-muted">{props.helper}</small>}
      {props.errors[name] || props.error ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">
            {props.errors[name] || props.error}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MyCustomInput;
