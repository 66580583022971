import React from 'react';
import {NavLink} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_helpers';
import SVG from 'react-inlinesvg';

const AsideMenuListElement = ({
  layoutProps,
  href,
  icon,
  title,
  menuIsActive,
  notification,
}) => {
  return (
    <li role="menuitem" className={`menu-item ${menuIsActive}`} aria-haspopup="true">
      <NavLink className="menu-link" to={href}>
        {icon ? (
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
        ) : (
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
        )}
        <span className={`menu-text ${notification ? 'font-weight-bolder' : ''}`}>
          {title}
          {notification && <span class="label label-primary label-dot ml-3"></span>}
        </span>
      </NavLink>
    </li>
  );
};

export default AsideMenuListElement;
