import React from 'react';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {sortableKeyboardCoordinates} from '@dnd-kit/sortable';
import NewDroppableContainer from './NewDroppableContainer';
import DraggableItem from './DraggableItem';
import DroppableContainer from './DroppableContainer';

const DndWrapper = ({experience, setExperience, setCart}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {delay: 75, tolerance: 5},
    }),
    useSensor(PointerSensor, {
      activationConstraint: {delay: 75, tolerance: 5},
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {delay: 75, tolerance: 5},
    })
  );
  const findItem = (id) => {
    return experience.cts.find((el) => el.id === id);
  };

  const getItems = (day) => {
    return [
      ...experience.order
        .filter((x) => x.day_number === day)
        .sort((a, b) => a.day_number - b.day_number),
    ];
  };
  const getMaxDay = () => {
    return Math.max(...experience.order.map((el) => el.day_number), 0);
  };

  const getMaxRank = (day) => {
    return Math.max(
      ...experience.order.filter((el) => el.day_number === day).map((el) => el.rank),
      1
    );
  };

  const handleRemoveItem = (id) => {
    let idSplitted = id.split(' ');
    let itemId = parseInt(idSplitted[0]);
    let day = parseInt(idSplitted[1]);
    let rank = parseInt(idSplitted[2]);
    setExperience((state) => {
      const newState = [
        ...state.order.filter(
          (el) => el.id !== itemId || el.day_number !== day || el.rank !== rank
        ),
      ];
      const jours = [...new Set(newState.map((el) => el.day_number))];
      let newTripDays = jours.includes(day)
        ? state.trip_days
        : state.trip_days
            .filter((el) => el.day_number !== day)
            .sort((a, b) => a.day_number - b.day_number)
            .map((el, index) => {
              return {...el, day_number: index + 1};
            });
      return {
        ...state,
        order: reorderState(newState),
        trip_days: newTripDays,
      };
    });
  };

  const reorderState = (state) => {
    let newState = [...state];
    const jours = [...new Set(newState.map((el) => el.day_number))];
    jours
      .sort((a, b) => a - b)
      .forEach((jour, index) => {
        newState = [
          ...newState.filter((el) => el.day_number !== jour),
          ...newState
            .filter((el) => el.day_number === jour)
            .sort((a, b) => a.rank - b.rank)
            .map((el, rank) => {
              return {...el, rank: rank + 1, day_number: index + 1};
            }),
        ];
      });
    return newState;
  };
  const handleDragEnd = ({active, over}) => {
    const overId = over?.id;
    const activeId = active.id;
    const item = findItem(activeId);
    if (!overId || !item) {
      return;
    }
    if (overId === 'new-day') {
      setExperience((state) => {
        return {
          ...state,
          order: [...state.order, {...item, day_number: getMaxDay() + 1, rank: 1}],
        };
      });
    } else {
      setExperience((state) => {
        return {
          ...state,
          order: [
            ...state.order,
            {...item, day_number: overId, rank: getMaxRank(parseInt(overId)) + 1},
          ],
        };
      });
    }
  };
  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <div className="w-100 row justify-content-between" style={{isolation: 'isolate'}}>
        <div className="col-6 bg-white d-flex flex-column">
          {experience.cts.map((el, index) => (
            <DraggableItem key={index} el={el} setCart={setCart} />
          ))}
        </div>
        <div className="col-6 bg-white d-flex flex-column">
          {Array.from(Array(getMaxDay()), (_, i) => (
            <DroppableContainer
              key={i}
              day={i + 1}
              items={getItems(i + 1)}
              trip_day={experience.trip_days.find((el) => el.day_number === i + 1)}
              handleRemoveItem={handleRemoveItem}
              setExperience={setExperience}
              sensors={sensors}
            />
          ))}
          <NewDroppableContainer />
        </div>
      </div>
    </DndContext>
  );
};

export default DndWrapper;
