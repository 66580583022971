import {REQUIRED_FIELD} from '../../../../constants/texts';
import TouristicSitesForm from '../../TouristicSitesForm';
import * as Yup from 'yup';

const {
  formField: {
    title_fr,
    category,
    agency,
    ambassador_review,
    email,
    files,
    address,
    telephone_number,
    website_url,
    schedule,
    days,
    published,
    description_fr,
    title_en,
    description_en,
    title_it,
    description_it,
    title_ge,
    description_ge,
    title_sp,
    description_sp,
    title_cn,
    description_cn,
    title_sa,
    description_sa,
    title_pt,
    description_pt,
    title_jp,
    description_jp,
    title_ru,
    description_ru,
    video,
    tickets,
  },
} = TouristicSitesForm;

const scheduleDaySchema = (day) =>
  Yup.object()
    .shape({
      open_morning: Yup.string(),
      close_night: Yup.string(),
      closed_noon: Yup.boolean(),
      open_afternoon: Yup.string().when('closed_noon', {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD),
      }),
      close_morning: Yup.string().when('closed_noon', {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD),
      }),
    })
    .when(days.name, {
      is: (val) => val?.includes(day),
      then: Yup.object().required(REQUIRED_FIELD),
    });

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape(
    {
      [title_fr.name]: Yup.string().required(REQUIRED_FIELD),
      [agency.name]: Yup.number().nullable(),
      [ambassador_review.name]: Yup.string().nullable(),
      [email.name]: Yup.string().nullable(),

      [category.name]: Yup.number()
        .min(1, 'Vous devez choisir une catégorie')
        .required(REQUIRED_FIELD),

      [files.old_files_name]: Yup.array().when('files', {
        is: (files) =>
          files && files.filter((file) => file.type.includes('image/')).length === 0,
        then: Yup.array().min(1).required(REQUIRED_FIELD),
        otherwise: Yup.array(),
      }),

      [files.files_name]: Yup.array().when('medias', {
        is: (medias) =>
          medias && medias.filter((m) => m.content_type.includes('image/')).length === 0,
        then: Yup.array().min(1).required(REQUIRED_FIELD),
        otherwise: Yup.array(),
      }),

      [published.name]: Yup.bool(),
      [description_fr.name]: Yup.string().nullable(),
      [title_en.name]: Yup.string().nullable(),
      [description_en.name]: Yup.string().nullable(),
      [title_it.name]: Yup.string().nullable(),
      [description_it.name]: Yup.string().nullable(),
      [title_ge.name]: Yup.string().nullable(),
      [description_ge.name]: Yup.string().nullable(),
      [title_sp.name]: Yup.string().nullable(),
      [description_sp.name]: Yup.string().nullable(),
      [title_cn.name]: Yup.string().nullable(),
      [description_cn.name]: Yup.string().nullable(),
      [title_sa.name]: Yup.string().nullable(),
      [description_sa.name]: Yup.string().nullable(),
      [title_pt.name]: Yup.string().nullable(),
      [description_pt.name]: Yup.string().nullable(),
      [title_jp.name]: Yup.string().nullable(),
      [description_jp.name]: Yup.string().nullable(),
      [title_ru.name]: Yup.string().nullable(),
      [description_ru.name]: Yup.string().nullable(),
    },
    ['files', 'medias', 'title_fr']
  ),
  Yup.object().shape({
    [address.name]: Yup.string().required(REQUIRED_FIELD),
    [telephone_number.name]: Yup.string().nullable(),
    [website_url.name]: Yup.string()
      .url('Vous devez entrer un lien valide (ex: https://henri.pro)')
      .nullable(),
    [tickets.name]: Yup.string()
      .url('Vous devez entrer un lien valide (ex: https://henri.pro)')
      .nullable(),
    [video.name]: Yup.string().nullable(),
  }),
  Yup.object().shape({
    [schedule.name]: Yup.object().shape({
      lundi: scheduleDaySchema('lundi'),
      mardi: scheduleDaySchema('mardi'),
      mercredi: scheduleDaySchema('mercredi'),
      jeudi: scheduleDaySchema('jeudi'),
      vendredi: scheduleDaySchema('vendredi'),
      samedi: scheduleDaySchema('samedi'),
      dimanche: scheduleDaySchema('dimanche'),
    }),
    [days.name]: Yup.array().required("Choisissez au moins un jour d'ouverture"),
  }),
];
