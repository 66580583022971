import {Formik} from 'formik';
import React from 'react';
import {Card} from 'react-bootstrap';
import AddUserStepper from '../../../modules/MyModules/AddUserStepper/AddUserStepper';
import CreateManagerAuth from './CreateManagerViews/CreateManagerAuth';
import CreateManagerView from './CreateManagerViews/CreateManagerView';
import CreateManagerProfile from './CreateManagerViews/CreateManagerProfile';
import CreateManagerTouristicSites from './CreateManagerViews/CreateManagerTouristicSite';
import CreateManagerInfo from './CreateManagerViews/CreateManagerInfo';
import useStep from '../../../../_metronic/_helpers/useStep';
import {connect} from 'react-redux';
import * as manager from '../_redux/managerRedux';
import ManagerFormInitialValues from './Formfields/ManagerFormInitialValues';
import ManagerSchemaValidation from './Formfields/ManagerSchemaValidation';
import useRequest from '../../../../_metronic/_helpers/useRequest';
//import { API_CREATE_MANAGER, API_UPDATE_MANAGER } from "../../../constants/api";
import useConfig from '../../../../_metronic/_helpers/useConfig';
import ManagerForm from '../ManagerForm';
import {MANAGERS} from '../../../constants/constant-routes';

const {
  formField: {touristic_sites},
} = ManagerForm;

const stepsCreateManager = [
  {
    id: 1,
    title: 'Connexion',
    subtitle: 'Données de connexion',
    view: CreateManagerAuth,
  },
  {
    id: 2,
    title: 'Profil',
    subtitle: 'Données du profil ',
    view: CreateManagerProfile,
  },
  {
    id: 3,
    title: 'Sites touristiques',
    subtitle: 'Accès aux sites touristiques',
    view: CreateManagerTouristicSites,
  },
  {
    id: 4,
    title: 'Résumé',
    subtitle: 'Résumé du compte manager',
    view: CreateManagerInfo,
  },
];

const CreateManagerPage = (props) => {
  const [current, nextStep, previousStep] = useStep(1, stepsCreateManager.length);
  const [handleRequest] = useRequest();
  const config = useConfig();

  const Step = stepsCreateManager.filter((el) => el.id === current)[0];

  const handleSubmit = (values, formikBag) => {
    if (current === stepsCreateManager.length) {
      const data = {
        ...values,
        [touristic_sites.name]: JSON.stringify(values[touristic_sites.name]),
      };
      if (props.manager) {
        handleRequest(
          'post',
          'API_UPDATE_MANAGER(props.manager.id)',
          data,
          config,
          (data, status) => {
            if (status === 'Done') {
              data.manager.id = props.manager?.id;
              props.updateManager(data.manager);
              props.onHide();
            } else if (status === 'Error') {
              formikBag.setStatus(data?.status);
            }
          }
        );
      } else {
        handleRequest('post', 'API_CREATE_MANAGER', data, config, (data, status) => {
          if (status === 'Done') {
            props.addManager(data.account);
            props.history.push(MANAGERS);
          } else if (status === 'Error') {
            if (
              JSON.parse(data.text)?.message ===
              'Adresse mail deja utilisée par un compte Henri.'
            ) {
              formikBag.setStatus({
                status: data.status,
                message: 'Adresse mail déjà utilisée par un compte Henri.',
              });
              return;
            }
            formikBag.setStatus(data?.status);
          }
        });
      }
    } else {
      nextStep();
    }
  };

  return (
    <Card className="rounded-lg shadow-sm card-custom card-transparent">
      <div className="card-body p-0">
        <div
          className="wizard wizard-4"
          id="kt_wizard"
          data-wizard-state="first"
          data-wizard-clickable="true"
        >
          <AddUserStepper steps={stepsCreateManager} currentStep={current} />
          <div className="card card-custom card-shadowless rounded-top-0">
            <div className="card-body p-0">
              <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <Formik
                  initialValues={props.manager || ManagerFormInitialValues}
                  validationSchema={ManagerSchemaValidation[current - 1]}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validateOnMount={false}
                  onSubmit={handleSubmit}
                >
                  {({handleSubmit, ...subProps}) => (
                    <CreateManagerView
                      title="Add a manager"
                      next={{onClick: nextStep, title: 'Suivant'}}
                      previous={{onClick: previousStep, title: 'Précédent'}}
                      submit={{
                        onClick: handleSubmit,
                        title: !props.manager ? 'Créer' : 'Sauvegarder',
                      }}
                      isFirst={current === 1}
                      isLast={current === stepsCreateManager.length}
                    >
                      {subProps.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                          <div className="alert-text font-weight-bold">
                            {typeof subProps.status === 'number'
                              ? `Une erreur de type ${subProps.status} est survenue...`
                              : subProps.status.message}
                          </div>
                        </div>
                      )}
                      {!subProps.isValid && subProps.submitCount > 0 && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                          <div className="alert-text font-weight-bold">
                            Vérifiez que toutes les données entrées sont justes...
                          </div>
                        </div>
                      )}
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Step.view
                        editing={!!props.manager}
                        {...subProps}
                        title={Step.title}
                      />
                    </CreateManagerView>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default connect(null, manager.actions)(CreateManagerPage);
