import React, {useEffect, useState} from 'react';

const FileInputBase = (props) => {
  const [filesState, setFilesState] = useState(props.initialValues[props.name]);
  useEffect(() => {
    props.setFieldValue(props.name, filesState);
  }, [filesState]);

  const onChangeFiles = (event) => {
    const files = [...event.currentTarget.files];
    if (files) {
      setFilesState((state) => [...state, ...files]);
    }
  };
  const onDeleteHandler = (key) => {
    const filesStateClone = [...filesState].filter((file) => file.name !== key);
    setFilesState(filesStateClone);
  };

  return (
    <div className={`form-group fv-plugins-icon-container ${props.parentClasses}`}>
      {props.label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {props.label}
            {props.required ? ' *' : ''}
          </label>
          {props.labelSuffix}
        </div>
      )}
      <div className="d-flex flex-column">
        <input
          type="file"
          id="images"
          name="images"
          accept={props.accept}
          multiple={props.multiple || false}
          onChange={onChangeFiles}
        />
        {filesState.map((el) => {
          return (
            <ListFileElement file={el} key={el.name} onClickHandler={onDeleteHandler} />
          );
        })}
      </div>

      <p className="m-0 text-muted">{props.helper}</p>
      {props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
    </div>
  );
};

export default FileInputBase;

const ListFileElement = ({file, onClickHandler}) => {
  const [loading, setLoading] = useState(true);
  const [thumb, setThumb] = useState(undefined);
  const [fileExtension, setFileExtension] = useState(undefined);

  useEffect(() => {
    if (!file) {
      return;
    }
    if (typeof file === 'string') {
      setThumb(file);
      setLoading(false);
    } else {
      setFileExtension(file.type);
      if (file.type.match('image/*')) {
        let reader = new FileReader();
        reader.onloadend = () => {
          setThumb(reader.result);
          setLoading(false);
        };
        reader.readAsDataURL(file);
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <div className="d-flex justify-content-between align-items-center my-1 px-lg-3">
      {thumb ? (
        <img src={thumb} alt={file.name || file} height={50} width={50} />
      ) : (
        <div
          style={{height: 50, width: 50, backgroundColor: '#efefef'}}
          className="d-flex justify-content-center align-items-center"
        >
          <span className="fw-bold text-black">{fileExtension}</span>
        </div>
      )}
      <div className="w-75 text-truncate">
        <span className="text-truncate">{file.name || file.split('/').pop()}</span>
      </div>
      <button
        className="btn btn-outline-none btn-danger"
        type="button"
        onClick={() => onClickHandler(file.name || file)}
      >
        X
      </button>
    </div>
  );
};
