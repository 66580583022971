export const camelToSnake = (string) => {
  return string
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
};

export const keysToSnake = (o) => {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
    const n = {};

    Object.keys(o).forEach((el) => {
      n[camelToSnake(el)] = keysToSnake(o[el]);
    });
    return n;
  } else if (Array.isArray(o)) {
    return o.map((el) => keysToSnake(el));
  }
  return o;
};

export const toFormData = (v) => {
  const data = new FormData();

  Object.keys(v).forEach((el) => {
    if (Array.isArray(v[el])) {
      v[el].forEach((elD) => {
        if (typeof v[elD] === 'boolean') {
          v[elD] = v[elD] ? 1 : 0;
        }

        data.append(el + '[]', elD);
      });

      return;
    }

    if (typeof v[el] === 'boolean') {
      v[el] = v[el] ? 1 : 0;
    }

    data.append(el, v[el]);
  });

  return data;
};

export const toFormDataWithObject = (v) => {
  const data = new FormData();

  Object.keys(v).forEach((el) => {
    if (Array.isArray(v[el])) {
      v[el].forEach((elD) => {
        if (typeof elD === 'boolean') {
          data.append(el + '[]', elD ? 1 : 0);
          return;
        }
        if (typeof elD === 'object' && !(elD instanceof File)) {
          Object.keys(elD).forEach((key) => {
            if (
              elD[key] !== null &&
              typeof elD[key] === 'object' &&
              !(elD[key] instanceof File)
            ) {
              Object.keys(elD[key]).forEach((key2) => {
                !isFalsy(elD[key][key2]) &&
                  data.append(el + `[][${key}][${key2}]`, elD[key][key2]);
              });
            } else {
              !isFalsy(elD[key]) && data.append(el + `[][${key}]`, elD[key]);
            }
          });
          return;
        }
        !isFalsy(elD) && data.append(el + '[]', elD);
      });
      if (v[el].length === 0) {
        data.append(el + '[]', v[el]);
      }
      return;
    }
    if (typeof v[el] === 'object' && !(v[el] instanceof File)) {
      Object.keys(v[el]).forEach((key) => {
        !isFalsy(v[el][key]) && data.append(el + `[${key}]`, v[el][key]);
      });
      return;
    }

    if (typeof v[el] === 'boolean') {
      v[el] = v[el] ? 1 : 0;
    }

    !isFalsy(v[el]) && data.append(el, v[el]);
  });

  return data;
};

const isFalsy = (el) => {
  switch (el) {
    case null:
    case undefined:
      return true;
    default:
      return false;
  }
};

export const getAllowUsers = ({
  is_ambassador = false,
  is_admin = false,
  is_super_manager = false,
  is_manager = false,
}) => {
  if (is_manager) {
    return {
      is_manager: true,
      is_super_manager: true,
      is_admin: true,
      is_ambassador,
    };
  }
  if (is_super_manager) {
    return {
      is_manager: false,
      is_super_manager: true,
      is_admin: true,
      is_ambassador,
    };
  }
  if (is_admin) {
    return {
      is_manager: false,
      is_super_manager: false,
      is_admin: true,
      is_ambassador,
    };
  }
  return {
    is_manager,
    is_super_manager,
    is_admin,
    is_ambassador,
  };
};

export const isAllowed = (allow_roles, user_roles) => {
  const asArray = Object.entries(user_roles);
  const filtered = asArray.filter(([, value]) => value);
  const filteredObject = Object.fromEntries(filtered);
  return (
    !allow_roles ||
    Object.keys(filteredObject).some((key) => allow_roles[key] === filteredObject[key])
  );
};
