import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  GetEvent: '[Event] load',
  AddEvent: '[Event] add',
  RemoveEvent: '[Event] rm',
  UpdateEvent: '[Event] update',
  SetEvent: '[Event] set',
};

const initialEventsState = {
  events: [],
};

export const reducer = persistReducer(
  {storage, key: 'persistantData', whitelist: ['events']},
  (state = initialEventsState, action) => {
    switch (action.type) {
      case actionTypes.GetEvent: {
        return {...state, events: action.payload};
      }

      case actionTypes.AddEvent: {
        return {
          ...state,
          events: [...(state.events || undefined), action.payload],
        };
      }

      case actionTypes.RemoveEvent: {
        return {
          ...state,
          events: state.events.filter((el) => el.id !== parseInt(action.payload)),
        };
      }

      case actionTypes.UpdateEvent: {
        return {
          ...state,
          events: state.events.map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                ...action.payload,
              };
            }
            return el;
          }),
        };
      }

      case actionTypes.SetEvent: {
        return {
          ...state,
          events: state.events.map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                ...action.payload,
              };
            }
            return el;
          }),
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  getEvent: (data) => ({type: actionTypes.GetEvent, payload: data}),
  addEvent: (data) => ({type: actionTypes.AddEvent, payload: data}),
  rmEvent: (data) => ({type: actionTypes.RemoveEvent, payload: data}),
  updateEvent: (data) => ({type: actionTypes.UpdateEvent, payload: data}),
  setEvent: (data) => ({type: actionTypes.SetEvent, payload: data}),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  // yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  // yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  // const { data: user } = yield getUserByToken();
  // yield put(actions.fulfillUser(user));
  // });
}
