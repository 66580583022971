import React, {createRef, useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import TicketForm from '../../TicketForm';
import {Modal} from 'react-bootstrap';
import SchedulePicker from './SchedulePicker';

const {
  formField: {schedule, duration, always_open, start_date, end_date, open_holiday},
} = TicketForm;

const CreateTicketSchedule = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const ref = createRef();
  const [events, setEvents] = useState(JSON.parse(props.values[schedule.name]));
  useEffect(() => {
    props.setFieldValue(schedule.name, JSON.stringify(events));
  }, [events]);
  // const setEventss = () => {
  //   if (ref.current) {
  //     const arr = [...ref.current.getApi().getEvents()].filter(
  //       (ev) =>
  //         new Date(ev.startStr).getTime() ===
  //         new Date('2022-10-25T09:00:00+02:00').getTime()
  //     );
  //     arr.forEach((event) => console.log(event.startStr));
  //     ref.current
  //       .getApi()
  //       .getEvents()
  //       .forEach((event) => event.remove());
  //     arr.forEach((event) => ref.current.getApi().addEvent(event));
  //     console.log('done');
  //   }
  // };

  function addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000).toISOString();
  }

  const dateClickHandler = (date) => {
    setSelectedDate(
      new Date(date.dateStr)?.toLocaleDateString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    );
    const event = {
      daysOfWeek: [],
      startTime: date.date.toLocaleTimeString('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
      }),
      endTime: new Date(
        addMinutes(
          date.dateStr,
          isNaN(props.values[duration.name]) ? 0 : props.values[duration.name]
        )
      ).toLocaleTimeString('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
      }),
      startRecur: props.values[always_open.name]
        ? undefined
        : props.values[start_date.name],
      endRecur: props.values[always_open.name] ? undefined : props.values[end_date.name],
      start: date.date.toISOString(),
      end: addMinutes(
        date.dateStr,
        isNaN(props.values[duration.name]) ? 0 : props.values[duration.name]
      ),
      closeDates: [],
      repeat: false,
    };
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setEdit(false);
    setSelectedEvent(null);
  };

  const handleSave = (event) => {
    if (edit) {
      setEvents((state) => [...state.filter((el) => el.id !== event.id), event]);
    } else {
      setEvents((state) => [...state, event]);
    }
    handleClose();
  };

  const handleDeleteEvent = (id) => {
    setEvents((state) => [...state.filter((el) => el.id !== id)]);
    handleClose();
  };

  const eventClickHandler = (event) => {
    setSelectedDate(
      new Date(event.event.startStr)?.toLocaleDateString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    );
    setEdit(true);
    const e = events.find((el) => el.id === event.event.id);
    let evt = null;
    if (e) {
      if (e.repeat) {
        evt = {
          id: e.id,
          daysOfWeek: e.daysOfWeek,
          startTime: e.startTime,
          endTime: e.endTime,
          startRecur: e.startRecur,
          endRecur: e.endRecur,
          start: new Date(event.event.startStr).toISOString(),
          end: new Date(event.event.endStr).toISOString(),
          closeDates: e.closeDates,
          repeat: e.repeat,
          display: e.display,
          backgroundColor: e.backgroundColor,
        };
      } else {
        evt = {
          id: e.id,
          daysOfWeek: [],
          startTime: new Date(event.event.startStr).toLocaleTimeString('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }),
          endTime: new Date(event.event.endStr).toLocaleTimeString('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }),
          startRecur: props.values[always_open.name]
            ? undefined
            : props.values[start_date.name],
          endRecur: props.values[always_open.name]
            ? undefined
            : props.values[end_date.name],
          start: e.start,
          end: e.end,
          closeDates: e.closeDates,
          repeat: e.repeat,
          display: e.display,
          backgroundColor: e.backgroundColor,
        };
      }
      setSelectedEvent(evt);
      setShowModal(true);
    }
  };
  return (
    <div>
      <Modal
        size="lg"
        show={showModal}
        onHide={handleClose}
        centered={true}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {selectedEvent ? (
            <Modal.Title id="example-modal-sizes-title-lg">{selectedDate}</Modal.Title>
          ) : null}
        </Modal.Header>
        {selectedEvent ? (
          <SchedulePicker
            selectedEvent={selectedEvent}
            onHide={handleClose}
            handleSave={handleSave}
            edit={edit}
            handleDeleteEvent={handleDeleteEvent}
          />
        ) : null}
      </Modal>
      <FullCalendar
        ref={ref}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView="timeGridWeek"
        events={events}
        firstDay={1}
        locales={[frLocale]}
        locale="fr"
        buttonText={{
          today: "aujourd'hui",
          month: 'mois',
          week: 'semaine',
          day: 'jour',
          list: 'liste',
        }}
        allDaySlot={false}
        nowIndicator={true}
        now={new Date()}
        timeZone="local"
        initialDate={new Date()}
        selectable={true}
        eventDisplay={true}
        navLinks={true}
        dayMaxEvents={true}
        dateClick={dateClickHandler}
        eventClick={eventClickHandler}
        eventDidMount={(e) => {
          const d = e.event.startStr.substring(0, 10);
          if (
            e.event.extendedProps.closeDates.includes(d) ||
            (!props.values[open_holiday.name] && props.holidayDates?.includes(d))
          ) {
            e.el.style.backgroundColor = 'gray';
            e.el.style.borderColor = 'gray';
          } else {
            e.el.style.backgroundColor = '#3788d8';
            e.el.style.borderColor = '#3788d8';
          }
        }}
      />
    </div>
  );
};

export default CreateTicketSchedule;
