import React, {useEffect, useState} from 'react';
import axios from 'axios';
import useConfig from '../../../../_helpers/useConfig';
import {ProgressBar, Alert} from 'react-bootstrap';
import useRequest from '../../../../_helpers/useRequest';

const FileInputAsync = (props) => {
  const [filesState, setFilesState] = useState(props.initialValues[props.name]);
  const [progress, setProgress] = useState();
  const [error, setError] = useState();
  const config = useConfig();
  const [handleRequest] = useRequest();

  const onChangeFiles = (event) => {
    let formData = new FormData();
    const imgs = [...event.currentTarget.files];
    if (imgs.length === 0) {
      return;
    }
    imgs.forEach((file) => formData.append(props.field, file));
    setError('');
    setProgress(0);
    axios
      .post(process.env.REACT_APP_API_URL + props.api_upload, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...config,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        setFilesState(
          response?.data?.medias || response?.data?.images || response?.data?.files
        );
        setProgress(undefined);
      })
      .catch((error) => {
        const {code} = error?.response?.data;
        switch (code) {
          case 'FILE_MISSING':
            setError('Please select a file before uploading!');
            break;
          default:
            setError('Sorry! Something went wrong. Please try again later');
            break;
        }
      });
  };
  const onDeleteHandler = (key) => {
    setError('');
    setProgress(100);
    handleRequest('delete', `${props.api_delete}/${key}`, {}, config, (data, status) => {
      if (status === 'Done') {
        setFilesState(data?.medias || data?.images || data?.files);
        setProgress(undefined);
      } else {
        setProgress(undefined);
      }
    });
  };

  return (
    <div className={`form-group fv-plugins-icon-container ${props.parentClasses}`}>
      {props.label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {props.label}
            {props.required ? ' *' : ''}
          </label>
          {props.labelSuffix}
        </div>
      )}
      {!props.disabled ? (
        <div className="d-flex flex-column">
          <input
            type="file"
            id={props.name}
            name={props.name}
            accept={props.accept}
            multiple={props.multiple || false}
            onChange={onChangeFiles}
            disabled={props.disabled}
          />
          {error && <Alert variant="danger">{error}</Alert>}
          {!error && progress && <ProgressBar now={progress} animated />}
          {filesState.map((el) => {
            return (
              <ListFileElement
                file={el}
                key={el.id}
                onClickHandler={onDeleteHandler}
                disabled={props.disabled}
              />
            );
          })}
        </div>
      ) : (
        <div className="d-flex flex-row">
          {filesState.map((el) => {
            return (
              <img
                key={el.id}
                src={el.url.replace(/\?.*/, '')}
                alt={el.url}
                height={50}
                width={50}
                className="mx-3"
              />
            );
          })}
        </div>
      )}

      <p className="m-0 text-muted">{props.helper}</p>
    </div>
  );
};

export default FileInputAsync;

const ListFileElement = ({file, onClickHandler, disabled}) => {
  const [loading, setLoading] = useState(true);
  const [thumb, setThumb] = useState(undefined);
  const [fileExtension, setFileExtension] = useState(undefined);

  useEffect(() => {
    if (!file) {
      return;
    }
    setFileExtension(file.content_type);
    if (file.content_type.match('image/*')) {
      setThumb(file.url.replace(/\?.*/, ''));
      setLoading(false);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <div className="d-flex justify-content-between align-items-center my-1 px-lg-3">
      {thumb ? (
        <img src={thumb} alt={file.filename} height={50} width={50} />
      ) : (
        <div
          style={{height: 50, width: 50, backgroundColor: '#efefef'}}
          className="d-flex justify-content-center align-items-center"
        >
          <span className="fw-bold text-black">{fileExtension}</span>
        </div>
      )}
      <div className="w-75 text-truncate">
        <span className="text-truncate">{file.filename || file.split('/').pop()}</span>
      </div>
      <button
        disabled={disabled}
        className="btn btn-outline-none btn-danger"
        type="button"
        onClick={() => onClickHandler(file.blob_id)}
      >
        X
      </button>
    </div>
  );
};
