import {REQUIRED_FIELD} from '../../../../constants/texts';
import * as Yup from 'yup';
import TicketForm from '../../TicketForm';

const {
  formField: {
    touristicSites,
    title,
    duration,
    price,
    description,
    start_date,
    end_date,
    open_holiday,
    schedule,
    enabled,
    always_open,
    min_person,
    max_person,
  },
} = TicketForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [touristicSites.name]: Yup.number().required(REQUIRED_FIELD),
    [title.name]: Yup.string().required(REQUIRED_FIELD),
    [duration.name]: Yup.number(),
    [price.name]: Yup.number(),
    [description.name]: Yup.string(),
    [start_date.name]: Yup.date(),
    [end_date.name]: Yup.date(),
    [open_holiday.name]: Yup.bool(),
    [schedule.name]: Yup.string(),
    [enabled.name]: Yup.bool(),
    [always_open.name]: Yup.bool(),
    [min_person.name]: Yup.number(),
    [max_person.name]: Yup.number(),
  }),
  Yup.object().shape({
    [schedule.name]: Yup.string(),
  }),
];
