const {default: EventsForm} = require('../../EventsForm');

const {
  formField: {
    title,
    images,
    description,
    end_date,
    start_date,
    category,
    touristic_site_id,
  },
} = EventsForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [title.name]: '',
  [images.name]: [],
  [images.new_images_name]: [],
  [description.name]: '',
  [end_date.name]: '',
  [start_date.name]: '',
  [category.name]: 0,
  [touristic_site_id.name]: 0,
};
