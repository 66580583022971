import * as Yup from 'yup';
import {REQUIRED_FIELD} from '../../../constants/texts';
import ReviewsTSForm from '../ReviewsTSForm';

const {
  formField: {note, content, answer},
} = ReviewsTSForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [note.name]: Yup.number().required(REQUIRED_FIELD),
    [content.name]: Yup.string().required(REQUIRED_FIELD),
    [answer.name]: Yup.string(),
  }),
];
