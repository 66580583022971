import React, {useCallback, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {Route, Switch} from 'react-router-dom';
import {useSubheader} from '../../../_metronic/layout';
import CategoryIdentifier from '../../../_metronic/layout/components/form/category/categoryIdentifier';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import {
  TOURISTIC_SITE_VIEW,
  TOURISTIC_SITE_DELETE,
  TOURISTIC_SITE_EDIT,
  TOURISTIC_SITE_PUBLISH,
  TOURISTIC_SITES,
} from '../../constants/constant-routes';
import TouristicSitesView from './TouristicSitesView/TouristicSitesView';
import TouristicSitesDelete from './ToursitcSitesEdit/TouristicSitesDelete';
import TouristicSitePublish from './ToursitcSitesEdit/TouristicSitePublish';
import {API_GET_ALL_TOURISTIC_SITES} from '../../constants/api';
import {useSelector} from 'react-redux';

const TouristicPage = ({history, ...props}) => {
  const subheader = useSubheader();
  const ref = React.createRef();
  useEffect(() => {
    subheader.setTitle('Sites touristiques');
  }, [subheader]);
  const {categories} = useSelector((state) => state.auth);

  const categoryComponent = useCallback((row) => {
    return (
      <CategoryIdentifier
        key={row.id}
        categoryID={row.categories && row.categories[0]?.id}
        categories={row.categories}
      />
    );
  }, []);

  const townComponent = useCallback((r) => {
    return (
      <span key={r.id}>
        {r.city} - {r.zipcode}
      </span>
    );
  }, []);

  const statusComponent = useCallback((row) => {
    return (
      <div
        className={`${
          row.published ? 'bg-primary text-white' : 'bg-warning'
        } p-2 rounded no-wrap`}
      >
        <span>{row.published ? 'Publié' : 'Brouillon'}</span>
      </div>
    );
  }, []);
  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
    },
    {
      field: 'title_fr',
      title: 'Nom du site',
    },
    {
      field: 'categories',
      title: 'Catégorie',
      render: categoryComponent,
      sorting: false,
      lookup: {
        ...(function () {
          const newLookup = {};
          categories.forEach((e) => {
            newLookup[e.value] = e.label;
          });
          return newLookup;
        })(),
      },
    },
    {
      field: 'city',
      title: 'Ville',
      render: townComponent,
      filtering: false,
      sorting: false,
    },
    {
      field: 'published',
      title: 'Statut',
      render: statusComponent,
      filtering: false,
    },
  ];
  const actions = [
    {
      icon: 'view_column',
      tooltip: 'Voir',
      onClick: (event, rowData) => history.push(TOURISTIC_SITE_VIEW(rowData.id)),
    },
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) => history.push(TOURISTIC_SITE_EDIT(rowData.id)),
    },
    {
      icon: 'publish',
      tooltip: 'Publier',
      onClick: (event, rowData) =>
        history.push(
          `${TOURISTIC_SITE_PUBLISH(rowData.id)}?published=${rowData.published}`
        ),
    },
    {
      icon: 'delete',
      tooltip: 'Supprimer',
      onClick: (event, rowData) => history.push(TOURISTIC_SITE_DELETE(rowData.id)),
    },
    // {
    //   icon: 'chevron_right',
    //   tooltip: 'Voir stats',
    //   onClick: (event, rowData) => history.push(TOURISTIC_SITE_STATS(rowData.id))
    // }
  ];
  return (
    <>
      <Card className="p-4 rounded-lg shadow-sm">
        <h3 className="m-0">Gestion des sites touristiques</h3>
        <p className="text-muted">Gérer les sites touristiques de votre agence</p>
        <MyTable
          ref={ref}
          columns={columns}
          apiUrl={API_GET_ALL_TOURISTIC_SITES}
          actions={actions}
        />
      </Card>
      <Switch>
        {/*<Route path={TOURISTIC_SITE_EDIT(':id')}>*/}
        {/*  {({history, match}) => (*/}
        {/*    <TouristicSiteEdit*/}
        {/*      refresh={ref}*/}
        {/*      show={match != null}*/}
        {/*      id={match && match.params.id}*/}
        {/*      onHide={() => {*/}
        {/*        history.push(TOURISTIC_SITES);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</Route>*/}
        <Route path={TOURISTIC_SITE_VIEW(':id')}>
          {({history, match}) => (
            <TouristicSitesView
              refresh={ref}
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(TOURISTIC_SITES);
              }}
            />
          )}
        </Route>
        <Route path={TOURISTIC_SITE_PUBLISH(':id')}>
          {({history, match}) => (
            <TouristicSitePublish
              refresh={ref}
              show={match != null}
              id={match && match.params.id}
              published={
                new URLSearchParams(window.location.search).get('published') === 'true'
              }
              onHide={() => {
                history.push(TOURISTIC_SITES);
              }}
            />
          )}
        </Route>
        <Route path={TOURISTIC_SITE_DELETE(':id')}>
          {({history, match}) => (
            <TouristicSitesDelete
              refresh={ref}
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(TOURISTIC_SITES);
              }}
            />
          )}
        </Route>
        {/*<Route path={TOURISTIC_SITE_STATS(":id")}>*/}
        {/*  {({history, match}) => (*/}
        {/*    <TouristicSiteStats*/}
        {/*      show={match != null}*/}
        {/*      id={match && match.params.id}*/}
        {/*      onHide={() => {*/}
        {/*        history.push(TOURISTIC_SITES);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</Route>*/}
      </Switch>
    </>
  );
};

export default TouristicPage;
