/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState} from 'react';
import objectPath from 'object-path';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useHtmlClassService} from '../../_core/MetronicLayout';
import {AsideMenu} from './aside-menu/AsideMenu';
import {QuickUserToggler} from '../extras/QuickUserToggler';
import {Brand} from '../brand/Brand';
import {KTUtil} from '../../../_assets/js/components/util';
import AsideElement from './AsideElement';
import {useSelector} from 'react-redux';
import {
  ACTIVITIES,
  ACTIVITY_CREATE,
  EVENTS,
  EVENT_CREATE,
  HOME_ROUTE,
  MANAGERS,
  TICKETS_ROUTE,
  TICKET_CALENDAR,
  TICKET_CREATE,
  TOURISTIC_SITES,
  TOURISTIC_SITE_CREATE,
  MANAGER_INVITE,
  AGENCIES,
  AGENCY_CREATE,
  USERS,
  ADMINS,
  SUPER_MANAGERS,
  ROAD_TRIPS,
  SUGGESTIONS,
  REVIEWS,
  REVIEWS_RT,
  EXPERIENCE_CREATE,
  EXPERIENCES,
  TRIPS_CREATE,
} from '../../../../app/constants/constant-routes';
import {getAllowUsers, isAllowed} from '../../../_helpers/utils';

const tabs = {
  tabId1: 'kt_aside_tab_1',
  tabId2: 'kt_aside_tab_2',
  tabId3: 'kt_aside_tab_3',
  tabId4: 'kt_aside_tab_4',
  tabId5: 'kt_aside_tab_5',
  tabId6: 'kt_aside_tab_6',
  tabId7: 'kt_aside_tab_7',
  tabId8: 'kt_aside_tab_8',
  tabId9: 'kt_aside_tab_9',
  tabId10: 'kt_aside_tab_10',
  tabId11: 'kt_aside_tab_11',
};

export function Aside() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses('aside', true),
      asideSecondaryDisplay: objectPath.get(uiService.config, 'aside.secondary.display'),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        'aside.self.minimize.toggle'
      ),
      extrasSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        'extras.notifications.display'
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        'extras.quick-actions.display'
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        'extras.quick-panel.display'
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        'extras.languages.display'
      ),
      extrasUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    };
  }, [uiService]);

  const {
    is_admin,
    is_super_manager,
    is_manager,
    user: {ambassador: is_ambassador},
  } = useSelector((state) => state.auth);
  const AsideList = React.useMemo(
    () => [
      {
        titleTooltip: 'Dashboard',
        tabID: tabs.tabId1,
        icon: '/media/svg/icons/Layout/Layout-4-blocks.svg',
        title: 'Actions rapides',
        submenu: [
          {
            href: HOME_ROUTE,
            icon: '/media/svg/icons/Layout/Layout-4-blocks.svg',
            title: 'Dashboard',
          },
          {
            href: TOURISTIC_SITE_CREATE,
            icon: '/media/svg/icons/General/Duplicate.svg',
            title: 'Ajouter un site touristique',
            allow_roles: getAllowUsers({is_admin: true}),
          },
          {
            href: ACTIVITY_CREATE,
            icon: '/media/svg/icons/Code/Plus.svg',
            title: 'Ajouter une activité',
            allow_roles: getAllowUsers({is_manager: true}),
          },
          {
            href: TICKET_CREATE,
            icon: '/media/svg/icons/Communication/Write.svg',
            title: 'Ajouter un billet',
            allow_roles: getAllowUsers({is_manager: true}),
          },
        ],
      },
      {
        titleTooltip: 'Utilisateurs',
        tabID: tabs.tabId2,
        icon: '/media/svg/icons/Communication/Group.svg',
        href: '/managers',
        allow_roles: getAllowUsers({is_super_manager: true}),
        submenu: [
          {
            href: USERS,
            icon: '/media/svg/icons/Communication/Adress-book2.svg',
            title: 'Liste des utilisateurs',
            allow_roles: getAllowUsers({is_admin: true}),
          },
          {
            href: ADMINS,
            icon: '/media/svg/icons/Communication/Adress-book2.svg',
            title: 'Liste des admins',
            allow_roles: getAllowUsers({is_admin: true}),
          },
          {
            href: SUPER_MANAGERS,
            icon: '/media/svg/icons/Communication/Adress-book2.svg',
            title: 'Liste des super managers',
            allow_roles: getAllowUsers({is_admin: true}),
          },
          {
            href: MANAGERS,
            icon: '/media/svg/icons/Communication/Adress-book2.svg',
            title: 'Liste des managers',
            allow_roles: getAllowUsers({is_manager: true}),
          },
          {
            href: MANAGER_INVITE,
            icon: '/media/svg/icons/Communication/Add-user.svg',
            title: 'Inviter un nouveau manager',
            allow_roles: getAllowUsers({is_admin: true}),
          },
        ],
      },
      {
        titleTooltip: 'Agences',
        tabID: tabs.tabId3,
        icon: '/media/svg/icons/Home/Home.svg',
        allow_roles: getAllowUsers({is_admin: true}),
        submenu: [
          {
            href: AGENCIES,
            icon: '/media/svg/icons/General/Duplicate.svg',
            title: 'Liste des agences',
          },
          {
            href: AGENCY_CREATE,
            icon: '/media/svg/icons/Code/Plus.svg',
            title: 'Créer une nouvelle agence',
          },
        ],
      },
      {
        titleTooltip: 'Sites touristiques',
        tabID: tabs.tabId4,
        icon: '/media/svg/icons/Devices/Display3.svg',
        allow_roles: getAllowUsers({is_manager: true}),
        submenu: [
          {
            href: TOURISTIC_SITES,
            icon: '/media/svg/icons/General/Duplicate.svg',
            title: 'Liste des sites touristiques',
          },
          {
            href: TOURISTIC_SITE_CREATE,
            icon: '/media/svg/icons/Code/Plus.svg',
            title: 'Créer un nouveau site touristique',
            allow_roles: getAllowUsers({is_admin: true}),
          },
        ],
      },
      {
        titleTooltip: 'Activités',
        tabID: tabs.tabId5,
        icon: '/media/svg/icons/Communication/Flag.svg',
        allow_roles: getAllowUsers({is_manager: true}),
        submenu: [
          {
            title: 'Liste des activités',
            href: ACTIVITIES,
            icon: '/media/svg/icons/Communication/Clipboard-list.svg',
          },
          {
            title: 'Créer une activité',
            href: ACTIVITY_CREATE,
            icon: '/media/svg/icons/Code/Plus.svg',
          },
        ],
      },
      {
        titleTooltip: 'Billets',
        tabID: tabs.tabId6,
        icon: '/media/svg/icons/Shopping/Ticket.svg',
        allow_roles: getAllowUsers({is_manager: true}),
        submenu: [
          {
            title: 'Liste des billets',
            href: TICKETS_ROUTE,
            icon: '/media/svg/icons/Shopping/Wallet3.svg',
          },
          {
            title: 'Créer un billet',
            href: TICKET_CREATE,
            icon: '/media/svg/icons/Communication/Write.svg',
          },
          {
            title: 'Calendrier',
            href: TICKET_CALENDAR,
            icon: '/media/svg/icons/General/Calendar.svg',
          },
        ],
      },
      {
        titleTooltip: 'Road trips',
        tabID: tabs.tabId7,
        icon: '/media/svg/icons/Map/Road-trip.svg',
        allow_roles: getAllowUsers({is_ambassador: true, is_admin: true}),
        submenu: [
          {
            title: 'Liste des road trips',
            href: ROAD_TRIPS,
            icon: '/media/svg/icons/Communication/Clipboard-list.svg',
          },
          {
            title: 'Liste des expériences',
            href: EXPERIENCES,
            icon: '/media/svg/icons/Communication/Clipboard-list.svg',
          },
          {
            title: 'Créer un trip',
            href: TRIPS_CREATE,
            icon: '/media/svg/icons/Code/Plus.svg',
          },
          {
            title: 'Créer une expérience',
            href: EXPERIENCE_CREATE,
            icon: '/media/svg/icons/Code/Plus.svg',
          },
        ],
      },
      {
        titleTooltip: 'Suggestions',
        tabID: tabs.tabId8,
        icon: '/media/svg/icons/Communication/Suggestion.svg',
        allow_roles: getAllowUsers({is_admin: true}),
        submenu: [
          {
            title: 'Liste des suggestions',
            href: SUGGESTIONS,
            icon: '/media/svg/icons/Communication/Clipboard-list.svg',
          },
        ],
      },
      {
        titleTooltip: 'Avis',
        tabID: tabs.tabId9,
        icon: '/media/svg/icons/Communication/Review.svg',
        allow_roles: getAllowUsers({is_manager: true}),
        submenu: [
          {
            title: 'Liste des avis',
            href: REVIEWS,
            icon: '/media/svg/icons/Communication/Clipboard-list.svg',
          },
          {
            title: 'Liste des avis road trips',
            href: REVIEWS_RT,
            icon: '/media/svg/icons/Communication/Clipboard-list.svg',
            allow_roles: getAllowUsers({is_admin: true}),
          },
        ],
      },
      {
        titleTooltip: 'Évènements',
        tabID: tabs.tabId10,
        icon: '/media/svg/icons/General/Bookmark.svg',
        allow_roles: getAllowUsers({is_admin: true}),
        submenu: [
          {
            title: 'Liste des évènements',
            href: EVENTS,
            icon: '/media/svg/icons/General/Bookmark.svg',
          },
          {
            title: 'Créer un évènement',
            href: EVENT_CREATE,
            icon: '/media/svg/icons/General/Star.svg',
          },
        ],
      },
      {
        titleTooltip: 'Messagerie par site touristique',
        allow_roles: getAllowUsers({is_admin: true}),
        tabID: tabs.tabId11,
        href: '/conversations',
        icon: '/media/svg/icons/Communication/Chat2.svg',
        submenu: [],
      },
    ],
    []
  );

  const [activeTab, setActiveTab] = useState(AsideList[0]);

  const handleTabChange = (id) => {
    setActiveTab(id);
    const asideWorkspace = KTUtil.find(
      document.getElementById('kt_aside'),
      '.aside-secondary .aside-workspace'
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };

  React.useEffect(() => {
    AsideList.forEach((el) => {
      if (el.tabID === activeTab.tabID) {
        setActiveTab(el);
      }
    });
    // eslint-disable-next-line
  }, [AsideList]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {/* begin::Item */}
              {AsideList?.map((el) => {
                const user_roles = {
                  is_admin,
                  is_super_manager,
                  is_manager,
                  is_ambassador,
                };
                if (!isAllowed(el.allow_roles, user_roles)) {
                  return null;
                }

                return (
                  <AsideElement
                    activeTab={activeTab}
                    {...el}
                    key={el.titleTooltip}
                    handleTabChange={handleTabChange}
                  />
                );
              })}
              {/* end::Item */}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            {/* begin::Aside Toggle */}
            {layoutProps.asideSecondaryDisplay && layoutProps.asideSelfMinimizeToggle && (
              <>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="toggle-aside">Cacher/afficher menu secondaire</Tooltip>
                  }
                >
                  <span
                    className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                    id="kt_aside_toggle"
                  >
                    <i className="ki ki-bold-arrow-back icon-sm" />
                  </span>
                </OverlayTrigger>
              </>
            )}
            {/* end::Aside Toggle */}

            {/* begin::Languages*/}
            {/* {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />} */}
            {/* end::Languages */}

            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
            {/* end::User */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {layoutProps.asideSecondaryDisplay && activeTab?.submenu?.length !== 0 && (
          <>
            <div className="aside-secondary d-flex flex-row-fluid">
              <div className="aside-workspace scroll scroll-push my-2">
                <div className="tab-content">
                  <AsideMenu
                    isActive={
                      activeTab.tabID === tabs.tabId1 ||
                      activeTab.tabID === tabs.tabId2 ||
                      activeTab.tabID === tabs.tabId3 ||
                      activeTab.tabID === tabs.tabId4 ||
                      activeTab.tabID === tabs.tabId5 ||
                      activeTab.tabID === tabs.tabId6 ||
                      activeTab.tabID === tabs.tabId7 ||
                      activeTab.tabID === tabs.tabId8 ||
                      activeTab.tabID === tabs.tabId9 ||
                      activeTab.tabID === tabs.tabId10 ||
                      activeTab.tabID === tabs.tabId11
                    }
                    {...activeTab}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
