// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'activityForm',
  formField: {
    touristicSites: {
      name: 'touristic_site_id',
      label: 'Site touristique',
      placeholder: 'Site touristique',
      noOptionsMessage: () => 'Aucun site touristique trouvé',
      required: true,
    },
    title: {
      name: 'title',
      label: "Titre de l'activité",
      placeholder: "Titre de l'activité",
      required: true,
    },
    description: {
      name: 'description',
      label: 'Description',
      placeholder: "Description de l'activitée...",
    },
    published: {
      name: 'published',
      label: 'Voulez-vous le publier ?',
    },
    password: {
      name: 'password',
      label: 'Mot de passe',
      type: 'tel',
      required: true,
      mask: '9999',
    },
    medias: {
      name: 'medias',
      label: 'Médias',
      helper: 'Vous devez choisir au minimum 1 image.',
      required: true,
      multiple: true,
      accept: 'image/*,audio/*,video/*',
    },

    visit_sheet_images: {
      name: 'images',
      label: 'Images du point intérêt',
      helper: 'Vous devez choisir au minimum 1 image.',
      required: true,
      multiple: true,
      accept: 'image/*',
    },
    visit_sheet_longitude: {
      name: 'longitude',
      label: 'Longitude',
      type: 'number',
      required: true,
    },
    visit_sheet_latitude: {
      name: 'latitude',
      label: 'Latitude',
      type: 'number',
      required: true,
    },
  },
};
