import {REQUIRED_FIELD} from '../../../../constants/texts';
import * as Yup from 'yup';
import ActivityForm from '../../ActivityForm';

const {
  formField: {
    touristicSites,
    title,
    description,
    published,
    password,
    medias,

    visit_sheet_images,
    visit_sheet_longitude,
    visit_sheet_latitude,
  },
} = ActivityForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [touristicSites.name]: Yup.number().required(REQUIRED_FIELD),
    [title.name]: Yup.string().required(REQUIRED_FIELD),
    [description.name]: Yup.string(),
    [published.name]: Yup.bool(),
    [password.name]: Yup.string().min(4, 'Code à 4 chiffres').required(REQUIRED_FIELD),
    [medias.name]: Yup.array().min(1).required(REQUIRED_FIELD),
  }),
  Yup.object().shape({
    [visit_sheet_images.name]: Yup.array().min(1).required(REQUIRED_FIELD),
    [visit_sheet_longitude.name]: Yup.number().required(REQUIRED_FIELD),
    [visit_sheet_latitude.name]: Yup.number().required(REQUIRED_FIELD),
  }),
  Yup.object().shape({}),
];
