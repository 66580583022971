/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {checkIsActive} from '../../../../_helpers';
import AsideMenuDropdownList from './AsideMenuDropdownList';
import AsideMenuListElement from './AsideMenuListElement';
import AsideMenuTitle from './AsideMenuTitle';
import {isAllowed} from '../../../../_helpers/utils';

export function AsideMenuList({layoutProps, submenu, titleTooltip, title}) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : '';
  };
  const {
    is_admin,
    is_super_manager,
    is_manager,
    user: {ambassador: is_ambassador},
  } = useSelector((state) => state.auth);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {titleTooltip && <AsideMenuTitle title={titleTooltip || title} />}
        {/*begin::1 Level*/}
        {!submenu.length ? (
          <p className="text-muted">
            Vous n'avez pas encore ajouté de sites touristiques...
          </p>
        ) : (
          submenu?.map((el, index) => {
            const {Component} = el;
            if (Component) {
              return <Component key={index} {...el} />;
            }
            const user_roles = {
              is_admin,
              is_super_manager,
              is_manager,
              is_ambassador,
            };
            if (el?.submenu?.length)
              return (
                <AsideMenuDropdownList
                  {...el}
                  menuIsActive={getMenuItemActive(el.href, false)}
                  layoutProps={layoutProps}
                >
                  {el.submenu?.map((el, index) => {
                    if (!isAllowed(el.allow_roles, user_roles)) {
                      return null;
                    }
                    const {Component} = el;
                    if (Component) {
                      return <Component key={index} {...el} />;
                    }
                    return (
                      <AsideMenuListElement
                        key={el.href}
                        {...el}
                        menuIsActive={getMenuItemActive(el.href, false)}
                        layoutProps={layoutProps}
                      />
                    );
                  })}
                </AsideMenuDropdownList>
              );
            if (!isAllowed(el.allow_roles, user_roles)) {
              return null;
            }

            if (!el.href) {
              return <AsideMenuTitle title={el.title} key={el.icon} />;
            }
            return (
              <AsideMenuListElement
                {...el}
                key={el.href}
                menuIsActive={getMenuItemActive(el.href, false)}
                layoutProps={layoutProps}
              />
            );
          })
        )}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
