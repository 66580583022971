import React from 'react';
// import BootstrapTable from "react-bootstrap-table-next";
import MaterialTable, {MTableBodyRow} from '@material-table/core';
import useConfig from '../../../_helpers/useConfig';
import {removeAccents} from '../../../../app/constants/api';

const MyTable = React.forwardRef((props, ref) => {
  const config = useConfig();

  return (
    <div>
      <MaterialTable
        title=""
        tableRef={ref}
        components={{
          Row: (prop) => <MTableBodyRow id={prop.data.id} {...prop} key={prop.data.id} />,
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_API_URL + props.apiUrl;
            url += 'per_page=' + query.pageSize;
            url += '&current_page=' + (query.page + 1);
            if (query.orderBy) {
              url += `&field=${query.orderBy.field}&order=${query.orderDirection}`;
            }
            if (query.filters.length > 0) {
              url +=
                '&' +
                query.filters
                  .map(
                    (e) =>
                      `${e.column.field}=${
                        typeof e.value === 'string'
                          ? removeAccents(e.value)
                          : e.value.join(',')
                      }`
                  )
                  .join('&');
            }
            fetch(url, {
              method: 'GET',
              cache: 'default',
              headers: config.headers,
              mode: 'cors',
            })
              .then((response) => response.json())
              .then((result) => {
                props.customData
                  ? resolve({
                      data: result,
                      page: query.page,
                      totalCount: Number.MAX_SAFE_INTEGER,
                    })
                  : resolve({
                      data: result.data,
                      page: result.page - 1,
                      totalCount: result.total,
                    });
              });
          })
        }
        columns={props.columns}
        actions={[
          ...props.actions,
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => ref.current && ref.current.onQueryChange(),
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: "Pas d'enregistrement à afficher",
            addTooltip: 'Ajouter',
            deleteTooltip: 'Supprimer',
            editTooltip: 'Editer',
            filterRow: {
              filterTooltip: 'Filtrer',
            },
            editRow: {
              deleteText: 'Voulez-vous supprimer cette ligne?',
              cancelTooltip: 'Annuler',
              saveTooltip: 'Enregistrer',
            },
          },
          grouping: {
            placeholder: "Tirer l'entête ...",
            groupedBy: 'Grouper par:',
          },
          header: {
            actions: 'Actions',
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'lignes',
            labelRowsPerPage: 'lignes par page:',
            firstAriaLabel: 'Première page',
            firstTooltip: 'Première page',
            previousAriaLabel: 'Page précédente',
            previousTooltip: 'Page précédente',
            nextAriaLabel: 'Page suivante',
            nextTooltip: 'Page suivante',
            lastAriaLabel: 'Dernière page',
            lastTooltip: 'Dernière page',
          },
          toolbar: {
            addRemoveColumns: 'Ajouter ou supprimer des colonnes',
            nRowsSelected: '{0} ligne(s) sélectionée(s)',
            showColumnsTitle: 'Voir les colonnes',
            showColumnsAriaLabel: 'Voir les colonnes',
            exportTitle: 'Exporter',
            exportAriaLabel: 'Exporter',
            exportName: 'Exporter en CSV',
            searchTooltip: 'Chercher',
            searchPlaceholder: 'Chercher',
          },
        }}
        options={{
          sorting: true,
          search: false,
          toolbar: true,
          draggable: false,
          tableLayout: props.layout || 'auto',
          paging: true,
          emptyRowsWhenPaging: false,
          paginationType: 'stepped',
          numberOfPagesAround: 10,
          paginationPosition: 'bottom',
          actionsColumnIndex: -1,
          headerStyle: {position: 'sticky', top: 0, fontWeight: 'bold'},
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          filtering: true,
          debounceInterval: 1000,
        }}
      />
      {/* <BootstrapTable
        keyField="id"
        data={
          content?.map((el, i) => ({
            ...el,
            row: i + 1
          })) ?? []
        }
        columns={columns}
        bordered={false}
        defaultSorted={defaultSorted}
        headerClasses="text-secondary"
      /> */}
    </div>
  );
});

export default MyTable;
