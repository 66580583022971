// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'activityForm',
  formField: {
    name: {
      name: 'name',
      label: "Nom de l'agence",
      placeholder: "Nom de l'agence",
      required: true,
    },
    approved: {
      name: 'approved',
      label: 'Approuvé',
    },
    enabled: {
      name: 'enabled',
      label: 'Activé',
    },
    country: {
      name: 'country',
      label: 'Pays',
      placeholder: 'Choisissez une pays',
      noOptionsMessage: () => 'Aucun pays trouvée',
      disabled: true,
    },
    address: {
      name: 'address',
      label: 'Adresse',
      placeholder: 'Adresse',
      required: true,
    },
    email: {
      name: 'email',
      label: 'Adresse mail',
      placeholder: 'Adresse mail',
      type: 'email',
      required: true,
    },
    telephone_number: {
      name: 'phone_number',
      label: 'Numéro de téléphone',
      placeholder: 'Numéro de téléphone',
      type: 'tel',
      required: true,
      mask: '+33 9 99 99 99 99',
    },
    fix_fee: {
      name: 'fix_fee',
      label: 'Taux fixe',
      type: 'number',
    },
    variable_fee: {
      name: 'variable_fee',
      label: 'Taux varié',
      type: 'number',
    },
    max_activities: {
      name: 'max_activities',
      label: 'Activités maximum',
      type: 'number',
    },
    stripe_account_id: {
      name: 'stripe_account_id',
      label: 'Compte Stripe',
    },
    stripe_account_verified: {
      name: 'stripe_account_verified',
      label: 'Compte Stripe vérifié',
    },
    stripe_charges_enabled: {
      name: 'stripe_charges_enabled',
      label: 'Frais Stripe',
    },
    stripe_payouts_enabled: {
      name: 'stripe_payouts_enabled',
      label: 'Paiement Stripe autorisé',
    },
    mod_referencing: {
      name: 'mod_referencing',
      label: 'Module référencement',
    },
    mod_opinions: {
      name: 'mod_opinions',
      label: 'Module avis',
    },
    mod_activities: {
      name: 'mod_activities',
      label: 'Module activités',
    },
    mod_ticket_office_free: {
      name: 'mod_ticket_office_free',
      label: 'Module mod_ticket_office_free',
    },
    mod_ticker_office_paying: {
      name: 'mod_ticker_office_paying',
      label: 'Module mod_ticker_office_paying',
    },
    mod_chat: {
      name: 'mod_chat',
      label: 'Module mod_chat',
    },
    mod_events: {
      name: 'mod_events',
      label: 'Module mod_events',
    },
    mod_audioguide: {
      name: 'mod_audioguide',
      label: 'Module mod_audioguide',
    },
    mod_translation: {
      name: 'mod_translation',
      label: 'Module mod_translation',
    },
    mod_interactive_map: {
      name: 'mod_interactive_map',
      label: 'Module mod_interactive_map',
    },
    cgu: {
      name: 'cgu_status',
      label: "Accepter les Conditions Générales d'Utilisation",
      required: true,
    },
  },
};
