import React from 'react';

const Step = ({active, i, title, subtitle}) => {
  const classNames = ['wizard-step'].filter((el) => el).join(' ');

  return (
    <div
      data-wizard-type="step"
      data-wizard-state={active ? 'current' : 'pending'}
      className={classNames}
    >
      <div className="wizard-wrapper">
        <div className="wizard-number">{i}</div>
        <div className="wizard-label">
          <div className="wizard-title">{title}</div>
          <div className="wizard-desc font-weight-light font-size-sm">{subtitle}</div>
        </div>
      </div>
    </div>
  );
};

const AddUserStepper = ({steps, currentStep}) => {
  return (
    <div className="wizard-nav">
      <div className="wizard-steps">
        {steps.map((el) => (
          <Step active={currentStep === el.id} key={el.id} i={el.id} {...el} />
        ))}
      </div>
    </div>
  );
};

export default AddUserStepper;
