import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useSubheader} from '../../../_metronic/layout';
import DashboardElement from './Sites/DashboardSite';

export function DashboardPage() {
  const subheader = useSubheader();
  const {user, is_manager, is_super_manager} = useSelector((state) => state.auth);
  useEffect(() => {
    subheader.setTitle(null);
  }, [subheader]);
  return (
    <div className="container">
      <div>
        <h1 className="font-weight-bolder">Bonjour, {user.username} !</h1>
        <p>Voici tous les sites touristiques dont vous avez accès</p>
      </div>
      <div className="row">
        {is_manager &&
          user.touristic_sites?.map((el) => <DashboardElement el={el} key={el.id} />)}
        {is_super_manager &&
          user?.agencies[0]?.touristic_sites?.map((el) => (
            <DashboardElement el={el} key={el.id} />
          ))}
      </div>
    </div>
  );
}
