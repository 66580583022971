import ManagerForm from '../../ManagerForm';

const {
  formField: {
    username,
    firstname,
    lastname,
    gender_id,
    is_super_manager,
    touristic_sites,
    fonction,
  },
} = ManagerForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [username.name]: '',
  [firstname.name]: '',
  [lastname.name]: '',
  [gender_id.name]: 0,
  [is_super_manager.name]: false,
  [touristic_sites.name]: [],
  [fonction.name]: '',
};
