// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'managerForm',
  formField: {
    agency: {
      name: 'agency_id',
      label: 'Agence',
      isDisabled: true,
    },
    touristic_sites: {
      name: 'touristic_sites',
      label: 'Sites touristiques',
    },
    touristic_site: {
      name: 'touristic_site',
      label: 'Site touristique',
    },
  },
};
