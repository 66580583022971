import React from 'react';
import {Card} from 'react-bootstrap';

const NoAccessPage = () => {
  return (
    <Card className="shadow">
      <Card.Body>
        <h1 className="text-primary">Vous n'avez pas accès à ce module !</h1>
        <p className="m-0">
          Merci de vous diriger vers l'administration d'Henri pour changer d'abonnement.
        </p>
      </Card.Body>
    </Card>
  );
};

export default NoAccessPage;
