import React from 'react';
import {useSelector} from 'react-redux';
import FileInput from '../../../../../_metronic/layout/components/form/fileInput/fileInput';
import MyInput from '../../../../../_metronic/layout/components/form/input/input';
import MySelect from '../../../../../_metronic/layout/components/form/select/select';
import EventsForm from '../../EventsForm';

const {
  formField: {title, category, description, images},
} = EventsForm;

const EventCreateInfo = (props) => {
  const {categories} = useSelector((state) => state.auth);

  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{props.title}</h5>
      </div>
      <MyInput {...props} {...title} />
      <MyInput {...props} {...description} />
      <MySelect
        {...category}
        value={categories.filter((el) => el.value === props.values[category.name])}
        {...props}
        options={categories}
      />
      <FileInput
        {...props}
        {...images}
        appendImages="touristic_site/event"
        multiple={false}
      />
    </>
  );
};

export default EventCreateInfo;
