import {Form} from 'formik';
import React from 'react';

const CreateManagerView = ({
  title,
  children,
  previous,
  next,
  submit,
  isLast,
  isFirst,
}) => {
  return (
    <div className="col-xl-12 col-xxl-10">
      <Form className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
        <div className="row justify-content-center">
          <div className="col-xl-9">
            {children}
            <div className="d-flex justify-content-between border-top pt-10 mt-15">
              <div className="mr-2">
                {!isFirst && (
                  <button
                    type="button"
                    id="prev-step"
                    className="btn btn-light-primary font-weight-bolder px-9 py-4"
                    onClick={previous.onClick}
                  >
                    {previous.title}
                  </button>
                )}
              </div>
              <div>
                {isLast && (
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bolder px-9 py-4"
                  >
                    {submit.title}
                  </button>
                )}
                {!isLast && (
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bolder px-9 py-4"
                  >
                    {next.title}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateManagerView;
