import TouristicSitesForm from '../../TouristicSitesForm';

const {
  formField: {
    title_fr,
    category,
    agency,
    ambassador_review,
    email,
    files,
    address,
    telephone_number,
    website_url,
    schedule,
    days,
    published,
    video,
    tickets,
    description_fr,
    title_en,
    description_en,
    title_it,
    description_it,
    title_ge,
    description_ge,
    title_sp,
    description_sp,
    title_cn,
    description_cn,
    title_sa,
    description_sa,
    title_pt,
    description_pt,
    title_jp,
    description_jp,
    title_ru,
    description_ru,
  },
} = TouristicSitesForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [title_fr.name]: undefined,
  [category.name]: 0,
  [agency.name]: undefined,
  [ambassador_review.name]: undefined,
  [email.name]: undefined,
  [files.files_name]: [],
  [files.old_files_name]: [],
  [address.name]: undefined,
  [telephone_number.name]: undefined,
  [website_url.name]: undefined,
  [schedule.name]: {},
  [days.name]: [],
  [published.name]: false,
  [video.name]: undefined,
  [tickets.name]: undefined,
  [description_fr.name]: undefined,
  [title_en.name]: undefined,
  [description_en.name]: undefined,
  [title_it.name]: undefined,
  [description_it.name]: undefined,
  [title_ge.name]: undefined,
  [description_ge.name]: undefined,
  [title_sp.name]: undefined,
  [description_sp.name]: undefined,
  [title_cn.name]: undefined,
  [description_cn.name]: undefined,
  [title_sa.name]: undefined,
  [description_sa.name]: undefined,
  [title_pt.name]: undefined,
  [description_pt.name]: undefined,
  [title_jp.name]: undefined,
  [description_jp.name]: undefined,
  [title_ru.name]: undefined,
  [description_ru.name]: undefined,
};
