import React from 'react';
import {Modal} from 'react-bootstrap';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_GET_TICKET} from '../../../constants/api';
import CreateTicket from '../CreateTicket/CreateTicket';

const TicketEdit = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [ticket, setTicket] = React.useState(null);

  React.useEffect(() => {
    if (show) {
      handleRequest('get', API_GET_TICKET(id), {}, config, (data, status) => {
        if (status === 'Done') {
          const res = {
            ...data,
            duration: data.duration || 0,
            price: data.price || 0.0,
            start_date: data.start_date || '',
            end_date: data.end_date || '',
            schedule: data.schedule || '[]',
            touristic_site: data.touristic_site,
          };
          setTicket(res);
        } else if (status === 'Error') {
          console.error(data);
          onHide();
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setTicket(undefined);
    onHide();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName="create-modal"
    >
      {ticket && <CreateTicket ticket={ticket} onHide={handleHide} refresh={refresh} />}
    </Modal>
  );
};

export default TicketEdit;
