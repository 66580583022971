import React from 'react';

const useStep = (currentDefault, totalSteps) => {
  const [current, setCurrent] = React.useState(currentDefault);

  const nextStep = () => {
    if (current < totalSteps) setCurrent(current + 1);
  };

  const previousStep = () => {
    if (current > 1) setCurrent(current - 1);
  };

  return [current, nextStep, previousStep, setCurrent];
};

export default useStep;
