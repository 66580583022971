import React, {useCallback, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {Route, Switch} from 'react-router-dom';
import {useSubheader} from '../../../_metronic/layout';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import {REVIEWS, REVIEWS_EDIT, REVIEWS_DELETE} from '../../constants/constant-routes';
import {API_GET_ALL_REVIEWS} from '../../constants/api';
import ReviewsTSEdit from './ReviewsTSEdit';
import ReviewsTSDelete from './ReviewsTSDelete';

const ReviewsTSPage = ({history, ...props}) => {
  const subheader = useSubheader();
  const ref = React.createRef();
  useEffect(() => {
    subheader.setTitle('Avis');
  }, [subheader]);

  const touristicSiteComponent = useCallback((r) => {
    return (
      <span
        key={r.id}
        className={'d-inline-block text-truncate'}
        style={{maxWidth: '200px'}}
        data-toggle="tooltip"
        data-placement="bottom"
        title={`${r.touristic_site.id} - ${r.touristic_site.title_fr}`}
      >
        {r.touristic_site.id} - {r.touristic_site.title_fr}
      </span>
    );
  }, []);

  const commentComponent = useCallback((r) => {
    return (
      <span
        key={r.id}
        className={'d-inline-block text-truncate'}
        style={{maxWidth: '200px'}}
        data-toggle="tooltip"
        data-placement="bottom"
        title={`${r.content}`}
      >
        {r.content}
      </span>
    );
  }, []);

  const dateComponent = useCallback((r) => {
    return <span key={r.id}>{new Date(r.created_at).toLocaleDateString()}</span>;
  }, []);
  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
      filtering: false,
      sorting: true,
    },
    {
      field: 'touristic_site_id',
      title: 'Site touristiques',
      filtering: true,
      sorting: false,
      render: touristicSiteComponent,
      width: '100%',
    },
    {
      field: 'note',
      title: 'Note',
      filtering: false,
      sorting: true,
    },
    {
      field: 'content',
      title: 'Contenu',
      render: commentComponent,
      filtering: false,
      sorting: false,
      width: '100%',
    },
    {
      field: 'origin',
      title: 'Origine',
      sorting: true,
      lookup: {
        Henritrip: 'Henritrip',
        Google: 'Google',
        Other: 'Autres',
      },
    },
    {
      field: 'created_at',
      title: 'Date de création',
      render: dateComponent,
      filtering: false,
      sorting: true,
    },
  ];
  const actions = [
    {
      icon: 'view_column',
      tooltip: 'Voir',
      onClick: (event, rowData) => history.push(REVIEWS_EDIT(rowData.id)),
    },
    {
      icon: 'delete',
      tooltip: 'Supprimer',
      onClick: (event, rowData) => history.push(REVIEWS_DELETE(rowData.id)),
    },
  ];
  return (
    <>
      <Card className="p-4 rounded-lg shadow-sm">
        <h3 className="m-0">Gestion des avis</h3>
        <p className="text-muted">Gérer les avis de vos sites touristiques</p>
        <MyTable
          ref={ref}
          columns={columns}
          apiUrl={API_GET_ALL_REVIEWS('ts')}
          actions={actions}
        />
      </Card>
      <Switch>
        <Route path={REVIEWS_EDIT(':id')}>
          {({history, match}) => (
            <ReviewsTSEdit
              refresh={ref}
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(REVIEWS);
              }}
            />
          )}
        </Route>
        <Route path={REVIEWS_DELETE(':id')}>
          {({history, match}) => (
            <ReviewsTSDelete
              refresh={ref}
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(REVIEWS);
              }}
            />
          )}
        </Route>
      </Switch>
    </>
  );
};

export default ReviewsTSPage;
