import React from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';

const styles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#EBEDF3',
    borderRadius: 0.3 + 'rem',
    backgroundColor: '#EBEDF3',
    padding: 0.3 + 'rem',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#707070',
  }),
};

const AsyncSelectFormik = (props) => {
  const handleChange = (v, action) => {
    if (!props.setFieldValue) {
      props.onChange(v.value);
      return;
    }
    if (props.isMulti) {
      props.setFieldValue(
        props.name,
        v.map((el) => el.value)
      );
      return;
    }
    props.setFieldValue(props.name, v?.value ?? 0);
  };

  const handleBlur = () => {
    if (props.setFieldTouched) props.setFieldTouched(props.name, true);
  };

  return (
    <div className={'form-group fv-plugins-icon-container ' + props.parentClasses}>
      {props.label && (
        <div className="d-flex justify-content-between">
          <label className="font-size-h6 text-dark">
            {props.label}
            {props.required ? ' *' : ''}
          </label>
          {props.labelSuffix}
        </div>
      )}
      <AsyncSelect
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        styles={props.styles || styles}
      />
      {props.touched && props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
      {props.error && <p className="text-danger">{props.invalidMessage}</p>}
      {props.helper && <small>{props.helper}</small>}
    </div>
  );
};

AsyncSelectFormik.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  classNames: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.object,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  error: PropTypes.bool,
  invalidMessage: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  required: PropTypes.bool,
  helper: PropTypes.string,
};

export default AsyncSelectFormik;
