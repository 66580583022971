import React, {useRef} from 'react';
import AsyncSelectFormik from '../select/asyncSelect';
import {API_TOURISTIC_SITE_SEARCH} from '../../../../../app/constants/api';
import useRequest from '../../../../_helpers/useRequest';
import useConfig from '../../../../_helpers/useConfig';

const InputTouristicSite = (props) => {
  const timeout = useRef();
  const [handleRequest] = useRequest();
  const config = useConfig();

  const getTS = (v, cb) => {
    if (v) {
      clearTimeout(timeout.current);
      const s = v || props.values.touristic_site?.title;
      timeout.current = setTimeout(() => {
        if (s) {
          handleRequest(
            'get',
            API_TOURISTIC_SITE_SEARCH(s),
            {},
            config,
            (data, status) => {
              if (status === 'Done') {
                cb(
                  data.map((el) => ({
                    label: `${el.id} - ${el.title_fr}`,
                    value: el.id,
                  }))
                );
              } else if (status === 'Error') {
                console.log(data);
              }
            }
          );
        }
      }, 500);
    }
  };

  return (
    <AsyncSelectFormik
      defaultOptions
      defaultValue={{
        label: props.values.touristic_site?.title_fr
          ? `${props.values.touristic_site?.id} - ${props.values.touristic_site?.title_fr}`
          : props.values.touristic_site?.title_fr,
        value: props.values.touristic_site?.id || '',
      }}
      cacheOptions
      loadOptions={getTS}
      {...props}
    />
  );
};

export default InputTouristicSite;
