import React from 'react';
import Item from './Item';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

const SortableItem = ({el, onClick}) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: `${el.id} ${el.day_number} ${el.rank}`,
    data: el.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Item el={el} onClick={onClick} {...attributes} {...listeners} />
    </div>
  );
};

export default SortableItem;
