import React, {useEffect, useState} from 'react';
import {Card, Modal} from 'react-bootstrap';
import {FieldArray, Form, Formik} from 'formik';
import ManagerFormInitialValues from './Formfields/ManagerFormInitialValues';
import ManagerSchemaValidation from './Formfields/ManagerSchemaValidation';
import MySelect from '../../../_metronic/layout/components/form/select/select';
import MyCheckbox from '../../../_metronic/layout/components/form/checkbox/checkbox';
import {useSelector} from 'react-redux';
import useRequest from '../../../_metronic/_helpers/useRequest';
import useConfig from '../../../_metronic/_helpers/useConfig';
import {API_GET_ONE_USER, API_UPDATE_MANAGER} from '../../constants/api';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ManagerForm from './Formfields/ManagerForm';
import {FormGroup} from '@material-ui/core';
import InputTouristicSite from '../../../_metronic/layout/components/form/address/inputTouristicSite';

const {
  formField: {agency, touristic_sites, touristic_site},
} = ManagerForm;

const ManagerEdit = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [user, setUser] = useState(null);
  const {agencies} = useSelector((state) => state.auth);
  const [agencyTS, setAgencyTS] = useState(null);

  useEffect(() => {
    if (show) {
      handleRequest('get', API_GET_ONE_USER(id), {}, config, (data, status) => {
        if (status === 'Done') {
          setAgencyTS(data.agencies[0]?.touristic_sites);
          const belongToAgency = data.agencies[0]?.id || undefined;
          if (belongToAgency) {
            setUser({
              id: data.id,
              agency_id: data.agencies[0]?.id || undefined,
              touristic_sites: data.touristic_sites?.map((e) => e.id) || [],
            });
          } else {
            setUser({
              id: data.id,
              touristic_site: data.agencies[0]?.id
                ? undefined
                : data.touristic_sites[0] || undefined,
            });
          }
        } else if (status === 'Error') {
          console.log(data);
          onHide();
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setUser(undefined);
    onHide();
  };

  const handleSubmit = (values, formikBag) => {
    const data = {...values};
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    });
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Chargement',
      message: 'Enregistrer modifications en cours ...',
      autoClose: false,
      disallowClose: true,
    });
    handleRequest('put', API_UPDATE_MANAGER(user.id), data, config, (dataRes, status) => {
      if (status === 'Done') {
        updateNotification({
          id: 'load-data',
          color: 'teal',
          title: 'OK',
          message: 'Les modifications sont Enregistrées',
          icon: <CheckIcon />,
          autoClose: 4000,
        });
        refresh.current && refresh.current.onQueryChange();
        handleHide();
      } else if (status === 'Error') {
        const msg = {
          code: dataRes?.status,
          msg: dataRes?.text,
        };
        formikBag.setStatus(msg);
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Erreur',
          message: "Une erreur s'est produite",
          icon: <CloseIcon />,
          autoClose: 4000,
          loading: false,
        });
      }
    });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {user && (
        <Card className="rounded-lg shadow-sm card-custom card-transparent">
          <div className="card-body p-0">
            <div
              className="wizard wizard-4"
              id="kt_wizard"
              data-wizard-state="first"
              data-wizard-clickable="true"
            >
              <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <Formik
                      initialValues={user || ManagerFormInitialValues}
                      validationSchema={ManagerSchemaValidation[0]}
                      validateOnBlur={false}
                      validateOnChange={false}
                      validateOnMount={false}
                      onSubmit={handleSubmit}
                    >
                      {({handleSubmit, ...subProps}) => (
                        <div className="col-xl-12 col-xxl-10">
                          <Form
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                            id="kt_form"
                          >
                            <div className="row justify-content-center">
                              <div className="col-xl-9">
                                {subProps.status && (
                                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                      Une erreur de type {subProps.status.code} est
                                      survenue...
                                      <p>Message: {subProps.status.msg}</p>
                                    </div>
                                  </div>
                                )}
                                {!subProps.isValid && subProps.submitCount > 0 && (
                                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                      Vérifiez que toutes les données entrées sont
                                      justes...
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="my-5 step"
                                  data-wizard-type="step-content"
                                  data-wizard-state="current"
                                >
                                  <h5 className="text-dark font-weight-bold mb-10">
                                    Gérer les sites touristique
                                  </h5>
                                </div>

                                <MySelect
                                  {...agency}
                                  value={agencies.filter(
                                    (el) => el.value === subProps.values[agency.name]
                                  )}
                                  {...subProps}
                                  options={agencies}
                                />
                                {user.agency_id ? (
                                  <FormGroup>
                                    <FieldArray
                                      name={touristic_sites.name}
                                      render={(arrayHelpers) =>
                                        agencyTS.map((el) => (
                                          <MyCheckbox
                                            key={el.id}
                                            name={touristic_sites.name}
                                            el={el}
                                            arrayHelpers={arrayHelpers}
                                            {...subProps}
                                          ></MyCheckbox>
                                        ))
                                      }
                                    ></FieldArray>
                                  </FormGroup>
                                ) : (
                                  <InputTouristicSite {...subProps} {...touristic_site} />
                                )}

                                <div className="d-flex justify-content-between border-top pt-10 mt-15">
                                  <div className="mr-2"></div>
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn btn-primary font-weight-bolder px-9 py-4"
                                    >
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default ManagerEdit;
