import React from 'react';
import {ChatContext} from './chatProvider';

const useChat = () => {
  const chat = React.useContext(ChatContext);

  const sendMessage = (
    type,
    content,
    conversationID = '',
    imageName = undefined,
    hashName = undefined
  ) => {
    const data = {
      type: type,
      conversation_id: conversationID,
      sender_type: chat.senderType,
      payload: {
        content: content,
        image_name: imageName,
        hash_name: hashName,
      },
    };

    if (chat.ws) {
      try {
        chat.ws.send(JSON.stringify(data));
        return true;
      } catch (error) {
        console.error(error);
      }
    }
    return false;
  };

  const sendPhoto = (content, image, conversationID) => {
    const data = {
      input: content,
      file: image[0],
    };
    if (data.file) {
      data.hash_name = conversationID + '_';
      for (var i = 0; i < 10; i++) {
        data.hash_name += String.fromCharCode(Math.floor(Math.random() * 26) + 65);
      }
      // data.hash_name += "." + data.file.name.split(".")[1];
      data.hash_name += '.' + data.file.type.split('/')[1];
      data.image_name = data.file.name;
      var form = new FormData();
      form.append('file', data.file);
      var request = new XMLHttpRequest();
      request.open(
        'POST',
        'https://messaging.henritrip.fr/upload?filename=' + data.hash_name
      );
      request.send(form);
    }
    sendMessage('message', data.input, conversationID, data.image_name, data.hash_name);
  };

  const onRead = (convID) => {
    if (!chat?.conversations?.find((el) => el.id === convID)) return;
    sendMessage('read_conv', '', convID);
  };

  return [chat, sendMessage, sendPhoto, onRead];
};

export default useChat;
