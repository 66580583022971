import React, {useCallback, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {useSubheader} from '../../../_metronic/layout';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import {TICKET_DELETE, TICKET_EDIT, TICKETS_ROUTE} from '../../constants/constant-routes';
import {API_GET_ALL_TICKETS_PAGINATION} from '../../constants/api';
import {Route} from 'react-router-dom';
import TicketEdit from './TicketEdit/TicketEdit';
import TicketDelete from './TicketEdit/TicketDelete';

const TicketsPage = ({history, ...props}) => {
  const subheader = useSubheader();
  const ref = React.createRef();

  useEffect(() => {
    subheader.setTitle('Billets');
  }, [subheader]);

  const TSComponent = useCallback((row) => {
    return <span>{row.touristic_site?.title}</span>;
  }, []);

  const statusComponent = useCallback((row) => {
    return (
      <div
        className={`${
          row.enabled ? 'bg-primary text-white' : 'bg-warning'
        } p-2 rounded no-wrap`}
      >
        <span>{row.enabled ? 'Active' : 'Désactive'}</span>
      </div>
    );
  }, []);

  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
      filtering: false,
    },
    {
      field: 'title',
      title: 'Titre',
      filtering: false,
    },
    {
      field: 'touristic_site_id',
      title: 'Site touristique',
      render: TSComponent,
      filtering: false,
    },
    {
      field: 'enabled',
      title: 'Statut',
      render: statusComponent,
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) => history.push(TICKET_EDIT(rowData.id)),
    },
    {
      icon: 'delete',
      tooltip: 'Supprimer',
      onClick: (event, rowData) => history.push(TICKET_DELETE(rowData.id)),
    },
  ];

  return (
    <Card className="p-4 rounded-lg shadow-sm">
      <h3 className="m-0">Gestion des billets</h3>
      <p className="text-muted">Gérer les billets de votre agence</p>
      <MyTable
        ref={ref}
        columns={columns}
        apiUrl={API_GET_ALL_TICKETS_PAGINATION}
        actions={actions}
      />
      <Route path={TICKET_EDIT(':id')}>
        {({history, match}) => (
          <TicketEdit
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(TICKETS_ROUTE);
            }}
          />
        )}
      </Route>
      <Route path={TICKET_DELETE(':id')}>
        {({history, match}) => (
          <TicketDelete
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(TICKETS_ROUTE);
            }}
          />
        )}
      </Route>
    </Card>
  );
};

export default TicketsPage;
