import {FormGroup} from '@material-ui/core';
import {capitalize} from 'lodash';
import React from 'react';
import MyCheckbox from '../../../../../_metronic/layout/components/form/checkbox/checkbox';
import TouristicSitesForm from '../../TouristicSitesForm';
import InputMask from '../../../../../_metronic/layout/components/form/input/inputMask';

const {
  formField: {schedule, days},
} = TouristicSitesForm;

const DAYS = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

const CreateTouristicSiteTime = (props) => {
  React.useEffect(() => {
    const scheduleValues = {...props.values[schedule.name]};

    Object.keys(props.values[schedule.name]).forEach((el) => {
      if (!props.values.days?.includes(el)) scheduleValues[el] = undefined;
    });

    props.setFieldValue(schedule.name, scheduleValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.days]);

  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{props.title} *</h5>
      </div>
      <FormGroup>
        {DAYS.map((el, i) => (
          <React.Fragment key={el}>
            <MyCheckbox name={days.name} value={el} label={capitalize(el)} {...props} />
            {props.values[days.name].includes(el) && (
              <FormGroup className="pl-3">
                <MyCheckbox
                  name={`${schedule.name}.${el}.closed_noon`}
                  label="Fermé à midi"
                  className="text-primary m-0"
                  {...props}
                />
                <div className="row m-0 p-0">
                  <InputMask
                    error={
                      props.errors[schedule.name] &&
                      props.errors[schedule.name][el]?.open_morning
                    }
                    parentClasses="col-12 col-md-6"
                    {...props}
                    name={`${schedule.name}.${el}.open_morning`}
                    placeholder="00:00"
                    mask="99:99"
                    label={`Heure d'ouverture${
                      props.values[schedule.name][el]?.closed_noon ? ' le matin' : ''
                    }`}
                  />
                  {props.values[schedule.name] &&
                    props.values[schedule.name][el]?.closed_noon && (
                      <>
                        <InputMask
                          error={
                            props.errors[schedule.name] &&
                            props.errors[schedule.name][el]?.close_morning
                          }
                          parentClasses="col-12 col-md-6"
                          {...props}
                          name={`${schedule.name}.${el}.close_morning`}
                          placeholder="00:00"
                          mask="99:99"
                          label="Heure de fermeture le midi"
                        />
                        <InputMask
                          error={
                            props.errors[schedule.name] &&
                            props.errors[schedule.name][el]?.open_afternoon
                          }
                          parentClasses="col-12 col-md-6"
                          {...props}
                          name={`${schedule.name}.${el}.open_afternoon`}
                          placeholder="00:00"
                          mask="99:99"
                          label="Heure d'ouverture l'après-midi"
                        />
                      </>
                    )}
                  <InputMask
                    error={
                      props.errors[schedule.name] &&
                      props.errors[schedule.name][el]?.close_night
                    }
                    parentClasses="col-12 col-md-6"
                    {...props}
                    name={`${schedule.name}.${el}.close_night`}
                    placeholder="00:00"
                    mask="99:99"
                    label={`Heure de fermeture${
                      props.values[schedule.name][el]?.closed_noon ? ' le soir' : ''
                    }`}
                  />
                </div>
              </FormGroup>
            )}
            {props.touched[schedule.name] &&
            props.touched[schedule.name][el] &&
            props.errors[schedule.name] &&
            props.errors[schedule.name][el] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block text-danger">{props.errors[el]}</div>
              </div>
            ) : null}
          </React.Fragment>
        ))}
        <p className="m-0 text-muted">
          Choisissez les jours pendant lesquels le site est ouvert
        </p>
      </FormGroup>
    </>
  );
};

export default CreateTouristicSiteTime;
