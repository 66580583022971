import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import CategoryIdentifier from '../../../../_metronic/layout/components/form/category/categoryIdentifier';
import {TOURISTIC_SITE_EDIT} from '../../../constants/constant-routes';

const DashboardElement = withRouter(({el, history}) => {
  return (
    <div className="col-12 col-md-6 col-xl-4 py-4">
      <Card className="position-relative">
        <div
          className="card-img-top"
          style={{
            height: 250,
            backgroundColor: '#c4d6e5',
            backgroundImage: `url('${
              el.medias.filter((m) => m.content_type.includes('image/'))?.[0]?.url ||
              'https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg'
            }')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className="position-absolute" style={{top: 10, right: 10}}>
          {el.categories && (
            <CategoryIdentifier
              bolder
              categoryID={el.categories[0]?.id}
              categories={el.categories}
            />
          )}
        </div>
        <Card.Body>
          <h3>{el.title}</h3>
          <p>
            {el.city} - {el.zipcode}
          </p>
          <div className="d-flex justify-content-end">
            <Button
              color="primary"
              onClick={() => history.push(TOURISTIC_SITE_EDIT(el.id))}
            >
              Modifier
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
});

export default DashboardElement;
