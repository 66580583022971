import * as Yup from 'yup';
import UserForm from './UserForm';

const {
  formField: {ambassador, role, agency, ambassador_rate, verified},
} = UserForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape(
    {
      [ambassador.name]: Yup.bool(),
      [verified.name]: Yup.bool(),
      [ambassador_rate.name]: Yup.number(),
      [role.name]: Yup.string().nullable(),
      [agency.name]: Yup.number()
        .nullable(false)
        .when(['role'], {
          is: (a) => a === 'super_manager',
          then: Yup.number().nullable(false).required('Relier utilisateur à une agence'),
        }),
    },
    ['role']
  ),
];
