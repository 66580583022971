/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import {ContentRoute} from '../../../../_metronic/layout';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import ResetPassword from './ResetPassword';

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white align-items-center justify-content-center"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div className="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden col-lg-5 px-lg-20">
            {/*begin::Content header*/}
            <div className="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 w-50">
              <div className="d-flex flex-column-fluid flex-column flex-center">
                <img src="/media/logos/Logo.png" alt="" className="max-h-175px pb-6" />
                <Switch>
                  <ContentRoute path="/auth/login" component={Login} />
                  <ContentRoute path="/auth/reset-password" component={ResetPassword} />
                  <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
          {/*begin::Aside*/}

          {/*begin::Aside*/}
          <div
            className="content order-1 order-lg-2 d-flex flex-column col h-100 pb-0"
            style={{
              backgroundColor: '#B1DCED',
            }}
          ></div>
          {/*end: Aside Container*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
