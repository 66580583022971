import React from 'react';
import {Modal} from 'react-bootstrap';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_GET_ONE_ACTIVITY} from '../../../constants/api';
import CreateActivity from '../ActivityCreate/CreateActivity';

const ActivityEdit = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [activity, setActivity] = React.useState(null);

  React.useEffect(() => {
    if (show) {
      handleRequest('get', API_GET_ONE_ACTIVITY(id), {}, config, (data, status) => {
        if (status === 'Done') {
          setActivity({
            id: data.id,
            description: data.description,
            medias: data.medias,
            password: data.password,
            published: data.published,
            title: data.title,
            visit_sheets: data.visit_sheets,
            touristic_site: data.touristic_site,
            touristic_site_id: data.touristic_site_id,
          });
        } else if (status === 'Error') {
          console.error(data);
          onHide();
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setActivity(undefined);
    onHide();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName="create-modal"
    >
      {activity && (
        <CreateActivity activity={activity} onHide={handleHide} refresh={refresh} />
      )}
    </Modal>
  );
};

export default ActivityEdit;
