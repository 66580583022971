import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_PUBLIC_UPDATE_TOURISTIC_SITE} from '../../../constants/api';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

function TouristicSitePublish({refresh, id, published, show, onHide, ...props}) {
  const [handleRequest] = useRequest();
  const config = useConfig();

  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const updateTouristicSite = () => {
    const payload = {published: !published};

    showNotification({
      id: 'load-data',
      loading: true,
      title: 'En cours ...',
      message: published ? 'Dépublication en cours...' : 'Publication en cours...',
      autoClose: false,
      disallowClose: true,
    });
    handleRequest(
      'put',
      API_PUBLIC_UPDATE_TOURISTIC_SITE(id),
      payload,
      config,
      (data, status) => {
        if (status === 'Done') {
          updateNotification({
            id: 'load-data',
            color: 'teal',
            title: 'OK',
            message: published ? 'Dépublié' : 'Publié',
            icon: <CheckIcon />,
            autoClose: 4000,
          });
          refresh.current && refresh.current.onQueryChange();
          onHide();
        } else if (status === 'Error') {
          updateNotification({
            id: 'load-data',
            color: 'red',
            title: 'Erreur',
            message: "Une erreur s'est produite",
            icon: <CloseIcon />,
            autoClose: 4000,
            loading: false,
          });
          onHide();
        }
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {published ? 'Dépublier le site touristique' : 'Publier le site touristique'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {published ? (
          <span>Êtes-vous sur de vouloir dépublier ce site touristique ?</span>
        ) : (
          <span>Êtes-vous sur de vouloir publier ce site touristique ?</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Annuler
          </button>
          <> </>
          <button
            type="button"
            onClick={updateTouristicSite}
            className="btn btn-danger btn-elevate"
          >
            {published ? 'Dépublier' : 'Publier'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default TouristicSitePublish;
