import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import './date-time-picker.scss';
import fr from 'date-fns/locale/fr';
import {parseISO} from 'date-fns';
registerLocale('fr', fr);

const DateTimeRangePicker = (props) => {
  const handleChangeStart = (v) => {
    if (props.values[props.end?.name] && new Date(props.values[props.end?.name]) < v)
      props.setFieldValue(props.end?.name, '');
    props.setFieldValue(props.start?.name, v.toISOString());
    props.setFieldTouched(props.start?.name, true);
  };
  const handleChangeEnd = (v) => {
    props.setFieldValue(props.end?.name, v.toISOString());
    props.setFieldTouched(props.end?.name, true);
  };

  return (
    <>
      {props.start?.label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {props.start?.label}
            {props.start?.required ? ' *' : ''}
          </label>
          {props.start?.labelSuffix}
        </div>
      )}
      <DatePicker
        locale="fr"
        selected={
          props.values[props.start?.name] && parseISO(props.values[props.start?.name])
        }
        onChange={handleChangeStart}
        showTimeSelect
        selectsStart
        placeholderText={props.start?.placeholder}
        timeFormat="HH:mm"
        dateFormat="dd/MM/yyyy - HH:mm"
        minDate={new Date()}
        startDate={
          props.values[props.start?.name] && parseISO(props.values[props.start?.name])
        }
        endDate={props.values[props.end?.name] && parseISO(props.values[props.end?.name])}
      />
      {props.errors[props.start?.name] && (
        <p className="text-danger">{props.errors[props.start?.name]}</p>
      )}
      {props.end?.label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {props.end?.label}
            {props.end?.required ? ' *' : ''}
          </label>
          {props.end?.labelSuffix}
        </div>
      )}
      <DatePicker
        locale="fr"
        selected={
          props.values[props.end?.name] && parseISO(props.values[props.end?.name])
        }
        onChange={handleChangeEnd}
        placeholderText={props.end?.placeholder}
        showTimeSelect
        selectsEnd
        timeFormat="HH:mm"
        dateFormat="dd/MM/yyyy - HH:mm"
        startDate={
          props.values[props.start?.name] && parseISO(props.values[props.start?.name])
        }
        endDate={props.values[props.end?.name] && parseISO(props.values[props.end?.name])}
        minDate={
          props.values[props.start?.name] && parseISO(props.values[props.start?.name])
        }
      />
      {props.errors[props.end?.name] && (
        <p className="text-danger">{props.errors[props.end?.name]}</p>
      )}
    </>
  );
};

export default DateTimeRangePicker;
