import {Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Card, Modal} from 'react-bootstrap';
import {showNotification, updateNotification} from '@mantine/notifications';
import useStep from '../../../../_metronic/_helpers/useStep';
import WizardStepper from '../../../modules/MyModules/WizardStepper/WizardStepper';
import {API_GET_ONE_TOURISTIC_SITE_AUTH, API_POST_PARTNER} from '../../../constants/api';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import CreateTouristicSiteInfoManager from './CreateTouristicSiteViews/CreateTouristicSiteInfoManager';
import CreateTouristicSiteTimeManager from './CreateTouristicSiteViews/CreateTouristicSiteTimeManager';
import CreateTouristicSiteMapManager from './CreateTouristicSiteViews/CreateTouristicSiteMapManager';
import TouristicSitesValidationSchemaManager from './Formfield/TouristicSitesValidationSchemaManager';
import TouristicSitesInitialValuesManager from './Formfield/TouristicSitesInitialValuesManager';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {toAbsoluteUrl} from '../../../../_metronic/_helpers';
import CreateUserAuth from '../../Users/Auth/CreateUserAuth';
import {connect} from 'react-redux';
import * as auth from '../../../modules/Auth/_redux/authRedux';

const stepsCreateTouristicSite = [
  {
    id: 1,
    title: 'Site touristique',
    flaticon: 'flaticon-profile',
    view: CreateTouristicSiteInfoManager,
  },
  {
    id: 2,
    title: 'Retrouver le site',
    flaticon: 'flaticon-placeholder-1',
    view: CreateTouristicSiteMapManager,
  },
  {
    id: 3,
    title: "Horaires d'ouverture",
    flaticon: 'flaticon-clock-2',
    view: CreateTouristicSiteTimeManager,
  },
  {
    id: 4,
    title: 'Connexion',
    flaticon: 'flaticon-lock',
    view: CreateUserAuth,
  },
];

const CreateTouristicSiteManager = (subProps) => {
  const [current, nextStep, previousStep] = useStep(1, stepsCreateTouristicSite.length);
  const [touristicSite, setTouristicSite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [handleRequest] = useRequest();
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line
  const [isSignUp, setIsSignUp] = useState(false);
  const [startPopup, setStartPopup] = useState(true);
  useEffect(() => {
    subProps.logout();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const ts = new URLSearchParams(subProps.location.search).get('touristic_site');
    setLoading(true);
    if (ts) {
      handleRequest(
        'get',
        API_GET_ONE_TOURISTIC_SITE_AUTH(ts),
        {},
        {},
        (data, status) => {
          if (status === 'Done') {
            const touristic = data;
            delete touristic['email'];
            delete touristic['phone_number'];
            touristic.files = [];
            touristic.schedule = JSON.parse(touristic.schedule || '{}');
            touristic.website_url = touristic.website_url || undefined;
            touristic.days = Object.keys(touristic.schedule);
            touristic.category =
              (touristic.categories && touristic.categories[0]?.id) || 53;
            touristic.medias = touristic.medias?.filter((m) =>
              m.content_type.includes('image/')
            );
            touristic.zip_code = touristic?.zipcode;
            setTouristicSite(touristic);
            setLoading(false);
          } else if (status === 'Error') {
            console.error(data);
            setLoading(false);
          }
        }
      );
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values, formikBag) => {
    if (current === stepsCreateTouristicSite.length) {
      const ts = new URLSearchParams(subProps.location.search).get('touristic_site');
      setIsSignUp(values.sign_up);
      const data = {...values};
      data.schedule = JSON.stringify(data.schedule);
      data.public = true;
      data.published = false;
      data.ts_original_id = ts || null;
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === undefined) {
          delete data[key];
        }
      });
      data.files = data.files.map((file) => ({file: file, language: 'fr'}));
      if (!data.sign_up) {
        delete data['username'];
        delete data['firstname'];
        delete data['lastname'];
        delete data['gender'];
        delete data['birthdate'];
        delete data['password_confirmation'];
        delete data['statement'];
      }
      delete data['sign_up'];
      showNotification({
        id: 'load-data',
        loading: true,
        title: 'Chargement',
        message: 'En cours de création...',
        autoClose: false,
        disallowClose: true,
      });
      handleRequest('post', API_POST_PARTNER, data, {}, (data, status) => {
        if (status === 'Done') {
          updateNotification({
            id: 'load-data',
            color: 'teal',
            title: 'OK',
            message: 'Création succès',
            icon: <CheckIcon />,
            autoClose: 4000,
          });
          setSuccess(true);
        } else if (status === 'Error') {
          const msg = {
            code: data?.status,
            msg: data?.text,
          };
          formikBag.setStatus(msg);
          setSuccess(false);
          updateNotification({
            id: 'load-data',
            color: 'red',
            title: 'Erreur',
            message: "Une erreur s'est produite",
            icon: <CloseIcon />,
            autoClose: 4000,
            loading: false,
          });
        }
      });
    } else {
      nextStep();
    }
  };

  const Step = stepsCreateTouristicSite.filter((el) => el.id === current)[0];

  return (
    <div className="w-100 h-auto bg-light d-flex justify-content-center align-items-center flex-column">
      <div className="h-100 pb-5">
        <img
          src={toAbsoluteUrl('/media/logos/Logo.png')}
          alt=""
          className="max-h-125px pb-5"
        />
      </div>
      {!loading && (
        <>
          <Modal
            size="lg"
            show={startPopup}
            onHide={() => setStartPopup(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
          >
            <Card className="rounded-lg shadow-sm">
              <div className="card-body p-0">
                {touristicSite ? (
                  <div className="d-flex flex-column p-5">
                    <h5 className="p-5">
                      Bonne nouvelle, votre établissement est déjà référencé chez nous,
                      nous vous invitons à vérifier vos informations.
                    </h5>
                    <div className="w-100 d-flex">
                      <button
                        type="button"
                        id="prev-step"
                        className="btn btn-light-primary font-weight-bolder px-9 py-4 ml-auto"
                        onClick={() => setStartPopup(false)}
                      >
                        Continuer
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column p-5">
                    <h5 className="p-5">
                      Votre établissement n'est pas encore référencé chez nous, nous vous
                      invitons à compléter vos informations.
                    </h5>
                    <div className="w-100 d-flex">
                      <button
                        type="button"
                        id="prev-step"
                        className="btn btn-light-primary font-weight-bolder px-9 py-4 ml-auto"
                        onClick={() => setStartPopup(false)}
                      >
                        Continuer
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </Modal>
          <Modal
            size="lg"
            show={success}
            onHide={() => console.log(null)}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
          >
            <Card className="rounded-lg shadow-sm">
              <div className="card-body p-5">
                <h5 style={{color: 'green'}} className="p-5">
                  Bravo, comme de nombreux établissement vous avez pris le contrôle de
                  votre fiche sur Henri Trip, quelqu'un de notre équipe va vous contacter
                  pour vous expliquer les prochaines étapes.
                </h5>
              </div>
            </Card>
          </Modal>
          <Card className="rounded-lg shadow-sm" style={{width: '80vw'}}>
            <div className="card-body p-0">
              <div
                className="wizard wizard-1"
                id="kt_wizard"
                data-wizard-state="first"
                data-wizard-clickable="false"
              >
                <WizardStepper steps={stepsCreateTouristicSite} currentStep={current} />
                <h5 style={{textAlign: 'center'}} className="pt-5">
                  Sachez que la vérification de vos informations et le référencement sur
                  Henri Trip est gratuit.
                </h5>
                <div className="card card-custom card-shadowless rounded-top-0">
                  <div className="card-body p-0">
                    <div className="justify-content-center p-0 m-0 w-100 py-8 px-8 py-lg-15 px-lg-10">
                      <Formik
                        initialValues={
                          touristicSite || TouristicSitesInitialValuesManager
                        }
                        validationSchema={
                          TouristicSitesValidationSchemaManager[current - 1]
                        }
                        validateOnMount={false}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={handleSubmit}
                      >
                        {({values, handleSubmit, ...props}) => {
                          return (
                            <div className="w-100">
                              <Form
                                className="form fv-plugins-bootstrap fv-plugins-framework"
                                id="kt_form"
                              >
                                <div className="row justify-content-center">
                                  <div className="w-100">
                                    {props.status && (
                                      <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                          Une erreur de type {props.status.code} est
                                          survenue...
                                          <p>Message: {props.status.msg}</p>
                                        </div>
                                      </div>
                                    )}
                                    {!props.isValid && props.submitCount > 0 && (
                                      <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                          Vérifiez que toutes les données entrées sont
                                          justes...
                                        </div>
                                      </div>
                                    )}
                                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                                    <Step.view
                                      title={Step.title}
                                      values={values}
                                      {...props}
                                    />
                                    <div className="d-flex justify-content-between border-top pt-10 mt-15">
                                      <div className="mr-2">
                                        {!(current === 1) && (
                                          <button
                                            type="button"
                                            id="prev-step"
                                            className="btn btn-light-primary font-weight-bolder px-9 py-4"
                                            onClick={previousStep}
                                          >
                                            Précédent
                                          </button>
                                        )}
                                      </div>
                                      <div>
                                        {current === stepsCreateTouristicSite.length && (
                                          <button
                                            type="submit"
                                            className="btn btn-primary font-weight-bolder px-9 py-4"
                                          >
                                            Sauvegarder
                                          </button>
                                        )}
                                        {!(
                                          current === stepsCreateTouristicSite.length
                                        ) && (
                                          <button
                                            type="submit"
                                            className="btn btn-primary font-weight-bolder px-9 py-4"
                                          >
                                            Suivant
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default connect(null, auth.actions)(CreateTouristicSiteManager);
