import React, {useEffect, useState} from 'react';
import {Card, Modal} from 'react-bootstrap';
import {Form, Formik} from 'formik';
import UserFormInitialValues from './Formfields/UserFormInitialValues';
import UserSchemaValidation from './Formfields/UserSchemaValidation';
import UserForm from './Formfields/UserForm';
import MySelect from '../../../_metronic/layout/components/form/select/select';
import MyCheckbox from '../../../_metronic/layout/components/form/checkbox/checkbox';
import {useSelector} from 'react-redux';
import useRequest from '../../../_metronic/_helpers/useRequest';
import useConfig from '../../../_metronic/_helpers/useConfig';
import {API_GET_ONE_USER, API_UPDATE_USER} from '../../constants/api';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InputInline from '../../../_metronic/layout/components/form/input/inputInline';

const {
  formField: {ambassador, role, agency, ambassador_rate, verified},
} = UserForm;

const roles = [
  {label: 'Pas de role', value: undefined},
  {label: 'Admin', value: 'admin'},
  {label: 'Super Manager', value: 'super_manager'},
  {label: 'Manager', value: 'manager'},
];
const UserEdit = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [user, setUser] = useState(null);
  const {agencies} = useSelector((state) => state.auth);

  useEffect(() => {
    if (show) {
      handleRequest('get', API_GET_ONE_USER(id), {}, config, (data, status) => {
        if (status === 'Done') {
          setUser({
            id: data.id,
            ambassador: data.ambassador,
            ambassador_rate: data.ambassador_rate,
            role: data.is_admin
              ? 'admin'
              : data.is_super_manager
              ? 'super_manager'
              : data.is_manager
              ? 'manager'
              : undefined,
            agency_id: data.agencies[0]?.id || undefined,
            verified: data.verified,
          });
        } else if (status === 'Error') {
          console.log(data);
          onHide();
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setUser(undefined);
    onHide();
  };

  const handleSubmit = (values, formikBag) => {
    const data = {...values};
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    });
    if (data.role === 'admin' || data.role === undefined) {
      delete data['agency_id'];
    }
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Chargement',
      message: 'Enregistrer modifications en cours ...',
      autoClose: false,
      disallowClose: true,
    });
    handleRequest('put', API_UPDATE_USER(user.id), data, config, (dataRes, status) => {
      if (status === 'Done') {
        updateNotification({
          id: 'load-data',
          color: 'teal',
          title: 'OK',
          message: 'Les modifications sont Enregistrées',
          icon: <CheckIcon />,
          autoClose: 4000,
        });
        refresh.current && refresh.current.onQueryChange();
        handleHide();
      } else if (status === 'Error') {
        const msg = {
          code: dataRes?.status,
          msg: dataRes?.text,
        };
        formikBag.setStatus(msg);
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Erreur',
          message: "Une erreur s'est produite",
          icon: <CloseIcon />,
          autoClose: 4000,
          loading: false,
        });
      }
    });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {user && (
        <Card className="rounded-lg shadow-sm card-custom card-transparent">
          <div className="card-body p-0">
            <div
              className="wizard wizard-4"
              id="kt_wizard"
              data-wizard-state="first"
              data-wizard-clickable="true"
            >
              <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <Formik
                      initialValues={user || UserFormInitialValues}
                      validationSchema={UserSchemaValidation[0]}
                      validateOnBlur={false}
                      validateOnChange={false}
                      validateOnMount={false}
                      onSubmit={handleSubmit}
                    >
                      {({handleSubmit, ...subProps}) => (
                        <div className="col-xl-12 col-xxl-10">
                          <Form
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                            id="kt_form"
                          >
                            <div className="row justify-content-center">
                              <div className="col-xl-9">
                                {subProps.status && (
                                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                      Une erreur de type {subProps.status.code} est
                                      survenue...
                                      <p>Message: {subProps.status.msg}</p>
                                    </div>
                                  </div>
                                )}
                                {!subProps.isValid && subProps.submitCount > 0 && (
                                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                      Vérifiez que toutes les données entrées sont
                                      justes...
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="my-5 step"
                                  data-wizard-type="step-content"
                                  data-wizard-state="current"
                                >
                                  <h5 className="text-dark font-weight-bold mb-10">
                                    Modifier le role
                                  </h5>
                                </div>
                                <MyCheckbox {...subProps} {...verified} />
                                <MyCheckbox {...subProps} {...ambassador} />
                                {subProps.values[ambassador.name] && (
                                  <InputInline {...subProps} {...ambassador_rate} />
                                )}
                                <MySelect
                                  {...role}
                                  value={roles.filter(
                                    (el) => el.value === subProps.values[role.name]
                                  )}
                                  {...subProps}
                                  options={roles}
                                />
                                {(subProps.values[role.name] === 'manager' ||
                                  subProps.values[role.name] === 'super_manager') && (
                                  <MySelect
                                    {...agency}
                                    value={agencies.filter(
                                      (el) => el.value === subProps.values[agency.name]
                                    )}
                                    {...subProps}
                                    options={agencies}
                                  />
                                )}

                                <div className="d-flex justify-content-between border-top pt-10 mt-15">
                                  <div className="mr-2"></div>
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn btn-primary font-weight-bolder px-9 py-4"
                                    >
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default UserEdit;
