import React, {useState} from 'react';
import {Field} from 'formik';
import MyCheckbox from '../../../../../_metronic/layout/components/form/checkbox/checkbox';
import {Modal} from 'react-bootstrap';
import {v4 as uuidv4} from 'uuid';

const SchedulePicker = ({selectedEvent, edit, onHide, handleSave, handleDeleteEvent}) => {
  const [error, setError] = useState(false);
  const [repeat, setRepeat] = useState(selectedEvent.repeat);
  const [close, setClose] = useState(
    selectedEvent.closeDates.includes(new Date(selectedEvent.start).toLocaleDateString())
  );
  const [startTime, setStartTime] = useState(selectedEvent.startTime);
  const [endTime, setEndTime] = useState(selectedEvent.endTime);
  const [daysOfWeek, setDaysOfWeek] = useState(selectedEvent.daysOfWeek || []);
  const dow = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

  const handleClose = () => {
    setClose((state) => !state);
  };

  const eventBuilder = () => {
    setError(false);
    if (repeat && daysOfWeek.length === 0) {
      setError(true);
      return;
    }
    let e;
    if (edit) {
      if (repeat) {
        e = {
          id: selectedEvent.id,
          daysOfWeek: daysOfWeek,
          startTime: startTime,
          endTime: endTime,
          startRecur: selectedEvent.startRecur,
          endRecur: selectedEvent.endRecur,
          closeDates: close
            ? [
                ...selectedEvent.closeDates.filter(
                  (el) =>
                    el !== new Date(selectedEvent.start).toISOString().substring(0, 10)
                ),
                new Date(selectedEvent.start).toISOString().substring(0, 10),
              ]
            : [
                ...selectedEvent.closeDates.filter(
                  (el) =>
                    el !== new Date(selectedEvent.start).toISOString().substring(0, 10)
                ),
              ],
          repeat: true,
          display: 'block',
          backgroundColor: '#3788d8',
        };
      } else {
        let a1 = startTime.split(':');
        let a2 = endTime.split(':');
        e = {
          id: selectedEvent.id,
          start: new Date(
            new Date(selectedEvent.start).setHours(a1[0], a1[1])
          ).toISOString(),
          end: new Date(new Date(selectedEvent.end).setHours(a2[0], a2[1])).toISOString(),
          closeDates: close
            ? [new Date(selectedEvent.start).toISOString().substring(0, 10)]
            : [],
          repeat: false,
          display: 'block',
          backgroundColor: '#3788d8',
        };
      }
    } else {
      if (repeat) {
        e = {
          id: uuidv4(),
          daysOfWeek: daysOfWeek,
          startTime: startTime,
          endTime: endTime,
          startRecur: selectedEvent.startRecur,
          endRecur: selectedEvent.endRecur,
          closeDates: close
            ? [
                new Date(selectedEvent.start).toISOString().substring(0, 10),
                ...selectedEvent.closeDates,
              ]
            : [...selectedEvent.closeDates],
          repeat: true,
          display: 'block',
          backgroundColor: '#3788d8',
        };
      } else {
        let a1 = startTime.split(':');
        let a2 = endTime.split(':');
        e = {
          id: uuidv4(),
          start: new Date(
            new Date(selectedEvent.start).setHours(a1[0], a1[1])
          ).toISOString(),
          end: new Date(new Date(selectedEvent.end).setHours(a2[0], a2[1])).toISOString(),
          closeDates: close
            ? [new Date(selectedEvent.start).toISOString().substring(0, 10)]
            : [],
          repeat: false,
          display: 'block',
          backgroundColor: '#3788d8',
        };
      }
    }
    handleSave(e);
  };

  const handleChangeStartTime = (v) => {
    setStartTime(v.target.value);
  };
  const handleChangeEndTime = (v) => {
    setEndTime(v.target.value);
  };
  return (
    <>
      <Modal.Body>
        <div className="w-100 d-flex flex-column">
          <div className="form-group row fv-plugins-icon-container">
            <label className="col-xl-3 col-lg-3 col-form-label">Heure début</label>
            <div className="col-lg-9 col-xl-9">
              <Field
                name={'startTime'}
                value={startTime}
                onChange={handleChangeStartTime}
                className="form-control form-control-solid form-control-lg"
                type={'time'}
              />
              <div className="fv-plugins-message-container"></div>
            </div>
          </div>

          <div className="form-group row fv-plugins-icon-container">
            <label className="col-xl-3 col-lg-3 col-form-label">Heure fin</label>
            <div className="col-lg-9 col-xl-9">
              <Field
                name={'endTime'}
                value={endTime}
                onChange={handleChangeEndTime}
                className="form-control form-control-solid form-control-lg"
                type={'time'}
              />
              <div className="fv-plugins-message-container"></div>
            </div>
          </div>
          <div className="col-lg-9 col-xl-9 ml-auto d-flex flex-column">
            <div>
              <MyCheckbox
                checked={!repeat}
                onChange={() => setRepeat((state) => !state)}
                label={'Ne pas répéter'}
                value={!repeat}
              />
              {repeat && (
                <div className="d-flex">
                  {dow.map((el, index) => (
                    <div
                      onClick={() =>
                        setDaysOfWeek((state) =>
                          state.includes((index + 1) % 7)
                            ? [...state.filter((el) => el !== (index + 1) % 7)]
                            : [(index + 1) % 7, ...state]
                        )
                      }
                      key={index}
                      className={`d-flex justify-content-center align-items-center mx-2 font-weight-bolder ${
                        daysOfWeek.includes((index + 1) % 7)
                          ? 'bg-primary text-white'
                          : 'btn-white text-dark'
                      }`}
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                        border: `1px solid ${error ? 'red' : '#004f91'}`,
                        userSelect: 'none',
                      }}
                    >
                      {el}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <MyCheckbox
              checked={close}
              onChange={handleClose}
              label={'Fermé'}
              value={close}
            />
          </div>
          {edit ? (
            <>
              <button
                type="button"
                className="btn btn-danger btn-elevate align-self-center"
                onClick={() => handleDeleteEvent(selectedEvent.id)}
              >
                Supprimer
              </button>
              {repeat ? (
                <small className="align-self-center">
                  Cela supprimera tous les créneau correspondant, "Fermé" si vous voulez
                  déactiver seul ce créneau.
                </small>
              ) : null}
            </>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Annuler
          </button>
          <> </>
          <button
            type="button"
            onClick={eventBuilder}
            className="btn btn-primary btn-elevate"
          >
            Enregistrer
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default SchedulePicker;
