// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'reviewsTsForm',
  formField: {
    note: {
      name: 'note',
      label: 'Note',
      required: true,
    },
    content: {
      name: 'content',
      label: 'Contenu',
      required: true,
    },
    answer: {
      name: 'answer',
      label: 'Réponse',
    },
  },
};
