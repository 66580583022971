import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import useStep from '../../../../_metronic/_helpers/useStep';
import WizardStepper from '../../../modules/MyModules/WizardStepper/WizardStepper';
import CreateManagerView from '../../ManagersPage/CreateManager/CreateManagerViews/CreateManagerView';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import CreateTicketInfo from './CreateTicketViews/CreateTicketInfo';
import CreateTicketSchedule from './CreateTicketViews/CreateTicketSchedule';
import {API_CREATE_TICKET, API_UPDATE_TICKET} from '../../../constants/api';
import ErrorAlert from '../../../../_metronic/layout/components/alerts/ErrorAlert';
import TicketValidationSchema from './Formfield/TicketValidationSchema';
import TicketFormInitialValues from './Formfield/TicketInitialValue';
import {TICKETS_ROUTE} from '../../../constants/constant-routes';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const stepsCreateTicket = [
  {
    id: 1,
    title: 'Billet',
    flaticon: 'flaticon-file-2',
    view: CreateTicketInfo,
  },
  {
    id: 2,
    title: 'Calendrier',
    flaticon: 'flaticon-calendar-1',
    view: CreateTicketSchedule,
  },
];

const CreateTicket = (subProps) => {
  const [current, nextStep, previousStep] = useStep(1, stepsCreateTicket.length);
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [holidayDates, setHolidayDates] = useState();
  const Step = stepsCreateTicket.filter((el) => el.id === current)[0];

  useEffect(() => {
    fetch(`https://calendrier.api.gouv.fr/jours-feries/metropole.json`)
      .then((response) => response.json())
      .then((data) => setHolidayDates(Object.keys(data)));
  }, []);

  const handleSubmit = (values, formikBag) => {
    if (current === stepsCreateTicket.length) {
      if (subProps.ticket) {
        showNotification({
          id: 'load-data',
          loading: true,
          title: 'Chargement',
          message: 'Enregistrer modifications en cours ...',
          autoClose: false,
          disallowClose: true,
        });
        handleRequest(
          'put',
          API_UPDATE_TICKET(subProps.ticket.id),
          values,
          config,
          (data, status) => {
            if (status === 'Done') {
              updateNotification({
                id: 'load-data',
                color: 'teal',
                title: 'OK',
                message: 'Les modifications sont Enregistrées',
                icon: <CheckIcon />,
                autoClose: 4000,
              });
              subProps.refresh.current && subProps.refresh.current.onQueryChange();
              subProps.onHide();
            } else if (status === 'Error') {
              const msg = {
                code: data?.status,
                msg: data?.text,
              };
              formikBag.setStatus(msg);
              updateNotification({
                id: 'load-data',
                color: 'red',
                title: 'Erreur',
                message: "Une erreur s'est produite",
                icon: <CloseIcon />,
                autoClose: 4000,
                loading: false,
              });
            }
          }
        );
      } else {
        showNotification({
          id: 'load-data',
          loading: true,
          title: 'Chargement',
          message: 'Enregistrer modifications en cours ...',
          autoClose: false,
          disallowClose: true,
        });
        handleRequest('post', API_CREATE_TICKET, values, config, (data, status) => {
          if (status === 'Done') {
            updateNotification({
              id: 'load-data',
              color: 'teal',
              title: 'OK',
              message: 'Les modifications sont Enregistrées',
              icon: <CheckIcon />,
              autoClose: 4000,
            });
            subProps.history.push(TICKETS_ROUTE);
          } else if (status === 'Error') {
            const msg = {
              code: data?.status,
              msg: data?.text,
            };
            formikBag.setStatus(msg);
            updateNotification({
              id: 'load-data',
              color: 'red',
              title: 'Erreur',
              message: "Une erreur s'est produite",
              icon: <CloseIcon />,
              autoClose: 4000,
              loading: false,
            });
          }
        });
      }
    } else {
      nextStep();
    }
  };

  return (
    <Card className="rounded-lg shadow-sm">
      <div className="card-body p-0">
        <div
          className="wizard wizard-1"
          id="kt_wizard"
          data-wizard-state="first"
          data-wizard-clickable="false"
        >
          <WizardStepper steps={stepsCreateTicket} currentStep={current} />
          <div className="card card-custom card-shadowless rounded-top-0">
            <div className="card-body p-0">
              <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <Formik
                  initialValues={subProps.ticket || TicketFormInitialValues}
                  validationSchema={TicketValidationSchema[current - 1]}
                  onSubmit={handleSubmit}
                  validateOnMount={false}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({values, handleSubmit, ...props}) => {
                    return (
                      <CreateManagerView
                        title="Create a new ticket"
                        next={{onClick: nextStep, title: 'Suivant'}}
                        previous={{onClick: previousStep, title: 'Précédent'}}
                        submit={{
                          onClick: handleSubmit,
                          title: !subProps.ticket ? 'Créer' : 'Sauvegarder',
                        }}
                        isFirst={current === 1}
                        isLast={current === stepsCreateTicket.length}
                      >
                        {props.status && (
                          <ErrorAlert
                            text={`Une erreur de type ${props.status.code} est survenue... \nMessage: ${props.status.msg}`}
                          />
                        )}
                        {!props.isValid && props.submitCount > 0 && (
                          <ErrorAlert text="Vérifiez que toutes les données entrées sont justes..." />
                        )}
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Step.view
                          title={Step.title}
                          values={values}
                          {...props}
                          ticket={subProps.ticket}
                          holidayDates={holidayDates}
                        />
                      </CreateManagerView>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CreateTicket;
