import React from 'react';
import {useDroppable} from '@dnd-kit/core';
const NewDroppableContainer = () => {
  const {setNodeRef} = useDroppable({
    id: 'new-day',
  });

  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center my-1 px-lg-3 bg-light p-5"
      ref={setNodeRef}
      style={{zIndex: 0}}
    >
      Glisser ici pour insérer un nouveau jour
    </div>
  );
};

export default NewDroppableContainer;
