import React, {useCallback, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {connect, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useSubheader} from '../../../_metronic/layout';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import useConfig from '../../../_metronic/_helpers/useConfig';
import useRequest from '../../../_metronic/_helpers/useRequest';
//import { API_GET_ALL_MANAGERS } from "../../constants/api";
import {MANAGER_INVITE, USER_DELETE, USER_EDIT} from '../../constants/constant-routes';
import ManagerForm from './ManagerForm';
import * as manager from './_redux/managerRedux';

const {
  formField: {
    username,
    firstname,
    lastname,
    gender_id,
    is_super_manager,
    touristic_sites,
  },
} = ManagerForm;

const ManagersPage = ({history, ...props}) => {
  const subheader = useSubheader();
  const ref = React.createRef();
  useEffect(() => {
    subheader.setTitle('Managers');
  }, [subheader]);
  const {
    manager: {managers},
  } = useSelector((state) => state);
  const [handleRequest] = useRequest();
  const config = useConfig();

  React.useEffect(() => {
    if (managers?.length) {
      return;
    }
    handleRequest('get', 'API_GET_ALL_MANAGERS', {}, config, (data, status) => {
      if (status === 'Done') {
        props.getManager(data.managers);
      } else if (status === 'Error') {
        console.error(data);
      }
    });
    // eslint-disable-next-line
  }, []);

  const genderComponent = useCallback((row) => {
    return <span>{row.gender?.libelle}</span>;
  }, []);

  const isSuperManagerComponent = useCallback((row) => {
    return <span>{row.roles === 'ROLE_MANAGER' ? 'Non' : 'Oui'}</span>;
  }, []);

  const TSComponent = useCallback((row) => {
    return row.roles !== 'ROLE_MANAGER'
      ? 'Tous'
      : row?.manager?.touristic_sites?.map((elT, i) => (
          <span key={i}>
            {elT.title}
            {i !== row.touristicSites?.length - 1 ? '' : ', '}
          </span>
        ));
  }, []);

  const columns = [
    {
      field: 'row',
      title: '#',
    },
    {
      field: firstname.name,
      title: firstname.label,
      filtering: false,
      sorting: false,
    },
    {
      field: lastname.name,
      title: lastname.label,
      filtering: false,
      sorting: false,
    },
    {
      field: username.name,
      title: username.label,
      filtering: false,
      sorting: false,
    },
    {
      field: gender_id.name,
      title: gender_id.label,
      render: genderComponent,
      filtering: false,
      sorting: false,
    },
    {
      field: is_super_manager.name,
      title: is_super_manager.label,
      render: isSuperManagerComponent,
      filtering: false,
      sorting: false,
    },
    {
      field: touristic_sites.name,
      title: touristic_sites.label,
      render: TSComponent,
      filtering: false,
      sorting: false,
    },
  ];

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) => history.push(USER_EDIT(rowData.id)),
    },
    {
      icon: 'delete',
      tooltip: 'Supprimer',
      onClick: (event, rowData) => history.push(USER_DELETE(rowData.id)),
    },
  ];

  return (
    <Card className="p-4 rounded-lg shadow-sm">
      <h3 className="m-0">Gestion des utilisateurs</h3>
      <p className="text-muted">
        Gérer les super managers et les managers de votre agence
      </p>
      <MyTable ref={ref} columns={columns} content={managers} actions={actions} />
      {managers && !managers.length && (
        <div className="text-center">
          <p>Vous n'avez pas encore ajouté de managers.</p>
          <Link to={MANAGER_INVITE}>Ajoutez un manager ici</Link>
        </div>
      )}
      {/*<Route path={USER_EDIT(":id")}>*/}
      {/*  {({ history, match }) => (*/}
      {/*    <ManagerEdit*/}
      {/*      show={match != null}*/}
      {/*      id={match && match.params.id}*/}
      {/*      onHide={() => {*/}
      {/*        history.push(MANAGERS);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</Route>*/}
      {/*<Route path={USER_DELETE(":id")}>*/}
      {/*  {({ history, match }) => (*/}
      {/*    <ManagerDelete*/}
      {/*      show={match != null}*/}
      {/*      id={match && match.params.id}*/}
      {/*      onHide={() => {*/}
      {/*        history.push(MANAGERS);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</Route>*/}
    </Card>
  );
};

export default connect(null, manager.actions)(ManagersPage);
