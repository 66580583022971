import React from 'react';

const CardCTS = ({
  el,
  hoveredCTS,
  setHoveredCTS,
  setMapBoxPlace,
  setCreateCTS,
  setSelectedCTS,
}) => {
  return (
    <div
      id={`${el.id}`}
      onClick={() => {
        setMapBoxPlace(null);
        setCreateCTS(false);
        setSelectedCTS((state) => (state?.id === el.id ? null : el));
      }}
      className={`rounded overflow-hidden border-secondary border ${
        hoveredCTS && hoveredCTS.id === el.id ? 'shadow-lg' : ''
      }`}
      style={{
        width: 200,
        height: 200,
        scrollMargin: 50,
        outline: hoveredCTS && hoveredCTS.id === el.id ? '2px solid #004f91' : 'none',
      }}
      onMouseOver={() => setHoveredCTS(el)}
      onMouseOut={() => setHoveredCTS(null)}
    >
      <img
        src={el.medias.filter((el) => el.content_type.includes('image'))[0]?.url || ''}
        alt={el.title_fr}
        className="w-100"
        style={{objectFit: 'cover', height: 125}}
      />
      <h4 className="px-2 py-1">{el.title_fr}</h4>
      <p className="px-2">{el.description_fr}</p>
    </div>
  );
};

export default CardCTS;
