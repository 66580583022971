import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useSubheader} from '../../../_metronic/layout';
import {CHAT_CONVERSATION} from '../../constants/constant-routes';
import useChat from './useChat';

const Conversation = () => {
  const subheader = useSubheader();
  useEffect(() => {
    subheader.setTitle('Messagerie');
  }, [subheader]);
  const [stateWS] = useChat();
  const {touristicsites} = useSelector((state) => state.touristicSites);

  return (
    <div>
      <h1>Choisissez une conversation afin d'en voir le contenu.</h1>
      <div className="row px-4">
        {touristicsites.length !== 0 && stateWS.conversations?.length !== 0 ? (
          touristicsites.map((el) => {
            const tsChats = stateWS?.conversations
              ?.filter((elConv) => elConv.user_2_id === el.id)
              ?.map((elConv) => {
                const notification = elConv.unread?.includes(el.id);
                return (
                  <Link to={CHAT_CONVERSATION(el.id, elConv.id)}>
                    <p className={`m-0 ${notification} py-3 border-bottom`}>
                      {elConv.user1.firstname} {elConv.user1.lastname}
                    </p>
                  </Link>
                );
              })
              .sort((a, b) => {
                if (a.notification && !b.notification) {
                  return 1;
                } else if (!a.notification && b.notification) {
                  return 1;
                } else {
                  return 0;
                }
              });

            if (tsChats?.length === 0) {
              return null;
            }

            return (
              <div className="col-12 col-md-6 py-2">
                <h3>{el.title_fr}</h3>
                <div className="pl-2">
                  {tsChats?.length !== 0 ? (
                    tsChats
                  ) : (
                    <p>Aucune conversation en cours...</p>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p>Aucune conversation en cours...</p>
        )}
      </div>
    </div>
  );
};

export default Conversation;
