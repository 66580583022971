import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';

import * as auth from '../app/modules/Auth/_redux/authRedux';

const appReducer = combineReducers({
  auth: auth.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === '[Logout] Action') {
    storage.removeItem('persist:root');
    storage.removeItem('persist:v709-demo3-auth');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([auth.saga()]);
}
