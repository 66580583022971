// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'userForm',
  formField: {
    username: {
      name: 'username',
      label: "Nom d'utilisateur",
      placeholder: "Nom d'utilisateur",
      required: true,
    },
    email_user: {
      name: 'email_user',
      label: 'Adresse mail',
      placeholder: 'Adresse mail',
      type: 'email',
      required: true,
    },
    firstname: {
      name: 'firstname',
      label: 'Prénom',
      placeholder: 'Prénom',
      required: true,
    },
    lastname: {
      name: 'lastname',
      label: 'Nom',
      placeholder: 'Nom',
      required: true,
    },
    gender: {
      name: 'gender',
      label: 'Civilitée',
      placeholder: 'Choisissez votre genre',
      noOptionsMessage: () => 'Aucune civilitée trouvée',
      required: true,
    },
    birthdate: {
      name: 'birthdate',
      label: 'Date de naissance',
      type: 'date',
      required: true,
    },
    password: {
      name: 'password',
      label: 'Mot de passe',
      placeholder: '•••••••••',
      type: 'password',
      required: true,
    },
    password_confirmation: {
      name: 'password_confirmation',
      label: 'Confirmer le mot de passe',
      placeholder: '•••••••••',
      type: 'password',
      required: true,
    },
    statement: {
      name: 'statement',
      label:
        "J'accepte les conditions conditions générales d'utilisation et la politique de confidentialité",
      required: true,
    },
    sign_up: {
      name: 'sign_up',
      label: "Vous n'avez pas encore de compte ?",
    },
  },
};
