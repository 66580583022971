import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {Card, Modal} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import InputTouristicSite from '../../../../_metronic/layout/components/form/address/inputTouristicSite';
import MySelect from '../../../../_metronic/layout/components/form/select/select';
import {API_GET_ALL_TICKETS, API_GET_TICKET} from '../../../constants/api';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../_metronic/_helpers/useConfig';

const TicketCalendar = (props) => {
  const {is_admin, is_super_manager, is_manager, user} = useSelector(
    (state) => state.auth
  );
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [state, setState] = useState([]);
  const [events, setEvents] = useState([]);
  const [touristicSite, setTouristicSite] = useState();
  const [tickets, setTickets] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setEvents(
      state.map((event) => {
        return {
          id: event.id,
          start: event.start_date,
          end: event.end_date,
          display: 'block',
          backgroundColor: '#3788d8',
        };
      })
    );
  }, [state]);

  useEffect(() => {
    setTickets(null);
    if (touristicSite) {
      handleRequest(
        'get',
        API_GET_ALL_TICKETS(touristicSite),
        {},
        config,
        (data, status) => {
          if (status === 'Done') {
            const res = data.map((el) => ({
              label: el.title,
              value: el.id,
            }));
            setTickets(res);
          } else if (status === 'Error') {
            console.error(data);
          }
        }
      );
    }
  }, [touristicSite]);

  useEffect(() => {
    setState([]);
    if (selectedTicket) {
      handleRequest('get', API_GET_TICKET(selectedTicket), {}, config, (data, status) => {
        if (status === 'Done') {
          setState(data.reservations);
        } else if (status === 'Error') {
          console.error(data);
        }
      });
    }
  }, [selectedTicket]);

  const handleSelectTS = (value) => {
    let id;
    if (value) {
      if (value instanceof Object) {
        id = value.value;
      } else {
        id = value;
      }
    }
    setTouristicSite(id);
  };

  const handleSelectTicket = (value) => {
    let id;
    if (value) {
      if (value instanceof Object) {
        id = value.value;
      } else {
        id = value;
      }
    }
    setSelectedTicket(id);
  };

  const eventClickHandler = (event) => {
    setSelectedEvent(state.find((x) => x.id === parseInt(event.event.id)));
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  return (
    <>
      <Modal
        size="sm"
        show={showModal}
        onHide={handleClose}
        centered={true}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {selectedEvent ? (
            <Modal.Title id="example-modal-sizes-title-lg">
              {new Date(selectedEvent?.start_date).toLocaleDateString('fr-FR', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Modal.Title>
          ) : null}
        </Modal.Header>
        {selectedEvent ? (
          <Modal.Body>
            <div className="w-100 d-flex flex-column align-items-center">
              <div className="form-group d-flex flex-column align-items-center fv-plugins-icon-container">
                <h3>
                  {selectedEvent?.user?.user_profile?.firstname}{' '}
                  {selectedEvent?.user?.user_profile?.lastname[0]?.toUpperCase()}
                  {'.'}
                </h3>
                <h3>{selectedEvent?.user?.user_profile?.phone_number}</h3>
              </div>
            </div>
          </Modal.Body>
        ) : null}
      </Modal>
      <Card className="rounded-lg shadow-sm">
        <div className="card-body p-0">
          <div
            className="wizard wizard-1 p-10"
            id="kt_wizard"
            data-wizard-state="first"
            data-wizard-clickable="false"
          >
            {is_admin && (
              <InputTouristicSite
                onChange={handleSelectTS}
                values={{touristic_site: null}}
                parentClasses="w-100"
              />
            )}
            {is_super_manager && (
              <MySelect
                onChange={handleSelectTS}
                classNames="w-100"
                options={user?.agencies[0]?.touristic_sites.map((el) => ({
                  label: `${el.id} - ${el.title}`,
                  value: el.id,
                }))}
              />
            )}

            {is_manager && (
              <MySelect
                onChange={handleSelectTS}
                classNames="w-100"
                options={user?.touristic_sites.map((el) => ({
                  label: `${el.id} - ${el.title}`,
                  value: el.id,
                }))}
              />
            )}

            <MySelect
              onChange={handleSelectTicket}
              classNames="w-100"
              options={tickets || []}
            />
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              initialView="timeGridWeek"
              events={events}
              firstDay={1}
              locales={[frLocale]}
              locale="fr"
              buttonText={{
                today: "aujourd'hui",
                month: 'mois',
                week: 'semaine',
                day: 'jour',
                list: 'liste',
              }}
              allDaySlot={false}
              nowIndicator={true}
              now={new Date()}
              timeZone="local"
              initialDate={new Date()}
              selectable={true}
              eventDisplay={true}
              navLinks={true}
              dayMaxEvents={true}
              eventClick={eventClickHandler}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default TicketCalendar;
