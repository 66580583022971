// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'userForm',
  formField: {
    ambassador: {
      name: 'ambassador',
      label: "Ambassador d'Henri",
    },
    verified: {
      name: 'verified',
      label: 'Profil vérifié',
    },
    ambassador_rate: {
      name: 'ambassador_rate',
      label: 'Taux',
      type: 'number',
    },
    role: {
      name: 'role',
      label: 'Rôle',
      placeholder: 'Choisissez un rôle',
      noOptionsMessage: () => 'Aucune rôle trouvée',
    },
    agency: {
      name: 'agency_id',
      label: 'Agence',
      placeholder: 'Choisissez une agence',
      noOptionsMessage: () => 'Aucune agence trouvée',
    },
  },
};
