import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

const styles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#EBEDF3',
    borderRadius: 0.3 + 'rem',
    backgroundColor: '#EBEDF3',
    padding: 0.3 + 'rem',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#707070',
  }),
};

const MySelectInline = (props) => {
  const classNames = [
    'pt-1',
    'form-group row fv-plugins-icon-container',
    props.classNames,
  ]
    .filter((el) => el)
    .join(' ');

  const handleChange = (v, action) => {
    if (props.isMulti) {
      props.setFieldValue(
        props.name,
        v.map((el) => el.value)
      );
      return;
    }
    props.setFieldValue(props.name, v.value);
  };

  return (
    <div className={classNames}>
      {props.label && (
        <label className="col-xl-3 col-lg-3 col-form-label">
          {' '}
          {props.label}
          {props.required ? ' *' : ''}
        </label>
      )}
      <Select
        {...props}
        onChange={handleChange}
        onBlur={() => props.setFieldTouched(props.name, true)}
        placeholder={props.placeholder}
        className="col-lg-9 col-xl-9"
        styles={styles}
      />
      {props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
      {props.error && <p className="text-danger">{props.invalidMessage}</p>}
      {props.helper && <small>{props.helper}</small>}
    </div>
  );
};

MySelectInline.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  classNames: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.object,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  error: PropTypes.bool,
  invalidMessage: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  required: PropTypes.bool,
  helper: PropTypes.string,
};

export default MySelectInline;
