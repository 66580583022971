import React from 'react';
import MySelect from '../../../../../_metronic/layout/components/form/select/select';
import MyInput from '../../../../../_metronic/layout/components/form/input/input';
import FileInput from '../../../../../_metronic/layout/components/form/fileInput/fileInput';
import TouristicSitesForm from '../../TouristicSitesForm';
import MyTextArea from '../../../../../_metronic/layout/components/form/textarea/textarea';

const {
  formField: {
    title_fr: titleField,
    category,
    email,
    files,
    description_fr,
    description_en,
  },
} = TouristicSitesForm;

const CreateTouristicSiteInfoManager = ({title, ...props}) => {
  const categories = [
    {
      value: 58,
      label: 'Grotte / Site préhistorique',
    },
    {
      value: 57,
      label: 'Château / Monument historique',
    },
    {
      value: 59,
      label: 'Musée / Exposition',
    },
    {
      value: 61,
      label: "Parc d'attraction / Parc animalier",
    },
    {
      value: 60,
      label: 'Bien-être',
    },
    {
      value: 54,
      label: 'Site religieux',
    },
    {
      value: 56,
      label: 'Oenotourisme / Spiritourisme',
    },
    {
      value: 53,
      label: 'Activité',
    },
    {
      value: 55,
      label: 'Parc / Jardin',
    },
    {
      value: 62,
      label: "Le choix d'Henri",
    },
  ];
  return (
    <div>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
      </div>
      <div className="d-flex flex-wrap">
        <div className="col-md-6">
          <MyInput {...props} {...titleField} />
          <MyInput {...props} {...email} required={true} />
          <MySelect
            {...category}
            value={categories.filter((el) => el.value === props.values[category.name])}
            {...props}
            options={categories}
          />
        </div>
        <div className="col-md-6">
          <FileInput
            appendImages="touristic_site"
            {...props}
            {...files}
            helper=""
            required={false}
          />
          <MyTextArea {...props} {...description_fr} />
          <MyTextArea {...props} {...description_en} />
        </div>
      </div>
    </div>
  );
};

export default CreateTouristicSiteInfoManager;
