import {Formik} from 'formik';
import React from 'react';
import {Card} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import useRequest from '../../../_metronic/_helpers/useRequest';
import useStep from '../../../_metronic/_helpers/useStep';
import {API_AGENCY_CREATION, API_UPDATE_AGENCY} from '../../constants/api';
import WizardStepper from '../../modules/MyModules/WizardStepper/WizardStepper';
import CreateManagerView from '../ManagersPage/CreateManager/CreateManagerViews/CreateManagerView';
import AgencyCreationPayment from './AgencyCreationViews/AgencyCreationPayment';
import AgencyCreationInfo from './AgencyCreationViews/AgencyCreationInfo';
import AgencyCreationModule from './AgencyCreationViews/AgencyCreationModule';
import AgencyCreationView from './AgencyCreationViews/AgencyCreationView';
import AgencyInitialValues from './AgencyFormik/AgencyInitialValues';
import AgencyValidationSchema from './AgencyFormik/AgencyValidationSchema';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {AGENCIES} from '../../constants/constant-routes';
import useConfig from '../../../_metronic/_helpers/useConfig';

const stepsAgencyCreation = [
  {
    id: 1,
    title: "Informations de l'agence",
    flaticon: 'flaticon-home-2',
    view: AgencyCreationInfo,
  },
  {
    id: 2,
    title: 'Informations de paiement',
    flaticon: 'flaticon-notepad',
    view: AgencyCreationPayment,
  },
  {
    id: 3,
    title: 'Informations des modules',
    flaticon: 'flaticon-list-3',
    view: AgencyCreationModule,
  },
  {
    id: 4,
    title: 'Votre agence',
    flaticon: 'flaticon2-checking',
    view: AgencyCreationView,
  },
];

const AgencyCreation = (subProps) => {
  const [current, nextStep, previousStep] = useStep(1, stepsAgencyCreation.length);
  const [handleRequest] = useRequest();
  const config = useConfig();
  // eslint-disable-next-line
  const [error, setError] = React.useState();

  const handleSubmit = (values, formikBag) => {
    if (current === stepsAgencyCreation.length) {
      const data = {...values};
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === undefined) {
          delete data[key];
        }
      });
      if (subProps.agency) {
        showNotification({
          id: 'load-data',
          loading: true,
          title: 'Chargement',
          message: 'Enregistrer modifications en cours ...',
          autoClose: false,
          disallowClose: true,
        });
        handleRequest(
          'put',
          API_UPDATE_AGENCY(subProps.agency.id),
          data,
          config,
          (dataRes, status) => {
            if (status === 'Done') {
              updateNotification({
                id: 'load-data',
                color: 'teal',
                title: 'OK',
                message: 'Les modifications sont Enregistrées',
                icon: <CheckIcon />,
                autoClose: 4000,
              });
              subProps.refresh.current && subProps.refresh.current.onQueryChange();
              subProps.onHide();
            } else if (status === 'Error') {
              const msg = {
                code: dataRes?.status,
                msg: dataRes?.text,
              };
              formikBag.setStatus(msg);
              updateNotification({
                id: 'load-data',
                color: 'red',
                title: 'Erreur',
                message: "Une erreur s'est produite",
                icon: <CloseIcon />,
                autoClose: 4000,
                loading: false,
              });
            }
          }
        );
      } else {
        showNotification({
          id: 'load-data',
          loading: true,
          title: 'Chargement',
          message: 'En cours de création...',
          autoClose: false,
          disallowClose: true,
        });
        handleRequest('post', API_AGENCY_CREATION, data, config, (data, status) => {
          if (status === 'Done') {
            updateNotification({
              id: 'load-data',
              color: 'teal',
              title: 'OK',
              message: 'Création succès',
              icon: <CheckIcon />,
              autoClose: 4000,
            });
            subProps.history.push(AGENCIES);
          } else if (status === 'Error') {
            const msg = {
              code: data?.status,
              msg: data?.text,
            };
            formikBag.setStatus(msg);
            updateNotification({
              id: 'load-data',
              color: 'red',
              title: 'Erreur',
              message: "Une erreur s'est produite",
              icon: <CloseIcon />,
              autoClose: 4000,
              loading: false,
            });
          }
        });
      }
    } else {
      nextStep();
    }
  };

  const Step = stepsAgencyCreation.filter((el) => el.id === current)[0];

  return (
    <Card className="rounded-lg shadow-sm">
      <div className="card-body p-0">
        <div
          className="wizard wizard-1"
          id="kt_wizard"
          data-wizard-state="first"
          data-wizard-clickable="false"
        >
          <WizardStepper steps={stepsAgencyCreation} currentStep={current} />
          <div className="card card-custom card-shadowless rounded-top-0">
            <div className="card-body p-0">
              <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <Formik
                  initialValues={subProps.agency || AgencyInitialValues}
                  validationSchema={AgencyValidationSchema[current - 1]}
                  onSubmit={handleSubmit}
                  validateOnMount={false}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({values, handleSubmit, ...props}) => {
                    return (
                      <CreateManagerView
                        title="Create a new agency"
                        next={{onClick: nextStep, title: 'Suivant'}}
                        previous={{
                          onClick: previousStep,
                          title: 'Précédent',
                        }}
                        submit={{
                          onClick: handleSubmit,
                          title: !subProps.agency ? 'Créer' : 'Sauvegarder',
                        }}
                        isFirst={current === 1}
                        isLast={current === stepsAgencyCreation.length}
                      >
                        {props.status && (
                          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                              Une erreur de type {props.status.code} est survenue...
                              <p>Message: {props.status.msg}</p>
                            </div>
                          </div>
                        )}
                        {error && (
                          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{error}</div>
                          </div>
                        )}
                        {!props.isValid && props.submitCount > 0 && (
                          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                              Vérifiez que toutes les données entrées sont justes...
                            </div>
                          </div>
                        )}
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Step.view title={Step.title} values={values} {...props} />
                      </CreateManagerView>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default withRouter(AgencyCreation);
