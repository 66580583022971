export const REQUIRED_FIELD = 'Obligatoire';

export const ImageURL = (type, image_url) => {
  if (!image_url) return null;
  if (image_url.split('base64,').length !== 1) {
    return image_url;
  }
  return process.env.REACT_APP_API_URL + '/uploads/' + type + '/' + image_url;
};

export const imageUrl = (image) => {
  if (typeof image === 'string') {
    return image;
  }
  if (image instanceof File) {
    return URL.createObjectURL(image);
  }
  return null;
};

export const DURATION_OPTIONS = [
  {
    label: 'Heure(s)',
    value: 'hour',
  },
  {
    label: 'Jour(s)',
    value: 'day',
  },
  {
    label: 'Mois',
    value: 'month',
  },
  {
    label: 'Année(s)',
    value: 'year',
  },
];

export const LIMITER_OPTIONS = [
  {
    label: 'Heure(s)',
    value: 'hour',
  },
  {
    label: 'Jour(s)',
    value: 'day',
  },
  {
    label: 'Mois',
    value: 'month',
  },
  {
    label: 'Année(s)',
    value: 'year',
  },
];
