import React from 'react';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import * as auth from '../_redux/authRedux';
import MyInput from '../../../../_metronic/layout/components/form/input/input';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_LOGIN} from '../../../constants/api';
import {REQUIRED_FIELD} from '../../../constants/texts';
import PropagateLoader from 'react-spinners/PropagateLoader';
// import { login } from "../_redux/authCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  username: '',
  password: '',
};

function Login(props) {
  const [handleRequest, status] = useRequest();

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .email('Mauvais format')
      .min(3, '3 symboles minimum')
      .required(REQUIRED_FIELD),
    password: Yup.string().min(3, '3 symboles minimum').required(REQUIRED_FIELD),
  });

  return (
    <div className="login-form login-signin py-11 w-100 px-md-25 px-lg-0">
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={(values, {setStatus, setSubmitting}) => {
          setTimeout(() => {
            // login(values.email, values.password)
            // .then(({data: { accessToken}}) => props.login(accessToken))
            handleRequest('post', API_LOGIN, values, {}, (data, status) => {
              if (status === 'Done') {
                props.login(data);
                props.history.push('/');
              } else if (status === 'Error') {
                setSubmitting(false);
                setStatus(data.text);
              }
            });
          }, 1000);
        }}
      >
        {({handleSubmit, ...props}) => {
          const getInputClasses = (fieldname) => {
            if (props.touched[fieldname] && props.errors[fieldname]) {
              return 'is-invalid';
            }

            if (props.touched[fieldname] && !props.errors[fieldname]) {
              return 'is-valid';
            }

            return '';
          };

          return (
            <form
              onSubmit={handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
              id="kt_login_signin_form"
              noValidate="novalidate"
            >
              <div className="text-center pb-8">
                <h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                  Connexion
                </h2>
                {/* <span className="text-muted font-weight-bold font-size-h4">Ou
            <Link to="/auth/registration"> S'inscrire</Link></span> */}
              </div>
              {props.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{props.status}</div>
                </div>
              )}

              <MyInput
                label="Adresse mail"
                name="username"
                {...props}
                classes={getInputClasses('username')}
                type="email"
                placeholder="Adresse mail"
              />
              <MyInput
                label="Mot de passe"
                name="password"
                {...props}
                classes={getInputClasses('password')}
                type="password"
                placeholder="Mot de passe"
              />
              <div className="text-right">
                <Link
                  to="/auth/forgot-password"
                  className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                >
                  Mot de passe oublié ?
                </Link>
              </div>
              <div className="text-center pt-2">
                <button
                  type="submit"
                  className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100"
                >
                  {status === 'Loading' ? (
                    <div className="pt-1 pb-2 w-100 d-flex justify-content-center">
                      <PropagateLoader color="#fff" size={10} />
                    </div>
                  ) : (
                    'Se connecter'
                  )}
                </button>
                <Link
                  className="btn btn-light-primary w-100 text-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  to="/auth/registration"
                >
                  S'inscrire
                </Link>
              </div>
            </form>
          );
        }}
        {/*end::Form*/}
      </Formik>
    </div>
  );
}

export default connect(null, auth.actions)(Login);
