import {REQUIRED_FIELD} from '../../../../constants/texts';
import * as Yup from 'yup';
import ManagerForm from '../../ManagerForm';

const {
  formField: {
    username,
    firstname,
    lastname,
    gender_id,
    is_super_manager,
    touristic_sites,
    fonction,
  },
} = ManagerForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [username.name]: Yup.string()
      .email("Mauvais format d'adresse mail")
      .min(3, '3 symboles minimum')
      .max(50, '50 symboles maximum')
      .required(REQUIRED_FIELD),
  }),
  Yup.object().shape({
    [firstname.name]: Yup.string()
      .min(3, '3 symboles minimum')
      .max(50, '50 symboles maximum')
      .required(REQUIRED_FIELD),
    [lastname.name]: Yup.string()
      .min(3, '3 symboles minimum')
      .max(50, '50 symboles maximum')
      .required(REQUIRED_FIELD),
    [gender_id.name]: Yup.number().min(1, REQUIRED_FIELD).required(REQUIRED_FIELD),
    [fonction.name]: Yup.string(),
  }),
  Yup.object().shape({
    [is_super_manager.name]: Yup.bool(),
    [touristic_sites.name]: Yup.array().when(is_super_manager.name, {
      is: false,
      then: Yup.array()
        .min(1, 'Vous devez choisir au moins un site touristique')
        .required(REQUIRED_FIELD),
    }),
  }),
  Yup.object().shape({
    null: Yup.string().nullable(),
  }),
];
