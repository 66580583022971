import React, {useEffect, useState} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import Delete from '@material-ui/icons/Delete';

export function SortableItem({
  id,
  item,
  defaultImg,
  language,
  setActiveVisitSheet,
  activeVisitSheet,
  handleRemove,
  disabled,
}) {
  const [element, setElement] = useState(null);
  useEffect(() => {
    let content = item.visit_sheet_contents.filter((e) => e.language === language.value);
    if (content.length > 0) {
      setElement(content[0]);
    } else {
      setElement(null);
    }
  }, [item, language]);

  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: id,
    disabled: disabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    userSelect: 'none',
    cursor: 'grab',
    maxWidth: 300,
    borderRadius: '10px',
    overflow: 'hidden',
    outline: activeVisitSheet?.id === item.id ? '3px solid #004f91' : 'none',
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      onClick={() => !disabled && setActiveVisitSheet(item)}
      className="row flex-nowrap w-100 d-flex flex-column justify-content-center mb-5 bg-white shadow-lg position-relative"
    >
      <img
        {...listeners}
        width={'100%'}
        height={'100%'}
        src={
          element?.layout
            ? JSON.parse(element?.layout)['0']?.url?.replace(/\?.*/, '') || defaultImg
            : defaultImg
        }
        alt="bg"
        style={{maxHeight: 200}}
      />
      <div className="w-100 d-flex flex-row align-items-center m-2">
        <div
          style={{width: 25, height: 25, borderRadius: '50%', border: '1px solid black'}}
          className="d-flex justify-content-center align-items-center font-weight-bolder"
        >
          {item.rank}
        </div>
        <p className="w-100 m-0 font-weight-bold font-size-h4 px-2 text-truncate">
          {element?.title || 'Pas de Titre'}
        </p>
      </div>
      <div className="m-2 overflow-hidden">
        <p style={{height: '3.6em', textOverflow: '-o-ellipsis-lastline'}}>
          {element?.description || 'Pas de description'}
        </p>
      </div>
      {activeVisitSheet?.id === item.id && (
        <button
          className="btn btn-danger rounded-0"
          onClick={() => handleRemove(item.id)}
          disabled={disabled}
        >
          <Delete fontSize="medium" htmlColor="white" />
        </button>
      )}
    </li>
  );
}
