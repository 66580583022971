import React, {useState} from 'react';
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import * as Yup from 'yup';
import {injectIntl} from 'react-intl';
import * as auth from '../_redux/authRedux';
import MyInput from '../../../../_metronic/layout/components/form/input/input';
import {REQUIRED_FIELD} from '../../../constants/texts';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_RESET_PASSWORD, API_RESET_PASSWORD_CHECK} from '../../../constants/api';

const initialValues = {
  password: '',
  confirm_password: '',
};

function ResetPassword(props) {
  const [isRequested, setIsRequested] = useState(false);
  const [handleRequest] = useRequest();
  const [check, setCheck] = useState();
  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string().min(3, '3 symboles minimum').required(REQUIRED_FIELD),
    confirm_password: Yup.string()
      .when({
        is: (value) => value && value.length !== 0,
        then: Yup.string()
          .oneOf([Yup.ref('password')], 'Les mots de passes ne sont pas égaux')
          .required(REQUIRED_FIELD),
      })
      .required(REQUIRED_FIELD),
  });

  React.useEffect(() => {
    handleRequest('get', API_RESET_PASSWORD_CHECK, {}, {}, (data, status) => {
      if (status === 'Done') {
        setCheck(true);
      } else if (status === 'Error') {
        console.error(data);
        setCheck(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values, formikBag) => {
    handleRequest('post', API_RESET_PASSWORD, values, {}, (data, status) => {
      if (status === 'Done') {
        setIsRequested(true);
      } else if (status === 'Error') {
        console.error(data);
      }
    });
  };

  if (!check === undefined) {
    return <p>Vérification en cours...</p>;
  }

  return (
    <>
      {isRequested && <Redirect to="/auth/login" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{display: 'block'}}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Réinitialiser votre mot de passe</h3>
            <div className="text-muted font-weight-bold">
              Renseignez votre nouveau mot de passe.
            </div>
          </div>
          {check ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">Ce lien à expiré</div>
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={ForgotPasswordSchema}
              onSubmit={handleSubmit}
            >
              {({values, status, handleSubmit, ...props}) => {
                const getInputClasses = (fieldname) => {
                  if (props.touched[fieldname] && props.errors[fieldname]) {
                    return 'is-invalid';
                  }

                  if (props.touched[fieldname] && !props.errors[fieldname]) {
                    return 'is-valid';
                  }

                  return '';
                };

                return (
                  <>
                    {status && (
                      <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{status}</div>
                      </div>
                    )}
                    <MyInput
                      type="password"
                      placeholder="Nouveau mot de passe"
                      {...props}
                      classes={getInputClasses('password')}
                      name="password"
                    />
                    <MyInput
                      type="password"
                      placeholder="Confirmez votre mot de passe"
                      {...props}
                      classes={getInputClasses('confirm_password')}
                      name="confirm_password"
                    />
                    <div className="form-group d-flex flex-wrap flex-center">
                      <Link to="/auth">
                        <button
                          type="button"
                          id="kt_login_forgot_cancel"
                          className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                          Annuler
                        </button>
                      </Link>
                      <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        disabled={props.isSubmitting}
                      >
                        Réinitialiser
                      </button>
                    </div>
                  </>
                );
              }}
            </Formik>
          )}
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
