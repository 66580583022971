import * as Yup from 'yup';
import {REQUIRED_FIELD} from '../../../../constants/texts';

const {default: EventsForm} = require('../../EventsForm');

const {
  formField: {title, start_date, end_date, description, category, touristic_site_id},
} = EventsForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [title.name]: Yup.string().required(REQUIRED_FIELD),
    [description.name]: Yup.string().required(REQUIRED_FIELD),
    [category.name]: Yup.number().required(REQUIRED_FIELD),
    // [images.new_images_name]: Yup.array()
    //   .when("images", {
    //     is: val => val.length >= 0,
    //     then: Yup.array().max(1 - Yup.ref("images").length, "Vous ne pouvez ajouter qu'une seule image")
    //   })
    //   .required(REQUIRED_FIELD),
  }),
  Yup.object().shape({
    [start_date.name]: Yup.date().required(REQUIRED_FIELD),
    [end_date.name]: Yup.date().required(REQUIRED_FIELD),
    [touristic_site_id.name]: Yup.number()
      .min(1, REQUIRED_FIELD)
      .required(REQUIRED_FIELD),
  }),
];
