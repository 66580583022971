import React, {useState} from 'react';
import {Card} from 'react-bootstrap';
import useStep from '../../../../_metronic/_helpers/useStep';
import WizardStepper from '../../../modules/MyModules/WizardStepper/WizardStepper';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import CreateActivityInfo from './CreateActivityViews/CreateActivityInfo';
import {API_CREATE_ACTIVITY, API_UPDATE_ACTIVITY} from '../../../constants/api';
import CreateActivityVisitSheet from './CreateActivityViews/CreateActivityVisitSheet';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const stepsCreateActivity = [
  {
    id: 1,
    title: 'Activité',
    flaticon: 'flaticon-profile',
    view: CreateActivityInfo,
  },
  {
    id: 2,
    title: "Points d'intérêt",
    flaticon: 'flaticon-interface-11',
    view: CreateActivityVisitSheet,
  },
];

const CreateActivity = (subProps) => {
  const [current, nextStep] = useStep(1, stepsCreateActivity.length);
  const [payload, setPayload] = useState(null);
  const [handleRequest, status] = useRequest();
  const config = useConfig();

  const nextForm = (payload) => {
    setPayload(payload);
    nextStep();
  };

  const handleSubmit = (values, formikBag) => {
    if (current === 1) {
      handleSubmitActivity(values, formikBag);
    }
  };

  const handleSubmitActivity = (values, formikBag) => {
    const data = {...values};
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    });

    if (subProps.activity) {
      showNotification({
        id: 'load-data',
        loading: true,
        title: 'Chargement',
        message: 'Enregistrer modifications en cours ...',
        autoClose: false,
        disallowClose: true,
      });
      handleRequest(
        'put',
        API_UPDATE_ACTIVITY(subProps.activity.id),
        data,
        config,
        (dataRes, status) => {
          if (status === 'Done') {
            updateNotification({
              id: 'load-data',
              color: 'teal',
              title: 'OK',
              message: 'Les modifications sont Enregistrées',
              icon: <CheckIcon />,
              autoClose: 4000,
            });
            subProps.refresh.current && subProps.refresh.current.onQueryChange();
            nextForm(dataRes);
          } else if (status === 'Error') {
            const msg = {
              code: dataRes?.status,
              msg: dataRes?.text,
            };
            formikBag.setStatus(msg);
            updateNotification({
              id: 'load-data',
              color: 'red',
              title: 'Erreur',
              message: "Une erreur s'est produite",
              icon: <CloseIcon />,
              autoClose: 4000,
              loading: false,
            });
          }
        }
      );
    } else {
      showNotification({
        id: 'load-data',
        loading: true,
        title: 'Chargement',
        message: 'Enregistrer modifications en cours ...',
        autoClose: false,
        disallowClose: true,
      });
      handleRequest('post', API_CREATE_ACTIVITY, data, config, (dataRes, status) => {
        if (status === 'Done') {
          updateNotification({
            id: 'load-data',
            color: 'teal',
            title: 'OK',
            message: 'Les modifications sont Enregistrées',
            icon: <CheckIcon />,
            autoClose: 4000,
          });
          nextForm(dataRes);
        } else if (status === 'Error') {
          const msg = {
            code: dataRes?.status,
            msg: dataRes?.text,
          };
          formikBag.setStatus(msg);
          updateNotification({
            id: 'load-data',
            color: 'red',
            title: 'Erreur',
            message: "Une erreur s'est produite",
            icon: <CloseIcon />,
            autoClose: 4000,
            loading: false,
          });
        }
      });
    }
  };

  const Step = stepsCreateActivity.filter((el) => el.id === current)[0];

  return (
    <Card className="rounded-lg shadow-sm">
      <div className="card-body p-0">
        <div
          className="wizard wizard-1"
          id="kt_wizard"
          data-wizard-state="first"
          data-wizard-clickable="false"
        >
          <WizardStepper steps={stepsCreateActivity} currentStep={current} />
          <div className="card card-custom card-shadowless rounded-top-0">
            <div className="card-body p-0">
              <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <Step.view
                  title={Step.title}
                  handleSubmit={handleSubmit}
                  payload={payload}
                  loading={status === 'Loading'}
                  {...subProps}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CreateActivity;
