import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {ERROR_ROUTE} from '../../../../app/constants/constant-routes';

const ModularRoute = (props) => {
  if (props.condition) {
    return <Route {...props} />;
  }
  return <Redirect to={ERROR_ROUTE} />;
};

export default ModularRoute;
