import TouristicSitesForm from '../../TouristicSitesForm';
import UserForm from '../../../Users/Auth/UserForm';

const {
  formField: {
    title_fr,
    category,
    email,
    files,
    address,
    telephone_number,
    website_url,
    schedule,
    days,
    description_fr,
    description_en,
    video,
    tickets,
  },
} = TouristicSitesForm;

const {
  formField: {
    username,
    email_user,
    firstname,
    lastname,
    gender,
    birthdate,
    password,
    password_confirmation,
    statement,
    sign_up,
  },
} = UserForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [title_fr.name]: undefined,
  [category.name]: 0,
  [email.name]: undefined,
  [files.files_name]: [],
  [files.old_files_name]: [],
  [address.name]: undefined,
  [telephone_number.name]: undefined,
  [website_url.name]: undefined,
  [schedule.name]: {},
  [days.name]: [],
  [description_fr.name]: undefined,
  [description_en.name]: undefined,
  [video.name]: undefined,
  [tickets.name]: undefined,
  [username.name]: '',
  [email_user.name]: '',
  [firstname.name]: '',
  [lastname.name]: '',
  [gender.name]: '',
  [birthdate.name]: '',
  [password.name]: '',
  [password_confirmation.name]: '',
  [statement.name]: false,
  [sign_up.name]: false,
};
