import React from 'react';
import {useSelector} from 'react-redux';
import InputInline from '../../../../../_metronic/layout/components/form/input/inputInline';
import MySelectInline from '../../../../../_metronic/layout/components/form/select/selectInline';
import ManagerForm from '../../ManagerForm';

const {
  formField: {firstname, lastname, gender_id, fonction},
} = ManagerForm;

const CreateManagerProfile = ({title, ...props}) => {
  const {genders} = useSelector((state) => state.auth);

  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
      </div>
      <InputInline {...props} {...firstname} />
      <InputInline {...props} {...lastname} />
      <MySelectInline
        {...gender_id}
        value={genders.find((el) => el.value === props.values[gender_id.name])}
        {...props}
        options={genders}
      />
      <InputInline {...props} {...fonction} />
    </>
  );
};

export default CreateManagerProfile;
