import React, {useCallback, useEffect, useState} from 'react';
import {Card, Modal} from 'react-bootstrap';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import {API_GET_ALL_ROAD_TRIPS, API_GET_ONE_EXPERIENCE} from '../../constants/api';
import {
  EXPERIENCES,
  EXPERIENCES_DUPLICATE,
  ROAD_TRIPS,
  ROAD_TRIPS_DUPLICATE,
  ROAD_TRIPS_EDIT,
} from '../../constants/constant-routes';
import {Route, Switch} from 'react-router-dom';
import TripEdit from './TripEdit/TripEdit';
import useRequest from '../../../_metronic/_helpers/useRequest';
import useConfig from '../../../_metronic/_helpers/useConfig';
import CreateTrip from './TripCreate/CreateTrip';
import TripDuplicateCard from './TripDuplicate/TripDuplicate';

const TripDuplicate = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [experience, setExperience] = useState(null);

  useEffect(() => {
    if (show) {
      handleRequest('get', API_GET_ONE_EXPERIENCE(id), {}, config, (data, status) => {
        if (status === 'Done') {
          const cts = [];
          data.trip_days.forEach((el) => cts.push(...el.touristic_sites));

          const order = [];
          data.trip_days.forEach((el) => {
            el.touristic_sites.forEach((i, index) =>
              order.push({...i, day_number: el.day_number, rank: index + 1})
            );
          });

          const trip_days = data.trip_days.map((el) => {
            return {
              id: el.id,
              image: el.picture,
              description_fr: el.description_fr,
              day_number: el.day_number,
              trip_id: el.trip_id,
            };
          });

          const res = {
            id: data.id,
            title: data.title,
            description: data.description,
            image: data.picture,
            place: JSON.parse(data.place),
            country: data.country,
            cts: Object.values(cts.reduce((acc, obj) => ({...acc, [obj.id]: obj}), {})),
            order: order,
            trip_days: trip_days,
            public: data.public,
          };
          setExperience(res);
        } else if (status === 'Error') {
          console.error(data);
          onHide();
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setExperience(null);
    onHide();
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName="create-modal"
    >
      {experience && (
        <TripDuplicateCard exp={experience} onHide={handleHide} refresh={refresh} />
      )}
    </Modal>
  );
};

const RoadTripsPage = ({history, ...props}) => {
  const ref = React.createRef();
  const statusComponent = useCallback((row) => {
    return (
      <div
        className={`${
          row.public ? 'bg-primary text-white' : 'bg-warning'
        } p-2 rounded no-wrap`}
      >
        <span>{row.public ? 'Public' : 'Privé'}</span>
      </div>
    );
  }, []);

  const userComponent = useCallback((row) => {
    return <span>{row.user.username}</span>;
  }, []);

  const titleComponent = useCallback((row) => {
    return <span>{row.title?.text_fr || ''}</span>;
  }, []);

  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
      filtering: false,
    },
    {
      field: 'title',
      title: 'Titre',
      render: titleComponent,
    },
    {
      field: 'username',
      title: 'Utilisateur',
      render: userComponent,
      sorting: false,
    },
    {
      field: 'public',
      title: 'Statut',
      render: statusComponent,
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) => history.push(ROAD_TRIPS_EDIT(rowData.id)),
    },
    {
      icon: 'filter',
      tooltip: 'Dupliquer',
      onClick: (event, rowData) => history.push(ROAD_TRIPS_DUPLICATE(rowData.id)),
    },
    // {
    //   icon: 'delete',
    //   tooltip: 'Supprimer',
    //   onClick: (event, rowData) => history.push(ROAD_TRIPS_DELETE(rowData.id)),
    // },
  ];

  return (
    <Card className="p-4 rounded-lg shadow-sm">
      <h3 className="m-0">Gestion des road trips</h3>
      <p className="text-muted">Gérer les road trips</p>
      <MyTable
        ref={ref}
        columns={columns}
        apiUrl={API_GET_ALL_ROAD_TRIPS}
        actions={actions}
        customData={true}
      />

      <Switch>
        <Route path={ROAD_TRIPS_EDIT(':id')}>
          {({history, match}) => (
            <TripEdit
              refresh={ref}
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(ROAD_TRIPS);
              }}
            />
          )}
        </Route>
      </Switch>
      <Route path={ROAD_TRIPS_DUPLICATE(':id')}>
        {({history, match}) => (
          <TripDuplicate
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(EXPERIENCES);
            }}
          />
        )}
      </Route>
      {/*<Route path={USER_EDIT(":id")}>*/}
      {/*  {({ history, match }) => (*/}
      {/*    <ManagerEdit*/}
      {/*      show={match != null}*/}
      {/*      id={match && match.params.id}*/}
      {/*      onHide={() => {*/}
      {/*        history.push(MANAGERS);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</Route>*/}
      {/*<Route path={USER_DELETE(":id")}>*/}
      {/*  {({ history, match }) => (*/}
      {/*    <ManagerDelete*/}
      {/*      show={match != null}*/}
      {/*      id={match && match.params.id}*/}
      {/*      onHide={() => {*/}
      {/*        history.push(MANAGERS);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</Route>*/}
    </Card>
  );
};

export default RoadTripsPage;
