// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'eventsForm',
  formField: {
    title: {
      name: 'title',
      placeholder: 'Titre',
      label: "Titre de l'évènement",
      required: true,
    },
    category: {
      name: 'category_id',
      placeholder: 'Choisissez une catégorie',
      label: 'Catégorie',
      noOptionsMessage: () => 'Aucune catégorie trouvée',
      required: true,
    },
    description: {
      name: 'description',
      placeholder: 'Description',
      label: "Description de l'évènement",
      required: true,
    },
    start_date: {
      name: 'start_date',
      placeholder: '--/--/---- --:--',
      label: 'Date de début',
      required: true,
    },
    end_date: {
      name: 'end_date',
      placeholder: '--/--/---- --:--',
      label: 'Date de fin',
      required: true,
    },
    images: {
      name: 'images',
      new_images_name: 'new_images',
      label: 'Image',
    },
    touristic_site_id: {
      name: 'touristic_site_id',
      label: 'Site touristique',
      noOptionsLabel: () => 'Aucun site touristique trouvé',
      required: true,
      placeholder: 'Choisissez un site touristique',
    },
  },
};
