import React from 'react';

const WhiteCategoriesTexts = [53, 55, 56, 57, 58, 61, 62];

const CategoryIdentifier = ({categoryID, categories, bolder}) => {
  const category = React.useMemo(
    () =>
      categories?.filter((el) => el.id === categoryID)[0] || {
        color: '#EF7E26',
        title: 'Activité',
        textWhite: true,
        id: 53,
      },
    [categories, categoryID]
  );

  const TextIsWhite = React.useMemo(() => {
    if (WhiteCategoriesTexts.includes(categoryID)) {
      return true;
    }
    return false;
  }, [categoryID]);

  return (
    <div
      className="p-2 rounded d-inline-block"
      style={{backgroundColor: category?.color}}
    >
      <span
        className={`${bolder ? 'font-weight-bolder' : ''} ${
          TextIsWhite || category?.textWhite ? 'text-white' : 'text-dark'
        }`}
      >
        {category?.title}
      </span>
    </div>
  );
};

export default CategoryIdentifier;
