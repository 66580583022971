import {useSelector} from 'react-redux';

const useConfig = () => {
  const auth = useSelector((state) => state.auth.authToken);
  const config = {
    headers: {
      // "Content-type": "application/json",
      // "Content-type": "multipart/form-data",
      Authorization: 'Bearer ' + auth,
    },
  };

  return config;
};

export default useConfig;
