import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import LocationOn from '@material-ui/icons/LocationOn';
import Map, {Marker, NavigationControl} from 'react-map-gl';
import {ReactComponent as SvgMarker} from '../../../../../../assets/img/marker-icons/mapbox-marker-icon-red.svg';
import 'mapbox-gl/dist/mapbox-gl.css';
import useRequest from '../../../../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../../../../_metronic/_helpers/useConfig';
import Edit from '@material-ui/icons/Edit';
import Trash from '@material-ui/icons/Delete';
import {API_EDIT_VISIT_SHEET_CONTENT} from '../../../../../../constants/api';

import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapModule = ({
  data,
  edit,
  parentClasses,
  label,
  required,
  labelSuffix,
  classes,
  disabled,
  activeContent,
  setContent,
  setReady,
  handlerSetLayout,
  rank,
  setNewModule,
  newModule,
}) => {
  const [modify, setModify] = useState(newModule);
  const [handleRequest] = useRequest();
  const config = useConfig();

  const handleSubmit = (values, formikBag) => {
    handleRequest(
      'put',
      API_EDIT_VISIT_SHEET_CONTENT(activeContent.id),
      values,
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          const layout = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
          layout[rank] = [
            {
              sortable_item: 'map',
            },
          ];
          handlerSetLayout(layout);
          setContent(dataRes);
          setModify(false);
          setReady(true);
        } else if (status === 'Error') {
          setModify(false);
          setReady(true);
        }
      }
    );
  };

  const handleDelete = () => {
    const layout = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
    delete layout[rank];
    handlerSetLayout(layout);
    setModify(false);
    setReady(true);
  };

  return edit ? (
    <Formik
      initialValues={{
        location: data.location,
        longitude: data.longitude,
        latitude: data.latitude,
      }}
      onSubmit={handleSubmit}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({handleSubmit, ...subProps}) => (
        <Form className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
          <div className={'form-group fv-plugins-icon-container ' + parentClasses}>
            {label && (
              <div className="d-flex justify-content-between mt-n5">
                <label className="font-size-h6 text-dark pt-5">
                  {label}
                  {!modify && (
                    <>
                      <Edit
                        fontSize="medium"
                        className="mx-5"
                        onClick={() => setModify(true)}
                      />
                      <Trash fontSize="medium" htmlColor="red" onClick={handleDelete} />
                    </>
                  )}
                </label>
                {labelSuffix}
              </div>
            )}
            <div className="w-100 d-flex align-items-center">
              {modify ? (
                <div className="w-100 rounded ml-2">
                  <div className="d-flex align-items-center py-5">
                    <LocationOn fontSize="large" />
                    <Field
                      onChange={subProps.handleChange}
                      name={'location'}
                      placeholder={'Emplacement'}
                      type={'text'}
                      className={`form-control form-control-solid h-auto py-5 px-6 ${classes}`}
                      disabled={disabled}
                    />
                  </div>
                  <Map
                    initialViewState={{
                      longitude: data.longitude,
                      latitude: data.latitude,
                      zoom: 16,
                    }}
                    className="col-12"
                    style={{height: 400}}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    onClick={(map, event) => {
                      subProps.setFieldValue('longitude', map.lngLat.lng);
                      subProps.setFieldValue('latitude', map.lngLat.lat);
                    }}
                  >
                    <NavigationControl />
                    <Marker
                      longitude={subProps.values.longitude}
                      latitude={subProps.values.latitude}
                    >
                      <SvgMarker />
                    </Marker>
                  </Map>
                </div>
              ) : (
                <div className="w-100 rounded ml-2">
                  <div className="d-flex align-items-center py-5">
                    <LocationOn fontSize="large" />
                    <p className="m-0">
                      {data.location && data.location !== ''
                        ? data.location
                        : `${data.longitude} ${data.latitude}`}
                    </p>
                  </div>
                  <Map
                    initialViewState={{
                      longitude: data.longitude,
                      latitude: data.latitude,
                      zoom: 16,
                    }}
                    className="col-12"
                    style={{height: 400}}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                  >
                    <NavigationControl />
                    <Marker longitude={data.longitude} latitude={data.latitude}>
                      <SvgMarker />
                    </Marker>
                  </Map>
                </div>
              )}

              <div className="d-flex align-items-center px-2" style={{gap: 10}}>
                {modify && (
                  <>
                    <CheckCircleOutline
                      color="primary"
                      fontSize="large"
                      onClick={handleSubmit}
                    />
                    <HighlightOff
                      color="error"
                      fontSize="large"
                      onClick={() => {
                        setNewModule();
                        setModify(false);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <div className="w-100 rounded ml-2">
      <div className="d-flex align-items-center py-5">
        <LocationOn fontSize="large" />
        <p className="m-0">
          {data.location && data.location !== ''
            ? data.location
            : `${data.longitude} ${data.latitude}`}
        </p>
      </div>
      <Map
        initialViewState={{
          longitude: data.longitude,
          latitude: data.latitude,
          zoom: 16,
        }}
        className="col-12"
        style={{height: 400}}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <NavigationControl />
        <Marker longitude={data.longitude} latitude={data.latitude}>
          <SvgMarker />
        </Marker>
      </Map>
    </div>
  );
};

export default MapModule;
