import React from 'react';
import {useSelector} from 'react-redux';
import MyInput from '../../../../../_metronic/layout/components/form/input/input';
import MyTextArea from '../../../../../_metronic/layout/components/form/textarea/textarea';
import TicketForm from '../../TicketForm';
import InputTouristicSite from '../../../../../_metronic/layout/components/form/address/inputTouristicSite';
import MySelect from '../../../../../_metronic/layout/components/form/select/select';
import MyCheckbox from '../../../../../_metronic/layout/components/form/checkbox/checkbox';
import InputInline from '../../../../../_metronic/layout/components/form/input/inputInline';
import MyCustomInput from '../../../../../_metronic/layout/components/form/input/inputCustom';

const {
  formField: {
    title: titleField,
    touristicSites,
    duration,
    price,
    description,
    start_date,
    end_date,
    open_holiday,
    enabled,
    always_open,
    max_person,
    schedule,
  },
} = TicketForm;

const CreateTicketInfo = ({title, ticket, ...props}) => {
  const {is_admin, is_super_manager, is_manager, user} = useSelector(
    (state) => state.auth
  );

  const handleChangeStartDate = (e) => {
    const s = JSON.parse(props.values[schedule.name]);
    const newSchedule = s.map((el) => {
      if (el.repeat) {
        return {
          ...el,
          startRecur: props.values[always_open.name] ? undefined : e.target.value,
        };
      } else {
        return el;
      }
    });
    props.setFieldValue(schedule.name, JSON.stringify(newSchedule));
    props.handleChange(e);
  };

  const handleChangeEndDate = (e) => {
    const s = JSON.parse(props.values[schedule.name]);
    const newSchedule = s.map((el) => {
      if (el.repeat) {
        return {
          ...el,
          endRecur: props.values[always_open.name] ? undefined : e.target.value,
        };
      } else {
        return el;
      }
    });
    props.setFieldValue(schedule.name, JSON.stringify(newSchedule));
    props.handleChange(e);
  };

  const handleChangeAlwaysOpen = (e) => {
    const s = JSON.parse(props.values[schedule.name]);
    const newSchedule = s.map((el) => {
      if (el.repeat) {
        return {
          ...el,
          startRecur: e.target.checked ? undefined : props.values[start_date.name],
          endRecur: e.target.checked ? undefined : props.values[end_date.name],
        };
      } else {
        return el;
      }
    });
    props.setFieldValue(schedule.name, JSON.stringify(newSchedule));
    props.handleChange(e);
  };

  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
      </div>
      {is_admin && (
        <InputTouristicSite
          onChange={props.onChange}
          parentClasses="w-100"
          {...props}
          {...touristicSites}
          isDisabled={!!ticket}
        />
      )}
      {is_super_manager && (
        <MySelect
          {...touristicSites}
          classNames="w-100"
          {...props}
          value={
            user?.agencies[0]?.touristic_sites
              .map((el) => ({
                label: `${el.id} - ${el.title}`,
                value: el.id,
              }))
              .filter((el) => el.value === props.values[touristicSites.name])[0] || null
          }
          options={user?.agencies[0]?.touristic_sites.map((el) => ({
            label: `${el.id} - ${el.title}`,
            value: el.id,
          }))}
          isDisabled={!!ticket}
        />
      )}

      {is_manager && (
        <MySelect
          {...touristicSites}
          classNames="w-100"
          {...props}
          value={
            user?.touristic_sites
              .map((el) => ({
                label: `${el.id} - ${el.title}`,
                value: el.id,
              }))
              .filter((el) => el.value === props.values[touristicSites.name])[0] || null
          }
          options={user?.touristic_sites.map((el) => ({
            label: `${el.id} - ${el.title}`,
            value: el.id,
          }))}
          isDisabled={!!ticket}
        />
      )}
      <MyInput {...props} {...titleField} />
      <MyCustomInput inputProps={{min: 1}} {...props} {...duration} />
      <MyInput inputProps={{min: 0.0, step: '0.01'}} {...props} {...price} />
      <MyCheckbox {...props} {...always_open} handleChange={handleChangeAlwaysOpen} />
      {!props.values[always_open.name] && (
        <>
          <InputInline {...props} {...start_date} handleChange={handleChangeStartDate} />
          <InputInline
            inputProps={{min: props.values[start_date.name]}}
            {...props}
            {...end_date}
            handleChange={handleChangeEndDate}
          />
        </>
      )}
      <InputInline inputProps={{min: 1}} {...props} {...max_person} />
      <MyTextArea {...props} {...description} />
      <MyCheckbox {...props} {...open_holiday} />
      <MyCheckbox {...props} {...enabled} />
    </>
  );
};

export default CreateTicketInfo;
