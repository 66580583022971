import React, {useRef} from 'react';

const ImagesPicker = ({selectedCTS, setSelectedCTS}) => {
  const inputFilesRef = useRef();
  return (
    <div className="d-flex flex-wrap">
      {selectedCTS.medias.map((file, index) => (
        <div key={index} className="position-relative">
          <img
            src={file.url}
            alt={''}
            style={{objectFit: 'cover', height: 100, width: 100}}
          />
          <button
            className="btn btn-outline-none btn-danger position-absolute"
            type="button"
            onClick={() => {
              setSelectedCTS((state) => ({
                ...state,
                medias: [...state.medias.filter((f) => f.id !== file.id)],
              }));
            }}
            style={{
              zIndex: 1001,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            X
          </button>
        </div>
      ))}
      {selectedCTS.files?.map((file, index) => (
        <div key={index} className="position-relative">
          <img
            src={URL.createObjectURL(file)}
            alt={''}
            style={{objectFit: 'cover', height: 100, width: 100}}
          />
          <button
            className="btn btn-outline-none btn-danger position-absolute"
            type="button"
            onClick={() =>
              setSelectedCTS((state) => {
                return {
                  ...state,
                  files: [...state.files.filter((f) => f !== file)],
                };
              })
            }
            style={{
              zIndex: 1001,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            X
          </button>
        </div>
      ))}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: 100,
          width: 100,
          border: 'medium dashed green',
        }}
      >
        <button
          className="btn btn-outline-none btn-success"
          type="button"
          onClick={() => inputFilesRef.current.click()}
        >
          +
        </button>
        <input
          style={{display: 'none'}}
          ref={inputFilesRef}
          multiple={true}
          name="image"
          type="file"
          accept={'image/*'}
          onChange={(e) =>
            setSelectedCTS((state) => ({
              ...state,
              files: [...(state.files || []), ...e.target.files],
            }))
          }
        />
      </div>
    </div>
  );
};

export default ImagesPicker;
