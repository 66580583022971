import React, {useEffect, useState} from 'react';
import {Card, Modal} from 'react-bootstrap';
import useRequest from '../../../_metronic/_helpers/useRequest';
import useConfig from '../../../_metronic/_helpers/useConfig';
import {
  API_GET_TOURISTIC_SITE_SUGGESTIONS_DETAILS_BY_ID,
  API_PUBLISH_TOURISTIC_SITE,
} from '../../constants/api';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const SuggestionsView = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [touristicSite, setTouristicSite] = useState(null);

  useEffect(() => {
    if (show) {
      handleRequest(
        'get',
        API_GET_TOURISTIC_SITE_SUGGESTIONS_DETAILS_BY_ID(id),
        {},
        config,
        (data, status) => {
          if (status === 'Done') {
            setTouristicSite(data);
          } else if (status === 'Error') {
            console.error(data);
            onHide();
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setTouristicSite(undefined);
    onHide();
  };

  const handleSubmit = () => {
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Chargement',
      message: 'Enregistrer modifications en cours ...',
      autoClose: false,
      disallowClose: true,
    });
    handleRequest(
      'post',
      API_PUBLISH_TOURISTIC_SITE(touristicSite.ts.id),
      {},
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          updateNotification({
            id: 'load-data',
            color: 'teal',
            title: 'OK',
            message: 'Les modifications sont Enregistrées',
            icon: <CheckIcon />,
            autoClose: 4000,
          });
          refresh.current && refresh.current.onQueryChange();
          handleHide();
        } else if (status === 'Error') {
          updateNotification({
            id: 'load-data',
            color: 'red',
            title: 'Erreur',
            message: "Une erreur s'est produite",
            icon: <CloseIcon />,
            autoClose: 4000,
            loading: false,
          });
        }
      }
    );
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {touristicSite && (
        <Card className="rounded-lg shadow-sm card-custom card-transparent">
          <div className="card-body p-0">
            <div
              className="wizard wizard-4"
              id="kt_wizard"
              data-wizard-state="first"
              data-wizard-clickable="true"
            >
              <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <div className="col-xl-12 col-xxl-10">
                      <div
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                        id="kt_form"
                      >
                        <div className="row justify-content-center">
                          <div className="col-xxl-9">
                            <div>
                              <h5 className="text-dark font-weight-bold mb-3">
                                Site touristique suggéré
                              </h5>
                            </div>
                            <div className="px-5">
                              <h6 className="font-size-h6 text-dark pt-5">
                                {touristicSite?.ts?.id} - {touristicSite?.ts?.title_fr}
                              </h6>
                              <p className="font-italic m-0">
                                par {touristicSite?.proposer?.username} (
                                {touristicSite?.proposer?.email})
                              </p>
                              <div className="d-flex flex-row overflow-hidden">
                                {touristicSite?.ts?.medias
                                  ?.filter((m) => m.content_type.includes('image/'))
                                  ?.map((el) => {
                                    return (
                                      <img
                                        key={el.id}
                                        src={el.url}
                                        alt={el.url}
                                        height={50}
                                        width={50}
                                        className="mr-3"
                                      />
                                    );
                                  })}
                              </div>
                              <p className="text-dark pt-3">
                                Catégories: {touristicSite?.ts?.categories[0]?.title}
                              </p>
                              <p className="text-dark pt-3">
                                Email: {touristicSite?.ts?.email}
                              </p>
                              <p className="text-dark pt-3">
                                Tel: {touristicSite?.ts?.phone_number}
                              </p>
                              <p className="text-dark pt-3">
                                Adresse: {touristicSite?.ts?.address}
                              </p>
                              <p className="text-dark pt-3">
                                Description: {touristicSite?.ts?.description_fr}
                              </p>
                            </div>

                            {touristicSite?.ts_original_id && (
                              <>
                                <hr className="mt-10" />
                                <div>
                                  <h5 className="text-dark font-weight-bold my-3">
                                    Site touristique original
                                  </h5>
                                </div>
                                <div className="px-5">
                                  <h6 className="font-size-h6 text-dark pt-5">
                                    {touristicSite?.ts_original?.id} -{' '}
                                    {touristicSite?.ts_original?.title_fr}
                                  </h6>
                                  <div className="d-flex flex-row overflow-hidden">
                                    {touristicSite?.ts_original?.medias
                                      ?.filter((m) => m.content_type.includes('image/'))
                                      ?.map((el) => {
                                        return (
                                          <img
                                            key={el.id}
                                            src={el.url}
                                            alt={el.url}
                                            height={50}
                                            width={50}
                                            className="mr-3"
                                          />
                                        );
                                      })}
                                  </div>
                                  <p className="text-dark pt-3">
                                    Catégories:{' '}
                                    {touristicSite?.ts_original?.categories[0]?.title}
                                  </p>
                                  <p className="text-dark pt-3">
                                    Email: {touristicSite?.ts_original?.email}
                                  </p>
                                  <p className="text-dark pt-3">
                                    Tel: {touristicSite?.ts_original?.phone_number}
                                  </p>
                                  <p className="text-dark pt-3">
                                    Adresse: {touristicSite?.ts_original?.address}
                                  </p>
                                  <p className="text-dark pt-3">
                                    Description:
                                    {touristicSite?.ts_original?.description_fr}
                                  </p>
                                </div>
                              </>
                            )}
                            <div className="d-flex justify-content-between border-top pt-10 mt-15">
                              <div className="mr-2"></div>
                              <div>
                                <button
                                  className="btn btn-primary font-weight-bolder px-9 py-4"
                                  onClick={handleSubmit}
                                >
                                  Publier
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default SuggestionsView;
