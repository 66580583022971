import React, {useEffect, useState} from 'react';
import {Create as CreateIcon} from '@material-ui/icons';
import {SvgIcon} from '@material-ui/core';
import './imagePicker.scss';
import FilePicker from './FilePicker';

const ImagePicker = ({
  edit,
  activeContent,
  setReady,
  setContent,
  srcDefault,
  imgClass,
  handlerSetLayout,
}) => {
  const [bg, setBG] = useState(srcDefault);
  const [openFilePicker, setOpenFilePicker] = useState(false);
  const [layout, setLayout] = useState({});

  useEffect(() => {
    const l = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
    setBG(l['0']?.url?.replace(/\?.*/, '') || srcDefault?.replace(/\?.*/, ''));
    setLayout(l);
  }, [activeContent, srcDefault]);
  return edit ? (
    <div
      className={`dropZone-edit d-flex justify-content-center align-items-center position-relative ${imgClass} mt-sm-3 overflow-hidden`}
    >
      {openFilePicker ? (
        <FilePicker
          accept={'image/*'}
          files={activeContent.files}
          setReady={setReady}
          setContent={setContent}
          activeContent={activeContent}
          handlerSetLayout={handlerSetLayout}
          layout_field={'0'}
          initialState={layout['0']?.blob_id ? [layout['0']?.blob_id] : []}
          setOpenFilePicker={() => setOpenFilePicker(false)}
        />
      ) : (
        <>
          <img
            className={imgClass || ''}
            width={'100%'}
            height={'100%'}
            src={bg}
            alt={bg}
            onError={() =>
              setBG('https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg')
            }
          />
          <button
            type="button"
            onClick={() => setOpenFilePicker(true)}
            className={`dropZone-edit__btn position-absolute d-flex justify-content-center align-items-center `}
          >
            <SvgIcon component={CreateIcon} width="20" height="20" />
          </button>
        </>
      )}
    </div>
  ) : (
    <div
      className={`dropZone d-flex justify-content-center align-items-center position-relative ${imgClass} mt-sm-3 overflow-hidden`}
    >
      <img
        className={imgClass || ''}
        width={'100%'}
        height={'100%'}
        src={bg}
        alt={bg}
        onError={() =>
          setBG('https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg')
        }
      />
    </div>
  );
};

export default ImagePicker;
