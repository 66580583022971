import ActivityForm from '../../ActivityForm';

const {
  formField: {
    touristicSites,
    title,
    description,
    published,
    password,
    medias,

    visit_sheet_images,
    visit_sheet_longitude,
    visit_sheet_latitude,
  },
} = ActivityForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    [touristicSites.name]: '',
    [title.name]: '',
    [description.name]: '',
    [published.name]: false,
    [password.name]: '0000',
    [medias.name]: [],
  },
  {
    [visit_sheet_images.name]: [],
    [visit_sheet_longitude.name]: '',
    [visit_sheet_latitude.name]: '',
  },
];
