import React from 'react';
import MyInput from '../../../../_metronic/layout/components/form/input/input';
import AgencyForm from '../AgencyForm';
import MyCheckbox from '../../../../_metronic/layout/components/form/checkbox/checkbox';

const {
  formField: {
    max_activities,
    mod_referencing,
    mod_opinions,
    mod_activities,
    mod_ticket_office_free,
    mod_ticker_office_paying,
    mod_chat,
    mod_events,
    mod_audioguide,
    mod_translation,
    mod_interactive_map,
  },
} = AgencyForm;

const AgencyCreationModule = (props) => {
  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{props.title}</h5>
      </div>
      <MyInput {...props} {...max_activities} />
      <br />
      <MyCheckbox {...props} {...mod_referencing} />
      <br />
      <MyCheckbox {...props} {...mod_opinions} />
      <br />
      <MyCheckbox {...props} {...mod_activities} />
      <br />
      <MyCheckbox {...props} {...mod_ticket_office_free} />
      <br />
      <MyCheckbox {...props} {...mod_ticker_office_paying} />
      <br />
      <MyCheckbox {...props} {...mod_chat} />
      <br />
      <MyCheckbox {...props} {...mod_events} />
      <br />
      <MyCheckbox {...props} {...mod_audioguide} />
      <br />
      <MyCheckbox {...props} {...mod_translation} />
      <br />
      <MyCheckbox {...props} {...mod_interactive_map} />
    </>
  );
};

export default AgencyCreationModule;
