import React, {useState} from 'react';
import {showNotification, updateNotification} from '@mantine/notifications';
import {API_CREATE_TS, API_UPDATE_TS} from '../../../constants/api';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import Scheduler from './Scheduler';
import PriceList from './PriceList';
import ImagesPicker from './ImagesPicker';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import TipsList from './TipsList';
import {keysToSnake, toFormDataWithObject} from '../../../../_metronic/_helpers/utils';

const TSForm = ({selectedCTS, setSelectedCTS, setCart, setFilteredCTS}) => {
  const {categories} = useSelector((state) => state.auth);
  const config = useConfig();
  const [errors, setError] = useState(null);
  const saveSelectedCTS = () => {
    setError(null);
    const data = {
      ...selectedCTS,
      category_id: selectedCTS.category_id || 69,
      schedule: JSON.stringify(selectedCTS.schedule),
      price_list: JSON.stringify(
        selectedCTS.price_list.filter((price) => price.text !== '')
      ),
      medias: selectedCTS.medias.map((media) => {
        const {url, ...rest} = media;
        return {...rest, language: rest.language || selectedCTS.languages};
      }),
      files:
        selectedCTS.files?.map((file) => ({
          file: file,
          language: file.language || selectedCTS.languages,
        })) || [],
      touristic_site_tips: selectedCTS.tips?.filter((tip) => tip.title !== '') || [],
    };
    delete data['tips'];
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    });
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Chargement',
      message: 'Enregistrer modifications en cours ...',
      autoClose: false,
      disallowClose: true,
    });
    if (data.id) {
      updateCTS(data);
    } else {
      createCTS(data);
    }
  };

  const updateCTS = (data) => {
    let url = process.env.REACT_APP_API_URL + API_UPDATE_TS(data.id);

    fetch(url, {
      method: 'PUT',
      cache: 'default',
      body: toFormDataWithObject(data),
      headers: config.headers,
      mode: 'cors',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((dataRes) => {
        const data_snake = keysToSnake(dataRes);
        updateNotification({
          id: 'load-data',
          color: 'teal',
          title: 'OK',
          message: 'Les modifications sont Enregistrées',
          icon: <CheckIcon />,
          autoClose: 4000,
        });
        const data = {
          ...data_snake,
          schedule: data_snake.schedule ? JSON.parse(data_snake.schedule) : {},
          price_list: data_snake.price_list ? JSON.parse(data_snake.price_list) : [],
          tips: data_snake.touristic_site_tips,
        };
        setFilteredCTS((state) =>
          state.map((el) => {
            if (el.id !== data.id) {
              return el;
            } else {
              return data;
            }
          })
        );
        setSelectedCTS(data);
        updateNotification({
          id: 'load-data',
          color: 'teal',
          title: 'OK',
          message: 'Les modifications sont Enregistrées',
          icon: <CheckIcon />,
          autoClose: 4000,
        });
      })
      .catch((errmsg) => {
        const status = errmsg.status;
        errmsg.text &&
          errmsg.text().then((err) => {
            const errret = {
              status: status,
              text: err,
            };
            setError(errret);
            updateNotification({
              id: 'load-data',
              color: 'red',
              title: `Erreur ${errret.status}`,
              message: `${errret.err}`,
              icon: <CloseIcon />,
              autoClose: 4000,
              loading: false,
            });
          });
      });
  };

  const createCTS = (data) => {
    let url = process.env.REACT_APP_API_URL + API_CREATE_TS;

    fetch(url, {
      method: 'POST',
      cache: 'default',
      body: toFormDataWithObject(data),
      headers: config.headers,
      mode: 'cors',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((dataRes) => {
        const data_snake = keysToSnake(dataRes);
        updateNotification({
          id: 'load-data',
          color: 'teal',
          title: 'OK',
          message: 'Les modifications sont Enregistrées',
          icon: <CheckIcon />,
          autoClose: 4000,
        });
        const data = {
          ...data_snake,
          schedule: data_snake.schedule ? JSON.parse(data_snake.schedule) : {},
          price_list: data_snake.price_list ? JSON.parse(data_snake.price_list) : [],
          tips: data_snake.touristic_site_tips,
        };
        setFilteredCTS((state) => [data, ...state]);
        setSelectedCTS(data);
        updateNotification({
          id: 'load-data',
          color: 'teal',
          title: 'OK',
          message: 'Les modifications sont Enregistrées',
          icon: <CheckIcon />,
          autoClose: 4000,
        });
      })
      .catch((errmsg) => {
        const status = errmsg.status;
        errmsg.text &&
          errmsg.text().then((err) => {
            const errret = {
              status: status,
              text: err,
            };
            setError(errret);
            updateNotification({
              id: 'load-data',
              color: 'red',
              title: `Erreur ${errret.status}`,
              message: `${errret.err}`,
              icon: <CloseIcon />,
              autoClose: 4000,
              loading: false,
            });
          });
      });
  };
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '1000',
        overflowY: 'scroll',
      }}
      className="bg-secondary p-5 col-12 h-100 d-flex flex-column"
    >
      <button
        type="button"
        className="btn btn-primary"
        disabled={!!!selectedCTS.id}
        onClick={() =>
          setCart((state) => [
            ...state.filter((el) => el.id !== selectedCTS.id),
            selectedCTS,
          ])
        }
      >
        Ajouter au panier
      </button>
      <div className="mt-5 d-flex flex-column">
        <ImagesPicker selectedCTS={selectedCTS} setSelectedCTS={setSelectedCTS} />
        <label htmlFor="title">Titre</label>
        <input
          name="title"
          type="text"
          value={selectedCTS.title_fr}
          onChange={(e) =>
            setSelectedCTS((state) => {
              return {...state, title_fr: e.target.value};
            })
          }
        />
        <label htmlFor="description" className="mt-3">
          Description
        </label>
        <textarea
          name="description"
          value={selectedCTS.description_fr || ''}
          onChange={(e) =>
            setSelectedCTS((state) => {
              return {...state, description_fr: e.target.value};
            })
          }
        />
        <Select
          className="mt-3"
          name="category_id"
          onChange={(e) =>
            setSelectedCTS((state) => {
              return {...state, category_id: e.value};
            })
          }
          placeholder="Choisissez une catégorie"
          noOptionsMessage={() => 'Aucune catégorie trouvée'}
          required={true}
          value={categories.filter((el) => el.value === selectedCTS.category_id)}
          options={categories}
          styles={{
            control: (provided) => ({
              ...provided,
              borderColor: '#EBEDF3',
              borderRadius: 0.3 + 'rem',
              backgroundColor: '#FFFFFF',
              padding: 0.3 + 'rem',
            }),
            menu: (provided) => ({...provided, zIndex: 9999}),
            dropdownIndicator: (provided) => ({
              ...provided,
              color: '#707070',
            }),
          }}
        />
        <label htmlFor="tips" className="mt-3">
          Astuces
        </label>
        <TipsList data={selectedCTS} setData={setSelectedCTS} />
        {['fr', 'en', 'sp'].map((lang, index) => (
          <div key={index} className="mt-5 d-flex flex-column">
            <label htmlFor={`access_transport_${lang}`} className="mt-3">
              Accès/Transports {lang}
            </label>
            <textarea
              name={`access_transport_${lang}`}
              value={selectedCTS.access_transport?.[`text_${lang}`] || ''}
              onChange={(e) =>
                setSelectedCTS((state) => {
                  return {
                    ...state,
                    access_transport: {
                      ...(state.access_transport || {}),
                      [`text_${lang}`]: e.target.value,
                    },
                  };
                })
              }
            />
          </div>
        ))}
        <label htmlFor="duration_minutes" className="mt-3">
          Durée (en minutes)
        </label>
        <input
          name="duration_minutes"
          type="number"
          min={0}
          step={1}
          value={selectedCTS.duration_minutes || ''}
          onChange={(e) =>
            setSelectedCTS((state) => {
              return {...state, duration_minutes: e.target.value};
            })
          }
        />

        <label htmlFor="website_url" className="mt-3">
          Site Internet
        </label>
        <input
          name="website_url"
          type="text"
          value={selectedCTS.website_url}
          onChange={(e) =>
            setSelectedCTS((state) => {
              return {...state, website_url: e.target.value};
            })
          }
        />
        <label htmlFor="phone_number" className="mt-3">
          Téléphone
        </label>
        <input
          name="phone_number"
          type="text"
          value={selectedCTS.phone_number}
          onChange={(e) =>
            setSelectedCTS((state) => {
              return {...state, phone_number: e.target.value};
            })
          }
        />
        <label htmlFor="email" className="mt-3">
          Email
        </label>
        <input
          name="email"
          type="text"
          value={selectedCTS.email}
          onChange={(e) =>
            setSelectedCTS((state) => {
              return {...state, email: e.target.value};
            })
          }
        />
        <label htmlFor="ticket_link" className="mt-3">
          Lien Reservation
        </label>
        <input
          name="ticket_link"
          type="text"
          value={selectedCTS.ticket_link || ''}
          onChange={(e) =>
            setSelectedCTS((state) => {
              return {...state, ticket_link: e.target.value};
            })
          }
        />
        <PriceList selectedCTS={selectedCTS} setSelectedCTS={setSelectedCTS} />
        <Scheduler selectedCTS={selectedCTS} setSelectedCTS={setSelectedCTS} />
        {errors && (
          <div className="my-5 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">
              Une erreur de type {errors.status} est survenue...
              <p>Message: {errors.text}</p>
            </div>
          </div>
        )}
        <button type="button" className="btn btn-primary" onClick={saveSelectedCTS}>
          Sauvegarder
        </button>
      </div>
    </div>
  );
};

export default TSForm;
