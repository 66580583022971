// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'touristicSitesForm',
  formField: {
    title_fr: {
      name: 'title_fr',
      label: 'Nom du site',
      placeholder: 'Nom du site',
      required: true,
    },
    agency: {
      name: 'agency_id',
      label: 'Agence',
      placeholder: 'Choisissez une agence',
      noOptionsMessage: () => 'Aucune agence trouvée',
      required: false,
    },
    category: {
      name: 'category',
      label: 'Catégorie',
      placeholder: 'Choisissez une catégorie',
      noOptionsMessage: () => 'Aucune catégorie trouvée',
      required: true,
    },
    description_fr: {
      name: 'description_fr',
      label: 'Description',
      placeholder: 'Description',
    },
    title_en: {
      name: 'title_en',
      label: 'Nom du site EN',
      placeholder: 'Nom du site EN',
    },
    description_en: {
      name: 'description_en',
      label: 'Description EN',
      placeholder: 'Description EN',
    },
    title_it: {
      name: 'title_it',
      label: 'Nom du site IT',
      placeholder: 'Nom du site IT',
    },
    description_it: {
      name: 'description_it',
      label: 'Description IT',
      placeholder: 'Description IT',
    },
    title_ge: {
      name: 'title_ge',
      label: 'Nom du site GE',
      placeholder: 'Nom du site GE',
    },
    description_ge: {
      name: 'description_ge',
      label: 'Description GE',
      placeholder: 'Description GE',
    },
    title_sp: {
      name: 'title_sp',
      label: 'Nom du site SP',
      placeholder: 'Nom du site SP',
    },
    description_sp: {
      name: 'description_sp',
      label: 'Description SP',
      placeholder: 'Description SP',
    },
    title_cn: {
      name: 'title_cn',
      label: 'Nom du site CN',
      placeholder: 'Nom du site CN',
    },
    description_cn: {
      name: 'description_cn',
      label: 'Description CN',
      placeholder: 'Description CN',
    },
    title_sa: {
      name: 'title_sa',
      label: 'Nom du site SA',
      placeholder: 'Nom du site SA',
    },
    description_sa: {
      name: 'description_sa',
      label: 'Description SA',
      placeholder: 'Description SA',
    },
    title_pt: {
      name: 'title_pt',
      label: 'Nom du site PT',
      placeholder: 'Nom du site PT',
    },
    description_pt: {
      name: 'description_pt',
      label: 'Description PT',
      placeholder: 'Description PT',
    },
    title_jp: {
      name: 'title_jp',
      label: 'Nom du site JP',
      placeholder: 'Nom du site JP',
    },
    description_jp: {
      name: 'description_jp',
      label: 'Description JP',
      placeholder: 'Description JP',
    },
    title_ru: {
      name: 'title_ru',
      label: 'Nom du site RU',
      placeholder: 'Nom du site RU',
    },
    description_ru: {
      name: 'description_ru',
      label: 'Description RU',
      placeholder: 'Description RU',
    },
    video: {
      name: 'video',
      label: 'Lien video',
      placeholder: 'Lien video',
    },
    ambassador_review: {
      name: 'ambassador_review',
      label: 'Avis ambassadeur',
      placeholder: 'Avis ambassadeur',
    },
    email: {
      name: 'email',
      label: 'Email',
      placeholder: 'Email',
      required: false,
    },
    files: {
      files_name: 'files',
      label: 'Medias',
      old_files_name: 'medias',
      helper: 'Vous devez choisir au minimum 1 image.',
      required: true,
      multiple: true,
    },
    address: {
      name: 'address',
      label: 'Adresse',
      placeholder: 'Adresse',
      required: true,
    },
    telephone_number: {
      name: 'phone_number',
      label: 'Numéro de téléphone',
      placeholder: 'Numéro de téléphone',
      type: 'tel',
      required: false,
      mask: '+33 9 99 99 99 99',
    },
    website_url: {
      name: 'website_url',
      label: 'Site web',
      placeholder: 'https://',
      type: 'url',
    },
    schedule: {
      name: 'schedule',
      label: 'Horaires',
      placeholder: 'Horaires',
      required: true,
    },
    days: {
      name: 'days',
    },
    actuality: {
      name: 'actuality',
      label: 'Actualités',
      placeholder: 'Actualités',
    },
    published: {
      name: 'published',
      label: 'Voulez-vous le publier ?',
    },
    tickets: {
      name: 'ticket_link',
      label: 'Billetterie',
      placeholder: 'Lien vers la billetterie',
    },
  },
};
