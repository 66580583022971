import {Form, Formik} from 'formik';
import React from 'react';
import {Modal} from 'react-bootstrap';
import {connect, useSelector} from 'react-redux';
import {API_EDIT_PROFILE, API_SEARCH_CITIES} from '../../../../../../app/constants/api';
import useConfig from '../../../../../_helpers/useConfig';
import useRequest from '../../../../../_helpers/useRequest';
import MyInput from '../../../form/input/input';
import MySelect from '../../../form/select/select';
import QuickUserForm from './QuickUserForm';
import * as auth from '../../../../../../app/modules/Auth/_redux/authRedux';
import AsyncSelectFormik from '../../../form/select/asyncSelect';

const {
  formField: {firstname, lastname, address, city, gender_id, fonction, title, country},
} = QuickUserForm;

const QuickUserEdit = ({show, onHide, fulfillUser}) => {
  const {user, is_super_manager} = useSelector((state) => state.auth);
  const {genders} = useSelector((state) => state.auth);
  const initialState = React.useMemo(
    () => ({
      [firstname.name]: user?.firstname,
      [lastname.name]: user?.lastname,
      [gender_id.name]: user?.gender?.id,
      [address.name]: user?.address,
      [city.name]: user?.city?.id,
      city_label: `${user?.city?.nom_commune_complet} (${user?.city?.zip_code})`,
      city_name: user?.city?.nom_commune_complet,
      [fonction.name]: user?.manager?.fonction,
      [title.name]: user?.manager?.agency?.name,
      [country.name]: user?.manager?.agency?.country,
    }),
    [user]
  );
  const [handleRequest] = useRequest();
  const config = useConfig();

  const handleSubmit = (v) => {
    handleRequest('post', API_EDIT_PROFILE, v, config, (data, status) => {
      if (status === 'Done') {
        fulfillUser({
          ...user,
          ...data.account,
        });
        onHide();
      } else if (status === 'Error') {
        console.error(data);
      }
    });
  };

  const getCities = (v, cb) => {
    let s = v;

    if (!v) {
      s = initialState.city_name;
    }

    handleRequest('get', API_SEARCH_CITIES(s), {}, {}, (data, status) => {
      if (status === 'Done') {
        cb(
          data.map((el) => ({
            label: el.text,
            value: el.id,
          }))
        );
      } else if (status === 'Error') {
        console.error(data);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header>
        <Modal.Title>Modifier vos données</Modal.Title>
      </Modal.Header>
      <Formik initialValues={initialState} onSubmit={handleSubmit}>
        {(formProps) => {
          return (
            <Form onSubmit={formProps.handleSubmit}>
              <Modal.Body>
                <h3>Votre profil</h3>
                <MyInput {...formProps} {...firstname} />
                <MyInput {...formProps} {...lastname} />
                <MyInput {...formProps} {...address} />
                <AsyncSelectFormik
                  defaultOptions
                  defaultValue={{
                    label: formProps.values.city_label,
                    value: formProps.values.cityId,
                  }}
                  cacheOptions
                  loadOptions={getCities}
                  {...formProps}
                  {...city}
                />
                <MySelect
                  options={genders}
                  value={genders?.find(
                    (el) => el.value === formProps.values[gender_id.name]
                  )}
                  {...formProps}
                  {...gender_id}
                />
                {is_super_manager && (
                  <div className="mt-3">
                    <h3>Profil de l'agence</h3>
                    <MyInput {...formProps} {...title} />
                    <MyInput {...formProps} {...country} />
                    <MyInput {...formProps} {...fonction} />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <button className="btn btn-light btn-elevate">Annuler</button>
                  <> </>
                  <button type="submit" className="btn btn-primary btn-elevate">
                    Sauvegarder
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default connect(null, auth.actions)(QuickUserEdit);
