import React from 'react';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from '../../../../_metronic/_helpers';

const WizardStep = ({title, current, id, flaticon, isLast, onClickStep}) => {
  return (
    <div
      className="wizard-step"
      data-wizard-type="step"
      data-wizard-state={current === id ? 'current' : id < current ? 'done' : 'pending'}
      onClick={onClickStep}
      style={{cursor: 'pointer'}}
    >
      <div className="wizard-label mx-3">
        <i className={`wizard-icon ${flaticon}`}></i>
        <h3 className="wizard-title d-block">
          {id}. {title}
        </h3>
      </div>
      <span className="svg-icon svg-icon-xl wizard-arrow">
        {!isLast ? (
          <SVG
            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
            className="h-50 align-self-center"
          ></SVG>
        ) : (
          <SVG
            src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')}
            className="h-50 align-self-center"
          ></SVG>
        )}
      </span>
    </div>
  );
};

const WizardStepper = ({steps, currentStep, onClickStep}) => {
  return (
    <div className="wizard-nav border-bottom">
      <div className="wizard-steps p-8 py-lg-10">
        {steps.map((el, i) => (
          <WizardStep
            {...el}
            current={currentStep}
            isLast={i === steps.length - 1}
            key={el.id}
            onClickStep={() => onClickStep(el.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default WizardStepper;
