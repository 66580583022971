import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import React, {useEffect} from 'react';
import {Button} from 'react-bootstrap';
import FileInput from '../../../_metronic/layout/components/form/fileInput/fileInput';
import MyInput from '../../../_metronic/layout/components/form/input/input';
import useChat from './useChat';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from '../../../_metronic/_helpers';
import {useSubheader} from '../../../_metronic/layout';
import ImagePreviewCreate from '../../../_metronic/layout/components/form/preview/image-preview-create/image-preview-create';
import ConversationContent from './ConversationContent';

const MAX_MESSAGE_SIZE = 5000;

const Chat = ({match}) => {
  const subheader = useSubheader();
  useEffect(() => {
    subheader.setTitle('Messagerie');
  }, [subheader]);
  const [stateWS, sendMessage, sendImage, sendRead] = useChat();
  // eslint-disable-next-line
  const conversation = React.useMemo(
    () => stateWS?.conversations?.find((el) => el.id === match.params?.id),
    [stateWS.conversations, match.params.id]
  );
  const users = React.useMemo(
    () => {
      const conv = stateWS?.conversations?.find((el) => el.id === match.params?.id);

      return {
        receiver: conv?.user2,
        sender: conv?.user1,
      };
    },
    // eslint-disable-next-line
    [stateWS?.conversations, match.params?.id]
  );

  const handleSubmit = (v, d) => {
    if (v.input === '' && !v.file.length) {
      return;
    }

    if (v.file.length) {
      sendImage(v.input, v.file, match.params?.id);
    } else {
      sendMessage('message', v.input, match.params?.id);
    }
    d.resetForm({
      input: '',
      file: '',
    });
  };

  const onRead = () => {
    sendRead(match.params?.id);
  };

  return (
    <>
      {!conversation ? (
        <div className="bg-danger p-3 rounded">
          <p className="text-white m-0">Nous n'avons pas trouvé cette conversation...</p>
        </div>
      ) : stateWS.connected ? (
        <div>
          <div className="bg-white shadow p-3 rounded mb-1">
            <h1 className="m-0">
              {users.sender?.firstname} {users.sender?.lastname}
            </h1>
          </div>
          <>
            <ConversationContent
              stateWS={stateWS}
              conversation={conversation}
              users={users}
              id={parseInt(match.params.ts_id)}
              onRead={onRead}
            />
            <Formik
              initialValues={{
                input: '',
                file: [],
              }}
              validationSchema={Yup.object().shape({
                input: Yup.string()
                  .max(
                    MAX_MESSAGE_SIZE,
                    (max) =>
                      `Vous avez atteint la limite de taille du message (${max.value.length}/${max.max} charactères)`
                  )
                  .nullable(),
                file: Yup.array().nullable(),
              })}
              validateOnChange={true}
              onSubmit={handleSubmit}
            >
              {(props) => {
                return (
                  <div className="bg-white shadow mt-1 rounded">
                    {props.values?.file.length !== 0 && (
                      <div className="pt-3 pl-5 d-inline-block">
                        <ImagePreviewCreate
                          size="md"
                          src={URL.createObjectURL(props.values.file[0])}
                          alt=""
                          onClick={() => {
                            props.setFieldValue('file', '');
                            props.setFieldTouched('file', true);
                          }}
                        >
                          <SVG
                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Close.svg')}
                            className="h-50 align-self-center text-primary"
                          ></SVG>
                        </ImagePreviewCreate>
                      </div>
                    )}
                    <Form className="px-5">
                      <div className="d-flex justify-content-between align-items-center py-3">
                        <MyInput
                          classes="w-100"
                          parentClasses="w-100 m-0"
                          placeholder="Votre message..."
                          {...props}
                          name="input"
                        />
                        <FileInput
                          cancelPreview
                          parentClasses="m-0 px-2"
                          normal
                          multiple={false}
                          new_images_name="file"
                          {...props}
                          name="file"
                        >
                          <span className="svg-icon mx-2 svg-icon-3x svg-icon-primary svg-icon">
                            <SVG
                              src={toAbsoluteUrl('/media/svg/icons/Files/File-plus.svg')}
                            />
                          </span>
                        </FileInput>
                        <Button className="btn-primary" type="submit">
                          <span className="svg-icon m-0 svg-icon-md svg-icon-white">
                            <SVG
                              src={toAbsoluteUrl(
                                '/media/svg/icons/Communication/Send.svg'
                              )}
                            />
                          </span>
                        </Button>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </>
        </div>
      ) : (
        <div>Error connecting to websocket</div>
      )}
    </>
  );
};

export default Chat;
