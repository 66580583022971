import {toNumber} from 'lodash';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { takeLatest } from "redux-saga/effects";

export const actionTypes = {
  GetManager: '[Manager] load',
  AddManager: '[Manager] add',
  RemoveManager: '[Manager] rm',
  UpdateManager: '[Manager] update',
};

const initialManagersState = {
  managers: [],
};

export const reducer = persistReducer(
  {storage, key: 'persistantData', whitelist: ['managers']},
  (state = initialManagersState, action) => {
    switch (action.type) {
      case actionTypes.GetManager: {
        return {...state, managers: action.payload};
      }

      case actionTypes.AddManager: {
        return {
          ...state,
          managers: [...(state.managers || undefined), action.payload],
        };
      }

      case actionTypes.RemoveManager: {
        return {
          ...state,
          managers: state.managers.filter((el) => el.id !== toNumber(action.payload)),
        };
      }

      case actionTypes.UpdateManager: {
        return {
          ...state,
          managers: state.managers.map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                ...action.payload,
              };
            }
            return el;
          }),
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  getManager: (data) => ({type: actionTypes.GetManager, payload: data}),
  addManager: (data) => ({type: actionTypes.AddManager, payload: data}),
  rmManager: (data) => ({type: actionTypes.RemoveManager, payload: data}),
  updateManager: (data) => ({type: actionTypes.UpdateManager, payload: data}),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  // yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  // yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  // const { data: user } = yield getUserByToken();
  // yield put(actions.fulfillUser(user));
  // });
}
