import TicketForm from '../../TicketForm';

const {
  formField: {
    touristicSites,
    title,
    duration,
    price,
    description,
    start_date,
    end_date,
    open_holiday,
    schedule,
    enabled,
    always_open,
    min_person,
    max_person,
  },
} = TicketForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [touristicSites.name]: '',
  [title.name]: '',
  [duration.name]: 0,
  [price.name]: 0.0,
  [description.name]: '',
  [start_date.name]: '',
  [end_date.name]: '',
  [open_holiday.name]: false,
  [schedule.name]: '[]',
  [enabled.name]: true,
  [always_open.name]: true,
  [min_person.name]: 1,
  [max_person.name]: 1,
};
