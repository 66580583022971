import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

const styles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#EBEDF3',
    borderRadius: 0.3 + 'rem',
    backgroundColor: '#EBEDF3',
    padding: 0.3 + 'rem',
  }),
  menu: (provided) => ({...provided, zIndex: 95}),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#707070',
  }),
};

const MySelect = (props) => {
  const classNames = ['pt-1', 'form-group fv-plugins-icon-container', props.classNames]
    .filter((el) => el)
    .join(' ');

  const handleChange = (v, action) => {
    if (props.onChange) {
      props.onChange(v);
      return;
    }
    if (props.isMulti) {
      props.setFieldValue(props.name, v?.map((el) => el.value) ?? []);
      return;
    }
    props.setFieldValue(props.name, v.value);
  };

  const handleBlur = () => {
    if (props.setFieldTouched) props.setFieldTouched(props.name, true);
  };

  return (
    <div className={classNames} style={{zIndex: 95}}>
      {props.label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {props.label}
            {props.required ? ' *' : ''}
          </label>
          {props.labelSuffix}
        </div>
      )}
      <Select
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        styles={styles}
      />
      {props.touched && props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
      {props.error && <p className="text-danger">{props.invalidMessage}</p>}
      {props.helper && <small>{props.helper}</small>}
    </div>
  );
};

MySelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  classNames: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.object,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  error: PropTypes.bool,
  invalidMessage: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  required: PropTypes.bool,
  helper: PropTypes.string,
};

export default MySelect;
