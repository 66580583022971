import React, {useEffect, useState} from 'react';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_GET_ONE_TOURISTIC_SITE} from '../../../constants/api';
import CreateTouristicSite from '../TouristicSitesCreate/CreateTouristicSite';

const TouristicSiteEdit = ({id}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [touristicSite, setTouristicSite] = useState(null);

  useEffect(() => {
    handleRequest('get', API_GET_ONE_TOURISTIC_SITE(id), {}, config, (data, status) => {
      if (status === 'Done') {
        setTouristicSite(data);
      } else if (status === 'Error') {
        console.error(data);
      }
    });
    // eslint-disable-next-line
  }, [id]);

  return touristicSite ? <CreateTouristicSite touristicSite={touristicSite} /> : null;
};

export default TouristicSiteEdit;
