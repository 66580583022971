const {default: AgencyForm} = require('../AgencyForm');

const {
  formField: {
    name,
    approved,
    enabled,
    country,
    address,
    email,
    telephone_number,
    fix_fee,
    variable_fee,
    max_activities,
    stripe_account_id,
    stripe_account_verified,
    stripe_charges_enabled,
    stripe_payouts_enabled,
    mod_referencing,
    mod_opinions,
    mod_activities,
    mod_ticket_office_free,
    mod_ticker_office_paying,
    mod_chat,
    mod_events,
    mod_audioguide,
    mod_translation,
    mod_interactive_map,
    cgu,
  },
} = AgencyForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [name.name]: '',
  [approved.name]: false,
  [enabled.name]: false,
  [country.name]: 'France',
  [address.name]: '',
  [email.name]: '',
  [telephone_number.name]: '',
  [fix_fee.name]: 1,
  [variable_fee.name]: 1,
  [max_activities.name]: 20,
  [stripe_account_id.name]: '',
  [stripe_account_verified.name]: false,
  [stripe_charges_enabled.name]: false,
  [stripe_payouts_enabled.name]: false,
  [mod_referencing.name]: false,
  [mod_opinions.name]: false,
  [mod_activities.name]: false,
  [mod_ticket_office_free.name]: false,
  [mod_ticker_office_paying.name]: false,
  [mod_chat.name]: false,
  [mod_events.name]: false,
  [mod_audioguide.name]: false,
  [mod_translation.name]: false,
  [mod_interactive_map.name]: false,
  [cgu.name]: false,
};
