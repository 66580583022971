import React, {useRef} from 'react';
import AsyncSelectFormik from '../select/asyncSelect';
import {useSelector} from 'react-redux';

const InputAddress = (props) => {
  const {user} = useSelector((state) => state.auth);
  const timeout = useRef();

  const getAdress = (v, cb) => {
    clearTimeout(timeout.current);
    let s = v;

    if (!v) {
      s = user?.address || undefined;
    }
    const url = new URLSearchParams();
    url.append('q', s);

    timeout.current = setTimeout(() => {
      fetch(`https://api-adresse.data.gouv.fr/search/?${url}`)
        .then((response) => response.json())
        .then((data) =>
          cb(
            data.features.map((el) => ({
              label: el.properties.label,
              value: el.properties.label,
            }))
          )
        );
    }, 500);
  };

  return (
    <AsyncSelectFormik
      defaultOptions
      defaultValue={{label: props.values.address, value: props.values.address}}
      cacheOptions
      loadOptions={getAdress}
      {...props}
    />
  );
};

export default InputAddress;
