import React, {useCallback, useEffect, useState} from 'react';
import {Card, Modal} from 'react-bootstrap';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import {API_GET_ALL_EXPERIENCES, API_GET_ONE_EXPERIENCE} from '../../constants/api';
import {
  EXPERIENCES,
  EXPERIENCES_DELETE,
  EXPERIENCES_DUPLICATE,
  EXPERIENCES_EDIT,
  EXPERIENCES_VIEW,
} from '../../constants/constant-routes';
import ExperienceEdit from './ExperienceEdit/ExperienceEdit';
import {Route, Switch} from 'react-router-dom';
import useConfig from '../../../_metronic/_helpers/useConfig';
import useRequest from '../../../_metronic/_helpers/useRequest';
import CreateExperience from './ExperienceCreate/CreateExperience';
import ExperienceDuplicateCard from './ExperienceDuplicate/ExperienceDuplicate';
import ExperienceView from './ExperienceView/ExperienceView';

const ExperienceDuplicate = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [experience, setExperience] = useState(null);
  useEffect(() => {
    if (show) {
      handleRequest('get', API_GET_ONE_EXPERIENCE(id), {}, config, (data, status) => {
        if (status === 'Done') {
          const cts = [];
          data.trip_days.forEach((el) => cts.push(...el.touristic_sites));

          const order = [];
          data.trip_days.forEach((el) => {
            el.touristic_sites.forEach((i, index) =>
              order.push({...i, day_number: el.day_number, rank: index + 1})
            );
          });

          const trip_days = data.trip_days.map((el) => {
            return {
              image: el.picture,
              description_fr: el.description_fr,
              day_number: el.day_number,
              trip_id: el.trip_id,
            };
          });

          const parallaxes = data.parallaxes.map((el) => {
            const tmp = {
              ...el,
              image: el.file_url,
            };
            delete tmp['file_url'];
            return tmp;
          });
          const res = {
            id: data.id,
            title: data.title,
            description: data.description,
            tips: data.road_trip_tips,
            image: data.picture,
            amount: data.amount / 100,
            currency: data.currency,
            place: JSON.parse(data.place),
            country: data.country,
            cts: Object.values(cts.reduce((acc, obj) => ({...acc, [obj.id]: obj}), {})),
            order: order,
            trip_days: trip_days,
            parallaxes: parallaxes,
            published: data.published,
          };
          setExperience(res);
        } else if (status === 'Error') {
          console.error(data);
          onHide();
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setExperience(null);
    onHide();
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName="create-modal"
    >
      {experience && (
        <ExperienceDuplicateCard exp={experience} onHide={handleHide} refresh={refresh} />
      )}
    </Modal>
  );
};

const ExperiencesPage = ({history, ...props}) => {
  const ref = React.createRef();

  const titleComponent = useCallback((row) => {
    return <span>{row.title?.text_fr || ''}</span>;
  }, []);

  const amountComponent = useCallback((row) => {
    return (
      <span>
        {new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: row.currency,
        }).format(row.amount / 100)}
      </span>
    );
  }, []);

  const statusComponent = useCallback((row) => {
    return (
      <div
        className={`${
          row.public ? 'bg-primary text-white' : 'bg-warning'
        } p-2 rounded no-wrap`}
      >
        <span>{row.public ? 'Public' : 'Privé'}</span>
      </div>
    );
  }, []);
  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
      filtering: false,
      sorting: true,
    },
    {
      field: 'title',
      title: 'Titre',
      render: titleComponent,
      filtering: false,
      sorting: true,
    },
    {
      field: 'amount',
      title: 'Prix',
      render: amountComponent,
      filtering: false,
      sorting: true,
    },
    {
      field: 'public',
      title: 'Statut',
      render: statusComponent,
      filtering: false,
    },
  ];
  const actions = [
    {
      icon: 'view_column',
      tooltip: 'Voir',
      onClick: (event, rowData) => history.push(EXPERIENCES_VIEW(rowData.id)),
    },
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) => history.push(EXPERIENCES_EDIT(rowData.id)),
    },
    {
      icon: 'filter',
      tooltip: 'Dupliquer',
      onClick: (event, rowData) => history.push(EXPERIENCES_DUPLICATE(rowData.id)),
    },
  ];
  return (
    <Card className="p-4 rounded-lg shadow-sm">
      <h3 className="m-0">Gestion des experiences</h3>
      <p className="text-muted">Gérer les experiences</p>
      <MyTable
        ref={ref}
        columns={columns}
        apiUrl={API_GET_ALL_EXPERIENCES}
        actions={actions}
        customData={true}
      />
      <Switch>
        <Route path={EXPERIENCES_EDIT(':id')}>
          {({history, match}) => (
            <ExperienceEdit
              refresh={ref}
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(EXPERIENCES);
              }}
            />
          )}
        </Route>
      </Switch>
      <Route path={EXPERIENCES_DUPLICATE(':id')}>
        {({history, match}) => (
          <ExperienceDuplicate
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(EXPERIENCES);
            }}
          />
        )}
      </Route>
      <Route path={EXPERIENCES_VIEW(':id')}>
        {({history, match}) => (
          <ExperienceView
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(EXPERIENCES);
            }}
          />
        )}
      </Route>
    </Card>
  );
};

export default ExperiencesPage;
