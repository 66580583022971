import React, {useCallback} from 'react';
import {Card} from 'react-bootstrap';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import {API_GET_ALL_USERS} from '../../constants/api';
import {ADMIN_EDIT, ADMINS} from '../../constants/constant-routes';
import UserEdit from './UserEdit';
import {Route} from 'react-router-dom';

const AdminsPage = ({history, ...props}) => {
  const ref = React.createRef();
  const roleComponent = useCallback((row) => {
    return <span key={row.id}>{row.roles.length > 0 ? row.roles[0].name : 'user'}</span>;
  }, []);

  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
      filtering: false,
    },
    {
      field: 'username',
      title: 'Username',
    },
    {
      field: 'email',
      title: 'Adresse mail',
      filtering: false,
    },
    {
      field: 'roles',
      title: 'Roles',
      render: roleComponent,
      filtering: false,
      sorting: false,
    },
  ];

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) => history.push(ADMIN_EDIT(rowData.id)),
    },
  ];

  return (
    <Card className="p-4 rounded-lg shadow-sm">
      <h3 className="m-0">Gestion des utilisateurs</h3>
      <p className="text-muted">Gérer les utilisateurs</p>
      <MyTable
        ref={ref}
        columns={columns}
        apiUrl={API_GET_ALL_USERS('admin')}
        actions={actions}
      />

      <Route path={ADMIN_EDIT(':id')}>
        {({history, match}) => (
          <UserEdit
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(ADMINS);
            }}
          />
        )}
      </Route>
      {/*<Route path={USER_DELETE(":id")}>*/}
      {/*  {({ history, match }) => (*/}
      {/*    <ManagerDelete*/}
      {/*      show={match != null}*/}
      {/*      id={match && match.params.id}*/}
      {/*      onHide={() => {*/}
      {/*        history.push(MANAGERS);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</Route>*/}
    </Card>
  );
};

export default AdminsPage;
