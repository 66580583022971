import React from 'react';
import InputInline from '../../../../_metronic/layout/components/form/input/inputInline';
import UserForm from './UserForm';
import MyCheckbox from '../../../../_metronic/layout/components/form/checkbox/checkbox';
import MySelectInline from '../../../../_metronic/layout/components/form/select/selectInline';

const {
  formField: {
    username,
    email_user,
    firstname,
    lastname,
    gender,
    birthdate,
    password,
    password_confirmation,
    statement,
    sign_up,
  },
} = UserForm;

const CreateUserAuth = ({title, ...props}) => {
  const genders = [
    {
      label: 'Homme',
      value: 'Male',
    },
    {
      label: 'Femme',
      value: 'Female',
    },
    {
      label: 'Autre',
      value: 'Other',
    },
  ];
  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">
          Pour valider vos informations, et pouvoir en prendre le contrôle, il est
          nécéssaire d'avoir un compte henri trip cela prend moins d'une minute.
        </h5>
      </div>
      <MyCheckbox {...props} {...sign_up} />
      {props.values[sign_up.name] ? (
        <>
          <InputInline {...props} {...username} />
          <InputInline {...props} {...password} />
          <InputInline {...props} {...password_confirmation} />
          <InputInline {...props} {...email_user} />
          <InputInline {...props} {...firstname} />
          <InputInline {...props} {...lastname} />
          <MySelectInline
            options={genders}
            value={genders?.find((el) => el.value === props.values[gender.name])}
            {...props}
            {...gender}
          />
          <InputInline {...props} {...birthdate} />
          <MyCheckbox {...props} {...statement} />
        </>
      ) : (
        <>
          <InputInline {...props} {...email_user} />
          <InputInline {...props} {...password} />
        </>
      )}
    </>
  );
};

export default CreateUserAuth;
