/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from 'react';
import {connect, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import * as auth from '../../../../../app/modules/Auth/_redux/authRedux';
import UserView from './QuickUserViews/UserView';
import AgencyView from './QuickUserViews/AgencyView';
import QuickUserEdit from './QuickUserEdit/QuickUserEdit';
import {Button} from 'react-bootstrap';

const QuickUser = (props) => {
  const history = useHistory();
  const {user, is_admin, is_super_manager, is_manager} = useSelector(
    (state) => state.auth
  );
  const agency = React.useMemo(() => user?.agencies[0] || {}, [user]);
  // eslint-disable-next-line no-unused-vars
  const [edit, setEdit] = React.useState(false);

  const onClickButton = () => {
    console.log('clicked');
    //eslint-disable-next-line
  };

  const logoutClick = () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    history.push('/logout');
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">Profil</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content d-flex flex-column justify-content-between">
        <div>
          {is_super_manager && (
            <div className="mb-15">
              <AgencyView agency={agency} user={user} />
              {agency.stripe_account_verified &&
              agency.stripe_charges_enabled &&
              agency.stripe_payouts_enabled ? (
                <Button className="mt-4 w-100" onClick={onClickButton}>
                  Prêt pour la vente
                </Button>
              ) : (
                <Button className="btn-danger mt-4 w-100" onClick={onClickButton}>
                  Configurer les paiements
                </Button>
              )}
            </div>
          )}
          <UserView
            user={user}
            is_super_manager={is_super_manager}
            is_admin={is_admin}
            is_manager={is_manager}
            onEdit={() => setEdit(true)}
          />
        </div>
        <button className="btn btn-light-primary btn-bold w-100" onClick={logoutClick}>
          Se déconnecter
        </button>
      </div>
      <QuickUserEdit show={false} onHide={() => setEdit(false)} />
    </div>
  );
};

export default connect(null, auth.actions)(QuickUser);
