import React from 'react';
import MySelect from '../../../../../_metronic/layout/components/form/select/select';
import MyInput from '../../../../../_metronic/layout/components/form/input/input';
import FileInput from '../../../../../_metronic/layout/components/form/fileInput/fileInput';
import {useSelector} from 'react-redux';
import TouristicSitesForm from '../../TouristicSitesForm';
import MyTextArea from '../../../../../_metronic/layout/components/form/textarea/textarea';
import MyCheckbox from '../../../../../_metronic/layout/components/form/checkbox/checkbox';

const {
  formField: {
    title_fr: titleField,
    category,
    agency,
    ambassador_review,
    email,
    files,
    published,
    description_fr,
    title_en,
    description_en,
    title_it,
    description_it,
    title_ge,
    description_ge,
    title_sp,
    description_sp,
    title_cn,
    description_cn,
    title_sa,
    description_sa,
    title_pt,
    description_pt,
    title_jp,
    description_jp,
    title_ru,
    description_ru,
  },
} = TouristicSitesForm;

const CreateTouristicSiteInfo = ({title, ...props}) => {
  const {categories, agencies, user, is_admin} = useSelector((state) => state.auth);
  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
      </div>
      <MyInput {...props} {...titleField} />
      <MyInput {...props} {...email} />
      <MySelect
        {...agency}
        value={agencies.filter((el) => el.value === props.values[agency.name])}
        {...props}
        options={agencies}
        isDisabled={!is_admin}
      />
      <MySelect
        {...category}
        value={categories.filter((el) => el.value === props.values[category.name])}
        {...props}
        options={categories}
      />
      <FileInput appendImages="touristic_site" {...props} {...files} />
      {user.verified && <MyCheckbox {...props} {...published} />}
      <MyTextArea {...props} {...description_fr} />
      <MyInput {...props} {...title_en} />
      <MyTextArea {...props} {...description_en} />
      <MyInput {...props} {...title_it} />
      <MyTextArea {...props} {...description_it} />
      <MyInput {...props} {...title_ge} />
      <MyTextArea {...props} {...description_ge} />
      <MyInput {...props} {...title_sp} />
      <MyTextArea {...props} {...description_sp} />
      <MyInput {...props} {...title_cn} />
      <MyTextArea {...props} {...description_cn} />
      <MyInput {...props} {...title_sa} />
      <MyTextArea {...props} {...description_sa} />
      <MyInput {...props} {...title_pt} />
      <MyTextArea {...props} {...description_pt} />
      <MyInput {...props} {...title_jp} />
      <MyTextArea {...props} {...description_jp} />
      <MyInput {...props} {...title_ru} />
      <MyTextArea {...props} {...description_ru} />
      <MyTextArea {...props} {...ambassador_review} />
    </>
  );
};

export default CreateTouristicSiteInfo;
