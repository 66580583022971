import React from 'react';
import {Modal} from 'react-bootstrap';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_GET_AGENCY} from '../../../constants/api';
import AgencyCreation from '../AgencyCreation';

const AgencyEdit = ({refresh, id, show, onHide}) => {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const [agency, setAgency] = React.useState(null);

  React.useEffect(() => {
    if (show) {
      handleRequest('get', API_GET_AGENCY(id), {}, config, (data, status) => {
        if (status === 'Done') {
          setAgency(data);
        } else if (status === 'Error') {
          console.error(data);
          onHide();
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setAgency(undefined);
    onHide();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {agency && <AgencyCreation agency={agency} onHide={handleHide} refresh={refresh} />}
    </Modal>
  );
};

export default AgencyEdit;
