import * as Yup from 'yup';
import {REQUIRED_FIELD} from '../../../constants/texts';

const {default: AgencyForm} = require('../AgencyForm');

const {
  formField: {
    name,
    approved,
    enabled,
    country,
    address,
    email,
    telephone_number,
    fix_fee,
    variable_fee,
    max_activities,
    stripe_account_id,
    stripe_account_verified,
    stripe_charges_enabled,
    stripe_payouts_enabled,
    mod_referencing,
    mod_opinions,
    mod_activities,
    mod_ticket_office_free,
    mod_ticker_office_paying,
    mod_chat,
    mod_events,
    mod_audioguide,
    mod_translation,
    mod_interactive_map,
    cgu,
  },
} = AgencyForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(REQUIRED_FIELD),
    [approved.name]: Yup.bool(),
    [enabled.name]: Yup.bool(),
    [country.name]: Yup.string().required(REQUIRED_FIELD),
    [address.name]: Yup.string().required(REQUIRED_FIELD),
    [email.name]: Yup.string()
      .email("Mauvais format d'adresse mail")
      .min(3, '3 symboles minimum')
      .required(REQUIRED_FIELD),
    [telephone_number.name]: Yup.string().required(REQUIRED_FIELD),
  }),
  Yup.object().shape({
    [fix_fee.name]: Yup.number(),
    [variable_fee.name]: Yup.number(),
    [stripe_account_id.name]: Yup.string(),
    [stripe_account_verified.name]: Yup.bool(),
    [stripe_charges_enabled.name]: Yup.bool(),
    [stripe_payouts_enabled.name]: Yup.bool(),
  }),
  Yup.object().shape({
    [max_activities.name]: Yup.number(),
    [mod_referencing.name]: Yup.bool(),
    [mod_opinions.name]: Yup.bool(),
    [mod_activities.name]: Yup.bool(),
    [mod_ticket_office_free.name]: Yup.bool(),
    [mod_ticker_office_paying.name]: Yup.bool(),
    [mod_chat.name]: Yup.bool(),
    [mod_events.name]: Yup.bool(),
    [mod_audioguide.name]: Yup.bool(),
    [mod_translation.name]: Yup.bool(),
    [mod_interactive_map.name]: Yup.bool(),
  }),
  Yup.object().shape({
    [cgu.name]: Yup.bool().required(REQUIRED_FIELD).oneOf([true], REQUIRED_FIELD),
  }),
];
