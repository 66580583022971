import React, {useEffect, useRef, useState} from 'react';
import {Card, Pagination} from 'react-bootstrap';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import {
  API_GET_TOURISTIC_SITES_FILTERS,
  API_V1_GET_ALL_TOURISTIC_SITES,
} from '../../../constants/api';
import CardTravel from '@material-ui/icons/CardTravel';

import MySelect from '../../../../_metronic/layout/components/form/select/select';
import '../ExperienceCreate/experience.scss';
import Cart from '../TripComponent/Cart';
// import MapContainer from '../TripComponent/MapContainer';
import TSForm from '../TripComponent/TSForm';
import CardCTS from '../TripComponent/CardCTS';
import _ from 'underscore';

const ExperienceDuplicateCard = ({exp, onHide, refresh}) => {
  const [experience, setExperience] = useState(
    exp || {
      title: {text_fr: ''},
      description: {text_fr: ''},
      tips: [],
      image: null,
      amount: 0.01,
      currency: 'EUR',
      place: [],
      country: '',
      cts: [],
      order: [],
      trip_days: [],
      parallaxes: [],
      published: false,
    }
  );
  const [mapBoxPlace, setMapBoxPlace] = useState(null);
  const [filter, setFilter] = useState({
    provider: '',
    city: '',
    search: '',
    current_page: 1,
  });
  const [filterOptions, setFilterOptions] = useState();
  const [filteredCTS, setFilteredCTS] = useState([]);
  const [cart, setCart] = useState(exp?.cts || []);
  const [openCart, setOpenCart] = useState(false);
  const [createCTS, setCreateCTS] = useState(false);
  const [selectedCTS, setSelectedCTS] = useState(null);
  const [hoveredCTS, setHoveredCTS] = useState();
  const [handleRequest] = useRequest();
  const config = useConfig();
  const containerRef = useRef();

  const setLocalstorageExp = (experience) => {
    if (exp?.id) {
      localStorage.setItem('experienceEdit', JSON.stringify(experience));
    } else {
      localStorage.setItem('experienceCreate', JSON.stringify(experience));
    }
  };
  const setLocalstorageCart = (cart) => {
    if (exp?.id) {
      localStorage.setItem('cartExpEdit', JSON.stringify(cart));
    } else {
      localStorage.setItem('cartExpCreate', JSON.stringify(cart));
    }
  };
  const setLocalstorageExpDebounce = useRef(_.debounce(setLocalstorageExp, 1000));
  const setLocalstorageCartDebounce = useRef(_.debounce(setLocalstorageCart, 1000));
  useEffect(() => {
    setLocalstorageCartDebounce.current(cart);
  }, [cart]);

  useEffect(() => {
    setLocalstorageExpDebounce.current(experience);
  }, [experience]);

  useEffect(() => {
    setExperience((state) => {
      const newState = [
        ...state.order.filter((el) => cart.map((elc) => elc.id).includes(el.id)),
      ];
      let arr1 = [...new Set(state.order.map((el) => el.day_number))];
      let arr2 = [...new Set(newState.map((el) => el.day_number))];
      let diff = arr1
        .filter((x) => !arr2.includes(x))
        .concat(arr2.filter((x) => !arr1.includes(x)));
      let newTripDays =
        diff.length === 0
          ? state.trip_days
          : state.trip_days
              .filter((el) => !diff.includes(el.day_number))
              .sort((a, b) => a.day_number - b.day_number)
              .map((el, index) => {
                return {...el, day_number: index + 1};
              });

      return {
        ...state,
        cts: [...cart],
        order: [...reorderState(newState)],
        trip_days: newTripDays,
      };
    });
  }, [cart]);

  useEffect(() => {
    handleRequest(
      'get',
      API_GET_TOURISTIC_SITES_FILTERS(),
      {},
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          setFilterOptions(dataRes);
        }
      }
    );
  }, []);

  useEffect(() => {
    setMapBoxPlace(null);
    setCreateCTS(false);
    fetchDataDebounced.current(filter);
  }, [filter]);

  const fetchData = (filter) => {
    handleRequest(
      'get',
      API_V1_GET_ALL_TOURISTIC_SITES({
        provider: filter.provider,
        city: filter.city === 'Tous' ? '' : filter.city,
        search: filter.search,
        current_page: filter.current_page,
        per_page: 100,
      }),
      {},
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          const data = dataRes.touristic_sites.map((el) => {
            return {
              ...el,
              schedule: el.schedule ? JSON.parse(el.schedule) : {},
              price_list: el.price_list ? JSON.parse(el.price_list) : [],
              tips: el.touristic_site_tips,
            };
          });
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setFilteredCTS(data);
        }
      }
    );
  };
  const fetchDataDebounced = useRef(_.debounce(fetchData, 500));

  const reorderState = (state) => {
    let newState = [...state];
    const jours = [...new Set(newState.map((el) => el.day_number))];
    jours
      .sort((a, b) => a - b)
      .forEach((jour, index) => {
        newState = [
          ...newState.filter((el) => el.day_number !== jour),
          ...newState
            .filter((el) => el.day_number === jour)
            .sort((a, b) => a.rank - b.rank)
            .map((el, rank) => {
              return {...el, rank: rank + 1, day_number: index + 1};
            }),
        ];
      });
    return newState;
  };

  const toTitleCase = (str) =>
    str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());

  return (
    <Card className="rounded-lg shadow-sm">
      <div className="card-body p-0">
        <div
          className="wizard wizard-1"
          id="kt_wizard"
          data-wizard-state="first"
          data-wizard-clickable="false"
        >
          <div className="card card-custom card-shadowless rounded-top-0">
            <div className="card-body p-0">
              <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <div className="d-flex flex-row flex-nowrap align-items-center mb-5 w-100">
                  {filterOptions && (
                    <>
                      <MySelect
                        classNames="w-100 p-0 m-0"
                        onChange={(v) =>
                          setFilter((state) => {
                            return {
                              ...state,
                              provider: v.value,
                              current_page: 1,
                            };
                          })
                        }
                        options={[
                          ...filterOptions?.providers.sort().map((el) => {
                            return {
                              label: toTitleCase(
                                el.replace('crawler-google-flight:', '')
                              ),
                              value: el,
                            };
                          }),
                        ]}
                      />
                      <MySelect
                        classNames="w-100 p-0 m-0 ml-5"
                        onChange={(v) =>
                          setFilter((state) => {
                            return {
                              ...state,
                              city: v.value,
                              current_page: 1,
                            };
                          })
                        }
                        defaultValue={{label: 'Tous', value: 'Tous'}}
                        options={[
                          {label: 'Tous', value: 'Tous'},
                          ...filterOptions?.cities.sort().map((el) => {
                            return {label: el, value: el};
                          }),
                        ]}
                      />
                    </>
                  )}
                  <div className="pt-1 form-group fv-plugins-icon-container w-100 p-0 m-0 ml-5">
                    <input
                      value={filter.search}
                      onChange={(e) =>
                        setFilter((state) => {
                          return {
                            ...state,
                            search: e.target.value,
                            current_page: 1,
                          };
                        })
                      }
                      type="text"
                      placeholder="Recherche..."
                      className="form-control form-control-solid h-auto py-4 px-6 m-0 w-100"
                      style={{
                        borderColor: '#EBEDF3',
                        borderRadius: 0.3 + 'rem',
                        backgroundColor: '#EBEDF3',
                      }}
                    />
                  </div>

                  <div
                    style={{width: 32, height: 32}}
                    className="position-relative ml-5 d-flex justify-content-center align-items-center"
                    onClick={() => setOpenCart((state) => !state)}
                  >
                    <CardTravel fontSize={'large'} />
                    <div
                      className="bg-secondary font-weight-bolder d-flex justify-content-center align-items-center"
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        position: 'absolute',
                        bottom: -10,
                        right: -10,
                      }}
                    >
                      {cart.length}
                    </div>
                  </div>
                </div>
                <div className="position-relative w-100 row justify-content-center">
                  <div className="col-8 py-3">
                    <div className="d-flex flex-wrap flex-row" style={{gap: '1em'}}>
                      <button
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                          height: 200,
                          width: 200,
                          border: 'medium dashed green',
                        }}
                        type="button"
                        onClick={() => {
                          setSelectedCTS({
                            medias: [],
                            files: [],
                            title_fr: '',
                            description_fr: '',
                            tips: [],
                            access_transport: '',
                            duration_minutes: '',
                            ticket_link: '',
                            price_list: [],
                            schedule: {},
                            provider: 'admin-henri-trip',
                            languages: 'fr',
                            category_id: '',
                            phone_number: '',
                            website_url: '',
                            email: '',
                            enabled: true,
                          });
                          setMapBoxPlace(null);
                          setCreateCTS((state) => !state);
                        }}
                      >
                        {createCTS && <p>Positionner le marker sur la carte</p>}
                        <div
                          className={`btn btn-outline-none btn-${
                            createCTS ? 'danger' : 'success'
                          }`}
                        >
                          {createCTS ? 'Annuler' : '+'}
                        </div>
                      </button>
                      {filteredCTS.map((el) => (
                        <CardCTS
                          key={el.id}
                          el={el}
                          hoveredCTS={hoveredCTS}
                          setHoveredCTS={setHoveredCTS}
                          setMapBoxPlace={setMapBoxPlace}
                          setCreateCTS={setCreateCTS}
                          setSelectedCTS={setSelectedCTS}
                        />
                      ))}
                    </div>
                    <div className="d-flex justify-content-center w-100 mt-3">
                      <Pagination size="lg">
                        <Pagination.Prev
                          onClick={() =>
                            setFilter((state) => {
                              return {
                                ...state,
                                current_page: state.current_page - 1,
                              };
                            })
                          }
                          disabled={filter.current_page <= 1}
                        />
                        <Pagination.Next
                          onClick={() =>
                            setFilter((state) => {
                              return {
                                ...state,
                                current_page: state.current_page + 1,
                              };
                            })
                          }
                        />
                      </Pagination>
                    </div>
                  </div>
                  <div ref={containerRef} className="col-4 p-0 m-0 position-relative">
                    <div
                      className="col-12"
                      style={{
                        position: 'sticky',
                        height: '95vh',
                        width: '100%',
                        top: '2vh',
                      }}
                    >
                      {selectedCTS?.longitude && selectedCTS?.latitude && (
                        <TSForm
                          selectedCTS={selectedCTS}
                          setSelectedCTS={setSelectedCTS}
                          setCart={setCart}
                          setFilteredCTS={setFilteredCTS}
                        />
                      )}
                      {/*<MapContainer*/}
                      {/*  mapBoxPlace={mapBoxPlace}*/}
                      {/*  setMapBoxPlace={setMapBoxPlace}*/}
                      {/*  createCTS={createCTS}*/}
                      {/*  setSelectedCTS={setSelectedCTS}*/}
                      {/*  filteredCTS={filteredCTS}*/}
                      {/*  hoveredCTS={hoveredCTS}*/}
                      {/*  setHoveredCTS={setHoveredCTS}*/}
                      {/*  setCreateCTS={setCreateCTS}*/}
                      {/*  containerRef={containerRef}*/}
                      {/*/>*/}
                    </div>
                  </div>
                  {openCart && (
                    <>
                      <div className="overlay-cart" />
                      <Cart
                        experience={experience}
                        setExperience={setExperience}
                        setCart={setCart}
                        onHide={onHide}
                        refresh={refresh}
                        update={true}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ExperienceDuplicateCard;
