import React, {useState} from 'react';
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import * as Yup from 'yup';
import {injectIntl} from 'react-intl';
import * as auth from '../_redux/authRedux';
import MyInput from '../../../../_metronic/layout/components/form/input/input';
import {REQUIRED_FIELD} from '../../../constants/texts';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_FORGOT_PASSWORD} from '../../../constants/api';

const initialValues = {
  email: '',
};

function ForgotPassword(props) {
  const [isRequested, setIsRequested] = useState(false);
  const [handleRequest] = useRequest();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Mauvais format')
      .min(3, '3 symboles minimum')
      .required(REQUIRED_FIELD),
  });

  const handleSubmit = (values, formikBag) => {
    handleRequest('post', API_FORGOT_PASSWORD, values, {}, (data, status) => {
      if (status === 'Done') {
        setIsRequested(true);
      } else if (status === 'Error') {
        console.error(data);
      }
    });
  };

  return (
    <>
      {isRequested && <Redirect to="/auth/login" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{display: 'block'}}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Mot de passe oublié ?</h3>
            <div className="text-muted font-weight-bold">
              Ecrivez votre mail pour réinitialiser le mot de passe
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({values, status, handleSubmit, ...props}) => {
              const getInputClasses = (fieldname) => {
                if (props.touched[fieldname] && props.errors[fieldname]) {
                  return 'is-invalid';
                }

                if (props.touched[fieldname] && !props.errors[fieldname]) {
                  return 'is-valid';
                }

                return '';
              };

              return (
                <>
                  {status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-weight-bold">{status}</div>
                    </div>
                  )}
                  <MyInput
                    type="email"
                    placeholder="Adresse mail du compte"
                    {...props}
                    classes={getInputClasses('email')}
                    name="email"
                  />
                  <div className="form-group d-flex flex-wrap flex-center">
                    <Link to="/auth">
                      <button
                        type="button"
                        id="kt_login_forgot_cancel"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                      >
                        Annuler
                      </button>
                    </Link>
                    <button
                      id="kt_login_forgot_submit"
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                      disabled={props.isSubmitting}
                    >
                      Envoyer
                    </button>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
