import henriFrontPublicUrl from '../constants/henri-front-public-url';
import {useSelector} from 'react-redux';

const wrapRoutesWithAccessToken = (object, accessToken) => {
  const routesWithAccessToken = {};

  Object.keys(object).forEach((key) => {
    routesWithAccessToken[key] =
      typeof object[key] === 'object'
        ? wrapRoutesWithAccessToken(object[key], accessToken)
        : (...args) => `${object[key](...args)}?access_token=${accessToken}`;
  });

  return routesWithAccessToken;
};

const useHenriFrontPublicUrl = () => {
  const accessToken = useSelector((state) => state.auth.authToken);

  return wrapRoutesWithAccessToken(henriFrontPublicUrl, accessToken);
};

export default useHenriFrontPublicUrl;
