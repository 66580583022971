// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'ticketForm',
  formField: {
    touristicSites: {
      name: 'touristic_site_id',
      label: 'Site touristique',
      placeholder: 'Site touristique',
      noOptionsMessage: () => 'Aucun site touristique trouvé',
      required: true,
    },
    title: {
      name: 'title',
      label: 'Titre du billet',
      placeholder: 'Titre du billet',
      required: true,
    },
    duration: {
      name: 'duration',
      label: 'Durée',
      helper: 'Durée en mimutes.',
      placeholder: 'Durée en mimutes.',
      type: 'number',
    },
    price: {
      name: 'price',
      label: 'Prix (€)',
      type: 'number',
    },
    description: {
      name: 'description',
      label: 'Description',
      placeholder: 'Description du billet',
    },
    start_date: {
      name: 'start_date',
      label: 'Date début',
      type: 'date',
    },
    end_date: {
      name: 'end_date',
      label: 'Date fin',
      type: 'date',
    },
    open_holiday: {
      name: 'open_holiday',
      label: 'Ouvert jour férié ?',
    },
    schedule: {
      name: 'schedule',
      label: 'Horaires',
      placeholder: 'Horaires',
    },
    enabled: {
      name: 'enabled',
      label: 'Billet activé',
    },
    always_open: {
      name: 'always_open',
      label: 'Ouvert en toute saison',
    },
    min_person: {
      name: 'min_person',
      label: 'Min personne',
      type: 'number',
    },
    max_person: {
      name: 'max_person',
      label: 'Max personne par visite',
      type: 'number',
    },
  },
};
