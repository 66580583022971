import React from 'react';
import MyInput from '../../../../_metronic/layout/components/form/input/input';
import AgencyForm from '../AgencyForm';
import MyCheckbox from '../../../../_metronic/layout/components/form/checkbox/checkbox';
import InputAddress from '../../../../_metronic/layout/components/form/address/inputAddress';
import InputMask from '../../../../_metronic/layout/components/form/input/inputMask';

const {
  formField: {name, approved, enabled, country, address, email, telephone_number},
} = AgencyForm;

const AgencyCreationInfo = (props) => {
  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{props.title}</h5>
      </div>
      <MyCheckbox {...props} {...approved} />
      <MyCheckbox {...props} {...enabled} />
      <MyInput {...props} {...name} />
      <MyInput {...props} {...email} />
      <InputMask {...props} {...telephone_number} />
      <InputAddress {...props} {...address} />
      <MyInput {...props} {...country} />
    </>
  );
};

export default AgencyCreationInfo;
