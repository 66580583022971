import {toNumber} from 'lodash';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { takeLatest } from "redux-saga/effects";

export const actionTypes = {
  GetActivity: '[Activity] load',
  AddActivity: '[Activity] add',
  RemoveActivity: '[Activity] rm',
  UpdateActivity: '[Activity] update',
  SaveVisitSheet: '[Activity] save visitSheet',
  UpdateVisitSheet: '[Activity] update visitSheet',
  DeleteVisitSheet: '[Activity] delete visitSheet',
  SetVisitSheet: '[Activity] set visitSheet',
};

const initialActivitiesState = {
  activities: [],
  visitSheets: [],
};

export const reducer = persistReducer(
  {storage, key: 'persistantData', whitelist: ['activities']},
  (state = initialActivitiesState, action) => {
    switch (action.type) {
      case actionTypes.GetActivity: {
        return {...state, activities: action.payload};
      }

      case actionTypes.AddActivity: {
        return {
          ...state,
          activities: [...(state.activities || undefined), action.payload],
        };
      }

      case actionTypes.RemoveActivity: {
        return {
          ...state,
          activities: state.activities.filter((el) => el.id !== toNumber(action.payload)),
        };
      }

      case actionTypes.UpdateActivity: {
        return {
          ...state,
          activities: state.activities.map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                ...action.payload,
              };
            }
            return el;
          }),
        };
      }

      case actionTypes.SaveVisitSheet: {
        return {
          ...state,
          visitSheets: [...state.visitSheets, action.payload],
        };
      }

      case actionTypes.UpdateVisitSheet: {
        return {
          ...state,
          visitSheets: state.visitSheets?.map((el) => {
            if (el.id === action.payload?.id) {
              return {
                ...el,
                ...action.payload,
              };
            }
            return el;
          }),
        };
      }

      case actionTypes.DeleteVisitSheet: {
        return {
          ...state,
          visitSheets: state.visitSheets?.filter((el) => el.id !== action.payload),
        };
      }

      case actionTypes.SetVisitSheet: {
        return {
          ...state,
          visitSheets: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  getActivity: (data) => ({type: actionTypes.GetActivity, payload: data}),
  addActivity: (data) => ({type: actionTypes.AddActivity, payload: data}),
  rmActivity: (data) => ({type: actionTypes.RemoveActivity, payload: data}),
  updateActivity: (data) => ({type: actionTypes.UpdateActivity, payload: data}),
  saveVisitSheet: (data) => ({type: actionTypes.SaveVisitSheet, payload: data}),
  updateVisitSheet: (data) => ({
    type: actionTypes.UpdateVisitSheet,
    payload: data,
  }),
  rmVisitSheet: (data) => ({type: actionTypes.DeleteVisitSheet, payload: data}),
  setVisitSheet: (data) => ({type: actionTypes.SetVisitSheet, payload: data}),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  // yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  // yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  // const { data: user } = yield getUserByToken();
  // yield put(actions.fulfillUser(user));
  // });
}
