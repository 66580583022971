/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect, shallowEqual, useSelector} from 'react-redux';
import {Layout} from '../_metronic/layout';
import BasePage from './BasePage';
import {AuthPage, Logout} from './modules/Auth';
import CreateTouristicSiteManager from './pages/TouristicSites/TouristicSitesCreate/CreateTouristicSiteManager';
import * as auth from '../app/modules/Auth/_redux/authRedux';
import moment from 'moment';
import 'moment/locale/fr.js';

function Routes(props) {
  const {isAuthorized} = useSelector(
    ({auth}) => ({
      isAuthorized: auth.authToken != null,
    }),
    shallowEqual
  );

  moment.locale('fr');

  return (
    <Switch>
      <Route path="/auth/registration" component={CreateTouristicSiteManager} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Switch>
          <Route>
            <AuthPage />
          </Route>
        </Switch>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}

export default connect(null, auth.actions)(Routes);
