import React from 'react';
import CreateTrip from './CreateTrip';

const CreateTripWrapper = () => {
  let tripCreate = localStorage.getItem('tripCreate');
  let state = null;
  if (tripCreate) {
    state = JSON.parse(tripCreate);
  }
  return state ? <CreateTrip exp={state} /> : <CreateTrip />;
};

export default CreateTripWrapper;
