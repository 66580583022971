import React, {useCallback} from 'react';
import {Card} from 'react-bootstrap';
import CategoryIdentifier from '../../../_metronic/layout/components/form/category/categoryIdentifier';
import MyTable from '../../../_metronic/layout/components/table/MyTable';

import {API_GET_TOURISTIC_SITE_SUGGESTIONS_DETAILS} from '../../constants/api';
import {SUGGESTION_DETAILS, SUGGESTIONS} from '../../constants/constant-routes';
import {Switch} from 'react-router-dom';
import {Route} from 'react-router';
import SuggestionsView from './SuggestionsView';

const SuggestionsPage = ({history, ...props}) => {
  const ref = React.createRef();
  const categoryComponent = useCallback((row) => {
    return (
      <CategoryIdentifier
        key={row.id}
        categoryID={row?.ts?.categories && row?.ts?.categories[0]?.id}
        categories={row?.ts?.categories}
      />
    );
  }, []);

  const idComponent = useCallback((r) => {
    return <span key={r.id}>{r?.ts?.id}</span>;
  }, []);

  const titleComponent = useCallback((r) => {
    return <span key={r.id}>{r?.ts?.title_fr}</span>;
  }, []);

  const townComponent = useCallback((r) => {
    return (
      <span key={r.id}>
        {r?.ts?.city} - {r?.ts?.zipcode}
      </span>
    );
  }, []);

  const statusComponent = useCallback((row) => {
    return (
      <div
        className={`${
          row?.ts?.published ? 'bg-primary text-white' : 'bg-warning'
        } p-2 rounded no-wrap`}
      >
        <span>{row?.ts?.published ? 'Publié' : 'Brouillon'}</span>
      </div>
    );
  }, []);
  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
      render: idComponent,
      sorting: false,
      filtering: false,
    },
    {
      field: 'title_fr',
      title: 'Nom du site',
      render: titleComponent,
      sorting: false,
      filtering: false,
    },
    {
      field: 'categories',
      title: 'Catégorie',
      render: categoryComponent,
      sorting: false,
      filtering: false,
    },
    {
      field: 'city',
      title: 'Ville',
      render: townComponent,
      filtering: false,
      sorting: false,
    },
    {
      field: 'suggest_from',
      title: 'Depuis',
      filtering: false,
      sorting: false,
    },
    {
      field: 'status',
      title: 'Statut',
      render: statusComponent,
      sorting: false,
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: 'view_column',
      tooltip: 'Voir',
      onClick: (event, rowData) => history.push(SUGGESTION_DETAILS(rowData.id)),
    },
    // {
    //   icon: 'delete',
    //   tooltip: 'Supprimer',
    //   onClick: (event, rowData) => history.push(SUGGESTION_DELETE(rowData.id))
    // }
  ];
  return (
    <>
      <Card className="p-4 rounded-lg shadow-sm">
        <h3 className="m-0">Gestion des suggestions sites touristiques</h3>
        <p className="text-muted">Gérer les suggestions sites touristiques</p>
        <MyTable
          ref={ref}
          columns={columns}
          apiUrl={API_GET_TOURISTIC_SITE_SUGGESTIONS_DETAILS}
          actions={actions}
        />
      </Card>
      <Switch>
        <Route path={SUGGESTION_DETAILS(':id')}>
          {({history, match}) => (
            <SuggestionsView
              refresh={ref}
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(SUGGESTIONS);
              }}
            />
          )}
        </Route>
        {/*<Route path={SUGGESTION_DELETE(":id")}>*/}
        {/*  {({ history, match }) => (*/}
        {/*    <TouristicSitesDelete*/}
        {/*      refresh={ref}*/}
        {/*      show={match != null}*/}
        {/*      id={match && match.params.id}*/}
        {/*      onHide={() => {*/}
        {/*        history.push(SUGGESTIONS);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</Route>*/}
      </Switch>
    </>
  );
};

export default SuggestionsPage;
