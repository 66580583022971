import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_DELETE_EVENT} from '../../../constants/api';
import * as events from '../_redux/eventRedux';

function EventDelete({id, event_id, show, onHide, ...props}) {
  const [handleRequest] = useRequest();
  const config = useConfig();

  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteCustomer = () => {
    handleRequest(
      'delete',
      API_DELETE_EVENT(id, event_id),
      {},
      config,
      (data, status) => {
        if (status === 'Done') {
          props.rmEvent(parseInt(event_id));
          onHide();
        } else if (status === 'Error') {
          console.error(data);
        }
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Supprimer un évènement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Êtes-vous sur de vouloir supprimer cet évènement ?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Annuler
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteCustomer}
            className="btn btn-danger btn-elevate"
          >
            Supprimer
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default connect(null, events.actions)(EventDelete);
