import React from 'react';
import InputInline from '../../../../../_metronic/layout/components/form/input/inputInline';
import ManagerForm from '../../ManagerForm';

const {
  formField: {username},
} = ManagerForm;

const CreateManagerAuth = ({title, ...props}) => {
  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
      </div>
      <InputInline {...props} {...username} />
      <p>
        Le mot de passe de cet utilisateur sera généré aléatoirement et envoyé par mail à{' '}
        <span className="text-primary">{props.values[username.name]}</span>
      </p>
    </>
  );
};

export default CreateManagerAuth;
