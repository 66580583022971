import {Formik} from 'formik';
import React from 'react';
import {Card} from 'react-bootstrap';
import {showNotification, updateNotification} from '@mantine/notifications';
import useStep from '../../../../_metronic/_helpers/useStep';
import WizardStepper from '../../../modules/MyModules/WizardStepper/WizardStepper';
import CreateManagerView from '../../ManagersPage/CreateManager/CreateManagerViews/CreateManagerView';
import {
  API_CREATE_TOURISTIC_SITE,
  API_UPDATE_TOURISTIC_SITE,
} from '../../../constants/api';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import CreateTouristicSiteInfo from './CreateTouristicSiteViews/CreateTouristicSiteInfo';
import CreateTouristicSiteTime from './CreateTouristicSiteViews/CreateTouristicSiteTime';
import CreateTouristicSiteMap from './CreateTouristicSiteViews/CreateTouristicSiteMap';
import TouristicSitesValidationSchema from './Formfield/TouristicSitesValidationSchema';
import TouristicSitesInitialValues from './Formfield/TouristicSitesInitialValues';
import {TOURISTIC_SITES} from '../../../constants/constant-routes';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {CircularProgress} from '@material-ui/core';
import useHenriFrontPublicUrl from '../../../_hooks/useHenriFrontPublicUrl';

const stepsCreateTouristicSite = [
  {
    id: 1,
    title: 'Site touristique',
    flaticon: 'flaticon-profile',
    view: CreateTouristicSiteInfo,
  },
  {
    id: 2,
    title: 'Retrouver le site',
    flaticon: 'flaticon-placeholder-1',
    view: CreateTouristicSiteMap,
  },
  {
    id: 3,
    title: "Horaires d'ouverture",
    flaticon: 'flaticon-clock-2',
    view: CreateTouristicSiteTime,
  },
];

const CreateTouristicSite = (subProps) => {
  const [current, nextStep, previousStep] = useStep(1, stepsCreateTouristicSite.length);
  const [handleRequest] = useRequest();
  const config = useConfig();

  const handleSubmit = (values, formikBag) => {
    if (current === stepsCreateTouristicSite.length) {
      const data = {...values};
      data.schedule = JSON.stringify(data.schedule);
      data.public = true;
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === undefined) {
          delete data[key];
        }
      });
      data.files = data.files.map((file) => ({file: file, language: 'fr'}));
      if (subProps.touristicSite) {
        showNotification({
          id: 'load-data',
          loading: true,
          title: 'Chargement',
          message: 'Enregistrer modifications en cours ...',
          autoClose: false,
          disallowClose: true,
        });
        handleRequest(
          'put',
          API_UPDATE_TOURISTIC_SITE(subProps.touristicSite.id),
          data,
          config,
          (dataRes, status) => {
            if (status === 'Done') {
              updateNotification({
                id: 'load-data',
                color: 'teal',
                title: 'OK',
                message: 'Les modifications sont Enregistrées',
                icon: <CheckIcon />,
                autoClose: 4000,
              });
              subProps.refresh?.current?.onQueryChange?.();
              subProps.onHide();
            } else if (status === 'Error') {
              const msg = {
                code: dataRes?.status,
                msg: dataRes?.text,
              };
              formikBag.setStatus(msg);
              updateNotification({
                id: 'load-data',
                color: 'red',
                title: 'Erreur',
                message: "Une erreur s'est produite",
                icon: <CloseIcon />,
                autoClose: 4000,
                loading: false,
              });
            }
          }
        );
      } else {
        showNotification({
          id: 'load-data',
          loading: true,
          title: 'Chargement',
          message: 'En cours de création...',
          autoClose: false,
          disallowClose: true,
        });
        handleRequest('post', API_CREATE_TOURISTIC_SITE, data, config, (data, status) => {
          if (status === 'Done') {
            updateNotification({
              id: 'load-data',
              color: 'teal',
              title: 'OK',
              message: 'Création succès',
              icon: <CheckIcon />,
              autoClose: 4000,
            });
            subProps.history.push(TOURISTIC_SITES);
          } else if (status === 'Error') {
            const msg = {
              code: data?.status,
              msg: data?.text,
            };
            formikBag.setStatus(msg);
            updateNotification({
              id: 'load-data',
              color: 'red',
              title: 'Erreur',
              message: "Une erreur s'est produite",
              icon: <CloseIcon />,
              autoClose: 4000,
              loading: false,
            });
          }
        });
      }
    } else {
      nextStep();
    }
  };

  const Step = stepsCreateTouristicSite.filter((el) => el.id === current)[0];

  return (
    <Card className="rounded-lg shadow-sm">
      <div className="card-body p-0">
        <div
          className="wizard wizard-1"
          id="kt_wizard"
          data-wizard-state="first"
          data-wizard-clickable="false"
        >
          <WizardStepper steps={stepsCreateTouristicSite} currentStep={current} />
          <div className="card card-custom card-shadowless rounded-top-0">
            <div className="card-body p-0">
              <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <Formik
                  initialValues={subProps.touristicSite || TouristicSitesInitialValues}
                  validationSchema={TouristicSitesValidationSchema[current - 1]}
                  validateOnMount={false}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={handleSubmit}
                >
                  {({values, handleSubmit, ...props}) => {
                    return (
                      <CreateManagerView
                        title="Create a new touristic site"
                        next={{onClick: nextStep, title: 'Suivant'}}
                        previous={{onClick: previousStep, title: 'Précédent'}}
                        submit={{
                          onClick: handleSubmit,
                          title: !subProps.touristicSite ? 'Créer' : 'Sauvegarder',
                        }}
                        isFirst={current === 1}
                        isLast={current === stepsCreateTouristicSite.length}
                      >
                        {props.status && (
                          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                              Une erreur de type {props.status.code} est survenue...
                              <p>Message: {props.status.msg}</p>
                            </div>
                          </div>
                        )}
                        {!props.isValid && props.submitCount > 0 && (
                          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                              Vérifiez que toutes les données entrées sont justes...
                            </div>
                          </div>
                        )}
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Step.view title={Step.title} values={values} {...props} />
                      </CreateManagerView>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const HenriFrontCreateTouristicSite = ({touristicSite}) => {
  const henriFrontPublicUrl = useHenriFrontPublicUrl();

  return (
    <div
      style={{
        height: '100%',
        minHeight: '90vh',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          inset: 0,
          height: '40px',
          width: '40px',
          margin: 'auto',
        }}
      >
        <CircularProgress />
      </div>
      <iframe
        src={
          touristicSite?.id
            ? henriFrontPublicUrl.touristicSites.edit(touristicSite.id)
            : henriFrontPublicUrl.touristicSites.new()
        }
        style={{
          position: 'absolute',
          display: 'block',
          height: '100%',
          border: 'none',
        }}
      />
    </div>
  );
};

export default HenriFrontCreateTouristicSite;
