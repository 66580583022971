import React, {useRef, useState} from 'react';
import AsyncSelect from 'react-select/async';
import {showNotification, updateNotification} from '@mantine/notifications';
import {
  API_CREATE_EXPERIENCE,
  API_CREATE_TRIP,
  API_UPDATE_EXPERIENCE,
} from '../../../constants/api';
import CheckIcon from '@material-ui/icons/Check';
import {EXPERIENCES, ROAD_TRIPS} from '../../../constants/constant-routes';
import CloseIcon from '@material-ui/icons/Close';
import {useHistory} from 'react-router-dom';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import DndWrapper from './DnD/DndWrapper';
import {keysToSnake, toFormDataWithObject} from '../../../../_metronic/_helpers/utils';
import Select from 'react-select';
import countries from '../../../constants/countries.json';
import TipsList from './TipsList';
import {imageUrl} from '../../../constants/texts';
import {useSelector} from 'react-redux';

const Cart = ({experience, setExperience, setCart, onHide, refresh, update = false}) => {
  const {
    user: {ambassador: is_ambassador},
  } = useSelector((state) => state.auth);
  const timeout = useRef();
  const history = useHistory();
  const config = useConfig();
  const [errors, setError] = useState(null);

  const handleCreate = () => {
    setError(null);
    let data = {
      ...experience,
      place: JSON.stringify(experience.place),
      cts: experience.cts?.map((ct) => ct.id),
      order: experience.order?.map((order) => {
        return {
          touristic_site_id: order.id,
          day_number: order.day_number,
          rank: order.rank,
        };
      }),
      trip_days: experience.trip_days?.map((el) => {
        const tmp = el;
        if (tmp.image === null || typeof tmp.image === 'string') {
          delete tmp['image'];
        }
        return tmp;
      }),
    };
    if ('amount' in data) {
      data = {
        ...data,
        amount: data.amount * 100,
      };
    }
    if (data.image === null || typeof data.image === 'string') {
      delete data['image'];
    }
    if ('parallaxes' in data) {
      data = {
        ...data,
        parallaxes: data.parallaxes.filter((el) => typeof el.image !== 'string'),
      };
    }
    if ('tips' in data) {
      data = {
        ...data,
        tips: data.tips?.filter((tip) => tip.title !== {}),
      };
    }
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Chargement',
      message: 'Enregistrer modifications en cours ...',
      autoClose: false,
      disallowClose: true,
    });
    if (experience.id && !update) {
      updateExperience(data);
    } else {
      createExperience(data);
    }
  };

  const createExperience = (data) => {
    let url =
      process.env.REACT_APP_API_URL +
      ('amount' in data ? API_CREATE_EXPERIENCE : API_CREATE_TRIP);

    fetch(url, {
      method: 'POST',
      cache: 'default',
      body: toFormDataWithObject(data),
      headers: config.headers,
      mode: 'cors',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((res) => {
        const data = keysToSnake(res);
        updateNotification({
          id: 'load-data',
          color: 'teal',
          title: 'OK',
          message: 'Les modifications sont Enregistrées',
          icon: <CheckIcon />,
          autoClose: 4000,
        });
        if (data?.amount !== 0) {
          localStorage.removeItem('cartExpCreate');
          localStorage.removeItem('experienceCreate');
        } else {
          localStorage.removeItem('cartTripCreate');
          localStorage.removeItem('tripCreate');
        }
        history.push(data?.amount !== 0 ? EXPERIENCES : ROAD_TRIPS);
      })
      .catch((errmsg) => {
        const status = errmsg.status;
        errmsg.text &&
          errmsg.text().then((err) => {
            const errret = {
              status: status,
              text: err,
            };
            setError(errret);
            updateNotification({
              id: 'load-data',
              color: 'red',
              title: `Erreur ${errret.status}`,
              message: `${errret.err}`,
              icon: <CloseIcon />,
              autoClose: 4000,
              loading: false,
            });
          });
      });
  };

  const updateExperience = (data) => {
    let url = process.env.REACT_APP_API_URL + API_UPDATE_EXPERIENCE(data.id);

    fetch(url, {
      method: 'PUT',
      cache: 'default',
      body: toFormDataWithObject(data),
      headers: config.headers,
      mode: 'cors',
    })
      .then((res) => {
        if (res.ok) {
          updateNotification({
            id: 'load-data',
            color: 'teal',
            title: 'OK',
            message: 'Les modifications sont Enregistrées',
            icon: <CheckIcon />,
            autoClose: 4000,
          });
          if ('amount' in data) {
            localStorage.removeItem('cartExpEdit');
            localStorage.removeItem('experienceEdit');
          } else {
            localStorage.removeItem('cartTripEdit');
            localStorage.removeItem('tripEdit');
          }
          refresh.current && refresh.current.onQueryChange();
          onHide();
        } else {
          throw res;
        }
      })
      .catch((errmsg) => {
        const status = errmsg.status;
        errmsg.text &&
          errmsg.text().then((err) => {
            const errret = {
              status: status,
              text: err,
            };
            setError(errret);
            updateNotification({
              id: 'load-data',
              color: 'red',
              title: `Erreur ${errret.status}`,
              message: `${errret.err}`,
              icon: <CloseIcon />,
              autoClose: 4000,
              loading: false,
            });
          });
      });
  };
  const getAdress = (v, cb) => {
    clearTimeout(timeout.current);
    let s = v;

    if (!v) {
      s = undefined;
    }

    timeout.current = setTimeout(() => {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${s}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true&types=place&language=fr`
      )
        .then((response) => response.json())
        .then((data) => {
          cb(
            data.features.map((el) => ({
              label: el.place_name,
              value: el.place_name,
              data: el,
            }))
          );
        });
    }, 500);
  };
  return (
    <div
      className="bg-white overflow-auto w-100 d-flex flex-column pt-5 px-2 pb-10 h-auto"
      style={{position: 'absolute', top: 0, left: 0}}
    >
      {['fr', 'en', 'sp'].map((lang, index) => (
        <div
          key={lang + index}
          className="bg-white overflow-auto w-100 d-flex flex-column pt-5 px-2 pb-10 h-auto"
        >
          <label htmlFor="title" className="font-weight-bolder">
            Titre {lang}
          </label>
          <input
            name={`title_${lang}`}
            type="text"
            value={experience.title?.[`text_${lang}`] || ''}
            onChange={(e) =>
              setExperience((state) => {
                return {
                  ...state,
                  title: {...state.title, [`text_${lang}`]: e.target.value},
                };
              })
            }
          />
          <label htmlFor="description" className="mt-3 font-weight-bolder">
            Description {lang}
          </label>
          <textarea
            className="p-5"
            name={`description_${lang}`}
            rows={5}
            value={experience.description?.[`text_${lang}`] || ''}
            onChange={(e) =>
              setExperience((state) => {
                return {
                  ...state,
                  description: {...state.description, [`text_${lang}`]: e.target.value},
                };
              })
            }
          />
          <br />
        </div>
      ))}
      <label htmlFor="tips" className="mt-3 font-weight-bolder">
        Astuces
      </label>
      {experience.tips && <TipsList data={experience} setData={setExperience} />}
      <label htmlFor="image" className="mt-3 font-weight-bolder">
        Image
      </label>
      {experience.image && (
        <img src={imageUrl(experience.image)} alt="" width={50} height={50} />
      )}
      <input
        name="image"
        type="file"
        accept={'image/*'}
        onChange={(e) =>
          setExperience((state) => {
            return {...state, image: e.target.files[0]};
          })
        }
      />
      {experience.parallaxes && (
        <div className="d-flex flex-column justify-content-between px-5">
          <div className="d-flex flex-column">
            <label htmlFor="parallaxe1" className="mt-3">
              Image parallaxe 1
            </label>
            {experience.parallaxes.find((el) => el.order === 1)?.image && (
              <img
                src={imageUrl(experience.parallaxes.find((el) => el.order === 1)?.image)}
                alt=""
                width={50}
                height={50}
              />
            )}
            <input
              name="parallaxe1"
              type="file"
              accept={'image/*'}
              onChange={(e) =>
                setExperience((state) => {
                  return {
                    ...state,
                    parallaxes: [
                      ...state.parallaxes.filter((el) => el.order !== 1),
                      {
                        ...state.parallaxes.find((el) => el.order === 1),
                        order: 1,
                        image: e.target.files[0],
                      },
                    ],
                  };
                })
              }
            />
          </div>
          <div className="d-flex flex-column">
            <label htmlFor="parallaxe2" className="mt-3">
              Image parallaxe 2
            </label>
            {experience.parallaxes.find((el) => el.order === 2)?.image && (
              <img
                src={imageUrl(experience.parallaxes.find((el) => el.order === 2)?.image)}
                alt=""
                width={50}
                height={50}
              />
            )}
            <input
              name="parallaxe2"
              type="file"
              accept={'image/*'}
              onChange={(e) =>
                setExperience((state) => {
                  return {
                    ...state,
                    parallaxes: [
                      ...state.parallaxes.filter((el) => el.order !== 2),
                      {
                        ...state.parallaxes.find((el) => el.order === 2),
                        order: 2,
                        image: e.target.files[0],
                      },
                    ],
                  };
                })
              }
            />
          </div>
          <div className="d-flex flex-column">
            <label htmlFor="parallaxe3" className="mt-3">
              Image parallaxe 3
            </label>
            {experience.parallaxes.find((el) => el.order === 3)?.image && (
              <img
                src={imageUrl(experience.parallaxes.find((el) => el.order === 3)?.image)}
                alt=""
                width={50}
                height={50}
              />
            )}
            <input
              name="parallaxe3"
              type="file"
              accept={'image/*'}
              onChange={(e) =>
                setExperience((state) => {
                  return {
                    ...state,
                    parallaxes: [
                      ...state.parallaxes.filter((el) => el.order !== 3),
                      {
                        ...state.parallaxes.find((el) => el.order === 3),
                        order: 3,
                        image: e.target.files[0],
                      },
                    ],
                  };
                })
              }
            />
          </div>
        </div>
      )}
      {'amount' in experience && (
        <>
          <label htmlFor="amount" className="mt-3 font-weight-bolder">
            Prix
          </label>
          <input
            name="amount"
            type="number"
            min={0.01}
            step={0.01}
            value={experience.amount}
            onChange={(e) =>
              setExperience((state) => {
                return {
                  ...state,
                  amount: e.target.value <= 0 ? 0.01 : e.target.value || 0.01,
                };
              })
            }
          />
        </>
      )}
      <label htmlFor="place" className="mt-3 font-weight-bolder">
        Villes
      </label>
      <AsyncSelect
        isMulti={true}
        loadOptions={getAdress}
        value={experience.place}
        onChange={(e) =>
          setExperience((state) => {
            return {...state, place: [...e]};
          })
        }
      />
      <label htmlFor="country" className="mt-3 font-weight-bolder">
        Pays
      </label>
      <Select
        name="country"
        onChange={(e) =>
          setExperience((state) => {
            return {...state, country: e.value};
          })
        }
        value={countries.filter((el) => el.value === experience.country)}
        options={countries}
        styles={{
          control: (provided) => ({
            ...provided,
            borderColor: '#EBEDF3',
            borderRadius: 0.3 + 'rem',
            backgroundColor: '#FFFFFF',
            padding: 0.3 + 'rem',
          }),
          menu: (provided) => ({...provided, zIndex: 95}),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: '#707070',
          }),
        }}
      />
      {!is_ambassador && (
        <label
          htmlFor="public_cart"
          className="mt-3 font-weight-bolder align-items-center d-inline-flex"
        >
          <input
            className="mr-2"
            type="checkbox"
            id="public_cart"
            checked={'amount' in experience ? experience.published : experience.public}
            onChange={(e) =>
              setExperience((state) => {
                if ('amount' in experience) {
                  return {...state, published: e.target.checked};
                } else {
                  return {...state, public: e.target.checked};
                }
              })
            }
          />
          Voulez-vous le publier ?
        </label>
      )}
      <div className="mt-3">
        <DndWrapper
          experience={experience}
          setExperience={setExperience}
          setCart={setCart}
        />
      </div>
      {errors && (
        <div className="my-5 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">
            Une erreur de type {errors.status} est survenue...
            <p>Message: {errors.text}</p>
          </div>
        </div>
      )}
      <button type="button" className="btn btn-primary mt-5" onClick={handleCreate}>
        {experience.id ? 'Sauvegarder' : 'Créer'}
      </button>
    </div>
  );
};

export default Cart;
