import React from 'react';

const AsideMenuTitle = ({title}) => {
  return (
    <li className="menu-section ">
      <h4 className="menu-text">{title}</h4>
      <i className="menu-icon flaticon-more-v2"></i>
    </li>
  );
};

export default AsideMenuTitle;
