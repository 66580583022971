import React from 'react';
import CreateExperience from './CreateExperience';

const CreateExperienceWrapper = () => {
  let experienceCreate = localStorage.getItem('experienceCreate');
  let state = null;
  if (experienceCreate) {
    state = JSON.parse(experienceCreate);
  }
  return state ? <CreateExperience exp={state} /> : <CreateExperience />;
};

export default CreateExperienceWrapper;
