// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'managerForm',
  formField: {
    username: {
      name: 'username',
      label: 'Adresse mail',
      placeholder: 'Adresse mail',
      type: 'email',
      required: true,
    },
    firstname: {
      name: 'firstname',
      label: 'Prénom',
      placeholder: 'Prénom',
      required: true,
    },
    lastname: {
      name: 'lastname',
      label: 'Nom',
      placeholder: 'Nom',
      required: true,
    },
    fonction: {
      name: 'function',
      label: 'Fonction',
      placeholder: 'Fonction',
      required: true,
    },
    gender_id: {
      name: 'gender_id',
      label: 'Civilitée',
      placeholder: 'Choisissez votre genre',
      noOptionsMessage: () => 'Aucune civilitée trouvée',
      required: true,
    },
    is_super_manager: {
      name: 'is_super_manager',
      label: 'Est-ce un super manager ?',
    },
    touristic_sites: {
      name: 'touristic_sites',
      label: 'Sites touristiques',
    },
  },
};
