import ManagerForm from './ManagerForm';

const {
  formField: {agency, touristic_sites, touristic_site},
} = ManagerForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [agency.name]: undefined,
  [touristic_sites.name]: [],
  [touristic_site.name]: undefined,
};
