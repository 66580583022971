import {isNumber, toNumber} from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import ManagerForm from '../../ManagerForm';

const {
  formField: {
    username,
    firstname,
    lastname,
    gender_id,
    is_super_manager,
    touristic_sites,
  },
} = ManagerForm;

const CreateManagerInfo = ({title, values}) => {
  const {touristicsites} = useSelector((state) => state.touristicSites);
  const {genders} = useSelector((state) => state.auth);

  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
      </div>
      <p>
        <span className="font-weight-bolder">Email:</span> {values[username.name]}
      </p>
      <p>
        <span className="font-weight-bolder">Prénom:</span> {values[firstname.name]}
      </p>
      <p>
        <span className="font-weight-bolder">Nom:</span> {values[lastname.name]}
      </p>
      <p>
        <span className="font-weight-bolder">Civilitée:</span>{' '}
        {genders.filter((el) => el.value === values[gender_id.name])[0].label}
      </p>
      <p>
        <span className="font-weight-bolder">Super Manager:</span>{' '}
        {values[is_super_manager.name] ? 'Oui' : 'Non'}
      </p>
      <p>
        <span className="font-weight-bolder">Accès aux sites suivants:</span>{' '}
        {values[is_super_manager.name]
          ? 'Tous'
          : values[touristic_sites.name].map((elT, i) => (
              <span key={i}>
                {
                  touristicsites.filter((el) => {
                    if (isNumber(el.id)) {
                      return el.id === toNumber(elT);
                    }
                    return el.id === elT;
                  })[0]?.title_fr
                }
                {i !== values[touristic_sites.name].length - 1 ? ', ' : ''}
              </span>
            ))}
      </p>
    </>
  );
};

export default CreateManagerInfo;
