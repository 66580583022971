import React from 'react';
import {useLang} from './Metronici18n';
import {IntlProvider} from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/fr';

import enMessages from './messages/en';
import frMessages from './messages/fr';

const allMessages = {
  en: enMessages,
  fr: frMessages,
};

export function I18nProvider({children}) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
