import React from 'react';
import {NavLink} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_helpers';
import SVG from 'react-inlinesvg';

const AsideMenuDropdownList = ({
  menuIsActive,
  href,
  icon,
  title,
  children,
  notification,
}) => {
  return (
    <li
      className={`menu-item menu-item-submenu ${menuIsActive}`}
      role="menuitem"
      aria-haspopup="true"
      data-menu-toggle="hover"
    >
      <NavLink className="menu-link menu-toggle" to={href}>
        {icon && (
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
        )}
        <span className="menu-text">
          {title}
          {notification && <span class="label label-primary label-dot ml-3"></span>}
        </span>
        <i className="menu-arrow" />
      </NavLink>
      <div className="menu-submenu ">
        <i className="menu-arrow" />
        <ul className="menu-subnav">
          <li role="menuitem" className="menu-item menu-item-parent" aria-haspopup="true">
            <span className="menu-link">
              <span className="menu-text">
                {title}
                {notification && <span class="label label-primary label-dot ml-3"></span>}
              </span>
            </span>
          </li>
          {children}
        </ul>
      </div>
    </li>
  );
};

export default AsideMenuDropdownList;
