import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import axios from 'axios';
import * as _redux from './redux';
import store, {persistor} from './redux/store';
import App from './app/App';
import './index.scss';

import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from './_metronic/layout';
import {MetronicI18nProvider} from './_metronic/i18n';
import reportWebVitals from './reportWebVitals';

const {PUBLIC_URL} = process.env;

_redux.setupAxios(axios, store);

Bugsnag.start({
  apiKey: '92ed1a04f585a988a444a325edeb7f1a',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'preprod'],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorView = () => (
  <div>
    <p>An error occurred. Please retry later.</p>
  </div>
);

const AppWithProviders = () => (
  <ErrorBoundary FallbackComponent={ErrorView}>
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  </ErrorBoundary>
);

const parentElement = document.getElementById('root');

ReactDOM.render(<AppWithProviders />, parentElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
