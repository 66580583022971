import React, {useEffect, useMemo, useState} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import './CreateActivityVisitSheet.scss';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {SortableItem} from './DnD/SortableItem';
import {useHistory} from 'react-router-dom';
import ImagePicker from './VisitSheet/ImagePicker';
import useRequest from '../../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../../_metronic/_helpers/useConfig';
import {
  API_CREATE_CONTENT_VISIT_SHEET,
  API_CREATE_VISIT_SHEET,
  API_DELETE_VISIT_SHEET,
  API_DELETE_VISIT_SHEET_CONTENT,
  API_MOVE_VISIT_SHEET,
} from '../../../../constants/api';
import _ from 'underscore';
import 'flag-icons/css/flag-icons.min.css';
import AddIcon from '@material-ui/icons/Add';
import MySelect from '../../../../../_metronic/layout/components/form/select/select';
import TitleInput from './DnD/VSModule/TitleInput';
import DescriptionInput from './DnD/VSModule/DescriptionInput';
import DivDroppable from './DnD/Droppable/DivDroppable';
import Text from './DnD/VSModule/Text';
import Audio from './DnD/VSModule/Audio';
import File from './DnD/VSModule/File';
import Image from './DnD/VSModule/Image';
import Video from './DnD/VSModule/Video';
import Link from './DnD/VSModule/Link';
import MapModule from './DnD/VSModule/MapModule';

const ListModules = [
  {
    sortable_item: 'text',
    title: 'Texte',
    view: Text,
  },
  {
    sortable_item: 'link',
    title: 'Lien',
    view: Link,
  },
  {
    sortable_item: 'audio',
    title: 'Audio',
    view: Audio,
  },
  {
    sortable_item: 'image',
    title: 'Image',
    view: Image,
  },
  {
    sortable_item: 'video',
    title: 'Video',
    view: Video,
  },
  {
    sortable_item: 'map',
    title: 'Carte',
    view: MapModule,
  },
  {
    sortable_item: 'file',
    title: 'Ficher',
    view: File,
  },
];

const CreateActivityVisitSheet = ({title, ...subProps}) => {
  const history = useHistory();
  const [payload, setPayload] = useState(subProps.payload);
  const [activeVisitSheet, setActiveVisitSheet] = useState(
    subProps.payload?.visit_sheets[0] || null
  );
  const [language, setLanguage] = useState({
    label: 'French',
    value: 'French',
    icon: <span className="fi fi-fr"></span>,
    add: !Object.keys(
      _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
    ).includes('French'),
  });
  const [activeContent, setActiveContent] = useState(
    activeVisitSheet?.visit_sheet_contents.find((x) => x.language === language.value) ||
      null
  );
  const [activeLayout, setActiveLayout] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [edit, setEdit] = useState(false);
  const [ready, setReady] = useState(true);
  const [newModule, setNewModule] = useState(null);
  const [handleRequest, status] = useRequest();
  const config = useConfig();

  const languages = useMemo(
    () => [
      {
        label: 'French',
        value: 'French',
        icon: <span className="fi fi-fr"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('French'),
      },
      {
        label: 'English',
        value: 'English',
        icon: <span className="fi fi-gb"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('English'),
      },
      {
        label: 'German',
        value: 'German',
        icon: <span className="fi fi-de"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('German'),
      },
      {
        label: 'Spanish',
        value: 'Spanish',
        icon: <span className="fi fi-es"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('Spanish'),
      },
      {
        label: 'Italian',
        value: 'Italian',
        icon: <span className="fi fi-it"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('Italian'),
      },
      {
        label: 'Chinese',
        value: 'Chinese',
        icon: <span className="fi fi-cn"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('Chinese'),
      },
      {
        label: 'Arabic',
        value: 'Arabic',
        icon: <span className="fi fi-sa"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('Arabic'),
      },
      {
        label: 'Portuguese',
        value: 'Portuguese',
        icon: <span className="fi fi-pt"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('Portuguese'),
      },
      {
        label: 'Japanese',
        value: 'Japanese',
        icon: <span className="fi fi-jp"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('Japanese'),
      },
      {
        label: 'Russian',
        value: 'Russian',
        icon: <span className="fi fi-ru"></span>,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes('Russian'),
      },
    ],
    [activeVisitSheet]
  );

  useEffect(() => {
    if (status === 'Loading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    setLanguage((e) => {
      return {
        ...e,
        add: !Object.keys(
          _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
        ).includes(language.value),
      };
    });
    const content =
      activeVisitSheet?.visit_sheet_contents.find((x) => x.language === language.value) ||
      null;
    setActiveContent(content);
    // eslint-disable-next-line
  }, [activeVisitSheet]);

  useEffect(() => {
    const content =
      activeVisitSheet?.visit_sheet_contents.find((x) => x.language === language.value) ||
      null;
    setActiveContent(content);
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    const l = activeContent?.layout ? JSON.parse(activeContent.layout) : null;
    setActiveLayout(l);
    // eslint-disable-next-line
  }, [activeContent]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const {active, over} = event;

    if (active.id !== over.id) {
      const oldIndex = payload.visit_sheets.findIndex((e) => e.id === active.id);
      const newIndex = payload.visit_sheets.findIndex((e) => e.id === over.id);
      const newArr = arrayMove(payload.visit_sheets, oldIndex, newIndex);
      const arrSorted = newArr.map((el, index) => ({id: el.id, rank: index + 1}));
      const data = {
        activity_id: payload.id,
        visit_sheets_id: JSON.stringify(arrSorted),
      };
      handleRequest('post', API_MOVE_VISIT_SHEET, data, config, (dataRes, status) => {
        if (status === 'Done') {
          setPayload(dataRes);
        } else if (status === 'Error') {
          const msg = {
            code: dataRes?.status,
            msg: dataRes?.text,
          };
          setError(msg);
        }
      });
    }
  }

  const handleQuit = () => {
    if (subProps.onHide) {
      subProps.onHide();
    } else {
      history.push('/activities');
    }
  };

  const createVisitSheet = () => {
    const data = {
      activity_id: subProps.payload.id,
    };
    handleRequest('post', API_CREATE_VISIT_SHEET, data, config, (dataRes, status) => {
      if (status === 'Done') {
        setActiveVisitSheet(dataRes.visit_sheets[dataRes.visit_sheets.length - 1]);
        setPayload(dataRes);
      } else if (status === 'Error') {
        const msg = {
          code: dataRes?.status,
          msg: dataRes?.text,
        };
        setError(msg);
      }
    });
  };

  const createVisitSheetContent = () => {
    const data = {
      activity_id: subProps.payload.id,
      visit_sheet_id: activeVisitSheet?.id,
      language: language.value,
    };
    handleRequest(
      'post',
      API_CREATE_CONTENT_VISIT_SHEET,
      data,
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          setActiveVisitSheet(
            dataRes.visit_sheets.find((x) => x.id === activeVisitSheet?.id)
          );
          setPayload(dataRes);
        } else if (status === 'Error') {
          const msg = {
            code: dataRes?.status,
            msg: dataRes?.text,
          };
          setError(msg);
        }
      }
    );
  };

  const handleRemove = (visitSheet_id) => {
    handleRequest(
      'delete',
      API_DELETE_VISIT_SHEET(visitSheet_id),
      {},
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          const item = payload.visit_sheets.find((x) => x.id === visitSheet_id);
          let index = item.rank - 1 > 0 ? item.rank - 1 : 1;

          setActiveVisitSheet(dataRes.visit_sheets.find((x) => x.rank === index) || null);
          setPayload(dataRes);
        } else if (status === 'Error') {
          const msg = {
            code: dataRes?.status,
            msg: dataRes?.text,
          };
          setError(msg);
        }
      }
    );
  };

  const handleRemoveContent = () => {
    handleRequest(
      'delete',
      API_DELETE_VISIT_SHEET_CONTENT(activeContent.id),
      {},
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          setPayload((state) => {
            return {
              ...state,
              visit_sheets: state.visit_sheets.map((el) => {
                if (el.id === activeVisitSheet.id) {
                  return {
                    ...el,
                    visit_sheet_contents: el.visit_sheet_contents.filter(
                      (content) => content.id !== activeContent.id
                    ),
                  };
                } else {
                  return el;
                }
              }),
            };
          });
          setActiveVisitSheet((state) => {
            return {
              ...state,
              visit_sheet_contents: state.visit_sheet_contents.filter(
                (content) => content.id !== activeContent.id
              ),
            };
          });
        } else if (status === 'Error') {
          const msg = {
            code: dataRes?.status,
            msg: dataRes?.text,
          };
          setError(msg);
        }
      }
    );
  };

  const setContent = (data) => {
    setPayload((state) => {
      return {
        ...state,
        visit_sheets: state.visit_sheets.map((el) => {
          if (el.id === activeVisitSheet.id) {
            return {
              ...el,
              visit_sheet_contents: el.visit_sheet_contents.map((content) => {
                if (content.id === activeContent.id) {
                  return data;
                } else {
                  return content;
                }
              }),
            };
          } else {
            return el;
          }
        }),
      };
    });
    setActiveVisitSheet((state) => {
      return {
        ...state,
        visit_sheet_contents: state.visit_sheet_contents.map((content) => {
          if (content.id === activeContent.id) {
            return data;
          } else {
            return content;
          }
        }),
      };
    });
  };

  const handlerSetLayout = (layout) => {
    const sortedLayout = Object.keys(layout).reduce((accumulator, currentValue) => {
      accumulator[currentValue] = layout[currentValue];
      return accumulator;
    }, {});
    const newLayout = {};
    let index = 1;

    Object.keys(sortedLayout).forEach((key) => {
      if (key === '0') {
        newLayout['0'] = sortedLayout[key];
      }
      if (key !== '0' && sortedLayout[key].length > 0) {
        newLayout[index] = sortedLayout[key];
        index++;
      }
    });
    const data = {
      layout: JSON.stringify(newLayout),
    };

    handleRequest(
      'post',
      `/api/v1/pro/visit_sheet_contents/${activeContent.id}/layout`,
      data,
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          setContent(dataRes);
          setNewModule(null);
        } else if (status === 'Error') {
          const msg = {
            code: dataRes?.status,
            msg: dataRes?.text,
          };
          setError(msg);
        }
      }
    );
  };

  const Step = ListModules.filter((el) => el.sortable_item === newModule)[0];
  return (
    <div className="col">
      <div className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
        <div className="row justify-content-center">
          <div className="col-12">
            {error && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  Une erreur de type {error.code} est survenue...
                  <p>Message: {error.msg}</p>
                </div>
              </div>
            )}
            <div
              className="my-5 step"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
              {payload.visit_sheets.length > 0 ? (
                <ImagePicker
                  srcDefault={
                    payload.medias.filter((m) => m.content_type.includes('image/'))[0]
                      ?.url
                  }
                  activeContent={activeContent}
                  setContent={setContent}
                  edit={edit}
                  setReady={setReady}
                  handlerSetLayout={handlerSetLayout}
                />
              ) : (
                <div className="row">
                  <div className="d-flex flex-column m-auto">
                    <p className="text-muted text-center px-2 my-3">
                      Cette activité n'a pas encore de point d'intérêt
                    </p>
                    <button
                      onClick={createVisitSheet}
                      className="btn btn-primary font-weight-bolder py-4 w-auto"
                      disabled={loading}
                    >
                      Cliquez-ici pour les ajouter
                    </button>
                  </div>
                </div>
              )}
            </div>
            {payload.visit_sheets.length > 0 && (
              <div className="row m-0">
                <div className="d-flex w-100 justify-content-between col-12">
                  <div className="pt-1">
                    {Object.keys(
                      _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
                    ).includes(language.value) && (
                      <>
                        <button
                          className="btn btn-primary m-2"
                          disabled={loading || !ready}
                          onClick={() => setEdit((state) => !state)}
                        >
                          {edit ? 'Sauvegarder' : 'Modifer'}
                        </button>
                        <button
                          className="btn btn-danger m-2"
                          disabled={loading || edit || !ready}
                          onClick={handleRemoveContent}
                        >
                          Supprimer cette langue
                        </button>
                      </>
                    )}
                  </div>
                  <MySelect
                    name="language"
                    value={language}
                    onChange={(e) => setLanguage(e)}
                    defaultValue={languages[0]}
                    options={languages}
                    getOptionLabel={(e) => (
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        {e.icon}
                        <span style={{margin: '0 5px'}}>{e.label}</span>
                        <div
                          style={{
                            marginLeft: 'auto',
                            border: '1px solid',
                            borderRadius: '50%',
                            opacity: e.add ? 1 : 0,
                          }}
                        >
                          <AddIcon />
                        </div>
                      </div>
                    )}
                    isDisabled={loading || edit || !ready}
                  />
                </div>
                <div className="col-9">
                  {Object.keys(
                    _.groupBy(activeVisitSheet?.visit_sheet_contents, 'language')
                  ).includes(language.value) ? (
                    <>
                      <TitleInput
                        label="Titre"
                        edit={edit}
                        data={activeContent?.title || ''}
                        activeContent={activeContent}
                        setContent={setContent}
                        setReady={setReady}
                        setNewModule={() => setNewModule(null)}
                      />
                      <DescriptionInput
                        label="Description"
                        edit={edit}
                        data={activeContent?.description || ''}
                        activeContent={activeContent}
                        setContent={setContent}
                        setReady={setReady}
                        setNewModule={() => setNewModule(null)}
                      />
                      {activeLayout &&
                        Object.keys(activeLayout)
                          .filter((key) => key !== '0')
                          .map((key) => {
                            switch (activeLayout[key][0].sortable_item) {
                              case 'text':
                                return (
                                  <Text
                                    key={key}
                                    label="Texte"
                                    edit={edit}
                                    data={activeLayout[key][0]}
                                    activeContent={activeContent}
                                    setContent={setContent}
                                    setReady={setReady}
                                    handlerSetLayout={handlerSetLayout}
                                    rank={key}
                                    setNewModule={() => setNewModule(null)}
                                  />
                                );
                              case 'audio':
                                return (
                                  <Audio
                                    key={key}
                                    label="Audio"
                                    edit={edit}
                                    data={activeLayout[key]}
                                    activeContent={activeContent}
                                    setContent={setContent}
                                    setReady={setReady}
                                    handlerSetLayout={handlerSetLayout}
                                    rank={key}
                                    setNewModule={() => setNewModule(null)}
                                  />
                                );
                              case 'file':
                                return (
                                  <File
                                    key={key}
                                    label="Fichier"
                                    edit={edit}
                                    data={activeLayout[key]}
                                    activeContent={activeContent}
                                    setContent={setContent}
                                    setReady={setReady}
                                    handlerSetLayout={handlerSetLayout}
                                    rank={key}
                                    setNewModule={() => setNewModule(null)}
                                  />
                                );
                              case 'image':
                                return (
                                  <Image
                                    key={key}
                                    label="Image"
                                    edit={edit}
                                    data={activeLayout[key]}
                                    activeContent={activeContent}
                                    setContent={setContent}
                                    setReady={setReady}
                                    handlerSetLayout={handlerSetLayout}
                                    rank={key}
                                    setNewModule={() => setNewModule(null)}
                                  />
                                );
                              case 'video':
                                return (
                                  <Video
                                    key={key}
                                    label="Video"
                                    edit={edit}
                                    data={activeLayout[key]}
                                    activeContent={activeContent}
                                    setContent={setContent}
                                    setReady={setReady}
                                    handlerSetLayout={handlerSetLayout}
                                    rank={key}
                                    setNewModule={() => setNewModule(null)}
                                  />
                                );

                              case 'link':
                                return (
                                  <Link
                                    key={key}
                                    label="Lien"
                                    edit={edit}
                                    data={activeLayout[key][0]}
                                    activeContent={activeContent}
                                    setContent={setContent}
                                    setReady={setReady}
                                    handlerSetLayout={handlerSetLayout}
                                    rank={key}
                                    setNewModule={() => setNewModule(null)}
                                  />
                                );
                              case 'map':
                                return (
                                  <MapModule
                                    key={key}
                                    label="Carte"
                                    edit={edit}
                                    data={{
                                      location: activeContent.location || '',
                                      longitude:
                                        activeContent.longitude || payload.longitude,
                                      latitude:
                                        activeContent.latitude || payload.latitude,
                                    }}
                                    activeContent={activeContent}
                                    setContent={setContent}
                                    setReady={setReady}
                                    handlerSetLayout={handlerSetLayout}
                                    rank={key}
                                    setNewModule={() => setNewModule(null)}
                                  />
                                );
                              default:
                                return null;
                            }
                          })}

                      {edit && (
                        <>
                          {newModule && (
                            <>
                              {newModule !== 'map' ? (
                                // eslint-disable-next-line react/jsx-pascal-case
                                <Step.view
                                  label={Step.title}
                                  edit={true}
                                  activeContent={activeContent}
                                  setContent={setContent}
                                  setReady={setReady}
                                  handlerSetLayout={handlerSetLayout}
                                  rank={
                                    activeLayout
                                      ? `${Object.keys(activeLayout).length + 1}`
                                      : '1'
                                  }
                                  setNewModule={() => setNewModule(null)}
                                />
                              ) : (
                                // eslint-disable-next-line react/jsx-pascal-case
                                <Step.view
                                  label={Step.title}
                                  edit={edit}
                                  data={{
                                    location: activeContent.location || '',
                                    longitude:
                                      activeContent.longitude || payload.longitude,
                                    latitude: activeContent.latitude || payload.latitude,
                                  }}
                                  activeContent={activeContent}
                                  setContent={setContent}
                                  setReady={setReady}
                                  handlerSetLayout={handlerSetLayout}
                                  rank={
                                    activeLayout
                                      ? `${Object.keys(activeLayout).length + 1}`
                                      : '1'
                                  }
                                  setNewModule={() => setNewModule(null)}
                                  newModule={true}
                                />
                              )}
                            </>
                          )}
                          <div className="d-flex justify-content-center">
                            <DivDroppable setNewModule={setNewModule} />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="d-flex flex-column m-auto">
                      <p className="text-muted text-center px-2 my-3">
                        Cette langue n'est pas encore disponible pour ce point d'intérêt
                      </p>
                      <button
                        onClick={createVisitSheetContent}
                        className="btn btn-primary font-weight-bolder py-4 w-auto"
                        disabled={loading || edit || !ready}
                      >
                        Cliquez-ici pour l'ajouter
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-3">
                  <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                    <SortableContext
                      items={payload.visit_sheets}
                      strategy={verticalListSortingStrategy}
                    >
                      <ul className="d-flex flex-column align-items-center p-0 m-0">
                        {payload.visit_sheets.map((el) => (
                          <SortableItem
                            key={el.id}
                            id={el.id}
                            item={el}
                            defaultImg={
                              payload.medias.filter((m) =>
                                m.content_type.includes('image/')
                              )[0]?.url
                            }
                            language={language}
                            activeVisitSheet={activeVisitSheet}
                            setActiveVisitSheet={setActiveVisitSheet}
                            handleRemove={handleRemove}
                            disabled={loading || edit || !ready}
                          />
                        ))}
                      </ul>
                    </SortableContext>
                  </DndContext>
                  <div className="d-flex justify-content-center w-100 rounded">
                    <button
                      className="btn btn-primary "
                      type="button"
                      onClick={createVisitSheet}
                      disabled={loading || edit || !ready}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{width: '20px', height: '20px'}}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between border-top pt-10 mt-15">
              <div className="mr-2"></div>
              <div>
                <button
                  onClick={handleQuit}
                  className="btn btn-primary font-weight-bolder px-9 py-4"
                  disabled={loading || edit || !ready}
                >
                  Terminer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateActivityVisitSheet;
