import {useDraggable} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';
import Item from './Item';
import React from 'react';

const DraggableItem = ({el, setCart}) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: el.id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: 10,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Item
        el={el}
        onClick={() => setCart((state) => state.filter((x) => x.id !== el.id))}
        {...listeners}
        {...attributes}
      />
    </div>
  );
};

export default DraggableItem;
