import React from 'react';
import {Divider} from '@material-ui/core';

const TipsList = ({data, setData}) => {
  return (
    <>
      {data.tips?.map((tip, index) => (
        <div
          key={index}
          className="d-flex flex-column"
          style={{
            paddingLeft: 20,
            borderLeft: '3px solid #004f91',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          {['fr', 'en', 'sp'].map((lang, index2) => (
            <div className="d-flex flex-column" key={index2}>
              <label htmlFor="title" className="mt-3">
                Titre {lang}
              </label>
              <input
                name={`title[text_${lang}]`}
                type="text"
                value={tip.title?.[`text_${lang}`] || ''}
                onChange={(e) =>
                  setData((state) => {
                    return {
                      ...state,
                      tips: [
                        ...state.tips.map((el, i) => {
                          if (index !== i) {
                            return el;
                          } else {
                            return {
                              ...el,
                              title: {
                                ...el.title,
                                [`text_${lang}`]: e.target.value,
                              },
                            };
                          }
                        }),
                      ],
                    };
                  })
                }
              />
              <label htmlFor="description" className="mt-3">
                Description {lang}
              </label>
              <textarea
                name={`description[text_${lang}]`}
                rows={5}
                value={tip.description?.[`text_${lang}`] || ''}
                onChange={(e) =>
                  setData((state) => {
                    return {
                      ...state,
                      tips: [
                        ...state.tips.map((el, i) => {
                          if (index !== i) {
                            return el;
                          } else {
                            return {
                              ...el,
                              description: {
                                ...el.description,
                                [`text_${lang}`]: e.target.value,
                              },
                            };
                          }
                        }),
                      ],
                    };
                  })
                }
              />
            </div>
          ))}
          <Divider className="mt-2" />
          <label htmlFor={`link-${index}`} className="mt-3">
            Lien
          </label>
          <input
            name={`link-${index}`}
            type="text"
            value={tip.link || ''}
            onChange={(e) =>
              setData((state) => {
                return {
                  ...state,
                  tips: [
                    ...state.tips.map((el, i) => {
                      if (index !== i) {
                        return el;
                      } else {
                        return {
                          ...el,
                          link: e.target.value,
                        };
                      }
                    }),
                  ],
                };
              })
            }
          />
          <Divider className="mt-2" />
          <button
            className="btn btn-outline-none btn-danger"
            type="button"
            onClick={() =>
              setData((state) => {
                return {
                  ...state,
                  tips: [...state.tips.filter((el, i) => i !== index)],
                };
              })
            }
          >
            X
          </button>
          <Divider className="mt-2" />
        </div>
      ))}
      <div className="mt-3 d-flex justify-content-center w-100">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            setData((state) => {
              return {
                ...state,
                tips: [
                  ...state.tips,
                  {
                    title: {text_fr: ''},
                    description: {text_fr: ''},
                    link: '',
                  },
                ],
              };
            })
          }
        >
          +
        </button>
      </div>
    </>
  );
};

export default TipsList;
