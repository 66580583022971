import {Field} from 'formik';
import React from 'react';

const InputInline = ({label, placeholder, type, ...props}) => {
  return (
    <div className="form-group row fv-plugins-icon-container">
      <label className="col-xl-3 col-lg-3 col-form-label">
        {label}
        {props.required ? ' *' : ''}
      </label>
      <div className="col-lg-9 col-xl-9">
        <Field
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          name={props.name}
          className="form-control form-control-solid form-control-lg"
          placeholder={placeholder}
          type={type}
          {...props.inputProps}
        />
        <div className="fv-plugins-message-container"></div>
      </div>
      {props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
    </div>
  );
};

export default InputInline;
