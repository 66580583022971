import React from 'react';
import {toAbsoluteUrl} from '../../../../../_helpers';
import SVG from 'react-inlinesvg';
import {ImageURL} from '../../../../../../app/constants/texts';

const UserView = ({user, is_super_manager, is_admin, is_manager, onEdit}) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1>
          {is_admin
            ? 'Admin'
            : is_super_manager
            ? 'Super manager'
            : is_manager
            ? 'Manager'
            : 'Ambassadeur'}
        </h1>
        <span
          className="svg-icon svg-icon-2x svg-icon-dark"
          style={{cursor: 'cursor'}}
          onClick={onEdit}
        >
          <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}></SVG>
        </span>
      </div>
      <div className="d-flex align-items-center mt-5">
        <div className="symbol symbol-100 mr-5">
          <div
            className="symbol-label"
            style={{
              backgroundImage: user?.picture
                ? ImageURL('user', user?.picture)
                : `url(${toAbsoluteUrl('/media/logos/Logo.png')})`,
              backgroundSize: '75%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <div className="d-flex flex-column">
          <p className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary mb-0">
            {user?.username}
          </p>
          <div className="navi mt-2">
            <a href={`mailto:${user?.email}`} className="navi-item">
              <span className="navi-link p-0 pb-2">
                <span className="navi-icon mr-1">
                  <span className="svg-icon-lg svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl('/media/svg/icons/Communication/Mail.svg')}
                    ></SVG>
                  </span>
                </span>
                <span className="navi-text text-muted text-hover-primary">
                  {user?.email}
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserView;
