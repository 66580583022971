import UserForm from './UserForm';

const {
  formField: {ambassador, role, agency, ambassador_rate, verified},
} = UserForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [ambassador.name]: false,
  [ambassador_rate.name]: 1.0,
  [role.name]: undefined,
  [agency.name]: undefined,
  [verified.name]: false,
};
