import {REQUIRED_FIELD} from '../../../../constants/texts';
import TouristicSitesForm from '../../TouristicSitesForm';
import * as Yup from 'yup';
import UserForm from '../../../Users/Auth/UserForm';

const {
  formField: {
    title_fr,
    category,
    email,
    files,
    address,
    telephone_number,
    website_url,
    schedule,
    days,
    description_fr,
    description_en,
    video,
    tickets,
  },
} = TouristicSitesForm;

const {
  formField: {
    username,
    email_user,
    firstname,
    lastname,
    gender,
    birthdate,
    password,
    password_confirmation,
    statement,
    sign_up,
  },
} = UserForm;

const scheduleDaySchema = (day) =>
  Yup.object()
    .shape({
      open_morning: Yup.string(),
      close_night: Yup.string(),
      closed_noon: Yup.boolean(),
      open_afternoon: Yup.string().when('closed_noon', {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD),
      }),
      close_morning: Yup.string().when('closed_noon', {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD),
      }),
    })
    .when(days.name, {
      is: (val) => val?.includes(day),
      then: Yup.object().required(REQUIRED_FIELD),
    });

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape(
    {
      [title_fr.name]: Yup.string().required(REQUIRED_FIELD),
      [email.name]: Yup.string().required(REQUIRED_FIELD),

      [category.name]: Yup.number()
        .min(1, 'Vous devez choisir une catégorie')
        .required(REQUIRED_FIELD),

      [files.old_files_name]: Yup.array(),

      [files.files_name]: Yup.array(),

      [description_fr.name]: Yup.string().nullable(),
      [description_en.name]: Yup.string().nullable(),
    },
    ['files', 'medias', 'title_fr']
  ),
  Yup.object().shape({
    [address.name]: Yup.string().required(REQUIRED_FIELD),
    [telephone_number.name]: Yup.string().required(REQUIRED_FIELD),
    [website_url.name]: Yup.string()
      .url('Vous devez entrer un lien valide (ex: https://henri.pro)')
      .nullable(),
    [tickets.name]: Yup.string()
      .url('Vous devez entrer un lien valide (ex: https://henri.pro)')
      .nullable(),
    [video.name]: Yup.string().nullable(),
  }),
  Yup.object().shape({
    [schedule.name]: Yup.object().shape({
      lundi: scheduleDaySchema('lundi'),
      mardi: scheduleDaySchema('mardi'),
      mercredi: scheduleDaySchema('mercredi'),
      jeudi: scheduleDaySchema('jeudi'),
      vendredi: scheduleDaySchema('vendredi'),
      samedi: scheduleDaySchema('samedi'),
      dimanche: scheduleDaySchema('dimanche'),
    }),
    [days.name]: Yup.array().required("Choisissez au moins un jour d'ouverture"),
  }),
  Yup.object().shape(
    {
      [sign_up.name]: Yup.bool(),
      [username.name]: Yup.string().when('sign_up', {
        is: (a) => a === true,
        then: Yup.string()
          .matches(
            /^([A-Za-z0-9_]){4,15}$/,
            "Le format du nom d'utilisateur n'est pas le bon, 4 à 15 caractères, seuls les chiffres, les lettres et le tiret du bas sont autorisés. Exemple : henritrip_01"
          )
          .required(REQUIRED_FIELD),
        otherwise: Yup.string().nullable(),
      }),

      [email_user.name]: Yup.string()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Adresse mail incorrect'
        )
        .required(REQUIRED_FIELD),
      [firstname.name]: Yup.string().when('sign_up', {
        is: (a) => a === true,
        then: Yup.string().required(REQUIRED_FIELD),
        otherwise: Yup.string().nullable(),
      }),
      [lastname.name]: Yup.string().when('sign_up', {
        is: (a) => a === true,
        then: Yup.string().required(REQUIRED_FIELD),
        otherwise: Yup.string().nullable(),
      }),
      [gender.name]: Yup.string().when('sign_up', {
        is: (a) => a === true,
        then: Yup.string().required(REQUIRED_FIELD),
        otherwise: Yup.string().nullable(),
      }),
      [birthdate.name]: Yup.string().when('sign_up', {
        is: (a) => a === true,
        then: Yup.string().required(REQUIRED_FIELD),
        otherwise: Yup.string().nullable(),
      }),
      [password.name]: Yup.string()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$:%^&;,{}<>=|~.*_+-]).{8,70}$/,
          'Le mot de passe doit contenir au moins 8 caractères, incluant des lettres en majuscule, minuscule, au moins un chiffre et un caractère spécial [ #?!@$:%^&;,{}<>=|~.*_+- ].'
        )
        .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
        .required(REQUIRED_FIELD),
      [password_confirmation.name]: Yup.string().when('sign_up', {
        is: (a) => a === true,
        then: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Ces mots de passe ne correspondent pas!')
          .required(REQUIRED_FIELD),
        otherwise: Yup.string().nullable(),
      }),
      [statement.name]: Yup.bool().when('sign_up', {
        is: (a) => a === true,
        then: Yup.bool().required(REQUIRED_FIELD).oneOf([true], REQUIRED_FIELD),
        otherwise: Yup.bool().nullable(),
      }),
    },
    ['sign_up']
  ),
];
