import React from 'react';
import {Modal} from 'react-bootstrap';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_GET_EVENT} from '../../../constants/api';
import EventCreate from '../EventCreate/EventCreate';

const EventEdit = (props) => {
  const [handleRequest] = useRequest();
  const [event, setEvent] = React.useState();
  const config = useConfig();

  React.useEffect(() => {
    handleRequest(
      'get',
      API_GET_EVENT(props.touristicSiteID, props.id),
      {},
      config,
      (data, status) => {
        if (status === 'Done') {
          const parsedData = data.event;

          parsedData.new_images = [];
          parsedData.category_id = data.event.categories[0].id;
          parsedData.touristic_site_id = parseInt(props.touristicSiteID);
          parsedData.images = data.event?.touristic_site_event_image?.image
            ? [data.event?.touristic_site_event_image?.image]
            : [];
          setEvent(parsedData);
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <Modal show={props.show} onHide={props.onHide} size="lg">
      {event && (
        <EventCreate
          event={event}
          id={props.id}
          show={props.show}
          onHide={props.onHide}
        />
      )}
    </Modal>
  );
};

export default EventEdit;
