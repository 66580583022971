import React from 'react';

const ErrorAlert = (props) => {
  return (
    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
      <div className="alert-text font-weight-bold">{props.text}</div>
    </div>
  );
};

export default ErrorAlert;
