import {Field} from 'formik';
import React from 'react';

const MyTextArea = ({label, labelSuffix, classes, type, ...props}) => {
  const handleChange = (v) => {
    props.handleChange(v);

    if (
      props.status_name &&
      props.values &&
      props.values.attachments &&
      props.values.attachments[props.i]?.status !== 'created'
    ) {
      props.setFieldValue(props.status_name, 'modified');
    }
  };

  return (
    <div className={'form-group fv-plugins-icon-container ' + props.parentClasses}>
      {label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {label}
            {props.required ? ' *' : ''}
          </label>
          {labelSuffix}
        </div>
      )}
      <Field
        onChange={handleChange}
        onBlur={props.handleBlur}
        name={props.name}
        placeholder={props.placeholder}
        component="textarea"
        type="text"
        className={`form-control form-control-solid py-5 px-6 ${classes}`}
        style={{resize: 'vertical'}}
        disabled={props.disabled}
      />
      {props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
    </div>
  );
};

export default MyTextArea;
