import React from 'react';
import {Card, Modal} from 'react-bootstrap';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {
  API_GET_ONE_ACTIVITY,
  API_RESOURCE_ACCESSES,
  API_DELETE_RESOURCE_ACCESSES,
  API_CANCEL_RESOURCE_ACCESSES,
} from '../../../constants/api';
import {QRCodeSVG} from 'qrcode.react';
import './view.scss';
import {createUnsecuredToken} from 'jsontokens';
import {ReactComponent as RefreshSVG} from '../../../assets/icons/Refresh Icon.svg';

const ActivityView = ({refresh, id, show, onHide}) => {
  const config = useConfig();

  const [resourceAccessesTokens, setResourceAccessesTokens] = React.useState(null);

  React.useEffect(() => {
    if (show) {
      fetch(
        process.env.REACT_APP_API_URL +
          API_RESOURCE_ACCESSES +
          `?filters[resource_id][]=${id}&sorting[][name]=created_at&sorting[][direction]=desc&filters[resource_type][]=Activity`,
        {
          method: 'GET',
          headers: {...config.headers, 'Content-type': 'application/json'},
        }
      )
        .then((response) => response.json())
        .then((data) => setResourceAccessesTokens(data.list));
    }
    // eslint-disable-next-line
  }, [id]);

  const handleHide = () => {
    setResourceAccessesTokens(undefined);
    onHide();
  };
  const handleCreateResourceAccesses = async () => {
    const currentDate = new Date();
    const expiredDate = new Date(currentDate);
    expiredDate.setDate(currentDate.getDate() + 1);
    const data = {
      status: 'active',
      resource_type: 'Activity',
      resource_id: id,
      active_at: currentDate,
      expires_at: expiredDate,
    };
    const response = await fetch(process.env.REACT_APP_API_URL + API_RESOURCE_ACCESSES, {
      method: 'POST',
      headers: {...config.headers, 'Content-type': 'application/json'},
      body: JSON.stringify(data),
    });
    const res = await response.json();
    setResourceAccessesTokens((state) => [res, ...state]);
  };

  const handleDeleteResourceAccesses = async (id) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + API_DELETE_RESOURCE_ACCESSES(id),
      {
        method: 'DELETE',
        headers: {...config.headers, 'Content-type': 'application/json'},
      }
    );
    setResourceAccessesTokens((state) => state.filter((el) => el.id !== id));
  };

  const handleCancelResourceAccesses = async (id) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + API_CANCEL_RESOURCE_ACCESSES(id),
      {
        method: 'POST',
        headers: {...config.headers, 'Content-type': 'application/json'},
      }
    );

    const updatedTokens = [...resourceAccessesTokens];

    const tokenIndex = updatedTokens.findIndex((el) => el.id === id);

    const updatedToken = {...updatedTokens[tokenIndex]};
    updatedToken.status = updatedToken.status === 'active' ? 'canceled' : 'active';

    updatedTokens[tokenIndex] = updatedToken;

    setResourceAccessesTokens(updatedTokens);
  };

  const reformDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // January is month 0
    const day = date.getDate();
    return `${day}-${month}-${year}`;
  };

  function transformText(text, useCase) {
    switch (useCase) {
      case 'active':
        return 'Actif';
      case 'draft':
        return 'Brouillon';
      case 'canceled':
        return 'Annulé';
      case 'expired':
        return 'Expiré';
      default:
        return text;
    }
  }

  const [showAll, setShowAll] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(10);

  const handleShowMore = () => {
    setRowCount(resourceAccessesTokens.length);
    setShowAll(true);
  };

  const handleShowLess = () => {
    setRowCount(10);
    setShowAll(false);
  };
  return (
    <Modal
      size="lg"
      centered={true}
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {resourceAccessesTokens && (
        <Card className="rounded-lg shadow-sm">
          <div className="card-body p-0">
            <div
              className="wizard wizard-1"
              id="kt_wizard"
              data-wizard-state="first"
              data-wizard-clickable="false"
            >
              <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <div className="col-xl-12 col-xxl-10">
                      <div className="row justify-content-center">
                        <div className="col-xl-9">
                          <div className="row m-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex">
                              <div className="my-5 d-flex w-auto border-dark border url-input">
                                {resourceAccessesTokens[0]?.token
                                  .split('')
                                  .map((e, index) => {
                                    return (
                                      <div key={index} className="">
                                        <h1 className="p-5 m-0">{e}</h1>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="d-flex align-items-center ml-4">
                                <button
                                  onClick={handleCreateResourceAccesses}
                                  type="button"
                                  class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit"
                                >
                                  <RefreshSVG width={30} />
                                </button>
                              </div>
                            </div>
                            <div className="url-input my-5">
                              <div className="text">
                                <table className="table text-center">
                                  <thead>
                                    <tr>
                                      <th scope="col">Code</th>
                                      <th scope="col">Statut</th>
                                      <th scope="col">Date d'expiration</th>
                                      <th scope="col"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {resourceAccessesTokens
                                      ?.slice(0, rowCount)
                                      .map((item) => (
                                        <tr id={item.id}>
                                          <th scope="row" className="align-middle">
                                            {item.token}
                                          </th>
                                          <td className="align-middle">
                                            {transformText(item.status, item.status)}
                                          </td>
                                          <td className="align-middle">
                                            {reformDate(new Date(item.expires_at))}
                                          </td>
                                          <td className="align-middle">
                                            {item.status === 'active' ? (
                                              <button
                                                onClick={() =>
                                                  handleCancelResourceAccesses(item.id)
                                                }
                                                type="button"
                                                className="btn border border-primary px-9 py-2"
                                              >
                                                Annuler
                                              </button>
                                            ) : item.status === 'draft' ? (
                                              <button
                                                onClick={() =>
                                                  handleDeleteResourceAccesses(item.id)
                                                }
                                                type="button"
                                                className="btn border border-primary px-6 py-2"
                                              >
                                                Supprimer
                                              </button>
                                            ) : (
                                              ''
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            {rowCount < resourceAccessesTokens.length && !showAll && (
                              <button
                                type="button"
                                className="btn btn-primary font-weight-bolder px-9 py-4"
                                onClick={handleShowMore}
                              >
                                Afficher plus
                              </button>
                            )}
                            {showAll && (
                              <button
                                type="button"
                                className="btn btn-primary font-weight-bolder px-9 py-4"
                                onClick={handleShowLess}
                              >
                                Afficher moins
                              </button>
                            )}
                          </div>
                          <div className="d-flex justify-content-between border-top pt-10 mt-15">
                            <div className="mr-2"></div>
                            <div>
                              <button
                                onClick={handleHide}
                                type="button"
                                className="btn btn-primary font-weight-bolder px-9 py-4"
                              >
                                Fermer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default ActivityView;
