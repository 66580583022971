import React from 'react';
import MyCheckbox from '../../../../_metronic/layout/components/form/checkbox/checkbox';
import AgencyForm from '../AgencyForm';

const {
  formField: {
    name,
    approved,
    enabled,
    country,
    address,
    email,
    telephone_number,
    fix_fee,
    variable_fee,
    max_activities,
    stripe_account_id,
    stripe_account_verified,
    stripe_charges_enabled,
    stripe_payouts_enabled,
    mod_referencing,
    mod_opinions,
    mod_activities,
    mod_ticket_office_free,
    mod_ticker_office_paying,
    mod_chat,
    mod_events,
    mod_audioguide,
    mod_translation,
    mod_interactive_map,
    cgu,
  },
} = AgencyForm;

const AgencyCreationView = (props) => {
  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h4 className="text-dark font-weight-bold mb-10">{props.title}</h4>
      </div>
      <p className="font-weight-bolder">Agence</p>
      <p>
        <span className="font-weight-bold py-1">{name.label}: </span>
        {props.values[name.name]}
      </p>
      <p>
        <span className="font-weight-bold py-1">{approved.label}: </span>
        {props.values[approved.name] ? 'Approuvée' : 'Non approuvée'}
      </p>
      <p>
        <span className="font-weight-bold py-1">{enabled.label}: </span>
        {props.values[enabled.name] ? 'Activée' : 'Non activée'}
      </p>
      <p>
        <span className="font-weight-bold py-1">{address.label}: </span>
        {props.values[address.name]}, {props.values[country.name]}
      </p>
      <p>
        <span className="font-weight-bold py-1">{email.label}: </span>
        {props.values[email.name]}
      </p>
      <p>
        <span className="font-weight-bold py-1">{telephone_number.label}: </span>
        {props.values[telephone_number.name]}
      </p>

      <div className="py-4 pt-5">
        <p className="font-weight-bolder">Informations de paiement: </p>
        <p>
          <span className="font-weight-bold py-1">{fix_fee.label}: </span>
          {props.values[fix_fee.name]}
        </p>
        <p>
          <span className="font-weight-bold py-1">{variable_fee.label}: </span>
          {props.values[variable_fee.name]}
        </p>
        <p>
          <span className="font-weight-bold py-1">{stripe_account_id.label}: </span>
          {props.values[stripe_account_id.name]}
        </p>
        <p>
          <span className="font-weight-bold py-1">{stripe_account_verified.label}: </span>
          {props.values[stripe_account_verified.name] ? 'Vérifié' : 'Non vérifié'}
        </p>
        <p>
          <span className="font-weight-bold py-1">{stripe_charges_enabled.label}: </span>
          {props.values[stripe_charges_enabled.name] ? 'Activée' : 'Non activée'}
        </p>
        <p>
          <span className="font-weight-bold py-1">{stripe_payouts_enabled.label}: </span>
          {props.values[stripe_payouts_enabled.name] ? 'Activée' : 'Non activée'}
        </p>
      </div>
      <div className="py-2">
        <p className="font-weight-bolder">Modules sélectionnés: </p>
        {props.values[max_activities.name] && (
          <p>
            <span className="font-weight-bold py-1">{max_activities.label}: </span>
            {props.values[max_activities.name]}
          </p>
        )}
        {props.values[mod_referencing.name] && (
          <p>
            <span className="font-weight-bold py-1">{mod_referencing.label}: </span>
            {props.values[mod_referencing.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_opinions.name] && (
          <p>
            <span className="font-weight-bold py-1">{mod_opinions.label}: </span>
            {props.values[mod_opinions.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_activities.name] && (
          <p>
            <span className="font-weight-bold py-1">{mod_activities.label}: </span>
            {props.values[mod_activities.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_ticket_office_free.name] && (
          <p>
            <span className="font-weight-bold py-1">
              {mod_ticket_office_free.label}:{' '}
            </span>
            {props.values[mod_ticket_office_free.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_ticker_office_paying.name] && (
          <p>
            <span className="font-weight-bold py-1">
              {mod_ticker_office_paying.label}:{' '}
            </span>
            {props.values[mod_ticker_office_paying.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_chat.name] && (
          <p>
            <span className="font-weight-bold py-1">{mod_chat.label}: </span>
            {props.values[mod_chat.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_events.name] && (
          <p>
            <span className="font-weight-bold py-1">{mod_events.label}: </span>
            {props.values[mod_events.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_audioguide.name] && (
          <p>
            <span className="font-weight-bold py-1">{mod_audioguide.label}: </span>
            {props.values[mod_audioguide.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_translation.name] && (
          <p>
            <span className="font-weight-bold py-1">{mod_translation.label}: </span>
            {props.values[mod_translation.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
        {props.values[mod_interactive_map.name] && (
          <p>
            <span className="font-weight-bold py-1">{mod_interactive_map.label}: </span>
            {props.values[mod_interactive_map.name] ? 'Activée' : 'Non activée'}
          </p>
        )}
      </div>
      <MyCheckbox {...props} {...cgu} />
    </>
  );
};

export default AgencyCreationView;
