import React from 'react';
import * as ReactInputMask from 'react-input-mask';

const InputMask = (props) => {
  const value = React.useMemo(() => {
    const getProp = (object, path) => {
      if (path.length === 1) return object[path[0]];
      else if (path.length === 0) throw new Error('');
      else {
        if (object[path[0]]) return getProp(object[path[0]], path.slice(1));
        else {
          object[path[0]] = {};
          return getProp(object[path[0]], path.slice(1));
        }
      }
    };

    return getProp(props.values, props.name.split('.'));
  }, [props.values, props.name]);

  const handleChange = (v) => {
    props.setFieldValue(props.name, v.target.value);
  };

  return (
    <div className={'form-group ' + props.parentClasses}>
      {props.label && (
        <label className="font-size-h6 text-dark pt-5">
          {props.label}
          {props.required ? ' *' : ''}
        </label>
      )}
      <ReactInputMask
        className="form-control form-control-solid form-control-lg h-auto py-5 px-6"
        name={props.name}
        mask={props.mask}
        maskPlaceholder={null}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.handleChange || handleChange}
        onBlur={() => props.setFieldTouched(props.name, true)}
        value={value}
      />
      {props.helper && <small className="text-muted">{props.helper}</small>}
      {props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
    </div>
  );
};

export default InputMask;
