import React from 'react';
import MyInput from '../../../../_metronic/layout/components/form/input/input';
import AgencyForm from '../AgencyForm';
import MyCheckbox from '../../../../_metronic/layout/components/form/checkbox/checkbox';

const {
  formField: {
    fix_fee,
    variable_fee,
    stripe_account_id,
    stripe_account_verified,
    stripe_charges_enabled,
    stripe_payouts_enabled,
  },
} = AgencyForm;

const AgencyCreationPayment = (props) => {
  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{props.title}</h5>
      </div>
      <MyInput {...props} {...fix_fee} />
      <MyInput {...props} {...variable_fee} />
      <MyInput {...props} {...stripe_account_id} />
      <MyCheckbox {...props} {...stripe_account_verified} />
      <MyCheckbox {...props} {...stripe_charges_enabled} />
      <MyCheckbox {...props} {...stripe_payouts_enabled} />
    </>
  );
};

export default AgencyCreationPayment;
