import React, {useState} from 'react';
import useRequest from '../../../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../../../_metronic/_helpers/useConfig';
import {ProgressBar} from 'react-bootstrap';
import axios from 'axios';
import Delete from '@material-ui/icons/Delete';

const layout_builder = {
  background: {},
  text: {
    sortable_item: 'text',
    table_name: 'visit_sheet_content_has_texts',
  },
  link: {
    sortable_item: 'link',
    table_name: 'visit_sheet_content_has_links',
  },
  audio: {
    sortable_item: 'audio',
    table_name: 'files',
  },
  image: {
    sortable_item: 'image',
    table_name: 'files',
  },
  video: {
    sortable_item: 'video',
    table_name: 'files',
  },
  map: {
    sortable_item: 'map',
  },
  file: {
    sortable_item: 'file',
    table_name: 'files',
  },
};

const FilePicker = ({
  accept,
  multiple,
  files,
  activeContent,
  setReady,
  setContent,
  handlerSetLayout,
  layout_field,
  rank,
  initialState,
  setOpenFilePicker,
}) => {
  const [progress, setProgress] = useState();
  const [selectedFiles, setSelectedFiles] = useState([...initialState]);
  const [handleRequest, status] = useRequest();
  const config = useConfig();

  const upload = (event) => {
    let formData = new FormData();
    const upload_files = [...event.currentTarget.files];
    if (upload_files.length === 0) {
      return;
    }
    upload_files.forEach((file) => formData.append('files[]', file));
    setProgress(0);
    setReady(false);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/api/v1/pro/visit_sheet_contents/${activeContent.id}/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...config,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      )
      .then((response) => {
        setContent(response?.data);
        setProgress(undefined);
        setReady(true);
      })
      .catch((error) => {
        setProgress(undefined);
        setReady(true);
      });
  };

  const delete_file = (key) => {
    setProgress(100);
    setReady(false);
    handleRequest(
      'delete',
      `/api/v1/pro/visit_sheet_contents/${activeContent.id}/file/${key}`,
      {},
      config,
      (data, status) => {
        if (status === 'Done') {
          setContent(data);
          setProgress(undefined);
          setReady(true);
        } else {
          setProgress(undefined);
          setReady(true);
        }
      }
    );
  };

  const sendLayout = () => {
    const layout = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
    delete layout.undefined;
    if (selectedFiles.length > 0) {
      if (layout_field === '0') {
        layout['0'] = {...files.find((x) => x.blob_id === selectedFiles[0])};
      } else {
        layout[rank] = [
          ...selectedFiles.map((blob_id) => {
            return {
              ...files.find((x) => x.blob_id === blob_id),
              ...layout_builder[layout_field],
            };
          }),
        ];
      }
    } else {
      if (layout_field === '0') {
        delete layout['0'];
      } else {
        delete layout[rank];
      }
    }
    handlerSetLayout(layout);
    setOpenFilePicker();
  };
  return (
    <div className="w-100 h-100 bg-secondary d-flex flex-column" style={{zIndex: 2}}>
      <div className="w-100 bg-primary p-5 text-white font-weight-bold">
        {multiple ? (
          <span>Choisir un ou plusieurs fichiers</span>
        ) : (
          <span>Choisir un fichier</span>
        )}
      </div>
      <div className="w-100 bg-light h-100 p-5 d-flex flex-column">
        {progress && <ProgressBar now={progress} animated />}
        <label htmlFor="files" className="custom-file-upload">
          <i className="fa fa-cloud-upload"></i> Importer Fichiers
        </label>
        <input
          type="file"
          id={'files'}
          name={'files'}
          accept={accept}
          multiple={true}
          onChange={upload}
          disabled={status === 'Loading'}
          style={{display: 'none'}}
        />
        <div className="d-flex w-100 flex-wrap" style={{gap: 15}}>
          {files
            .filter((file) => accept.includes(file.content_type.split('/')[0]))
            .map((file) => (
              <div
                key={file.blob_id}
                onClick={() =>
                  setSelectedFiles((state) => {
                    if (multiple) {
                      if (state.includes(file.blob_id)) {
                        return [...state.filter((f) => f !== file.blob_id)];
                      } else {
                        return [...new Set([...state, file.blob_id])];
                      }
                    } else {
                      if (state.includes(file.blob_id)) {
                        return [...state.filter((f) => f !== file.blob_id)];
                      } else {
                        return [file.blob_id];
                      }
                    }
                  })
                }
                className="d-flex flex-column rounded"
                style={{
                  width: 100,
                  height: 'auto',
                  outline: selectedFiles.includes(file.blob_id)
                    ? '3px solid #004f91'
                    : 'none',
                }}
              >
                {file.content_type.match('image/*') ? (
                  <img
                    src={file.url.replace(/\?.*/, '')}
                    alt={file.filename}
                    height={75}
                  />
                ) : (
                  <div
                    style={{height: 75, width: 100, backgroundColor: '#efefef'}}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span className="fw-bold text-black">
                      {file.content_type.split('/')[1]}
                    </span>
                  </div>
                )}
                <div className="w-100 text-truncate">
                  <span className="text-truncate">{file.filename}</span>
                </div>
                <button
                  className=" btn btn-danger rounded-bottom p-0"
                  onClick={() => delete_file(file.blob_id)}
                  disabled={status === 'Loading' || progress}
                >
                  <Delete htmlColor="white" />
                </button>
              </div>
            ))}
        </div>
      </div>
      <div className="w-100 bg-secondary mt-auto p-3 d-flex justify-content-end">
        <button
          onClick={() => sendLayout()}
          className="btn btn-primary mr-5"
          disabled={status === 'Loading' || progress}
        >
          Terminer
        </button>
      </div>
    </div>
  );
};

export default FilePicker;
