import {Formik} from 'formik';
import React from 'react';
import {withRouter} from 'react-router-dom';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import useStep from '../../../../_metronic/_helpers/useStep';
import {API_CREATE_EVENT, API_EVENT_EDIT} from '../../../constants/api';
import WizardStepper from '../../../modules/MyModules/WizardStepper/WizardStepper';
import CreateManagerView from '../../ManagersPage/CreateManager/CreateManagerViews/CreateManagerView';
import EventCreateDate from './EventCreateViews/EventCreateDate';
import EventCreateInfo from './EventCreateViews/EventCreateInfo';
import EventInitialValues from './EventFormField/EventInitialValues';
import EventValidationSchema from './EventFormField/EventValidationSchema';
import * as events from '../_redux/eventRedux';
import {connect} from 'react-redux';
import {EVENTS} from '../../../constants/constant-routes';
import {Card} from 'react-bootstrap';

const stepsEventCreate = [
  {
    id: 1,
    title: "Détails de l'évènement",
    flaticon: 'flaticon-interface-11',
    view: EventCreateInfo,
  },
  {
    id: 2,
    title: "Horaires de l'évènement",
    flaticon: 'flaticon-clock-2',
    view: EventCreateDate,
  },
];

const EventCreate = (subProps) => {
  const [current, nextStep, previousStep] = useStep(1, stepsEventCreate.length);
  const [handleRequest] = useRequest();
  const config = useConfig();

  const handleSubmit = (values, formikBag) => {
    if (current === stepsEventCreate.length) {
      const eventData = {...values};

      eventData.schedule = JSON.stringify(eventData.schedule);
      eventData.is_public = false;

      if (!subProps.event) {
        handleRequest('post', API_CREATE_EVENT, eventData, config, (data, status) => {
          if (status === 'Done') {
            let ev = data.event;
            // ev.touristic_site = toNumber(id);
            subProps.setEvent(ev);
            subProps.history.push(EVENTS);
            // onHide();
          } else if (status === 'Error') {
            formikBag.setStatus(data?.status);
          }
        });
      } else {
        handleRequest(
          'post',
          API_EVENT_EDIT(subProps.event.touristic_site_id, subProps.event.id),
          eventData,
          config,
          (data, status) => {
            if (status === 'Done') {
              subProps.setEvent(eventData);
              subProps.onHide();
            } else if (status === 'Error') {
              console.error(data);
            }
          }
        );
      }
    } else {
      nextStep();
    }
  };

  const Step = stepsEventCreate.filter((el) => el.id === current)[0];

  return (
    <Card className="rounded-lg shadow-sm">
      <div className="card-body p-0">
        <div
          className="wizard wizard-1"
          id="kt_wizard"
          data-wizard-state="first"
          data-wizard-clickable="false"
        >
          <WizardStepper steps={stepsEventCreate} currentStep={current} />
          <div className="card card-custom card-shadowless rounded-top-0">
            <div className="card-body p-0">
              <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <Formik
                  initialValues={subProps.event || EventInitialValues}
                  validationSchema={EventValidationSchema[current - 1]}
                  onSubmit={handleSubmit}
                >
                  {(props) => {
                    // if (!id && !props.values.id) {
                    //     return <SelectTouristicSite />
                    // }
                    return (
                      <CreateManagerView
                        title="Create a new event"
                        next={{onClick: nextStep, title: 'Suivant'}}
                        previous={{onClick: previousStep, title: 'Précédent'}}
                        submit={{
                          onClick: props.handleSubmit,
                          title: !subProps.event ? 'Créer' : 'Sauvegarder',
                        }}
                        isFirst={current === 1}
                        isLast={current === stepsEventCreate.length}
                      >
                        {props.status && (
                          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                              Une erreur de type {props.status} est survenue...
                            </div>
                          </div>
                        )}
                        {!props.isValid && props.submitCount > 0 && (
                          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                              Vérifiez que toutes les données entrées sont justes...
                            </div>
                          </div>
                        )}
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Step.view title={Step.title} {...props} />
                      </CreateManagerView>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default withRouter(connect(null, events.actions)(EventCreate));
