import ReviewsTSForm from '../ReviewsTSForm';

const {
  formField: {note, content, answer},
} = ReviewsTSForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [note.name]: 1,
  [content.name]: '',
  [answer.name]: '',
};
