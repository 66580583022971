import * as Yup from 'yup';
import ManagerForm from './ManagerForm';

const {
  formField: {agency, touristic_sites, touristic_site},
} = ManagerForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [agency.name]: Yup.number().nullable(),
    [touristic_sites.name]: Yup.array(),
    [touristic_site.name]: Yup.number().nullable(),
  }),
];
