import React from 'react';
import PropTypes from 'prop-types';

import './image-preview-create.scss';

const ImagePreviewCreate = (props) => {
  const parentClassNames = ['preview h-100', props.parentClassNames]
    .filter((el) => el)
    .join(' ');
  const classNames = [
    'image-preview-' + props.size,
    'rounded',
    'shadow-sm',
    props.classNames,
  ]
    .filter((el) => el)
    .join(' ');

  return (
    <div className={parentClassNames} onClick={props.onClick}>
      <div className="position-relative d-inline-block">
        <div
          className={classNames}
          style={{
            width: 80,
            height: 80,
            backgroundColor: '#a6a6a6',
            backgroundImage: `url('${props.src}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
        <div className="child shadow-sm">{props.children}</div>
      </div>
    </div>
  );
};

ImagePreviewCreate.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  classNames: PropTypes.string,
};

export default ImagePreviewCreate;
