import React from 'react';
import {ImageURL} from '../../../../../../app/constants/texts';
import {toAbsoluteUrl} from '../../../../../_helpers';
import SVG from 'react-inlinesvg';

const AgencyView = ({agency, user}) => {
  return (
    <>
      <h1>Agence</h1>
      <div className="d-flex align-items-center mt-5">
        <div className="symbol symbol-100 mr-5">
          <div
            className="symbol-label"
            style={{
              backgroundImage: agency?.image
                ? ImageURL('user', agency?.image)
                : `url(${toAbsoluteUrl('/media/logos/Logo.png')})`,
              backgroundSize: '75%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <div className="d-flex flex-column">
          <p className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary mb-0">
            {agency.name}
          </p>
          <div className="navi mt-2">
            {user.address && (
              <p className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG src={toAbsoluteUrl('/media/svg/icons/Map/Marker1.svg')}></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.address}, {user.city?.nom_commune_complet} -{' '}
                    {user.city?.zip_code}
                  </span>
                </span>
              </p>
            )}
            {user.longitude && (
              <p className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG src={toAbsoluteUrl('/media/svg/icons/Map/Position.svg')}></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.longitude} - {user.latitude}
                  </span>
                </span>
              </p>
            )}
            {agency.email && (
              <p className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-text text-muted text-hover-primary">
                    {agency.email}
                  </span>
                </span>
              </p>
            )}
            {agency.phone_number && (
              <p className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-text text-muted text-hover-primary">
                    {agency.phone_number}
                  </span>
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyView;
