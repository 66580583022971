import React, {useCallback, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {useSubheader} from '../../../_metronic/layout';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import {
  ACTIVITIES,
  ACTIVITY_DELETE,
  ACTIVITY_EDIT,
  ACTIVITY_VIEW,
} from '../../constants/constant-routes';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from '../../../_metronic/_helpers';
import {API_GET_ALL_ACTIVITIES} from '../../constants/api';
import {useSelector} from 'react-redux';
import {Route} from 'react-router-dom';
import ActivityEdit from './ActivityEdit/ActivityEdit';
import ActivityDelete from './ActivityEdit/ActivityDelete';
import ActivityView from './ActivityEdit/ActivityView';

const ActivitiesPage = ({history, ...props}) => {
  const {user} = useSelector((state) => state.auth);
  const subheader = useSubheader();
  const ref = React.createRef();

  useEffect(() => {
    subheader.setTitle('Activités');
  }, [subheader]);

  const titleComponent = useCallback((row) => {
    const activityUrl = `https://henritrip.fr/touristic-site/${row.touristic_site?.id}/activity/${row.uuid}`;

    return (
      <span className="d-flex justify-content-between">
        {row.title}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={activityUrl}
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1 mb-2"
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Sign-out.svg')} />
          </span>
        </a>
      </span>
    );
  }, []);

  const TSComponent = useCallback((row) => {
    return <span>{row.touristic_site?.title}</span>;
  }, []);

  const statusComponent = useCallback((row) => {
    return (
      <div
        className={`${
          row.published ? 'bg-primary text-white' : 'bg-warning'
        } p-2 rounded no-wrap`}
      >
        <span>{row.published ? 'Publié' : 'Brouillon'}</span>
      </div>
    );
  }, []);
  const columns = [
    {
      field: 'id',
      title: 'ID',
      type: 'numeric',
      filtering: false,
    },
    {
      field: 'title',
      title: "Titre de l'activité",
      render: titleComponent,
      filtering: false,
    },
    {
      field: 'touristic_site_id',
      title: 'Site touristique',
      render: TSComponent,
      filtering: false,
    },
    {
      field: 'published',
      title: 'Statut',
      render: statusComponent,
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: 'view_column',
      tooltip: 'Voir',
      onClick: (event, rowData) => history.push(ACTIVITY_VIEW(rowData.id)),
    },
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) => history.push(ACTIVITY_EDIT(rowData.id)),
    },
    {
      icon: 'delete',
      tooltip: 'Supprimer',
      onClick: (event, rowData) => history.push(ACTIVITY_DELETE(rowData.id)),
    },
  ];

  return (
    <Card className="p-4 rounded-lg shadow-sm">
      <h3 className="m-0">Gestion des activités</h3>
      <p className="text-muted">Gérer les activités de votre agence</p>
      {user.agencies?.max_activities <= 20 && (
        <div className="p-3 bg-danger rounded">
          <p className="m-0 text-white">
            Vous avez atteint votre limite d'activités, vous ne pouvez désormais plus en
            créer. Veuillez vous diriger vers l'administration d'Henri pour accéder à plus
            d'activités.
          </p>
        </div>
      )}
      <MyTable
        ref={ref}
        columns={columns}
        apiUrl={API_GET_ALL_ACTIVITIES}
        actions={actions}
      />

      <Route path={ACTIVITY_VIEW(':id')}>
        {({history, match}) => (
          <ActivityView
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(ACTIVITIES);
            }}
          />
        )}
      </Route>
      <Route path={ACTIVITY_EDIT(':id')}>
        {({history, match}) => (
          <ActivityEdit
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(ACTIVITIES);
            }}
          />
        )}
      </Route>
      <Route path={ACTIVITY_DELETE(':id')}>
        {({history, match}) => (
          <ActivityDelete
            refresh={ref}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(ACTIVITIES);
            }}
          />
        )}
      </Route>
    </Card>
  );
};

export default ActivitiesPage;
