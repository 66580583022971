import {Card, Modal} from 'react-bootstrap';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import {
  API_GET_ACTIVITIES_TS,
  API_RESOURCE_ACCESSES_BATCH,
  API_RESOURCE_ACCESSES,
  API_DELETE_RESOURCE_ACCESSES,
  API_CANCEL_RESOURCE_ACCESSES,
} from '../../../constants/api';
import {useState, useEffect} from 'react';
import {ReactComponent as CancelSVG} from '../../../assets/icons/Icon-cancel.svg';
import {ReactComponent as DeleteSVG} from '../../../assets/icons/Icon-trash.svg';

const TouristicSitesView = ({refresh, id, show, onHide}) => {
  const config = useConfig();

  const [activities, setActivities] = useState(null);

  const [resourceAccessesTokens, setResourceAccessesTokens] = useState(null);

  const [selectedActivites, setSelectedActivites] = useState([]);

  const [token, setToken] = useState(null);

  const arrayFilters = [];

  const [showAll, setShowAll] = useState(false);
  const [rowCount, setRowCount] = useState(10);

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    setSelectedDate(tomorrow);
  }, []);

  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const currentDate = new Date().toISOString().slice(0, 10); // Get the current date in the format "YYYY-MM-DD"

    if (inputDate >= currentDate) {
      setSelectedDate(inputDate);
    }
  };

  const handleShowMore = () => {
    setRowCount(resourceAccessesTokens.length);
    setShowAll(true);
  };

  const handleShowLess = () => {
    setRowCount(10);
    setShowAll(false);
  };

  const reformDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // January is month 0
    const day = date.getDate();
    return `${day} / ${month} / ${year}`;
  };

  function transformText(text, useCase) {
    switch (useCase) {
      case 'active':
        return 'Actif';
      case 'draft':
        return 'Brouillon';
      case 'canceled':
        return 'Annulé';
      case 'expired':
        return 'Expiré';
      default:
        return text;
    }
  }

  useEffect(() => {
    if (show) {
      fetch(process.env.REACT_APP_API_URL + API_GET_ACTIVITIES_TS(id), {
        method: 'GET',
        headers: {...config.headers, 'Content-type': 'application/json'},
      })
        .then((response) => response.json())
        .then((data) => {
          setActivities(data.activities);
          data.activities.forEach((activity) =>
            arrayFilters.push(`filters[resource_id][]=${activity.id}`)
          );
          const filters = arrayFilters.join('&');

          filters &&
            fetch(
              process.env.REACT_APP_API_URL +
                API_RESOURCE_ACCESSES +
                `?${filters}&sorting[][name]=created_at&sorting[][direction]=desc&filters[resource_type][]=Activity`,
              {
                method: 'GET',
                headers: {...config.headers, 'Content-type': 'application/json'},
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setResourceAccessesTokens(data.list);
              });
        });
    }
  }, [id]);

  const handleHide = () => {
    setActivities(undefined);
    setResourceAccessesTokens(undefined);
    onHide();
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedActivites([...selectedActivites, value]);
    } else {
      setSelectedActivites(selectedActivites.filter((item) => item !== value));
    }
  };

  const handleDeleteResourceAccesses = async (id) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + API_DELETE_RESOURCE_ACCESSES(id),
      {
        method: 'DELETE',
        headers: {...config.headers, 'Content-type': 'application/json'},
      }
    );
    setResourceAccessesTokens((state) => state.filter((el) => el.id !== id));
  };

  const handleCancelResourceAccesses = async (id) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + API_CANCEL_RESOURCE_ACCESSES(id),
      {
        method: 'POST',
        headers: {...config.headers, 'Content-type': 'application/json'},
      }
    );

    const updatedTokens = [...resourceAccessesTokens];

    const tokenIndex = updatedTokens.findIndex((el) => el.id === id);

    const updatedToken = {...updatedTokens[tokenIndex]};
    updatedToken.status = updatedToken.status === 'active' ? 'canceled' : 'active';

    updatedTokens[tokenIndex] = updatedToken;

    setResourceAccessesTokens(updatedTokens);
  };

  const handleCreateResourceAccesses = async (activities, date = new Date()) => {
    const currentDate = new Date();
    const expiredDate = new Date(date);
    expiredDate.setHours(23);
    expiredDate.setMinutes(59);
    expiredDate.setSeconds(59);
    const resourcesToPost = [];
    activities.forEach((activity) => {
      resourcesToPost.push({resource_type: 'Activity', resource_id: parseInt(activity)});
    });

    const data = {
      resources: resourcesToPost,
      status: 'active',
      active_at: currentDate,
      expires_at:
        expiredDate !== '' ? expiredDate : expiredDate.setDate(currentDate.getDate() + 1),
    };

    const response = await fetch(
      process.env.REACT_APP_API_URL + API_RESOURCE_ACCESSES_BATCH,
      {
        method: 'POST',
        headers: {...config.headers, 'Content-type': 'application/json'},
        body: JSON.stringify(data),
      }
    );
    const res = await response.json();
    setToken(res[0]?.token);
    activities.forEach((activity) =>
      arrayFilters.push(`filters[resource_id][]=${activity}`)
    );
    const filters = arrayFilters.join('&');

    if (show) {
      fetch(
        process.env.REACT_APP_API_URL +
          API_RESOURCE_ACCESSES +
          `?${filters}&sorting[][name]=created_at&sorting[][direction]=desc&filters[resource_type][]=Activity`,
        {
          method: 'GET',
          headers: {...config.headers, 'Content-type': 'application/json'},
        }
      )
        .then((response) => response.json())
        .then((data) => setResourceAccessesTokens(data.list));
    }
  };

  return (
    <Modal
      size="lg"
      centered={true}
      show={show}
      onHide={handleHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {activities && (
        <Card className="rounded-lg shadow-sm">
          <div className="card-body p-0">
            <div
              className="wizard wizard-1"
              id="kt_wizard"
              data-wizard-state="first"
              data-wizard-clickable="false"
            >
              <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <div className="col-xl-12 col-xxl-10">
                      <div className="row justify-content-center">
                        <div className="col">
                          <div className="row m-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex">
                              {activities.length > 0 ? (
                                <div className="url-input my-5">
                                  <div className="text">
                                    <table className="table text-center">
                                      <thead>
                                        <tr>
                                          <th scope="col">Sélectionner</th>
                                          <th scope="col">ID</th>
                                          <th scope="col">Titre de l'activité</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {activities.map((item) => (
                                          <tr id={item.id}>
                                            <th scope="row">
                                              <div class="form-check align-middle">
                                                <input
                                                  class="form-check-input"
                                                  type="checkbox"
                                                  id={item.id}
                                                  value={item.id}
                                                  onChange={handleCheckboxChange}
                                                ></input>
                                              </div>
                                            </th>
                                            <td>{item.id}</td>
                                            <td>{item.title}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              ) : (
                                <h3>Il n'y a aucune activité</h3>
                              )}
                            </div>
                          </div>
                          {activities.length > 0 ? (
                            <div className="d-flex justify-content-center align-items-center">
                              {selectedActivites.length > 0 ? (
                                <div className="d-flex flex-column">
                                  <h5>Choisir la date d'expiration :</h5>
                                  <input
                                    className="form-control form-control-solid form-control-lg"
                                    type="date"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-primary font-weight-bolder px-9 py-4 my-2"
                                    onClick={() =>
                                      handleCreateResourceAccesses(
                                        selectedActivites,
                                        selectedDate
                                      )
                                    }
                                  >
                                    Générer code
                                  </button>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-secondary font-weight-bolder px-9 py-4"
                                  disabled
                                >
                                  Générer code
                                </button>
                              )}
                            </div>
                          ) : null}
                          <div className="my-5 d-flex w-auto url-input justify-content-center">
                            {token &&
                              token.split('').map((e, index) => {
                                return (
                                  <div key={index} className="">
                                    <h1 className="p-5 m-0">{e}</h1>
                                  </div>
                                );
                              })}
                          </div>
                          {resourceAccessesTokens ? (
                            <>
                              <div className="url-input my-5">
                                <div className="text">
                                  <table className="table text-center">
                                    <thead>
                                      <tr>
                                        <th scope="col">Visite</th>
                                        <th scope="col">Code</th>
                                        <th scope="col">Statut</th>
                                        <th scope="col">Date d'expiration</th>
                                        <th scope="col"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resourceAccessesTokens
                                        ?.slice(0, rowCount)
                                        .map((item) => {
                                          return (
                                            <tr id={item.id}>
                                              <td scope="row" className="align-middle">
                                                {activities.map((activity) =>
                                                  activity.id === item.resource_id
                                                    ? activity.title
                                                    : ''
                                                )}
                                              </td>
                                              <td scope="row" className="align-middle">
                                                {item.token}
                                              </td>
                                              <td className="align-middle py-1">
                                                {transformText(item.status, item.status)}
                                              </td>
                                              <td className="align-middle">
                                                {reformDate(new Date(item.expires_at))}
                                              </td>
                                              <td className="align-middle p-0">
                                                {item.status === 'active' ? (
                                                  <button
                                                    onClick={() =>
                                                      handleCancelResourceAccesses(
                                                        item.id
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn p-0"
                                                  >
                                                    <CancelSVG />
                                                  </button>
                                                ) : item.status === 'draft' ? (
                                                  <button
                                                    onClick={() =>
                                                      handleDeleteResourceAccesses(
                                                        item.id
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn p-0"
                                                  >
                                                    <DeleteSVG />
                                                  </button>
                                                ) : (
                                                  ''
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center align-items-center">
                                {rowCount < resourceAccessesTokens.length && !showAll && (
                                  <button
                                    type="button"
                                    className="btn btn-primary font-weight-bolder px-9 py-4"
                                    onClick={handleShowMore}
                                  >
                                    Afficher plus
                                  </button>
                                )}
                                {showAll && (
                                  <button
                                    type="button"
                                    className="btn btn-primary font-weight-bolder px-9 py-4"
                                    onClick={handleShowLess}
                                  >
                                    Afficher moins
                                  </button>
                                )}
                              </div>
                            </>
                          ) : null}
                          <div className="d-flex justify-content-between border-top pt-10 mt-15">
                            <div className="mr-2"></div>
                            <div>
                              <button
                                onClick={handleHide}
                                type="button"
                                className="btn btn-primary font-weight-bolder px-9 py-4"
                              >
                                Fermer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default TouristicSitesView;
