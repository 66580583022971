import React, {useEffect, useState} from 'react';
import {DndContext, DragOverlay, useDroppable} from '@dnd-kit/core';
import SortableItem from './SortableItem';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import Item from './Item';
import {imageUrl} from '../../../../constants/texts';

const DroppableContainer = ({
  items,
  day,
  trip_day,
  handleRemoveItem,
  setExperience,
  sensors,
}) => {
  useEffect(() => {
    if (!trip_day) {
      setExperience((state) => {
        return {
          ...state,
          trip_days: [
            ...state.trip_days,
            {day_number: day, image: null, description_fr: ''},
          ],
        };
      });
    }
  }, []);
  const {setNodeRef} = useDroppable({
    id: day,
  });
  const [activeId, setActiveId] = useState(null);

  const handleDragEnd = ({active, over}) => {
    setActiveId(null);
    const overId = over?.id;
    const activeId = active.id;
    if (!overId) {
      return;
    }
    if (overId !== activeId) {
      rearrangeOrder(activeId, overId);
    }
  };
  const handleDragStart = (event) => {
    const activeItemSplitted = event.active.id.split(' ');
    const activeId = parseInt(activeItemSplitted[0]);
    const activeRank = parseInt(activeItemSplitted[2]);
    const item = items.find((el) => el.id === activeId && el.rank === activeRank);
    setActiveId(item);
  };

  const rearrangeOrder = (itemId, over) => {
    const activeItemSplitted = itemId.split(' ');
    const activeId = parseInt(activeItemSplitted[0]);
    const activeDayNumber = parseInt(activeItemSplitted[1]);
    const activeRank = parseInt(activeItemSplitted[2]);

    const overItemSplitted = over.split(' ');
    const overId = parseInt(overItemSplitted[0]);
    const overRank = parseInt(overItemSplitted[2]);
    setExperience((state) => {
      const newOrder = [...state.order]
        .filter((el) => el.day_number === activeDayNumber)
        .sort((a, b) => a.rank - b.rank);
      const itemIndex = newOrder.findIndex(
        (el) => el.id === activeId && el.rank === activeRank
      );

      const overIndex =
        newOrder.findIndex((el) => el.id === overId && el.rank === overRank) || 0;
      const element = newOrder[itemIndex];
      newOrder.splice(itemIndex, 1);
      newOrder.splice(overIndex, 0, element);
      return {
        ...state,
        order: [
          ...state.order.filter((el) => el.day_number !== activeDayNumber),
          ...newOrder.map((el, index) => {
            return {
              ...el,
              rank: index + 1,
            };
          }),
        ],
      };
    });
  };
  return (
    <div
      className="w-100 d-flex flex-column justify-content-center align-items-center my-1 px-lg-3 bg-white shadow-sm p-5"
      ref={setNodeRef}
      style={{zIndex: 0}}
    >
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <div className="p-5 w-100">
          <div className="d-flex flex-column">
            <label htmlFor="title">
              <strong>Jour {day}</strong>
            </label>
            {trip_day?.image && (
              <img src={imageUrl(trip_day?.image)} alt="" width={50} height={50} />
            )}
            <input
              name="image"
              type="file"
              accept={'image/*'}
              onChange={(e) =>
                setExperience((state) => {
                  return {
                    ...state,
                    trip_days: [
                      ...state.trip_days.filter((el) => el.day_number !== day),
                      {
                        ...state.trip_days.find((el) => el.day_number === day),
                        image: e.target.files[0],
                      },
                    ],
                  };
                })
              }
            />
          </div>
          {['fr', 'en', 'sp'].map((lang, index) => (
            <fieldset key={index}>
              <label htmlFor={`title_${lang}`}>Titre {lang}</label>
              <input
                key={`title_${index}`}
                name={`title_${lang}`}
                rows="5"
                className="w-100"
                value={trip_day?.[`title_${lang}`] || ''}
                onChange={(e) =>
                  setExperience((state) => {
                    return {
                      ...state,
                      trip_days: [
                        ...state.trip_days.filter((el) => el.day_number !== day),
                        {
                          ...state.trip_days.find((el) => el.day_number === day),
                          [`title_${lang}`]: e.target.value,
                        },
                      ],
                    };
                  })
                }
              />
              <label htmlFor={`description_${lang}`}>Description {lang}</label>
              <textarea
                key={index}
                name={`description_${lang}`}
                rows="5"
                className="w-100"
                value={trip_day?.[`description_${lang}`] || ''}
                onChange={(e) =>
                  setExperience((state) => {
                    return {
                      ...state,
                      trip_days: [
                        ...state.trip_days.filter((el) => el.day_number !== day),
                        {
                          ...state.trip_days.find((el) => el.day_number === day),
                          [`description_${lang}`]: e.target.value,
                        },
                      ],
                    };
                  })
                }
              />
            </fieldset>
          ))}
        </div>

        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((el, index) => (
            <SortableItem
              key={index}
              el={el}
              onClick={() => handleRemoveItem(`${el.id} ${el.day_number} ${el.rank}`)}
            />
          ))}
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <Item
              el={activeId}
              onClick={() =>
                handleRemoveItem(`${activeId.id} ${activeId.day_number} ${activeId.rank}`)
              }
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default DroppableContainer;
