import React from 'react';
import {useSelector} from 'react-redux';
import DateTimeRangePicker from '../../../../../_metronic/layout/components/form/datetimepicker/date-time-range-picker';
import MySelect from '../../../../../_metronic/layout/components/form/select/select';
import EventsForm from '../../EventsForm';

const {
  formField: {start_date, end_date, touristic_site_id},
} = EventsForm;

const EventCreateDate = (props) => {
  const {touristicsites} = useSelector((state) => state.touristicSites);
  const parsedTouristicSites = React.useMemo(
    () => touristicsites.map((el) => ({label: el.title_fr, value: el.id})),
    [touristicsites]
  );

  return (
    <>
      <div
        className="my-5 step"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 className="text-dark font-weight-bold mb-10">{props.title}</h5>
      </div>
      <DateTimeRangePicker {...props} start={start_date} end={end_date} />
      <MySelect
        {...props}
        {...touristic_site_id}
        options={parsedTouristicSites}
        value={parsedTouristicSites.filter(
          (el) => el.value === props.values[touristic_site_id.name]
        )}
      />
    </>
  );
};

export default EventCreateDate;
