import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import useConfig from '../../../../_metronic/_helpers/useConfig';
import useRequest from '../../../../_metronic/_helpers/useRequest';
import {API_REMOVE_AGENCY} from '../../../constants/api';
import {showNotification, updateNotification} from '@mantine/notifications';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

function AgencyDelete({refresh, id, show, onHide, ...props}) {
  const [handleRequest] = useRequest();
  const config = useConfig();

  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteAgency = () => {
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'En cours ...',
      message: 'Suppression des données en cours...',
      autoClose: false,
      disallowClose: true,
    });
    handleRequest('delete', API_REMOVE_AGENCY(id), {}, config, (data, status) => {
      if (status === 'Done') {
        updateNotification({
          id: 'load-data',
          color: 'teal',
          title: 'OK',
          message: 'Données supprimées',
          icon: <CheckIcon />,
          autoClose: 4000,
        });
        refresh.current && refresh.current.onQueryChange();
        onHide();
      } else if (status === 'Error') {
        updateNotification({
          id: 'load-data',
          color: 'red',
          title: 'Erreur',
          message: "Une erreur s'est produite",
          icon: <CloseIcon />,
          autoClose: 4000,
          loading: false,
        });
        onHide();
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Supprimer une agence</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Êtes-vous sur de vouloir supprimer cette agence ?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Annuler
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteAgency}
            className="btn btn-danger btn-elevate"
          >
            Supprimer
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default AgencyDelete;
