// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'UserForm',
  formField: {
    firstname: {
      name: 'firstname',
      label: 'Prénom',
      placeholder: 'Prénom',
      required: true,
    },
    lastname: {
      name: 'lastname',
      label: 'Nom',
      placeholder: 'Nom',
      required: true,
    },
    address: {
      name: 'address',
      label: 'Adresse',
      placeholder: 'Adresse',
      required: true,
    },
    city: {
      name: 'cityId',
      label: 'Choisissez une ville',
      placeholder: 'Ville / Code postal',
      noOptionsMessage: () => 'Aucune ville trouvée',
      required: true,
    },
    gender_id: {
      name: 'gender_id',
      label: 'Civilité',
      placeholder: 'Civilité',
      noOptionsMessage: () => 'Aucune civilité trouvée',
      required: true,
    },
    fonction: {
      name: 'function',
      label: 'Fonction',
      placeholder: 'Fonction',
    },
    title: {
      name: 'title',
      label: "Nom de l'agence",
      placeholder: "Nom de l'agence",
      required: true,
    },
    country: {
      name: 'country',
      label: 'Pays',
      placeholder: 'Pays',
      required: true,
    },
  },
};
