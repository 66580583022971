import React, {useEffect, useState} from 'react';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Edit from '@material-ui/icons/Edit';
import Trash from '@material-ui/icons/Delete';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import FilePicker from '../../VisitSheet/FilePicker';

const Audio = ({
  data,
  edit,
  parentClasses,
  label,
  required,
  labelSuffix,
  classes,
  disabled,
  activeContent,
  setContent,
  setReady,
  handlerSetLayout,
  rank,
  setNewModule,
}) => {
  const [urls, setUrls] = useState(
    data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
  );
  const [modify, setModify] = useState(!data);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  useEffect(() => {
    setUrls(
      data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
    );
  }, [data]);

  const handleDelete = () => {
    const layout = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
    delete layout[rank];
    handlerSetLayout(layout);
    setModify(false);
    setReady(true);
  };

  return edit ? (
    <div className={'form-group fv-plugins-icon-container ' + parentClasses}>
      {label && (
        <div className="d-flex justify-content-between mt-n5">
          <label className="font-size-h6 text-dark pt-5">
            {label}
            {!modify && (
              <>
                <Edit
                  fontSize="medium"
                  className="mx-5"
                  onClick={() => setModify(true)}
                />
                <Trash fontSize="medium" htmlColor="red" onClick={handleDelete} />
              </>
            )}
          </label>
          {labelSuffix}
        </div>
      )}
      <div className="w-100 d-flex align-items-center">
        {modify ? (
          <FilePicker
            accept={'audio/*'}
            multiple={true}
            rank={rank}
            files={activeContent.files}
            setReady={setReady}
            setContent={setContent}
            activeContent={activeContent}
            handlerSetLayout={handlerSetLayout}
            layout_field={'audio'}
            initialState={data?.filter((el) => el.id).map((el) => el.blob_id) || []}
            setOpenFilePicker={() => setModify(false)}
          />
        ) : (
          <AudioPlayer
            loop={true}
            style={{
              color: '#868686 !default',
            }}
            src={urls[currentSongIndex]}
            customVolumeControls={[]}
            showSkipControls={true}
            showJumpControls={false}
            customAdditionalControls={[]}
            autoPlayAfterSrcChange={true}
            onClickPrevious={() =>
              setCurrentSongIndex((state) => (state - 1 >= 0 ? state - 1 : 0))
            }
            onClickNext={() =>
              setCurrentSongIndex((state) => (state + 1 < urls.length ? state + 1 : 0))
            }
            onEnded={() =>
              setCurrentSongIndex((state) => (state + 1 < urls.length ? state + 1 : 0))
            }
          />
        )}
        <div className="d-flex align-items-center px-2" style={{gap: 10}}>
          {modify && (
            <>
              <CheckCircleOutline color="primary" fontSize="large" />
              <HighlightOff
                color="error"
                fontSize="large"
                onClick={() => {
                  setNewModule();
                  setModify(false);
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <AudioPlayer
      loop={true}
      style={{
        color: '#868686 !default',
      }}
      src={urls[currentSongIndex]}
      customVolumeControls={[]}
      showSkipControls={true}
      showJumpControls={false}
      customAdditionalControls={[]}
      autoPlayAfterSrcChange={true}
      onClickPrevious={() =>
        setCurrentSongIndex((state) => (state - 1 >= 0 ? state - 1 : 0))
      }
      onClickNext={() =>
        setCurrentSongIndex((state) => (state + 1 < urls.length ? state + 1 : 0))
      }
      onEnded={() =>
        setCurrentSongIndex((state) => (state + 1 < urls.length ? state + 1 : 0))
      }
    />
  );
};

export default Audio;
