import React, {Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import {ContentRoute, LayoutSplashScreen} from '../_metronic/layout';
import * as auth from '../app/modules/Auth/_redux/authRedux';

import useRequest from '../_metronic/_helpers/useRequest';
import useConfig from '../_metronic/_helpers/useConfig';

import {DashboardPage} from './pages/Dashboard/DashboardPage';

import {CreateManagerPage} from './pages/ManagersPage';

import TouristicPage from './pages/TouristicSites/TouristicSites';
import CreateTouristicSite from './pages/TouristicSites/TouristicSitesCreate/CreateTouristicSite';

import ActivityCreate from './pages/Activites/ActivityCreate/CreateActivity';
import ActivitiesPage from './pages/Activites/Activites';

import TicketsPage from './pages/Tickets/Tickets';
import CreateTicket from './pages/Tickets/CreateTicket/CreateTicket';

import {
  API_GET_ALL_AGENCIES,
  API_GET_CATEGORIES,
  API_GET_USER_DETAILS,
} from './constants/api';

import Chat from './pages/Chat/Chat';
import Events from './pages/Events/Events';
import {
  ACTIVITIES,
  ACTIVITY_CREATE,
  ADMINS,
  AGENCIES,
  AGENCY_CREATE,
  CHAT_CONVERSATION,
  CHAT_CONVERSATIONS,
  ERROR_ROUTE,
  EVENT_CREATE,
  EVENTS,
  EXPERIENCE_CREATE,
  EXPERIENCES,
  HOME_ROUTE,
  MANAGER_INVITE,
  MANAGERS,
  REVIEWS,
  REVIEWS_RT,
  ROAD_TRIPS,
  SUGGESTIONS,
  SUPER_MANAGERS,
  TICKET_CALENDAR,
  TICKET_CREATE,
  TICKETS_ROUTE,
  TOURISTIC_SITE_CREATE,
  TOURISTIC_SITE_EDIT,
  TOURISTIC_SITES,
  TRIPS_CREATE,
  USERS,
} from './constants/constant-routes';
import EventCreate from './pages/Events/EventCreate/EventCreate';
import ModularRoute from '../_metronic/layout/components/content/ModularRoute';
import NoAccessPage from './pages/Errors/NoAccessPage';
import Conversation from './pages/Chat/Conversation';
import UsersPage from './pages/Users/Users';
import AdminsPage from './pages/Users/Admins';
import SuperManagersPage from './pages/Users/SuperManagers';
import ManagersPage from './pages/Users/Managers';
import AgenciesPage from './pages/Agency/Agencies';
import RoadTripsPage from './pages/RoadTrips/RoadTrips';
import SuggestionsPage from './pages/Suggestions/Suggestions';
import AgencyCreation from './pages/Agency/AgencyCreation';
import ReviewsTSPage from './pages/Reviews/ReviewsTS';
import ReviewsRTPage from './pages/Reviews/ReviewsRT';
import TicketCalendar from './pages/Tickets/TicketCalendar/TicketCalendar';
import ExperiencesPage from './pages/RoadTrips/Experiences';
import CreateExperienceWrapper from './pages/RoadTrips/ExperienceCreate/CreateExperienceWrapper';
import CreateTripWrapper from './pages/RoadTrips/TripCreate/CreateTripWrapper';
import TouristicSiteEdit from './pages/TouristicSites/ToursitcSitesEdit/TouristicSitesEdit';

function BasePage(props) {
  const [handleRequest] = useRequest();
  const config = useConfig();
  const {
    is_admin,
    is_super_manager,
    is_manager,
    user: {ambassador: is_ambassador},
  } = useSelector((state) => state.auth);

  React.useEffect(() => {
    handleRequest('get', API_GET_USER_DETAILS, {}, config, (data, status) => {
      if (status === 'Done') {
        props.login(data);
      } else if (status === 'Error') {
        console.log(data);
      }
    });

    handleRequest('get', API_GET_CATEGORIES, {}, config, (data, status) => {
      if (status === 'Done') {
        const categoryId = [
          53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
        ];
        let categoriesTouristicSites = [];

        data.forEach((el) => {
          if (categoryId.includes(el.id)) {
            categoriesTouristicSites.push({label: el.title, value: el.id});
          }
        });
        props.addCategories(categoriesTouristicSites);
      }
    });

    handleRequest(
      'get',
      API_GET_ALL_AGENCIES + 'per_page=100',
      {},
      config,
      (data, status) => {
        if (status === 'Done') {
          props.addAgencies([
            {label: 'Pas agence', value: undefined},
            ...data.data.map((el) => ({label: el.name, value: el.id})),
          ]);
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={HOME_ROUTE} />
        }
        <ContentRoute path={HOME_ROUTE} component={DashboardPage} />
        <Route path={TOURISTIC_SITE_EDIT(':id')}>
          {({match}) => <TouristicSiteEdit id={match?.params?.id} />}
        </Route>
        <Route path={TOURISTIC_SITES} component={TouristicPage} />
        <ModularRoute
          path={TOURISTIC_SITE_CREATE}
          component={CreateTouristicSite}
          exact
          condition={is_admin}
        />
        <ModularRoute path={USERS} component={UsersPage} condition={is_admin} />
        <ModularRoute path={ADMINS} component={AdminsPage} condition={is_admin} />
        <ModularRoute
          path={SUPER_MANAGERS}
          component={SuperManagersPage}
          condition={is_admin}
        />
        <ModularRoute
          path={MANAGERS}
          component={ManagersPage}
          condition={is_super_manager || is_admin}
        />
        <ModularRoute
          path={MANAGER_INVITE}
          component={CreateManagerPage}
          condition={is_admin}
        />
        <ModularRoute path={AGENCIES} component={AgenciesPage} condition={is_admin} />
        <ModularRoute
          path={AGENCY_CREATE}
          component={AgencyCreation}
          condition={is_admin}
        />
        <ModularRoute
          path={TICKETS_ROUTE}
          component={TicketsPage}
          condition={is_manager || is_super_manager || is_admin}
        />
        <ModularRoute
          path={TICKET_CREATE}
          component={CreateTicket}
          condition={is_manager || is_super_manager || is_admin}
        />
        <ModularRoute
          path={TICKET_CALENDAR}
          component={TicketCalendar}
          condition={is_manager || is_super_manager || is_admin}
        />
        <ModularRoute
          path={EVENT_CREATE}
          component={NoAccessPage || EventCreate}
          condition={is_admin}
        />
        <ModularRoute
          path={EVENTS}
          component={NoAccessPage || Events}
          condition={is_admin}
        />
        <ModularRoute
          path={ACTIVITIES}
          component={ActivitiesPage}
          condition={is_manager || is_super_manager || is_admin}
        />
        <ModularRoute
          path={ACTIVITY_CREATE}
          component={ActivityCreate}
          condition={is_manager || is_super_manager || is_admin}
        />
        <ModularRoute
          path={ROAD_TRIPS}
          component={RoadTripsPage}
          condition={is_admin || is_ambassador}
        />
        <ModularRoute
          path={EXPERIENCES}
          component={ExperiencesPage}
          condition={is_admin || is_ambassador}
        />
        <ModularRoute
          path={TRIPS_CREATE}
          component={CreateTripWrapper}
          condition={is_admin || is_ambassador}
        />
        <ModularRoute
          path={EXPERIENCE_CREATE}
          component={CreateExperienceWrapper}
          condition={is_admin || is_ambassador}
        />
        <ModularRoute
          path={SUGGESTIONS}
          component={SuggestionsPage}
          condition={is_admin}
        />
        <ModularRoute
          path={REVIEWS}
          component={ReviewsTSPage}
          condition={is_manager || is_super_manager || is_admin}
        />
        <ModularRoute path={REVIEWS_RT} component={ReviewsRTPage} condition={is_admin} />
        <ModularRoute
          path={CHAT_CONVERSATION(':ts_id', ':id')}
          component={NoAccessPage || Chat}
          condition={is_admin}
        />
        <ModularRoute
          path={CHAT_CONVERSATIONS}
          component={NoAccessPage || Conversation}
          condition={is_admin}
        />
        <Route to={ERROR_ROUTE} component={NoAccessPage} />
      </Switch>
    </Suspense>
  );
}

export default connect(null, {
  ...auth.actions,
})(BasePage);
// export default connect(null, auth.actions)(BasePage);
