import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import useRequest from '../../../../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../../../../_metronic/_helpers/useConfig';
import {
  API_CREATE_VISIT_SHEET_CONTENT_LINK,
  API_UPDATE_VISIT_SHEET_CONTENT_LINK,
} from '../../../../../../constants/api';
import Edit from '@material-ui/icons/Edit';
import Trash from '@material-ui/icons/Delete';
import ReactPlayer from 'react-player';

const Link = ({
  data,
  edit,
  parentClasses,
  label,
  required,
  labelSuffix,
  classes,
  disabled,
  activeContent,
  setContent,
  setReady,
  handlerSetLayout,
  rank,
  setNewModule,
}) => {
  const [object, setObject] = useState(
    data ? activeContent[data.table_name].find((x) => x.id === data.id) : null
  );
  const [modify, setModify] = useState(!data);
  const [handleRequest] = useRequest();
  const config = useConfig();

  useEffect(() => {
    setObject(data ? activeContent[data.table_name].find((x) => x.id === data.id) : null);
  }, [activeContent, data]);

  const handleSubmit = (values, formikBag) => {
    setReady(false);
    if (data) {
      handleRequest(
        'put',
        API_UPDATE_VISIT_SHEET_CONTENT_LINK(activeContent.id, data.id),
        values,
        config,
        (dataRes, status) => {
          if (status === 'Done') {
            setContent(dataRes);
            setModify(false);
            setReady(true);
          } else if (status === 'Error') {
            setModify(false);
            setReady(true);
          }
        }
      );
    } else {
      handleRequest(
        'post',
        API_CREATE_VISIT_SHEET_CONTENT_LINK(activeContent.id),
        values,
        config,
        (dataRes, status) => {
          if (status === 'Done') {
            const layout = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
            layout[rank] = [
              {
                sortable_item: 'link',
                table_name: 'visit_sheet_content_has_links',
                id: dataRes.id,
              },
            ];
            handlerSetLayout(layout);
            setModify(false);
            setReady(true);
          } else if (status === 'Error') {
            setModify(false);
            setReady(true);
          }
        }
      );
    }
  };

  const handleDelete = () => {
    if (data) {
      handleRequest(
        'delete',
        API_UPDATE_VISIT_SHEET_CONTENT_LINK(activeContent.id, data.id),
        {},
        config,
        (dataRes, status) => {
          if (status === 'Done') {
            const layout = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
            delete layout[rank];
            handlerSetLayout(layout);
            setModify(false);
            setReady(true);
          } else if (status === 'Error') {
            setModify(false);
            setReady(true);
          }
        }
      );
    }
  };

  return edit ? (
    <Formik
      initialValues={{link: object?.url || '', link_title: object?.link_title || ''}}
      onSubmit={handleSubmit}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({handleSubmit, ...subProps}) => (
        <Form className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
          <div className={'form-group fv-plugins-icon-container ' + parentClasses}>
            {label && (
              <div className="d-flex justify-content-between mt-n5">
                <label className="font-size-h6 text-dark pt-5">
                  {label}
                  {!modify && (
                    <>
                      <Edit
                        fontSize="medium"
                        className="mx-5"
                        onClick={() => setModify(true)}
                      />
                      <Trash fontSize="medium" htmlColor="red" onClick={handleDelete} />
                    </>
                  )}
                </label>
                {labelSuffix}
              </div>
            )}
            <div className="w-100 d-flex align-items-center">
              {modify ? (
                <div className="w-100">
                  <div className="w-100 rounded p-2">
                    <Field
                      onChange={subProps.handleChange}
                      name={'link_title'}
                      placeholder={"L'intitulé de lien"}
                      type={'text'}
                      className={`form-control form-control-solid h-auto py-5 px-6 ${classes}`}
                      disabled={disabled}
                    />
                  </div>
                  <div className="w-100 rounded p-2">
                    <Field
                      onChange={subProps.handleChange}
                      name={'link'}
                      placeholder={'https://www.google.com'}
                      type={'url'}
                      className={`form-control form-control-solid h-auto py-5 px-6 ${classes}`}
                      disabled={disabled}
                    />
                  </div>
                </div>
              ) : object ? (
                <>
                  {ReactPlayer.canPlay(object?.url || '') ? (
                    <ReactPlayer
                      className="react-player__custom my-10"
                      width="100%"
                      url={object?.url}
                      controls
                    />
                  ) : (
                    <ul className="my-5">
                      <li>
                        <a target="_blank" href={object?.url} rel="noreferrer">
                          {object?.link_title || object?.url}
                        </a>
                      </li>
                    </ul>
                  )}
                </>
              ) : null}
              <div className="d-flex align-items-center px-2" style={{gap: 10}}>
                {modify && (
                  <>
                    <CheckCircleOutline
                      color="primary"
                      fontSize="large"
                      onClick={handleSubmit}
                    />
                    <HighlightOff
                      color="error"
                      fontSize="large"
                      onClick={() => {
                        setNewModule();
                        setModify(false);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : object ? (
    <>
      {ReactPlayer.canPlay(object?.url || '') ? (
        <ReactPlayer
          className="react-player__custom my-10"
          width="100%"
          url={object?.url}
          controls
        />
      ) : (
        <ul className="my-5">
          <li>
            <a target="_blank" href={object?.url} rel="noreferrer">
              {object?.link_title || object?.url}
            </a>
          </li>
        </ul>
      )}
    </>
  ) : null;
};

export default Link;
