import React, {useState} from 'react';
import TouristicSiteEdit from '../../../TouristicSites/ToursitcSitesEdit/TouristicSitesEdit';

const Item = ({el, onClick, ...props}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="d-flex justify-content-between align-items-center my-1 px-lg-3 bg-light">
      {el.rank && <span>{el.rank}</span>}
      <img
        src={el.medias.filter((el) => el.content_type.includes('image'))[0]?.url || ''}
        alt={el.title_fr}
        height={50}
        width={50}
      />
      <div className="w-75 text-truncate user-select-none" {...props}>
        <span>{el.title_fr}</span>
      </div>
      <button className="btn btn-outline-none" onClick={() => setIsOpen(true)}>
        Modifier
      </button>
      <button className="btn btn-outline-none btn-danger" onClick={onClick}>
        X
      </button>
      {isOpen && (
        <TouristicSiteEdit show={true} id={el.id} onHide={() => setIsOpen(false)} />
      )}
    </div>
  );
};

export default Item;
