import React from 'react';
import MySelect from '../../../../../_metronic/layout/components/form/select/select';
import MyInput from '../../../../../_metronic/layout/components/form/input/input';
import {useSelector} from 'react-redux';
import ActivityForm from '../../ActivityForm';
import InputTouristicSite from '../../../../../_metronic/layout/components/form/address/inputTouristicSite';
import MyCheckbox from '../../../../../_metronic/layout/components/form/checkbox/checkbox';
import MyTextArea from '../../../../../_metronic/layout/components/form/textarea/textarea';
import FileInputBase from '../../../../../_metronic/layout/components/form/fileInput/fileInputBase';
import InputMask from '../../../../../_metronic/layout/components/form/input/inputMask';
import ActivityFormInitialValues from '../Formfield/ActivityFormInitialValues';
import ActivityValidationSchema from '../Formfield/ActivityValidationSchema';
import {Form, Formik} from 'formik';
import FileInputAsync from '../../../../../_metronic/layout/components/form/fileInput/fileInputAsync';
import {useHistory} from 'react-router-dom';

const {
  formField: {
    title: titleField,
    touristicSites,
    description,
    published,
    password,
    medias,
  },
} = ActivityForm;

const CreateActivityInfo = ({title, loading, ...subProps}) => {
  const history = useHistory();
  const {is_admin, is_super_manager, is_manager, user} = useSelector(
    (state) => state.auth
  );

  const handleQuit = () => {
    history.goBack();
  };
  return (
    <Formik
      initialValues={subProps.activity || ActivityFormInitialValues[0]}
      validationSchema={ActivityValidationSchema[0]}
      onSubmit={subProps.handleSubmit}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({handleSubmit, validateForm, ...props}) => {
        return (
          <>
            <div className="col-xl-12 col-xxl-10">
              <Form
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_form"
              >
                <div className="row justify-content-center">
                  <div className="col-xl-9">
                    {props.status && (
                      <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                          Une erreur de type {props.status.code} est survenue...
                          <p>Message: {props.status.msg}</p>
                        </div>
                      </div>
                    )}
                    {!props.isValid && props.submitCount > 0 && (
                      <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                          Vérifiez que toutes les données entrées sont justes...
                        </div>
                      </div>
                    )}
                    <div
                      className="my-5 step"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <h5 className="text-dark font-weight-bold mb-10">{title}</h5>
                    </div>
                    <div className="row m-0">
                      {is_admin && (
                        <InputTouristicSite
                          onChange={props.onChange}
                          parentClasses="w-100"
                          {...props}
                          {...touristicSites}
                          isDisabled={!!subProps.activity}
                        />
                      )}
                      {is_super_manager && (
                        <MySelect
                          {...touristicSites}
                          classNames="w-100"
                          {...props}
                          value={
                            user?.agencies[0]?.touristic_sites
                              .map((el) => ({
                                label: `${el.id} - ${el.title}`,
                                value: el.id,
                              }))
                              .filter(
                                (el) => el.value === props.values[touristicSites.name]
                              )[0] || null
                          }
                          options={user?.agencies[0]?.touristic_sites.map((el) => ({
                            label: `${el.id} - ${el.title}`,
                            value: el.id,
                          }))}
                          isDisabled={!!subProps.activity}
                        />
                      )}

                      {is_manager && (
                        <MySelect
                          {...touristicSites}
                          classNames="w-100"
                          {...props}
                          value={
                            user?.touristic_sites
                              .map((el) => ({
                                label: `${el.id} - ${el.title}`,
                                value: el.id,
                              }))
                              .filter(
                                (el) => el.value === props.values[touristicSites.name]
                              )[0] || null
                          }
                          options={user?.touristic_sites.map((el) => ({
                            label: `${el.id} - ${el.title}`,
                            value: el.id,
                          }))}
                          isDisabled={!!subProps.activity}
                        />
                      )}
                      <MyInput {...props} {...titleField} parentClasses="w-100" />
                      <InputMask {...props} parentClasses="w-100" {...password} />
                      {subProps.activity ? (
                        <FileInputAsync
                          {...props}
                          parentClasses="w-100"
                          name={'medias'}
                          label={'Médias'}
                          field={'medias[]'}
                          multiple={true}
                          accept="image/*,audio/*,video/*"
                          api_upload={`/api/v1/pro/activities/${subProps.activity.id}/upload`}
                          api_delete={`/api/v1/pro/activities/${subProps.activity.id}/image`}
                        />
                      ) : (
                        <FileInputBase
                          parentClasses="w-100"
                          appendImages="touristic_site"
                          {...props}
                          {...medias}
                        />
                      )}
                      <MyTextArea parentClasses="w-100" {...props} {...description} />
                      <MyCheckbox {...props} {...published} />
                    </div>
                    <div className="d-flex justify-content-between border-top pt-10 mt-15">
                      <div className="mr-2">
                        <button
                          type="button"
                          id="prev-step"
                          className="btn btn-light-primary font-weight-bolder px-9 py-4"
                          onClick={handleQuit}
                        >
                          Quitter
                        </button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary font-weight-bolder px-9 py-4"
                          disabled={loading}
                        >
                          {loading ? (
                            <div className="spinner-border text-white" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            'Suivant'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateActivityInfo;
