import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Edit from '@material-ui/icons/Edit';
import {API_EDIT_VISIT_SHEET_CONTENT} from '../../../../../../constants/api';
import useRequest from '../../../../../../../_metronic/_helpers/useRequest';
import useConfig from '../../../../../../../_metronic/_helpers/useConfig';

const TitleInput = ({
  data,
  edit,
  parentClasses,
  label,
  required,
  labelSuffix,
  classes,
  disabled,
  activeContent,
  setContent,
  setReady,
  setNewModule,
}) => {
  const [modify, setModify] = useState(false);
  const [handleRequest] = useRequest();
  const config = useConfig();

  const handleSubmit = (values, formikBag) => {
    setReady(false);
    handleRequest(
      'put',
      API_EDIT_VISIT_SHEET_CONTENT(activeContent.id),
      values,
      config,
      (dataRes, status) => {
        if (status === 'Done') {
          setContent(dataRes);
          setModify(false);
          setReady(true);
        } else if (status === 'Error') {
          setModify(false);
          setReady(true);
        }
      }
    );
  };

  return edit ? (
    <Formik
      initialValues={{title: data}}
      onSubmit={handleSubmit}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({handleSubmit, ...subProps}) => (
        <Form className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
          <div className={'form-group fv-plugins-icon-container ' + parentClasses}>
            {label && (
              <div className="d-flex justify-content-between mt-n5">
                <label className="font-size-h6 text-dark pt-5">
                  {label}
                  {!modify && (
                    <Edit
                      className="mx-5"
                      fontSize="medium"
                      onClick={() => setModify(true)}
                    />
                  )}
                  {required ? ' *' : ''}
                </label>
                {labelSuffix}
              </div>
            )}
            <div className="w-100 d-flex">
              {modify ? (
                <Field
                  onChange={subProps.handleChange}
                  name={'title'}
                  placeholder={'Titre'}
                  type={'text'}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${classes}`}
                  disabled={disabled}
                />
              ) : (
                <h1>{data || 'Pas de Titre'}</h1>
              )}

              <div className="d-flex align-items-center px-2" style={{gap: 10}}>
                {modify && (
                  <>
                    <CheckCircleOutline
                      color="primary"
                      fontSize="large"
                      onClick={handleSubmit}
                    />
                    <HighlightOff
                      color="error"
                      fontSize="large"
                      onClick={() => {
                        setNewModule();
                        setModify(false);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <h1>{data || 'Pas de Titre'}</h1>
  );
};

export default TitleInput;
