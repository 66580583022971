import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {put, takeLatest} from 'redux-saga/effects';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  Categories: '[Categories] load',
  Agencies: '[Agencies] load',
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  categories: [],
  agencies: [],
  is_super_manager: false,
  is_manager: false,
  is_admin: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: 'v709-demo3-auth',
    whitelist: [
      'user',
      'authToken',
      'managers',
      'is_super_manager',
      'is_manager',
      'is_admin',
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const {token, account, is_super_manager, is_manager, is_admin} = action.payload;

        return {
          ...state,
          authToken: token,
          user: account,
          is_super_manager: is_super_manager,
          is_manager: is_manager,
          is_admin: is_admin,
        };
      }

      case actionTypes.Register: {
        const {authToken} = action.payload;

        return {authToken, user: undefined};
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        storage.removeItem('persist:root');
        storage.removeItem('persist:v709-demo3-auth');
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const {user} = action.payload;
        return {...state, user};
      }

      case actionTypes.Categories: {
        return {...state, categories: action.payload};
      }

      case actionTypes.Agencies: {
        return {...state, agencies: action.payload};
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({type: actionTypes.Login, payload: authToken}),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: {authToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: {user},
  }),
  fulfillUser: (user) => ({type: actionTypes.UserLoaded, payload: {user}}),

  addCategories: (data) => ({type: actionTypes.Categories, payload: data}),

  addAgencies: (data) => ({type: actionTypes.Agencies, payload: data}),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    // yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {});
}
