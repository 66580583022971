// Auth

export const API_LOGIN = '/api/v1/pro/managers/login';

export const API_FORGOT_PASSWORD = '';

export const API_RESET_PASSWORD_CHECK = '';

export const API_RESET_PASSWORD = '';

// Agency

export const API_AGENCY_CREATION = '/api/v1/pro/agencies';

export const API_GET_ALL_AGENCIES = `/api/v1/pro/agencies?`;

export const API_GET_AGENCY = (id) => `/api/v1/pro/agencies/${id}`;

export const API_UPDATE_AGENCY = (id) => `/api/v1/pro/agencies/${id}`;

export const API_REMOVE_AGENCY = (id) => `/api/v1/pro/agencies/${id}`;

export const API_GET_CONFIG_STRIPE = '/api/agency/stripeconfig';

export const API_GET_ACCESS_STRIPE = '/api/agency/stripe';

// Profile

export const API_GET_PROFILE = '/api/v1/users/profile';

export const API_EDIT_PROFILE = '/api/v1/users/profile';

// Touristic sites

export const API_GET_ACTIVITIES_TS = (id) => `/api/v1/touristic_sites/${id}/details`;

export const API_CREATE_TOURISTIC_SITE = '/api/v1/touristic_sites/suggestion';

export const API_UPDATE_TOURISTIC_SITE = (id) => `/api/v1/pro/touristic_sites/${id}`;

export const API_PUBLIC_UPDATE_TOURISTIC_SITE = (id) => `/api/v1/touristic_sites/${id}`;

export const API_REMOVE_TOURISITC_SITE = (id) => `/api/v1/pro/touristic_sites/${id}`;

export const API_GET_ALL_TOURISTIC_SITES = `/api/v1/pro/touristic_sites?`;

export const API_GET_TOURISTIC_SITE_SUGGESTIONS_DETAILS = '/api/v1/pro/suggestions?';

export const API_GET_TOURISTIC_SITE_SUGGESTIONS_DETAILS_BY_ID = (id) =>
  `/api/v1/pro/suggestions/${id}`;

export const API_PUBLISH_TOURISTIC_SITE = (id) =>
  `/api/v1/pro/touristic_sites/${id}/publish`;

export const API_GET_ONE_TOURISTIC_SITE = (id) => `/api/v1/pro/touristic_sites/${id}`;

export const API_GET_ONE_TOURISTIC_SITE_AUTH = (id) => `/api/v1/touristic_sites/${id}`;

export const API_V1_GET_ALL_TOURISTIC_SITES = ({
  per_page,
  current_page,
  provider,
  city,
  search,
}) => {
  const url = new URLSearchParams();
  if (per_page) url.append('per_page', per_page);
  if (current_page) url.append('current_page', current_page);
  if (provider) url.append('provider', provider);
  if (city) url.append('city', city);
  if (search) url.append('search', removeAccents(search).toLowerCase());

  return `/api/v1/touristic_sites?${url}`;
};

export const API_GET_TOURISTIC_SITE_VIEWS = (id, filter) => {
  const url = new URLSearchParams();

  if (filter) url.append('period', filter);

  return `/api/touristic_site/${id}/viewsstats?${url}`;
};

export const API_GET_TOURISTIC_SITE_TICKETS_SELL = (id, filter) => {
  const url = new URLSearchParams();

  if (filter) url.append('period', filter);

  return `/api/touristic_site/${id}/ordersstats?${url}`;
};

export const API_TOURISTIC_SITE_SEARCH = (title) => {
  const url = new URLSearchParams();
  if (title) url.append('title_fr', removeAccents(title));
  return `/api/v1/touristic_sites/search_bar/search?${url}`;
};
// Roadtrips

export const API_UPDATE_ROAD_TRIP = (id) => `/api/v1/pro/road_trips/${id}`;

export const API_REMOVE_ROAD_TRIP = (id) => `/api/v1/pro/road_trips/${id}`;

export const API_GET_ONE_ROAD_TRIP = (id) => `/api/v1/road_trips/${id}`;

export const API_GET_ALL_ROAD_TRIPS = `/api/v1/pro/road_trips?`;

export const API_CREATE_TRIP = `/api/v1/pro/road_trips`;

export const API_GET_TOURISTIC_SITES_FILTERS = () =>
  `/api/v1/pro/touristic_sites/filters`;

// Experiences

export const API_GET_ALL_EXPERIENCES = `/api/v1/experiences?`;

export const API_UPDATE_TS = (id) => `/api/v1/touristic_sites/${id}`;

export const API_CREATE_TS = `/api/v1/touristic_sites`;

export const API_CREATE_EXPERIENCE = `/api/v1/pro/experiences`;

export const API_UPDATE_EXPERIENCE = (id) => `/api/v1/pro/experiences/${id}`;

export const API_GET_ONE_EXPERIENCE = (id) => `/api/v1/experiences/${id}`;

// Events

export const API_CREATE_EVENT = '/api/touristic_site/event/create';

export const API_GET_EVENTS = (touristic_site_id) =>
  `/api/touristic_site/${touristic_site_id}/events`;

export const API_DELETE_EVENT = (touristic_site_id, event_id) =>
  `/api/touristic_site/${touristic_site_id}/event/${event_id}/delete`;

export const API_GET_EVENT = (touristic_site_id, event_id) =>
  `/api/touristic_site/${touristic_site_id}/event/${event_id}`;

export const API_EVENT_EDIT = (touristic_site_id, event_id) =>
  `/api/touristic_site/${touristic_site_id}/event/${event_id}/edit`;

// User

export const API_GET_ALL_USERS = (role) => {
  if (role) {
    return `/api/v1/pro/users?role=${role}&`;
  } else {
    return '/api/v1/pro/users?';
  }
};

export const API_GET_ONE_USER = (id) => `/api/v1/pro/users/${id}`;

export const API_GET_USER_DETAILS = '/api/v1/pro/user/detail';

export const API_UPDATE_USER = (id) => `/api/v1/pro/users/${id}`;

export const API_UPDATE_MANAGER = (id) => `/api/v1/pro/users/${id}/manager`;

export const API_INVITE_MANAGER = '/api/v1/pro/users/invite';

export const API_REMOVE_MANAGER = (id) => `/api/v1/pro/users/${id}/remove_role`;

export const API_POST_PARTNER = '/api/v1/pro/partner';

// Activity

export const API_CREATE_ACTIVITY = '/api/v1/pro/activities';

export const API_UPDATE_ACTIVITY = (id) => `/api/v1/pro/activities/${id}`;

export const API_REMOVE_ACTIVITY = (id) => `/api/v1/pro/activities/${id}`;

export const API_GET_ALL_ACTIVITIES = '/api/v1/pro/activities?';

export const API_GET_ONE_ACTIVITY = (id) => `/api/v1/pro/activities/${id}`;

// Reviews

export const API_UPDATE_REVIEW = (id, type) =>
  `/api/v1/pro/${type === 'ts' ? 'reviews_ts' : 'reviews_rt'}/${id}`;

export const API_REMOVE_REVIEW = (id, type) =>
  `/api/v1/pro/${type === 'ts' ? 'reviews_ts' : 'reviews_rt'}/${id}`;

export const API_GET_ALL_REVIEWS = (type) =>
  `/api/v1/pro/${type === 'ts' ? 'reviews_ts' : 'reviews_rt'}?`;

export const API_GET_ONE_REVIEW = (id, type) =>
  `/api/v1/pro/${type === 'ts' ? 'reviews_ts' : 'reviews_rt'}/${id}`;

// Tickets

export const API_GET_ALL_TICKETS_PAGINATION = '/api/v1/pro/tickets?';

export const API_GET_ALL_TICKETS = (ts_id) => `/api/v1/pro/tickets/all/${ts_id}`;

export const API_GET_TICKET = (id) => `/api/v1/pro/tickets/${id}`;

export const API_CREATE_TICKET = '/api/v1/pro/tickets';

export const API_UPDATE_TICKET = (id) => `/api/v1/pro/tickets/${id}`;

export const API_REMOVE_TICKET = (id) => `/api/v1/pro/tickets/${id}`;

// Visit Sheets

export const API_CREATE_VISIT_SHEET = `/api/v1/pro/visit_sheets`;

export const API_CREATE_CONTENT_VISIT_SHEET = `/api/v1/pro/visit_sheets/content`;

export const API_MOVE_VISIT_SHEET = `/api/v1/pro/visit_sheets/move`;

export const API_DELETE_VISIT_SHEET = (visit_sheet_id) =>
  `/api/v1/pro/visit_sheets/${visit_sheet_id}`;

export const API_DELETE_VISIT_SHEET_CONTENT = (visit_sheet_content_id) =>
  `/api/v1/pro/visit_sheet_contents/${visit_sheet_content_id}`;

export const API_EDIT_VISIT_SHEET_CONTENT = (visit_sheet_content_id) =>
  `/api/v1/pro/visit_sheet_contents/${visit_sheet_content_id}`;

export const API_CREATE_VISIT_SHEET_CONTENT_TEXT = (visit_sheet_content_id) =>
  `/api/v1/pro/visit_sheet_contents/${visit_sheet_content_id}/text`;

export const API_CREATE_VISIT_SHEET_CONTENT_LINK = (visit_sheet_content_id) =>
  `/api/v1/pro/visit_sheet_contents/${visit_sheet_content_id}/link`;

export const API_UPDATE_VISIT_SHEET_CONTENT_TEXT = (visit_sheet_content_id, text_id) =>
  `/api/v1/pro/visit_sheet_contents/${visit_sheet_content_id}/text/${text_id}`;

export const API_UPDATE_VISIT_SHEET_CONTENT_LINK = (visit_sheet_content_id, link_id) =>
  `/api/v1/pro/visit_sheet_contents/${visit_sheet_content_id}/link/${link_id}`;

// Resource Accesses
export const API_RESOURCE_ACCESSES_BATCH = `/api/v1/resource_accesses/batch`;

export const API_RESOURCE_ACCESSES = `/api/v1/resource_accesses`;

export const API_DELETE_RESOURCE_ACCESSES = (id) => `/api/v1/resource_accesses/${id}`;

export const API_CANCEL_RESOURCE_ACCESSES = (id) =>
  `/api/v1/resource_accesses/${id}/cancel`;
// Other

export const API_GET_CATEGORIES = '/api/v1/categories';

export const API_GET_GENDERS = '/public_api/gender/types';

export const API_SEARCH_CITIES = (name) => {
  const url = new URLSearchParams();

  url.append('name', name);

  return `/public_api/location/citysearch?${url}`;
};

const accentsMap = new Map([
  ['A', 'Á|À|Ã|Â|Ä'],
  ['a', 'á|à|ã|â|ä'],
  ['E', 'É|È|Ê|Ë'],
  ['e', 'é|è|ê|ë'],
  ['I', 'Í|Ì|Î|Ï'],
  ['i', 'í|ì|î|ï'],
  ['O', 'Ó|Ò|Ô|Õ|Ö'],
  ['o', 'ó|ò|ô|õ|ö'],
  ['U', 'Ú|Ù|Û|Ü'],
  ['u', 'ú|ù|û|ü'],
  ['C', 'Ç'],
  ['c', 'ç'],
  ['N', 'Ñ'],
  ['n', 'ñ'],
]);

const reducer = (acc, [key]) => acc.replace(new RegExp(accentsMap.get(key), 'g'), key);

export const removeAccents = (text) => [...accentsMap].reduce(reducer, text);
