import React, {useCallback, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import {useSubheader} from '../../../_metronic/layout';
import {EVENT_DELETE, EVENT_EDIT, EVENTS} from '../../constants/constant-routes';
import EventDelete from './EventDelete/EventDelete';
import EventsForm from './EventsForm';
import CategoryIdentifier from '../../../_metronic/layout/components/form/category/categoryIdentifier';
import {Card} from 'react-bootstrap';
import MyTable from '../../../_metronic/layout/components/table/MyTable';
import MySelect from '../../../_metronic/layout/components/form/select/select';
import useRequest from '../../../_metronic/_helpers/useRequest';
import {API_GET_EVENTS} from '../../constants/api';
import useConfig from '../../../_metronic/_helpers/useConfig';
import EventEdit from './EventEdit/EventEdit';
import * as events from './_redux/eventRedux';

const {
  formField: {title, category, description, start_date, end_date},
} = EventsForm;

const Events = ({history, getEvent}) => {
  const subheader = useSubheader();
  const ref = React.createRef();
  useEffect(() => {
    subheader.setTitle('Évènements');
  }, [subheader]);
  const {touristicsites} = useSelector((state) => state.touristicSites);
  const parsedTouristicSites = React.useMemo(
    () =>
      touristicsites.map((el) => ({
        label: el.title_fr,
        value: el.id,
      })),
    [touristicsites]
  );
  const [touristicSite, setTouristicSite] = React.useState();
  const {events} = useSelector((state) => state.ts_events);
  const [handleRequest] = useRequest();
  const config = useConfig();

  const categoryComponent = useCallback((row) => {
    return (
      <CategoryIdentifier
        categoryID={row.categories && row.categories[0]?.id}
        categories={row.categories}
      />
    );
  }, []);

  const startDateComponent = useCallback((row) => {
    return (
      <p className="m-0">
        {new Date(row[start_date.name]).toLocaleString()} -{' '}
        {new Date(row[end_date.name]).toLocaleString()}
      </p>
    );
  }, []);

  const columns = [
    {
      field: 'row',
      title: '#',
    },
    {
      field: title.name,
      title: title.label,
      filtering: false,
      sorting: false,
    },
    {
      field: category.name,
      title: category.label,
      render: categoryComponent,
      filtering: false,
      sorting: false,
    },
    {
      field: description.name,
      title: description.label,
      filtering: false,
      sorting: false,
    },
    {
      field: start_date.name,
      title: start_date.label,
      render: startDateComponent,
      filtering: false,
      sorting: false,
    },
  ];

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Modifier',
      onClick: (event, rowData) =>
        history.push(EVENT_EDIT(touristicSite.value, rowData.id)),
    },
    {
      icon: 'delete',
      tooltip: 'Supprimer',
      onClick: (event, rowData) =>
        history.push(EVENT_DELETE(touristicSite.value, rowData.id)),
    },
  ];

  React.useEffect(() => {
    if (!touristicSite?.value) {
      getEvent([]);
      return;
    }

    handleRequest(
      'get',
      API_GET_EVENTS(touristicSite.value),
      {},
      config,
      (data, status) => {
        if (status === 'Done') {
          getEvent(data.events);
        } else if (status === 'Error') {
          console.error(data);
        }
      }
    );
    // eslint-disable-next-line
  }, [touristicSite]);

  const handleTouristicSite = (v) => {
    setTouristicSite(v);
  };

  return (
    <Card className="p-4 rounded-lg shadow-sm">
      <h3>Gestion des évènements d'un site touristique</h3>
      <p>Gérer tous les évènements de vos sites touristiques</p>
      <MySelect
        value={touristicSite}
        options={parsedTouristicSites}
        placeholder="Choisissez un site touristique"
        noOptionsMessage={() => 'Aucun site touristique trouvé.'}
        onChange={(v) => handleTouristicSite(v)}
      />
      <MyTable ref={ref} columns={columns} content={events} actions={actions} />
      <Switch>
        <Route path={EVENT_DELETE(':touristic_site_id', ':event_id')} exact>
          {({history, match}) => (
            <EventDelete
              show={match != null}
              id={match && match.params?.touristic_site_id}
              event_id={match && match.params?.event_id}
              onHide={() => {
                history.push(EVENTS);
              }}
            />
          )}
        </Route>
        <Route path={EVENT_EDIT(':touristic_site_id', ':event_id')}>
          {({history, match}) => (
            <EventEdit
              show={match != null}
              id={match?.params?.event_id}
              touristicSiteID={match?.params?.touristic_site_id}
              onHide={() => {
                history.push(EVENTS);
              }}
            />
          )}
        </Route>
      </Switch>
    </Card>
  );
};

export default withRouter(connect(null, events.actions)(Events));
