import React from 'react';
import {Divider} from '@material-ui/core';

const PriceList = ({selectedCTS, setSelectedCTS}) => {
  return (
    <>
      <label htmlFor="price_list" className="mt-3">
        Tarifs
      </label>
      {selectedCTS.price_list.map((price, index) => (
        <div key={index} className="d-flex flex-column">
          <div className="d-flex flex-column">
            <label htmlFor="price_list_text" className="mt-3">
              Texte
            </label>
            <input
              name="price_list_text"
              type="text"
              value={price.text || ''}
              onChange={(e) =>
                setSelectedCTS((state) => {
                  let tmp = [...state.price_list];
                  tmp[index].text = e.target.value;
                  return {...state, price_list: [...tmp]};
                })
              }
            />
            <label htmlFor="price_list_price" className="mt-3">
              Prix
            </label>
            <input
              name="price_list_price"
              type="number"
              min={0}
              step={0.01}
              value={price.price || ''}
              onChange={(e) =>
                setSelectedCTS((state) => {
                  let tmp = [...state.price_list];
                  tmp[index].price = e.target.value;
                  return {...state, price_list: [...tmp]};
                })
              }
            />
          </div>
          <button
            className="btn btn-outline-none btn-danger"
            type="button"
            onClick={() =>
              setSelectedCTS((state) => {
                return {
                  ...state,
                  price_list: [...state.price_list.filter((el, i) => i !== index)],
                };
              })
            }
          >
            X
          </button>
          <Divider className="mt-2" />
        </div>
      ))}
      <div className="mt-3 d-flex justify-content-center w-100">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            setSelectedCTS((state) => {
              return {
                ...state,
                price_list: [...state.price_list, {text: '', price: '', currency: 'EUR'}],
              };
            })
          }
        >
          +
        </button>
      </div>
    </>
  );
};

export default PriceList;
